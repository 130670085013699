
import { useNavigate } from "react-router-dom";

import { IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";

import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useFacturaStore } from "../../../../hooks";

import Swal from 'sweetalert2';






export const TablaDeFacturas = () => {



    const { facturas, updateEstadoFactura, updatePagoFactura } = useFacturaStore();
    const navigate = useNavigate();



    const verInfoFactura = (id) => {
        navigate(`/admin/factura/${ id }`);
    }


    const updateFactura = ( f ) => {
        Swal.fire({
        title: (f.estado) ? 'Archivar Factura' : 'Activar Factura',
        text: `¿Esta Seguro De Hacer Esta Acción?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si' 
        }).then( ( result ) => {
            if(result.value){
            Swal.fire({
                title: (f.estado) ? 'Esta a Punto De Archivar La Factura' : 'Esta a Punto De Activar La Factura',
                text: `Asegurese de Hacer Esta Acción.`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: (f.estado) ? 'Si, Archivar' : 'Si Activar' 
            }).then( ( result ) => {
                if(result.value){
                updateEstadoFactura(f.id);
                }
            });
            }
        });
    }


    const cambiarPago = (e, uid) => {
        Swal.fire({
            title: 'Cambio de Pago',
            text: `¿Esta Seguro De Hacer El Cambio De Pago?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
            }).then( ( result ) => {
            if(result.value){
                updatePagoFactura({
                    pago: e.target.value,
                    uid
                });
            }
        });
    }


    const verAbonosFactura = (uid) => {
        navigate(`/admin/abonos/${ uid }`);
    }


    return (


        <TableContainer>
            <Table sx={{ minWidth: 700, }} aria-label="customized table">
                <TableHead >
                    <TableRow>
                        <TableCell>A.</TableCell>
                        <TableCell>#FACTURA</TableCell>
                        {/* <TableCell>LOTE</TableCell> */}
                        <TableCell>FORMA DE PAGO</TableCell>
                        {/* <TableCell>DIAS</TableCell> */}
                        <TableCell>PAGO</TableCell>
                        <TableCell>CREACION DE FACTURA</TableCell>
                        <TableCell>VENCIMIENTO</TableCell>
                        <TableCell>OPCIONES</TableCell>
                    </TableRow>
                </TableHead>

                { facturas &&(
                <TableBody>
                { facturas.map( ( f ) => (
                <TableRow key={ f.id } >
                    <TableCell>
                    { ( f.estado ) 
                    ? <button className='boton-estado trru'
                        onClick={ () => updateFactura(f) }
                        >
                        </button>
                    : <button className='boton-estado fall'
                        onClick={ () => updateFactura(f) }
                        >
                        </button>
                    } 
                    </TableCell>
                    <TableCell>{ f.numero }</TableCell>   
                    {/* <TableCell>{ f.lote?.lote }</TableCell>    */}
                    <TableCell>{ f.formaPago }</TableCell>
                    {/* <TableCell>{ f.cuantosDias }</TableCell> */}
                    <TableCell>   
                        <TextField required
                            size="small"
                            select
                            fullWidth
                            value={ f.pago }
                            onChange={ (e) => cambiarPago( e, f.id ) }
                        >
                            <MenuItem value='PENDIENTE'>
                                PENDIENTE
                            </MenuItem>
                            <MenuItem value='REALIZADO'>
                                REALIZADO
                            </MenuItem>
                        </TextField>
                    </TableCell>
                    <TableCell>{ f.createdAt }</TableCell>
                    <TableCell>
                        { 
                        (f.vencimiento)
                        ? f.vencimiento
                        : <span>----/----/----</span> 
                        }
                    </TableCell>
                    <TableCell sx={{ width:'200px' }}>
                        <IconButton onClick={ () => verInfoFactura(f.id) } >    
                            <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                        </IconButton>

                        <IconButton onClick={ () => verAbonosFactura(f.id) } >    
                            <AddCircleIcon fontSize="small" sx={{ color: '#4caf50' }}/>
                        </IconButton>
                    </TableCell>

                </TableRow>
                ))
                }
                </TableBody>
                )}

            </Table>
        </TableContainer>



    )


    
}
