
import { useEffect, useState } from 'react';


import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { usePlanillaStore } from '../../../hooks';
import { dejarTresDecimales } from '../../../helpers/dejarDecimales';




export const TablaDecuentoPlanilla = () => {


    const { activePlanilla } = usePlanillaStore();



    const [descuentos, setDescuentos] = useState({
        descartes: '',
        ahogados: '',
        buches: '',
        mermas: '',
        subtotal: 0,
    });
    const { ahogados, buches, mermas, subtotal } = descuentos;


    useEffect(() => {
        if( activePlanilla ){
            const subtotal = activePlanilla.mermas.reduce( (total, can) => Number(total) + Number(can.cantidad), 0);
            const descartes = activePlanilla.mermas.filter( x => x.nombre === 'DESCARTES' ).reduce( (total, can) => total + can.cantidad, 0);
            const ahogados = activePlanilla.mermas.filter( x => x.nombre === 'AHOGADOS' ).reduce( (total, can) => total + can.cantidad, 0);
            const buches = activePlanilla.mermas.filter( x => x.nombre === 'BUCHES' ).reduce( (total, can) => total + can.cantidad, 0);
            const mermas = activePlanilla.mermas.filter( x => x.nombre === 'MERMAS' ).reduce( (total, can) => total + can.cantidad, 0);
            setDescuentos({
                descartes,
                ahogados,
                buches,
                mermas,
                subtotal,
            })
        }
    }, [activePlanilla])


    return (
        
        <>
            <Typography variant='h5' sx={{ mb:1, mt:5, fontWeight:'bold', textAlign:'center' }} >DESCUENTOS</Typography>

            <TableContainer sx={{mb:3}} >
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>Descartes</TableCell>    */}
                            <TableCell><Typography variant="body1">AHOGADOS</Typography></TableCell>
                            <TableCell><Typography variant="body1">BUCHES</Typography></TableCell>
                            <TableCell><Typography variant="body1">MERMAS</Typography></TableCell>
                            <TableCell><Typography variant="body1">TOTAL</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {/* <TableCell>{ descartes }Kg</TableCell>    */}
                            <TableCell><Typography variant="body2">{ ahogados }Kg</Typography></TableCell>   
                            <TableCell><Typography variant="body2">{ buches }Kg</Typography></TableCell>   
                            <TableCell><Typography variant="body2">{ mermas }Kg</Typography></TableCell>   
                            <TableCell><Typography variant="body2">{ dejarTresDecimales(subtotal) }Kg</Typography></TableCell> 
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    )





}
