
import { useEffect, useState } from "react";

import { Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useDistribucionStore, useSugeridoStore } from "../../../../hooks";

import moment from "moment";



export const SugeridosConsumos = () => {


    const { startLoadingSugerido, sugeridos } = useSugeridoStore();
    const { activeDistribucion } = useDistribucionStore();
    const [numero, setNumero] = useState(0);
    const [sugerido, setSugerido] = useState(0);



    useEffect(() => {
        if(activeDistribucion){
            startLoadingSugerido(activeDistribucion.genero);
        }
    }, [activeDistribucion])



    useEffect( () => {
        if(activeDistribucion){
            const today = moment();
            const diferenciaEnDias = today.diff(activeDistribucion.lote.createdAt, 'days');
            let weekSuggest = Math.ceil(diferenciaEnDias / 7);
            if(weekSuggest < 1){
                weekSuggest = 1;
            }
            if(weekSuggest > 6){
                weekSuggest = 6;
            }
            setNumero(weekSuggest); 
        }
    }, [activeDistribucion])


    useEffect( () => {
        if(activeDistribucion){

            const buscarCantidad = (suger) => {
                const total = activeDistribucion.cantidad - activeDistribucion.descuentos;
                for( const s of suger) {
                    if(s.semana === numero && activeDistribucion.genero === s.genero){
                        const resultado = (total * s.comidaGramos) / 1000;
                        const resp = Math.round(resultado / 40);
                        return resp;
                    }
                }
                return null;
            }
            setSugerido(buscarCantidad(sugeridos));
        }
    }, [activeDistribucion, sugeridos])



    return (


        <>

            <Grid container direccion='row' alignItems='center'>
                <Box flex={ 1 } />

                <Typography variant='h4' sx={{ mb:2, fontWeight:'bold' }} >Sugerido de Consumos { (sugerido) ? sugerido : 0 }</Typography>

                <Box flex={ 1 } />
            </Grid>

            
            { activeDistribucion &&
            <Grid container alignItems='center' justifyContent='center'>
                <Grid item xs={12} sm={10} sx={{ my: 3 }} >
                    <TableContainer>
                        <Table  aria-label="customized table">
                            <TableHead >
                                <TableRow>
                                    <TableCell colSpan={2}>TABLA DE CONSUMO { activeDistribucion.genero }</TableCell>
                                </TableRow>
                            </TableHead>
                            {
                            sugeridos &&
                            <TableBody >
                                {
                                sugeridos.map( s => (
                                <TableRow key={ s.id } style={{ background: (Number(s.semana) == numero) ? '#ff9700' : '' }}>
                                    <TableCell>SEMANA-{ s.semana }</TableCell>
                                    <TableCell>{ s.comidaGramos } gramos</TableCell>
                                </TableRow>  
                                ))
                                }             
                            </TableBody>
                            }
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            }

        </>


    )



}
