
import { createSlice } from "@reduxjs/toolkit";




export const modalImgSlice = createSlice({

    name: 'modal',
    initialState: {
        modalDistriPollos: false,
        modalDescuentos: false,
        modalMermas: false,
        modalConsumo: false,
        modalIngresoProductoBodegas: false,
    },
    reducers: {


        abrirModalDistribucion: ( state ) => {
            state.modalDistriPollos = true;
            state.modalDescuentos = false;
            state.modalMermas = false;
            state.modalConsumo = false;
            state.modalIngresoProductoBodegas = false;
        },

        cerrarModalDistribucion: ( state ) => {
            state.modalDistriPollos = false;
            state.modalDescuentos = false;
            state.modalMermas = false;
            state.modalConsumo = false;
            state.modalIngresoProductoBodegas = false;
        },

        abrirModalDescuentos: ( state ) => {
            state.modalDistriPollos = false;
            state.modalDescuentos = true;
            state.modalMermas = false;
            state.modalConsumo = false;
            state.modalIngresoProductoBodegas = false;
        },

        cerrarModalDescuentos: ( state ) => {
            state.modalDistriPollos = false;
            state.modalDescuentos = false;
            state.modalMermas = false;
            state.modalConsumo = false;
            state.modalIngresoProductoBodegas = false;
        },


        abrirModalMermas: ( state ) => {
            state.modalDistriPollos = false;
            state.modalDescuentos = false;
            state.modalMermas = true;
            state.modalConsumo = false;
            state.modalIngresoProductoBodegas = false;
        },

        cerrarModalMermas: ( state ) => {
            state.modalDistriPollos = false;
            state.modalDescuentos = false;
            state.modalMermas = false;
            state.modalConsumo = false;
            state.modalIngresoProductoBodegas = false;
        },



        abrirModalConsumo: ( state ) => {
            state.modalDistriPollos = false;
            state.modalDescuentos = false;
            state.modalMermas = false;
            state.modalConsumo = true;
            state.modalIngresoProductoBodegas = false;
        },

        cerrarModalConsumo: ( state ) => {
            state.modalDistriPollos = false;
            state.modalDescuentos = false;
            state.modalMermas = false;
            state.modalConsumo = false;
            state.modalIngresoProductoBodegas = false;
        },

        abrirModalIngresoProductos: ( state ) => {
            state.modalDistriPollos = false;
            state.modalDescuentos = false;
            state.modalMermas = false;
            state.modalConsumo = false;
            state.modalIngresoProductoBodegas = true;
        },

        cerrarModalIngresoProductos: ( state ) => {
            state.modalDistriPollos = false;
            state.modalDescuentos = false;
            state.modalMermas = false;
            state.modalConsumo = false;
            state.modalIngresoProductoBodegas = false;
        },

    }
});





export const { abrirModalDistribucion, cerrarModalDistribucion, abrirModalDescuentos, cerrarModalDescuentos, abrirModalMermas, cerrarModalMermas, abrirModalConsumo, cerrarModalConsumo, abrirModalIngresoProductos,  cerrarModalIngresoProductos } = modalImgSlice.actions;
