import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import { onAddNewAbonoPlanilla, onLoandingAbonosPlanilla } from "../store";
import { prepararFechaPedido, prepararFechaPedidoUno } from "../helpers/prepararFecha";
import Swal from "sweetalert2";




export const useAbonoPlanillaStore = () => {


    const dispatch = useDispatch();
    const { abonosPlanilla, activeAbonoPlanilla } = useSelector( state => state.abonoPlanilla );



    // const activeAbonoStart = async( uid ) => {
    //     try {
    //         const { data } = await granjaApi.get(`/abonof/uno/${ uid }`);
    //         dispatch( onSetActiveAbono( data.abono ) );
            
    //     } catch (error) {
    //         Swal.fire('Error', error.response.data.msg, 'error');
    //         console.log(error)
    //     }
    // }



    // const activeAbonoEliminar = async() => {
    //     dispatch( activeAbonoDelete() );
    // }



    const startSavingAbono = async( abono ) => {
        try {
            const { data } = await granjaApi.post('/abonoPl/new', abono );
            if( data.ok ){
                const ab = prepararFechaPedidoUno(data.abono);
                dispatch( onAddNewAbonoPlanilla( ab ) );
                Swal.fire('Exitoso', 'Abono Creada', 'success');
            }
        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    } 


    const listadoAbonosPlanilla = async(uid) => {
        try {
            const { data } = await granjaApi.get(`/abonoPl/porplanilla/${uid}`);
            const abonos = prepararFechaPedido(data.abonos);
            dispatch( onLoandingAbonosPlanilla(abonos) );
        } catch (error) {
            console.log(error);
        }
    }



    // const deleteAbono = async( abono ) => {
    //     try {
    //         const { data } = await granjaApi.put(`/abonof/${ abono.id }`, abono);
    //         if( data.ok ){
    //             dispatch( onDeleteAbono( data.abonoDelete ) );
    //             Swal.fire('Exitoso', 'Abono Eliminado', 'success');
    //         }
    //     } catch (error) {
    //         Swal.fire('Error', error.response.data.msg, 'error');
    //         console.log(error);
    //     }
    // }




    return {
        //Propiedades
        abonosPlanilla,
        activeAbonoPlanilla,

        //Metodos
        startSavingAbono,
        listadoAbonosPlanilla,
        // deleteAbono,
        // activeAbonoStart,
        // activeAbonoEliminar,
    }


}
