

import { createSlice } from '@reduxjs/toolkit';



export const unidadMedidaSlice = createSlice({
    name: 'unidadMedida',
    initialState: {
        isLoadingUnidadMedida: true,
        unidadMedidas: [],
        activeUnidadMedida: null
    },
    reducers:{

        onSetActiveUnidadMedida: ( state, { payload } ) => {
            state.activeUnidadMedida = payload;
        },

        activeUnidadMedidaClear: ( state ) => {
            state.activeUnidadMedida = null;
        },
        
        onAddNewUnidadMedida: ( state, { payload } ) => {
            state.unidadMedidas.push( payload );
            state.activeUnidadMedida = null;
        },

        onUpdateUnidadMedida: ( state, { payload } ) => {
            state.unidadMedidas = state.unidadMedidas.map( unidadMedida => {
                if( unidadMedida.id === payload.id ){
                    return payload
                }
                return unidadMedida
            });
        },

        onLoandingUnidadMedida: ( state, { payload = [] } ) => {
            state.isLoadingUnidadMedida = false;
            // state.galpons = payload;
            payload.forEach( unidadMedida => {
                const exists = state.unidadMedidas.some( dbUnidadMedida => dbUnidadMedida.id === unidadMedida.id );
                if( !exists ){
                    state.unidadMedidas.push( unidadMedida );
                }
            })
        },

        onDeleteUnidadMedida: ( state, { payload } ) => {
            state.unidadMedidas = state.unidadMedidas.filter( unidadMedida => unidadMedida.id !== payload.id );
        }
    }
});





export const { onSetActiveUnidadMedida, onAddNewUnidadMedida, onUpdateUnidadMedida, onLoandingUnidadMedida, onDeleteUnidadMedida, activeUnidadMedidaClear } = unidadMedidaSlice.actions;