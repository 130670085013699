
import { useState, useEffect } from 'react';

import { Button, Grid, TextField, Backdrop, Box, Modal, Typography } from '@mui/material';
import { useModalStore, useUnidadMedidaStore } from '../../hooks';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const initialState = {
    nombre: ''
};



export const ModalUnidadMedida = () => {


    const { modalMedida, cerrarMedida } = useModalStore();
    const { activeUnidadMedida, startSavingUnidadMedida } = useUnidadMedidaStore();


    const [formNombre, setFormNombre] = useState({
        nombre: ''
    });
    const { nombre } = formNombre;


    const handleInputChange = ({ target }) => {
        setFormNombre({
            ...formNombre,
            [target.name]: target.value
        })
    }


    useEffect(() => {
        if ( activeUnidadMedida !== null ) {
            setFormNombre({ ...activeUnidadMedida });
        }else {
            setFormNombre( initialState );
        }
    }, [ activeUnidadMedida ]);


    

    const handleCrearMedida = async(e) => {
        e.preventDefault();

        if( nombre.length < 1 ){ return }
        await startSavingUnidadMedida( formNombre );
        cerrarMedida();
        setFormNombre(initialState);
    }




    return (
        <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ modalMedida }
            onClose={ cerrarMedida }
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Box sx={ style }>

                <Typography align='center' variant='h4' sx={{ mb:2, fontWeight:'bold'  }} >Crear Unidad de Medida</Typography>

                <form  onSubmit={ handleCrearMedida } >
                    <Grid container spacing={ 2 }>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                            <TextField required
                                label='Nombre'
                                type='text'
                                placeholder='Nombre'
                                fullWidth
                                name='nombre'
                                value={ nombre }
                                onChange={ handleInputChange }
                            />
                        </Grid>


                        <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                            <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                <Button variant='contained' fullWidth type='submit'>
                                    Crear Medida
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                </form>
            </Box>
        </Modal>
        </div>
    );







}