import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import { onAddNewUnidadMedida, onDeleteUnidadMedida, onLoandingUnidadMedida, onSetActiveUnidadMedida, onUpdateUnidadMedida } from "../store";
import Swal from 'sweetalert2';



export const useUnidadMedidaStore = () => {


    const dispatch = useDispatch();
    const { unidadMedidas, activeUnidadMedida } = useSelector( state => state.unidadMedida );


    const setActiveUnidadMedida = async( uid ) => {

        try {
            const { data } = await granjaApi.get(`/unidadmedida/unixid/${ uid }`);
            if( data.ok ){
                dispatch( onSetActiveUnidadMedida( data.unidadMedida ) );
            }else {
                Swal.fire('Error', 'Algo Salio Mal', 'error');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    const startSavingUnidadMedida = async( unidad ) => {
        try {
            if( unidad.id ){
                const { data } = await granjaApi.put(`/unidadmedida/${ unidad.id }`, { ...unidad } );
                if( data.ok ){
                    dispatch( onUpdateUnidadMedida( data.unidadMedida ) );
                    Swal.fire('Exitoso', 'Medida Actualizada', 'success');
                }
            }else {
                const { data } = await granjaApi.post('/unidadmedida/new', { ...unidad } );
                if( data.ok ){
                    Swal.fire('Exitoso', 'Unidad de Medida Creada', 'success');
                    dispatch( onAddNewUnidadMedida( data.unidadMedida ) );
                } else{
                    Swal.fire('Error', 'Algo Salio Mal', 'error');
                }
            }

        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    } 


    const startLoadingUnidadMedida = async() => {
        try {
            const { data } = await granjaApi.get('/unidadmedida');
            dispatch( onLoandingUnidadMedida( data.unidadMedidas ) );
            
        } catch (error) {
            console.log('Error al Cargar Medidas');
            console.log(error);
        }
    }



    const deleteUnidadMedida = async( unidad ) => {
        try {
            const { data } = await granjaApi.delete(`/unidadmedida/${ unidad.id }`);
            if( data.ok ){
                dispatch( onDeleteUnidadMedida( data.unidadMedida ) );
                Swal.fire('Exitoso', 'Medida Eliminada', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    

    return {
        //Propiedades
        unidadMedidas,
        activeUnidadMedida,

        //Metodos
        setActiveUnidadMedida,
        startSavingUnidadMedida,
        startLoadingUnidadMedida,
        deleteUnidadMedida,

    }


}
