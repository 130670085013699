
import { useDispatch, useSelector } from 'react-redux';
import { abrirModalConsumo, abrirModalDescuentos, abrirModalDistribucion, abrirModalIngresoProductos, abrirModalMermas, cerrarModalConsumo, cerrarModalDescuentos, cerrarModalDistribucion, cerrarModalIngresoProductos, cerrarModalMermas } from '../store';



export const useModalImgStore = () => {

    const dispatch = useDispatch();
    const { modalDescuentos, modalDistriPollos, modalMermas, modalConsumo, modalIngresoProductoBodegas } = useSelector( state => state.modalImg );



    const openModalDistribucion = () => {
        dispatch( abrirModalDistribucion() );
    }


    const closeModalDistribucion = () => {
        dispatch( cerrarModalDistribucion() );
        // dispatch( onSetEliminarInventario() );
    }


    const openModalDescuentos = () => {
        dispatch( abrirModalDescuentos() );
    }


    const closeModalDescuentos = () => {
        dispatch( cerrarModalDescuentos() );
    }



    const openModalMermas = () => {
        dispatch( abrirModalMermas() );
    }


    const closeModalMermas = () => {
        dispatch( cerrarModalMermas() );
    }


    const openModalConsumo = () => {
        dispatch( abrirModalConsumo() );
    }


    const closeModalConsumo = () => {
        dispatch( cerrarModalConsumo() );
    }



    const openModalIngresoProductoBodega = () => {
        dispatch( abrirModalIngresoProductos() );
    }


    const closeModalIngresoProductoBodega = () => {
        dispatch( cerrarModalIngresoProductos() );
    }





    return {
        //Propiedades
        modalDistriPollos,
        modalDescuentos,
        modalMermas,
        modalConsumo,
        modalIngresoProductoBodegas,

        // Metodos
        openModalDistribucion,
        closeModalDistribucion,
        openModalDescuentos,
        closeModalDescuentos,
        openModalMermas,
        closeModalMermas,
        openModalConsumo,
        closeModalConsumo,
        openModalIngresoProductoBodega,
        closeModalIngresoProductoBodega,
    }

}