import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2";
import { granjaApi } from "../api";
import { onAddNewGranja, onDeleteGranja, onLoandingGranja, onSetActiveGranja, onUpdateGranja } from "../store";



export const useGranjaStore = () => {


    const dispatch = useDispatch();
    const { granjas, activeGranja } = useSelector( state => state.granja );



    const setActiveGranja = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/granja/${ uid }`);
            dispatch( onSetActiveGranja( data.granja ) );
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error)
        }
    }


    const startSavingGranja = async( granja ) => {
        try {

            if( granja.id ){
                const { data } = await granjaApi.put(`/granja/${ granja.id }`, granja );
                if( data.ok ){
                    dispatch( onUpdateGranja( granja ) );
                    Swal.fire('Exitoso', 'Granja Actualizada', 'success');
                }
            }else {
                const { data } = await granjaApi.post('/granja/new', { ...granja } );
                if( data.ok ){
                    dispatch( onAddNewGranja( data.granja ) );
                    Swal.fire('Exitoso', 'Granja Creada', 'success');
                }
            }

        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');

        }
    } 


    
    const startLoadingGranjas = async() => {
        try {
            const { data } = await granjaApi.get('/granja');
            dispatch( onLoandingGranja(data.granjas) );
            
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }



    const deleteGranja = async( granja ) => {
        try {
            const { data } = await granjaApi.delete(`/granja/${ granja.id }`);
            if( data.ok ){
                dispatch( onDeleteGranja( data.granja ) );
                Swal.fire('Exitoso', 'Granja Eliminada', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    return {
        //Propiedades
        granjas,
        activeGranja,

        //Metodos
        setActiveGranja,
        startSavingGranja,
        startLoadingGranjas,
        deleteGranja, 
    }


}
