

import { createSlice } from '@reduxjs/toolkit';



export const productoSlice = createSlice({
    name: 'producto',
    initialState: {
        isLoadingProducto: true,
        productos: [],
        activeProducto: null
    },
    reducers:{
        onSetActiveProducto: ( state, { payload } ) => {
            state.activeProducto = payload;
        },

        onActiveProductoClear: ( state ) => {
            state.activeProducto = null;
        },
        
        onAddNewProducto: ( state, { payload } ) => {
            state.productos.push( payload );
            state.activeProducto = null;
        },

        onUpdateProducto: ( state, { payload } ) => {
            state.productos = state.productos.map( pro => {
                if( pro.id === payload.id ){
                    return payload
                }
                return pro
            });
        },

        onLoandingProducto: ( state, { payload = [] } ) => {
            state.isLoadingProducto = false;
            state.productos = payload;
            // payload.forEach( producto => {
            //     const exists = state.productos.some( dbProducto => dbProducto.id === producto.id );
            //     if( !exists ){
            //         state.productos.push( producto );
            //     }
            // })
        },

        onDeleteProducto: ( state, { payload } ) => {
            // if( state.activeProducto ){
                state.productos = state.productos.filter( pro => pro.id !== payload.id );
            //     state.activeProducto = null;
            // }
        }
    }
});





export const { onSetActiveProducto, onAddNewProducto, onUpdateProducto, onLoandingProducto, onDeleteProducto, onActiveProductoClear } = productoSlice.actions;