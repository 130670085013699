

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, Grid, CardActionArea } from '@mui/material';
import { LayoutReturn } from '../../layout';

import { useUIStore, useUpload, useVehiculoStore } from '../../../hooks';
import { ModalGlobal } from '../../components/ModalGlobal';


const baseUrl = process.env.REACT_APP_API_URL;


const imagenes = [
    { name: 'soat', title: 'Soat', url: '' },
    { name: 'tecno', title: 'Tecnomecanica', url: '' },
    { name: 'riesgo', title: 'Todo Riesgo', url: '' },
    { name: 'propiedad', title: 'Tarjeta Propiedad', url: '' },
]


export const ImagesSeguros = () => {


    const { id } = useParams();
    const { activeVehiculo, setActiveVehiculo } = useVehiculoStore();    
    const { subirImagenTodoCategoria } = useUpload();
    const { openGlobal } = useUIStore();



    const [category, setCategory] = useState('')
    const [datos, setDatos] = useState(imagenes);
    const [titulo, setTitulo] = useState('');
    const [images, setImages] = useState('');



    useEffect(() => {
        if( id ){
            setActiveVehiculo(id);
        }
    }, [id])



    useEffect(() => {
        if( activeVehiculo ){
            setDatos([
                { name: 'soat', title: 'Soat', url: activeVehiculo.imgSoat },
                { name: 'tecno', title: 'Tecnomecanica', url: activeVehiculo.imgTecno },
                { name: 'riesgo', title: 'Todo Riesgo', url: activeVehiculo.imgTodoRiesgo },
                { name: 'propiedad', title: 'Tarjeta Propiedad', url: activeVehiculo.imgPropiedad },
            ]);
            setTitulo(`Datos del Vehiculo ${ activeVehiculo.placa }`)
        }
    }, [activeVehiculo])
    


    // Subir Imagen de Abono
    const handlePictureClick = async( cat ) => {
        setCategory( cat );
        document.querySelector('#fileSelector').click();
    }

    
    const handleFileChange = async( e ) => {
        const file = e.target.files[0];
        if( file && category ) {
            await subirImagenTodoCategoria( activeVehiculo.id, category, file );
            setActiveVehiculo(id);
        }
    }



    const handleDescargar = (img) => {
        const url = `${ baseUrl }/uploads/${img.name}/${ img.url }`;
        fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${ img.title }`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(() => alert('Hubo un Error al Descargar.'));
    }



    const handleVerImg = ( img ) => {
        openGlobal();
        setImages(`${ baseUrl }/uploads/${ img.name }/${ img.url }`)
    }



    return (

        <LayoutReturn title={titulo} url='/admin/list-vehiculo'>

            <Grid container spacing={4} sx={{ padding:5, justifyContent:'center' }}>

                {
                datos.map( s => (
                    <Grid item xs={12} sm={6} md={4} key={s.name} >
                        <Card sx={{ maxWidth: 345, minHeight: 220 }}>
                            <CardActionArea onClick={ () => handleVerImg(s) }>
                                {
                                activeVehiculo &&
                                <CardMedia
                                    // component="img"
                                    component={ (s.url?.split('.').includes('pdf')) ? 'iframe' : 'img' }
                                    alt="Imagen-Vehiculo"
                                    sx={{ objectFit:'cover', height:180, width:'100%', overflow:'hidden' }}
                                    image={`${ baseUrl }/uploads/${ s.name }/${ s.url }`}
                                />
                                }
                                <CardContent>
                                    <Typography sx={{ textAlign:'center' }} variant="h5">
                                        { s.title }
                                    </Typography>
                                </CardContent>
                            </CardActionArea>

                            <CardActions sx={{ justifyContent:'center' }} >
                                <Button size="small" variant="contained" color='primary' onClick={ () => handlePictureClick(s.name) } >
                                    Subir Imagen
                                </Button>
                                <Button size="small" variant="contained" color='primary' onClick={ () => handleDescargar(s) } >
                                    Descargar
                                </Button>
                            </CardActions>
                        </Card>

                    </Grid>
                ))
                }
                

            </Grid>

            
            <input type="file" 
                id="fileSelector"
                className="boton-file"
                onChange={ handleFileChange }
                name="file"
            />




            <ModalGlobal url={images} />


        </LayoutReturn>


        
    )



}
