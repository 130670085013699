

import { createSlice } from '@reduxjs/toolkit';



export const consumoSlice = createSlice({

    name: 'consumo',
    initialState: {
        isLoadingConsumo: true,
        consumos: [],
        activeConsumo: null
    },
    reducers:{

        onSetActiveConsumo: ( state, { payload } ) => {
            state.activeConsumo = payload;
        },
        
        onAddNewConsumo: ( state, { payload } ) => {
            state.consumos.unshift( payload );
            state.activeConsumo = null;
        },

        // onUpdateGranja: ( state, { payload } ) => {
        //     state.granjas = state.granjas.map( granja => {
        //         if( granja.id === payload.id ){
        //             return payload
        //         }
        //         return granja
        //     });
        // },

        onLoandingConsumo: ( state, { payload = [] } ) => {
            state.isLoadingConsumo = false;
            state.consumos = payload;
        },


        onDeleteConsumo: ( state, { payload } ) => {
            state.consumos = state.consumos.filter( consumo => consumo.id !== payload.id );
        }


    }
});





export const {onSetActiveConsumo, onAddNewConsumo, onLoandingConsumo, onDeleteConsumo } = consumoSlice.actions;