

import { createSlice } from '@reduxjs/toolkit';



export const distribucionSlice = createSlice({
    name: 'distribucion',
    initialState: {
        isLoadingDistribucion: true,
        distribuciones: [],
        activeDistribucion: null
    },
    reducers:{

        onSetActiveDistribucion: ( state, { payload } ) => {
            state.activeDistribucion = payload;
        },

        
        onAddNewDistribucion: ( state, { payload } ) => {
            state.distribuciones.push( payload );
            state.activeDistribucion = null;
        },

        onUpdateDistribucion: ( state, { payload } ) => {
            state.distribuciones = state.distribuciones.map( distribucion => {
                if( distribucion.id === payload.id ){
                    return payload
                }
                return distribucion
            });
        },

        onLoandingDistribucion: ( state, { payload = [] } ) => {
            state.isLoadingDistribucion = false;
            state.distribuciones = payload;
        },

        onDeletedistribucion: ( state, { payload } ) => {
            state.distribuciones = state.distribuciones.filter( distribucion => distribucion.id !== payload.id );
        }
    }
});





export const { onSetActiveDistribucion, onAddNewDistribucion, onUpdateDistribucion, onLoandingDistribucion, onDeletedistribucion } = distribucionSlice.actions;