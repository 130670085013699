


import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Grid, MenuItem, TextField, Typography, Box, Modal } from '@mui/material';
import { useBodegaStore, useInventarioStore, useModalImgStore } from '../../../../hooks';
import { NumberFormatCustomSoloNumber } from '../../../../hooks/hooks/useInputSoloNumero';





const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};




export const ModalIngresoProductosABodega = ({ uid }) => {


    
    const { modalIngresoProductoBodegas, closeModalIngresoProductoBodega } = useModalImgStore();
    const { startCrearInventarioProducto } = useInventarioStore();
    const { bodegas, startLoadingBodegas, } = useBodegaStore();
    const soloNumber = NumberFormatCustomSoloNumber



    useEffect(() => {
        startLoadingBodegas();
    }, [] );


    useEffect(() => {
        setFormInventario({
            ...formInventario,
            producto: uid
        });
    }, [uid]);



    const [formInventario, setFormInventario] = useState({
        producto: '',
        cantidad: '',
        bodega: '',
    });
    const { producto, cantidad, bodega } = formInventario;


    const handleInputChange = ({ target }) => {
        setFormInventario({
            ...formInventario,
            [target.name]: target.value
        })
    }


    const enviarProductoBodega = async(e) => {
        e.preventDefault();
        closeModalIngresoProductoBodega();
        await startCrearInventarioProducto(formInventario);
        setFormInventario({
            ...formInventario,
            cantidad: '',
            bodega: '',
        });
    }



    return (

        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={ modalIngresoProductoBodegas }
                onClose={ closeModalIngresoProductoBodega }
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box sx={ style }>

                    <Typography  variant='h1' sx={{ mb:2, textAlign:'center' }} >Enviar Producto</Typography>

                    <form  onSubmit={ enviarProductoBodega } >
                        <Grid container spacing={ 2 }>

                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    select
                                    label="Seleccione Bodega"
                                    fullWidth
                                    value={ bodega }
                                    name='bodega'
                                    onChange={ handleInputChange }
                                >
                                    <MenuItem value=''>
                                        Seleccione Bodega
                                    </MenuItem>
                                    {
                                    bodegas.map( e => (
                                    <MenuItem key={ e.id } value={ e.id } >
                                        { e.nombre }
                                    </MenuItem>
                                    ))
                                    }
                                </TextField>
                            </Grid>

                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    label='Cantidad de Pollos'
                                    placeholder='Cantidad'
                                    fullWidth
                                    name='cantidad'
                                    value={ cantidad }
                                    id="formatted-numberformat-input"
                                    onChange={ handleInputChange }
                                    InputProps={{
                                        inputComponent: soloNumber,
                                    }}
                                />
                            </Grid>
                            
                            <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                                <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                    <Button variant='contained' fullWidth type='submit'>
                                        Enviar Producto
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>

    )



}
