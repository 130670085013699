



export const separadorMillares = ( numero ) => {
    try {
        if( typeof numero !== 'number' ) {
            return;
        }
        return numero.toLocaleString('es-CO');
        
    } catch (error) {
        return console.log(error);
    }
}