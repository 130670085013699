

import { createSlice } from '@reduxjs/toolkit';



export const proveedorSlice = createSlice({
    name: 'proveedor',
    initialState: {
        isLoadingProveedor: true,
        proveedores: [],
        activeProveedor: null
    },
    reducers:{
        onSetActiveProveedor: ( state, { payload } ) => {
            state.activeProveedor = payload;
        },
        
        onAddNewProveedor: ( state, { payload } ) => {
            state.proveedores.push( payload );
            state.activeProveedor = null;
        },

        onUpdateProveedor: ( state, { payload } ) => {
            state.proveedores = state.proveedores.map( pro => {
                if( pro.id === payload.id ){
                    return payload
                }
                return pro
            });
        },

        onLoandingProveedor: ( state, { payload = [] } ) => {
            state.isLoadingProveedor = false;
            // state.Proveedors = payload;
            payload.forEach( proveedor => {
                const exists = state.proveedores.some( dbProveedor => dbProveedor.id === proveedor.id );
                if( !exists ){
                    state.proveedores.push( proveedor );
                }
            })
        },

        onDeleteProveedor: ( state, { payload } ) => {
            // if( state.activeProveedor ){
                state.proveedores = state.proveedores.filter( pro => pro.id !== payload.id );
            //     state.activeProveedor = null;
            // }
        }
    }
});





export const { onSetActiveProveedor, onAddNewProveedor, onUpdateProveedor, onLoandingProveedor, onDeleteProveedor } = proveedorSlice.actions;