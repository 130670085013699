

import { useNavigate } from "react-router-dom";

import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { AddOutlined } from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import { useInventarioStore } from "../../hooks";






export const LayoutAddInfo = ({ children, title, url, url2 }) => {


    const navigate = useNavigate();
    const { LimpiarListaInventario } = useInventarioStore();




    const handleCrear = () => {
        navigate(url);
    }


    const handleInfo = () => {
        LimpiarListaInventario();
        navigate(url2);
    }



    return (

        <Grid container sx={{ minHeight:'50vh', padding:4 }}>
            
            <Grid  container direccion='row' alignItems='center' mb={3} >
                        
            <Tooltip title="Crear Bodega" placement="top">
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ handleCrear }
                >
                    <AddOutlined />
                </IconButton>
            </Tooltip>

                <Box flex={ 1 } />

                <Typography variant='h1' >{ title } </Typography>

                <Box flex={ 1 } />

                <Tooltip title="Ver Productos En Bodegas" placement="top">
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'primary.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ handleInfo }
                >
                    <InfoIcon />
                </IconButton>
                </Tooltip>
            </Grid>

                
                { children }



        </Grid>

    )


}
