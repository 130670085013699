

import { Grid, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { useBusquedas, useVehiculoStore } from "../../../hooks"



export const BuscarVehiculos = () => {


    const { startLoadingVehiculos } = useVehiculoStore();
    const { busquedaPorVehiculos } = useBusquedas();
    
    const [valor, setValor] = useState('');

    
    // const hanldeInputChange = ({ target }) => {
    //     setValor( target.value );
    //     if( target.value.trim().length <= 0 ){
    //         startLoadingVehiculos();
    //     }else {
    //         busquedaPorVehiculos(target.value);
    //     }
    // }


    useEffect(() => {
        if( valor.trim().length > 0 ){
            busquedaPorVehiculos(valor);
        }else {
            startLoadingVehiculos();
        }
    }, [valor])
    


    return (

        <Grid
            item
            xs={ 12 } sm={ 6 }
            sx={{ my: 4 }}
        >
            <TextField  
                autoComplete="off"
                label="Buscar Vehiculo..." 
                variant="filled" 
                fullWidth
                name="valor"
                value={ valor }
                onChange={ (e) => setValor(e.target.value) }
            />

        </Grid>


    )




}
