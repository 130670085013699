
import { useDispatch, useSelector } from "react-redux"

import Swal from "sweetalert2";
import { granjaApi } from "../api";
import { onAddNewUsuario, onDeleteUsuario, onLoandingCliente, onLoandingConductor, onLoandingUsuario, onSetActiveUsuario, onUpdateUsuario } from "../store";



export const useUsuarioStore = () => {


    const dispatch = useDispatch();
    const { usuarios, clientes, conductores, activeUsuario } = useSelector( state => state.usuario );



    const setActiveUsuario = async( uid ) => {
        
        try {
            const { data } = await granjaApi.get(`/usuario/porid/${ uid }`);
            if( data.ok ){
                dispatch( onSetActiveUsuario( data.usuario ) );
            }

        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }



    const startSavingUsuario = async( user ) => {
        try {
            if( user.id ){
                const { data } = await granjaApi.put(`/usuario/update/${ user.id }`, user );
                if( data.ok ){
                    dispatch( onUpdateUsuario({ ...data.usuario }) );
                    Swal.fire('Exitoso', 'Usuario Actualizado', 'success');
                }
            }else {
                const { data } = await granjaApi.post('/usuario/new', user );
                if( data.ok ){
                    dispatch( onAddNewUsuario( data.usuario ) );
                    Swal.fire('Exitoso', 'Usuario Creado', 'success');
                }
            }
        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data?.msg || '', 'error');
        }
    } 



    const startUpdatePassword = async( user ) => {
        try {

            const { data } = await granjaApi.put(`/usuario/cambioclave/${ user.id }`, user );
            if( data.ok ){
                // dispatch( onUpdateUsuario({ ...data.usuario }) );
                Swal.fire('Exitoso', 'Clave Actualizado', 'success');
                return true;
            }
        } catch (error) {
            Swal.fire('Error', error.response.data?.msg || '', 'error');
            console.log(error);
        }
    } 



    const startLoadingUsuarios = async() => {
        try {
            const { data } = await granjaApi.get('/usuario/todos');
            dispatch( onLoandingUsuario(data.usuarios) );
            
        } catch (error) {
            console.log('Error al Cargar Usuarios');
            console.log(error);
        }
    }

    

    const startLoadingUsuariosConductor = async() => {
        try {
            const { data } = await granjaApi.get('/usuario/conductor');
            dispatch( onLoandingConductor(data.usuario) );
        } catch (error) {
            console.log('Error al Cargar Usuarios');
            console.log(error);
        }
    }



    const startLoadingUsuariosCliente = async() => {
        try {
            const { data } = await granjaApi.get('/usuario/clientes');
            dispatch( onLoandingCliente(data.usuarios) );
        } catch (error) {
            console.log('Error al Cargar Clientes');
            console.log(error);
        }
    }



    const deleteUsuario = async( usuario ) => {
        try {
            const { data } = await granjaApi.delete(`/usuario/${ usuario.id }`);
            if( data.ok ){
                dispatch( onDeleteUsuario( data.usuario ) );
                Swal.fire('Exitoso', 'Usuario Eliminado', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }



    return {
        //Propiedades
        usuarios,
        conductores,
        activeUsuario,
        clientes,

        //Metodos
        startSavingUsuario,
        startLoadingUsuarios,
        deleteUsuario,
        setActiveUsuario,
        startUpdatePassword,
        startLoadingUsuariosConductor,
        startLoadingUsuariosCliente,
    }


}
