
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {  useAbonoStore, useUIStore, useUpload } from "../../../../hooks";
import { ModalAbonoFactura } from "../components/ModalAbonoFactura";
import { separadorMillares } from "../../../../hooks/hooks/useMillares";
import { TablaInformacionFactura } from "../components/TablaInformacionFactura";
import { AlertScreen } from "../../../components/AlertScreen";
import { LayoutReturnAdd } from "../../../layout";

import Swal from 'sweetalert2';
import { ModalGlobal } from "../../../components/ModalGlobal";



const baseUrl = process.env.REACT_APP_API_URL;



export const AbonosFacturaScreen = () => {


    const { id } = useParams();
    const { abonos, activeAbono, startLoadingAbonos, deleteAbono, activeAbonoStart } = useAbonoStore();
    const { subirImagenTodoCategoria } = useUpload();
    const { openGlobal } = useUIStore();

    const [images, setImages] = useState('');


    useEffect(() => {
        if( id ){
            startLoadingAbonos(id);
        }
    }, [id]);

    
    
    const eliminarAbono = (abono) => {
        Swal.fire({
            title: 'Eliminar Abono',
            text: `¿Esta Seguro De Eliminar Este Abono?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
            }).then( ( result ) => {
            if(result.value){
                deleteAbono(abono);
            }
        });
    }


    const handleVerImg = ( img ) => {
        openGlobal();
        setImages(`${ baseUrl }/uploads/abonos/${ img.img }`)
        console.log(img);
    }


    // Subir Imagen de Abono
    const handlePictureClick = async(uid) => {
        await activeAbonoStart(uid);
        document.querySelector('#fileSelector').click();
    }


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if( file ) {
            subirImagenTodoCategoria( activeAbono.id, 'abonos', file );
        }
    }



    return (

        <>
        
        <LayoutReturnAdd title="Abonos Factura" url="/admin/facturas">

            <TablaInformacionFactura />

            { (abonos.length > 0 )
            ?<TableContainer>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">

                    <TableHead >
                    <TableRow>
                        <TableCell>VALOR</TableCell>
                        <TableCell>FECHA</TableCell>
                        <TableCell>MEDIO DE PAGO</TableCell>
                        <TableCell>REFERENCIA</TableCell>
                        <TableCell sx={{ width:200 }}>OBSERVACION</TableCell>
                        <TableCell>OPCIONES</TableCell>
                    </TableRow>
                    </TableHead>

                    <TableBody>
                    { abonos.map( ( f ) => (
                    <TableRow key={ f.id } >
                        <TableCell>{ `$${ separadorMillares(f.valor) }` }</TableCell>
                        <TableCell>{ f.createdAt }</TableCell>   
                        <TableCell>{ f.medio }</TableCell>
                        <TableCell>{ f.ref }</TableCell>
                        <TableCell className="cortar-texto">{ f.observacion }</TableCell>
                        <TableCell sx={{ width:'200px' }}>

                            <IconButton sx={{ color: '#4caf50' }} onClick={ () => handlePictureClick(f.id) } >
                                <AddPhotoAlternateIcon fontSize="small" />
                            </IconButton>
                            <IconButton onClick={ () => handleVerImg(f) }>    
                                <VisibilityIcon fontSize="small" sx={{ color: '#ff9800' }}/>
                            </IconButton>
                            <IconButton color="error" onClick={ () => eliminarAbono( f ) } >
                                <DeleteIcon fontSize="small" />
                            </IconButton>

                        </TableCell>

                    </TableRow>
                    ))
                    }
                    </TableBody>

                </Table>
            </TableContainer>

            :<AlertScreen title={'No hay registro de Abonos.'} />

            }
            <input type="file" 
                id="fileSelector"
                className="boton-file"
                onChange={ handleFileChange }
                name="file"
            />

        </LayoutReturnAdd>



        <ModalAbonoFactura />

        <ModalGlobal url={images} />


        </>
    )


}
