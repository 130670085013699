
import html2PDF from 'jspdf-html2canvas';
import cpp from '../../assets/empresa/pccpollo.png';



export const generarPDF = async( referencia, title ) => {

    const htmlTodo = `
            <div class="img-logo-imprimir"  >  
                <img src=${cpp} alt='LogoEmpresa' />
            </div>
            <h1 class="titulo-imprimir">${ title }</h1> 
            ${referencia.outerHTML}
    `;


    const div = document.createElement('div');
    div.innerHTML = htmlTodo;
    const respuesta = document.body.appendChild(div);
    // console.log(respuesta);

    html2PDF(respuesta, {
        jsPDF: {
            format: 'a4',
        },
        imageType: 'image/jpeg',
        margin: {
            top: 20,
            right: 10,
            bottom: 10,
            left: 10,
        },
        output: './Documento.pdf'
    });
    document.body.removeChild(div);

        // const options = {
        //     margin:       1,
        //     filename:     'Documento.pdf',
        //     image:        { type: 'jpeg', quality: 0.98 },
        //     html2canvas:  { scale: 2 },
        //     jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
        // };

        // html2PDF(respuesta, options);

}




