



import { createSlice } from '@reduxjs/toolkit';



export const sugeridoSlice = createSlice({

    name: 'sugerido',
    initialState: {
        isLoadingSugerido: true,
        sugeridos: [],
        activeSugerido: null
    },
    reducers:{

        // onSetActiveSugerido: ( state, { payload } ) => {
        //     state.activeSugerido = payload;
        // },
        
        // onAddNewSugerido: ( state, { payload } ) => {
        //     state.sugeridos.unshift( payload );
        //     state.activeSugerido = null;
        // },

        // onUpdateGranja: ( state, { payload } ) => {
        //     state.granjas = state.granjas.map( granja => {
        //         if( granja.id === payload.id ){
        //             return payload
        //         }
        //         return granja
        //     });
        // },

        onLoandingSugerido: ( state, { payload = [] } ) => {
            state.isLoadingSugerido = false;
            state.sugeridos = payload;
        },

       
    }
});





export const { onLoandingSugerido } = sugeridoSlice.actions;