
import { useState, useEffect } from 'react';

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDescuentoStore } from '../../../hooks';

import { AlertScreen } from '../../components/AlertScreen';
import moment from 'moment';
import Swal from 'sweetalert2';




export const TablaDeDatos = ({ activeDistribucion, datos, titulo }) => {


    const { deleteDesuento } = useDescuentoStore();
    const [total, setTotal] = useState(0);


    useEffect(() => {
        if( datos ){
            const totales = datos.reduce( (t, c) => t + c.cantidad, 0 );
            setTotal( totales );
        }
    }, [datos])

    const  handleDeleteDescuento = (m) => {
        if( m.descuento === 'VENTA'){
            return Swal.fire('Error', 'Las Ventas No se Pueden Eliminar', 'error');
        }
        Swal.fire({
            title: `Eliminar ${m.descuento}`,
            text: `¿Esta Seguro De Eliminar este Dato?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Eliminar' 
        }).then( ( result ) => {
            if(result.value){
                deleteDesuento( m.id );
            }
        });
    }



    return (

        <>
        { ( datos.length > 0 )
            ?<>
            {
            activeDistribucion &&
            <TableContainer>
                <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>LOTE</TableCell>
                        <TableCell>GALPON</TableCell>
                        <TableCell>TOTAL AVES</TableCell>
                        <TableCell>{ titulo } TOTAL</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{ activeDistribucion.lote.lote }</TableCell> 
                        <TableCell>{ activeDistribucion.galpon.nombre }</TableCell> 
                        <TableCell>{ activeDistribucion.cantidad }</TableCell> 
                        <TableCell>{ total }</TableCell> 
                    </TableRow>
                </TableBody>
                </Table>
            </TableContainer>
            }

            <TableContainer sx={{my:3}} >
                <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>CANTIDAD</TableCell>
                        <TableCell>DIA</TableCell>
                        <TableCell>FECHA</TableCell>
                        <TableCell>OBSERVACION</TableCell>
                        <TableCell>ACCION</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                    datos.map( m => (
                    <TableRow key={ m.id } >
                        <TableCell>{ m.cantidad }</TableCell>   
                        <TableCell>{ moment(m.createdAt).format('dddd') }</TableCell>
                        <TableCell>{ moment(m.createdAt).format('LLL') }</TableCell>
                        <TableCell>{ m.observacion }</TableCell>

                        <TableCell sx={{ width:'200px' }}>
                            <IconButton color="error" onClick={ () => handleDeleteDescuento(m) } >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </TableCell>

                    </TableRow>
                    ))
                    }
                </TableBody>
                </Table>
            </TableContainer>
            </>

            : <AlertScreen title={`No hay registro de ${ titulo }.`} />

        }
        </>     

    )


}
