
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { usePlanillaStore } from "../../../hooks";
import { Box, Grid, IconButton,  Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { AdminLayout } from "../../layout/AdminLayout";
import { AlertScreen, ModalMermas } from "../../components";
import { generarPDF } from "../../../hooks/hooks";
import { BotonesOpcionales } from "./BotonesOpcionales";
import { ModalAbonoPlanilla } from "../AbonosPlanilla";
import { TablaDescartes, TablaTotalesPlanilla, TablaItemsPlanilla, TablaSubTotalesDePlanillas, TablaInfoPersonaPlanilla, TablaDecuentoPlanilla } from "./";




export const VerPlanillaID = () => {

    
    const { id } = useParams();
    const divRef2 = useRef();
    const navigate = useNavigate();
    const { activePlanilla, setActivePlanilla } = usePlanillaStore();


    useEffect(() => {
        if( id ){
            setActivePlanilla(id);
        }
    }, [id])



    const generarArchivo = () => {
        generarPDF(divRef2.current, `Reporte de Planilla #${activePlanilla.numero}`);
    }



    const regresar = () => {
        navigate(-1);
    }
    

    return (

        <AdminLayout>

            <Grid container direccion='row' alignItems='center' mb={3} >

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        fontSize:'30px',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ regresar }
                >
                    <ArrowBackIcon sx={{ fontSize:'30px' }} />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h1'>Informacion de Planilla</Typography>

                <Box flex={ 1 } />

                <BotonesOpcionales pasarDatos={ generarArchivo } />

            </Grid>

            <div ref={ divRef2 } >

                { ( activePlanilla ) 
                ?<>

                {/* Tabla Informacion Personal y de la Planilla */}
                <TablaInfoPersonaPlanilla />


                {/* Tabla que contiene los items de la planilla */}
                <TablaItemsPlanilla />


                {/* Tabla que contiene los Subtotales de la Planilla */}
                <TablaSubTotalesDePlanillas />


                {/* Tabla que contiene los TOTALES de la Planilla */}
                <TablaTotalesPlanilla />


                {/* Tabla que contiene los Descuentos de la Planilla */}
                <TablaDecuentoPlanilla />


                {/* Tabla de Descartes de Planilla */}
                <TablaDescartes />

                </>

                :<AlertScreen title="No Se Pudieron Cargar Los Datos..." />
                
                }


                <ModalMermas />

                <ModalAbonoPlanilla />

            </div>
        
                
        </AdminLayout>
        
    )



}
