
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Grid, MenuItem, TextField } from "@mui/material";

import { useEncubadoraStore, useLoteStore } from "../../../../hooks";
import { LayoutReturn } from "../../../layout";


import Swal from "sweetalert2";




const initialState = {
    lote: '',
    granja: '',
    encubadora: '',
    cantidadTotal: '',
    factura: '',
    machos: '',
    hembras: '',
}


export const ActualizarLote = () => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { activeLote, startSavingLote, setActiveLote } = useLoteStore();
    // const { granjas, startLoadingGranjas } = useGranjaStore();
    const { encubadoras, startLoadingEncubadora } = useEncubadoraStore();

    
    const [formLote, setFormLote] = useState( initialState )
    const  { encubadora, cantidadTotal, factura, machos, hembras, lote } = formLote;

    const handleChangeInput = ({ target }) => {
        setFormLote({
            ...formLote,
            [target.name]: target.value
        });
    }



    useEffect(() => {
        startLoadingEncubadora();
    }, []);


    useEffect(() => {
        if( id ){
            setActiveLote( id );
        }
    }, [id]);


    useEffect(() => {
        if( activeLote ){
            setFormLote({ 
                ...activeLote,
                encubadora: activeLote.encubadora._id
            });
        }
    }, [activeLote]);



    const actualizarLote = async(e) => {
        e.preventDefault();
        if( Number(cantidadTotal) !== (Number(machos) + Number(hembras)) ){
            return Swal.fire('Error', 'Las Cantidades No Coinciden, Verifique Por Favor', 'error');
        }
        await Swal.fire({
            title: 'Actualizar Lote',
            text: `¿Esta Seguro De Actualizar El Lote: ${ lote }?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                startSavingLote( formLote );
                navigate('/admin/list-lotes');
            }
        });
    }



    return (


        <LayoutReturn title='Actualizar Lote' url='/admin/list-lotes' >

            <form onSubmit={ actualizarLote } >
                <Grid container spacing={ 2 }>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Lote'
                            type='text'
                            placeholder='Lote'
                            fullWidth
                            disabled
                            name='lote'
                            value={ lote }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Factura'
                            type='text'
                            placeholder='Factura'
                            fullWidth
                            name='factura'
                            value={ factura }
                            onChange={ handleChangeInput }
                        />
                    </Grid>


                    {/* <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            id="outlined-select-currency"
                            select
                            label="Selecione Granja"
                            fullWidth
                            value={ granja }
                            name='granja'
                            onChange={ handleChangeInput }
                        >
                            <MenuItem value=''>
                                Selecione Granja
                            </MenuItem>
                            {
                            granjas.map( e => (
                            <MenuItem key={ e.id } value={ e.id } >
                                { e.nombre }
                            </MenuItem>
                            ))
                            }
                        </TextField>
                    </Grid> */}

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Cantidad Total'
                            type='number'
                            placeholder='Cantidad Total'
                            fullWidth
                            name='cantidadTotal'
                            value={ cantidadTotal }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Cantidad Machos'
                            type='number'
                            placeholder='Cantidad Machos'
                            fullWidth
                            name='machos'
                            value={ machos }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Cantidad Hembras'
                            type='number'
                            placeholder='Cantidad Hembras'
                            fullWidth
                            name='hembras'
                            value={ hembras }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            id="outlined-select-currency"
                            select
                            label="Selecione Encubadora"
                            fullWidth
                            value={ encubadora }
                            name='encubadora'
                            onChange={ handleChangeInput }
                        >
                            <MenuItem value=''>
                                Selecione Encubadora
                            </MenuItem>
                            {
                            encubadoras.map( e => (
                            <MenuItem key={ e.id } value={ e.id } >
                                { e.nombre }
                            </MenuItem>
                            ))
                            }
                        </TextField>
                    </Grid>

                </Grid>

                <Grid container 
                    sx={{ mb:2, mt:2 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit' >
                            Actualizar Lote
                        </Button>
                    </Grid>
                </Grid>

            </form>


        </LayoutReturn>




    )





}
