import { useNavigate } from "react-router-dom";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import { useEffect } from "react";
import { useUsuarioStore } from "../../../hooks";

import { BuscarUsuarios } from "./BuscarUsuarios";
import { AlertScreen } from "../../components/AlertScreen";
import { LayoutAdd } from "../../layout";
import Swal from "sweetalert2";





export const ListaUsuarios = () => {


    const navigate = useNavigate();
    const { usuarios, startLoadingUsuarios, deleteUsuario } = useUsuarioStore();


    useEffect(() => {
        startLoadingUsuarios();
    }, [])
    


    const actualizarUsuario = ( user ) => {
        navigate(`/admin/user-update/${ user.id }`);
    }


    
    const eliminarUsuario = ( user ) => {
        Swal.fire({
            title: '¿Eliminar Usuario?',
            text: `Esta Seguro De Eliminar a: ${ user.nombre }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Eliminar' 
        }).then( ( result ) => {
            if(result.value){
                deleteUsuario( user );
            }
        });
    }

    

    return (


        
        <LayoutAdd title='Listado de Usuario' url='/admin/usuario' >

            <BuscarUsuarios />

            { ( usuarios.length > 0 )
            ?<TableContainer>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">

                    <TableHead >
                        <TableRow>
                            <TableCell>NOMBRE</TableCell>
                            <TableCell>EMAIL</TableCell>
                            <TableCell>MOVIL</TableCell>
                            <TableCell>ROLE</TableCell>
                            <TableCell>OPCIONES</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    { usuarios.map( ( user ) => (
                        <TableRow  key={ user.id}>

                            <TableCell>{ user.nombre }</TableCell>   
                            <TableCell>{ user.email }</TableCell>
                            <TableCell>{ user.movil }</TableCell>
                            <TableCell>{ user.role }</TableCell>

                            <TableCell sx={{ width:'200px' }}>
                                <IconButton >
                                    <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                                </IconButton>

                                <IconButton color="success" onClick={ () => actualizarUsuario( user ) }>
                                    <UpdateIcon fontSize="small" />
                                </IconButton>

                                <IconButton color="error"  onClick={ () => eliminarUsuario( user ) } > 
                                    <DeleteIcon fontSize="small"/>
                                </IconButton>
                            </TableCell>

                        </TableRow>
                    ))}
                    </TableBody>

                </Table>
            </TableContainer>

            :<AlertScreen title="No Existen Datos De Usuario" />

            }

        </LayoutAdd>

    )


}
