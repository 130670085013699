
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2";
import { granjaApi } from "../api";
import { quitarComa } from "../helpers/quitarComa";
import { onAddNewProducto, onDeleteProducto, onLoandingProducto, onSetActiveProducto, onUpdateProducto } from "../store";





export const useProductoStore = () => {
  
    
    const dispatch = useDispatch();
    const { productos, activeProducto } = useSelector( state => state.producto );


    const setActiveProducto = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/producto/porid/${ uid }`);
            dispatch( onSetActiveProducto( data.producto ) );
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error)
        }
    }

    const actualizarDesdeModal = async( producto ) => {
        try {
            producto = {
                ...producto,
                precioSin: quitarComa(producto.precioSin)
            }
            const { data } = await granjaApi.put(`/producto/update/${ producto.id }`, producto);
            if( data.ok ){
                dispatch( onUpdateProducto( data.producto ) );
            }
            
        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    }


    const startSavingProducto = async( producto ) => {
        try {
            if( producto.id ){
                const { data } = await granjaApi.put(`/producto/update/${ producto.id }`, producto );
                if( data.ok ){
                    dispatch( onUpdateProducto( data.producto ) );
                    Swal.fire('Exitoso', 'Producto Actualizado', 'success');
                }
            }else {
                const { data } = await granjaApi.post('/producto/new', producto );
                if( data.ok ){
                    dispatch( onAddNewProducto( data.producto ) );
                    Swal.fire('Exitoso', 'Producto Creado', 'success');
                }
            }

        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    } 


    const startLoadingProducto = async() => {
        try {
            const { data } = await granjaApi.get('/producto/todos');
            dispatch( onLoandingProducto(data.productos) );
            
        } catch (error) {
            console.log('Error al Cargar Proveedores');
            console.log(error);
        }
    }



    const startProductoPorProveedor = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/producto/porproveedor/${ uid }`);
            dispatch( onLoandingProducto(data.producto) );
            
        } catch (error) {
            console.log('Error al Cargar Proveedores');
            console.log(error);
        }
    }



    const deleteProducto = async( producto ) => {
        try {
            const { data } = await granjaApi.delete(`/producto/${ producto.id }`);
            if( data.ok ){
                dispatch( onDeleteProducto( producto ) );
                Swal.fire('Exitoso', 'Producto Eliminado', 'success');
            }
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    return {
        //Propiedades
        productos,
        activeProducto,

        //Metodos
        startSavingProducto,
        startLoadingProducto,
        startProductoPorProveedor,
        deleteProducto,
        setActiveProducto,
        actualizarDesdeModal,
    }

}
