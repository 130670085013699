

import { Grid, TextField } from "@mui/material"
import { useState } from "react";
import { useBusquedas, useUsuarioStore } from "../../../hooks"



export const BuscarClientes = () => {


    const { startLoadingUsuariosCliente } = useUsuarioStore();
    const { busquedaPorClientes } = useBusquedas();
    

    const [valor, setValor] = useState('');

    const hanldeInputChange = ({ target }) => {
        setValor( target.value );
        if( target.value.trim().length > 0 ){
            busquedaPorClientes(target.value);
        }else {
            startLoadingUsuariosCliente();
        }
    }


    return (

        <Grid
            item
            xs={ 12 } sm={ 6 }
            sx={{ my: 4 }}
        >
            <TextField  
                autoComplete="off"
                label="Buscar Cliente..." 
                variant="filled" 
                fullWidth
                name="valor"
                value={ valor }
                onChange={ hanldeInputChange }
            />

        </Grid>


    )




}
