
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { yellowTheme } from './';




export const AppTheme = ({ children }) => {


    return (
    
        <ThemeProvider theme={ yellowTheme }>
            <CssBaseline />

            { children }
        </ThemeProvider>

    )



}
