

import { createSlice } from '@reduxjs/toolkit';



export const ingresoRaizSlice = createSlice({
    name: 'ingresoRaiz',
    initialState: {
        isLoadingIngresoRaiz: true,
        ingresos: [],
        activeIngresoRaiz: null
    },
    reducers:{
        onSetActiveIngresoRaiz: ( state, { payload } ) => {
            state.activeIngresoRaiz = payload;
        },

        onSetClearListaIngresoRaiz: ( state ) => {
            state.ingresos = [];
        },

        onSetEliminarIngresoRaiz: ( state ) => {
            state.activeIngresoRaiz = null;
        },
        
        onAddNewIngresoRaiz: ( state, { payload } ) => {
            state.ingresos.push( payload );
            state.activeIngresoRaiz = null;
        },


        onUpdateIngresoRaiz: ( state, { payload } ) => {
            state.ingresos = state.ingresos.map( raiz => {
                if( raiz.id === payload.id ){
                    return payload
                }
                return raiz
            });
        },


        onLoandingIngresoRaiz: ( state, { payload = [] } ) => {
            state.isLoadingIngresoRaiz = false;
            state.ingresos = payload;
        },

        onDeleteIngresoRaiz: ( state, { payload } ) => {
            state.ingresos = state.ingresos.filter( raiz => raiz.id !== payload.id );
        }

    }
});





export const { onSetActiveIngresoRaiz, onSetClearListaIngresoRaiz, onSetEliminarIngresoRaiz, onAddNewIngresoRaiz, onUpdateIngresoRaiz, onLoandingIngresoRaiz, onDeleteIngresoRaiz } = ingresoRaizSlice.actions;