

import { useEffect } from "react";

import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';


import { useModalStore, useUnidadMedidaStore } from "../../../hooks";
import { AdminLayout } from "../../layout/AdminLayout";
import Swal from "sweetalert2";




export const ListadoUnidadMedidas = () => {


    const { unidadMedidas, setActiveUnidadMedida, startLoadingUnidadMedida, deleteUnidadMedida } = useUnidadMedidaStore();
    const {abrirMedida } = useModalStore();


    useEffect(() => {
      startLoadingUnidadMedida();
    }, []);

    
    
    const crearUnidadMedida = () => {
      abrirMedida();
    }


    const eliminarMedida = ( uni ) => {
      Swal.fire({
          title: 'Eliminar Unidad Medida',
          text: `¿Esta Seguro De Eliminar La Medida: ${ uni.nombre }?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Eliminar' 
      }).then( ( result ) => {
          if(result.value){
            deleteUnidadMedida( uni );
          }
      });
    }


    const handleActualizarMedida = async( uid ) => {
        await setActiveUnidadMedida(uid);
        abrirMedida();
        // console.log(activeUnidadMedida);
    }




  return (


    <AdminLayout >

      <Grid
        container
        direccion='row'
        alignItems='center'
      >
          <IconButton
              sx={{
                  color: '#fff',
                  backgroundColor: 'error.main',
                  ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
              }}
              onClick={ crearUnidadMedida }
          >
              <AddOutlined />
          </IconButton>

          <Box flex={ 1 } />

          <Typography variant='h4' sx={{ mb:2, fontWeight:'bold' }} >Listado Unidad de Medidas</Typography>

          <Box flex={ 1 } />

      </Grid>

        

      <TableContainer>
        <Table sx={{ minWidth: 700, }}>

          <TableHead>
            <TableRow>
              <TableCell>NOMBRE</TableCell>
              <TableCell>OPCIONES</TableCell>
            </TableRow>
          </TableHead>

          { unidadMedidas && (
          <TableBody>
            { unidadMedidas.map( ( uni ) => (

              <TableRow key={ uni.id } >
                <TableCell> { uni.nombre } </TableCell>

                <TableCell>

                    <IconButton color="success" onClick={ () => handleActualizarMedida( uni.id ) } >
                      <UpdateIcon fontSize="small"/>
                    </IconButton>

                    <IconButton color="error" onClick={ () => eliminarMedida( uni ) } >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                </TableCell>
              </TableRow>

            ))}
          </TableBody>
          )}

        </Table>
      </TableContainer>

    </AdminLayout>


  )



}

