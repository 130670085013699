
import { useEffect, useState } from 'react';

import { Box, Grid, TextField, MenuItem, Button } from '@mui/material';
import { useUsuarioStore } from '../../../hooks';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';




export const FormPlanillaInfoConductor = ({ onChange }) => {



    const  { usuarios, clientes, conductores, startLoadingUsuariosConductor, startLoadingUsuarios, startLoadingUsuariosCliente } = useUsuarioStore();


    useEffect(() => {
        startLoadingUsuariosConductor();
    }, []);


    useEffect(() => {
        startLoadingUsuariosCliente();
    }, []);


    useEffect(() => {
        startLoadingUsuarios();
    }, [])


    const [formValues, setFormValues] = useState({
        ciudad: '',
        conductor: '',
        cliente: '',
        pesador: '',
        horaInicio: '',
        horaFinal: '',
    });
    const { ciudad, conductor, cliente, pesador } = formValues;
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);

    // console.log(horaInicio && horaInicio.$d.toLocaleTimeString() );


    const handleChanges = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

    
    useEffect(() => {
        setFormValues({
            ...formValues,
            horaInicio: start && start.$d.toLocaleTimeString(),
            horaFinal: end && end.$d.toLocaleTimeString(),
        })
    }, [start, end])

    

    useEffect(() => { 
        onChange({ 
            ...formValues
        }); 
    }, [formValues, onChange]);




    return (

        <Box sx={{ my:3 }}>
            {/* ref={ datosEnviados } */}
            <form  >   
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 12 } sm={4} mt={ 2 }>
                        <TextField
                            label="Ciudad"
                            fullWidth
                            name='ciudad'
                            value={ ciudad }
                            onChange={ handleChanges }
                        >    
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={4} mt={ 2 } >
                        <TextField required
                            select
                            label="Selecione Conductor"
                            fullWidth
                            value={ conductor }
                            name='conductor'
                            onChange={ handleChanges }
                        >
                            <MenuItem value=''>
                                Selecione Conductor
                            </MenuItem>
                            {
                            conductores.map( e => (
                            <MenuItem key={ e.id } value={ e.id } >
                                { e.nombre }
                            </MenuItem>
                            ))
                            }
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={4} mt={ 2 }>
                        <TextField
                            select
                            label="Selecione Cliente"
                            fullWidth
                            name='cliente'
                            value={ cliente }
                            onChange={ handleChanges }
                        >    
                            <MenuItem value=''>
                                Selecione Cliente
                            </MenuItem>
                            {
                            clientes.map( e => (
                            <MenuItem key={ e.id } value={ e.id } >
                                { e.nombre }
                            </MenuItem>
                            ))
                            }
                        </TextField>
                    </Grid>  

                    <Grid item xs={ 12 } sm={4} mt={ 2 }>
                        <TextField required
                            select
                            label="Selecione Pesador"
                            fullWidth
                            name='pesador'
                            value={ pesador }
                            onChange={ handleChanges }
                        >
                            <MenuItem value=''>
                                Selecione Pesador
                            </MenuItem>
                            {
                            usuarios.map( e => (
                            <MenuItem key={ e.id } value={ e.id } >
                                { e.nombre }
                            </MenuItem>
                            ))
                            }
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={4} mt={ 2 }>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Hora Inicio"
                                name='start'
                                value={ start }
                                // onChange={ handleChangeStart }
                                onChange={ (newTime) => setStart(newTime) }
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={ 12 } sm={4} mt={ 2 }>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label="Hora Final"
                                name='end'
                                value={ end }
                                // onChange={ handleChangeStart }
                                onChange={ (newTime) => setEnd(newTime) }
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>

                </Grid>

            </form>

        </Box>
    )

}


// export default forwardRef(FormPlanillaInfoConductor);