


const baseUrl = process.env.REACT_APP_API_URL;



export const descargaImagenes = (img, tipo) => {
    const url = `${ baseUrl }/uploads/${tipo}/${ img }`;
    fetch(url)
    .then(resp => resp.blob())
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${ tipo }`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('Hubo un Error al Descargar.'));
}