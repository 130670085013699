
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, CardActionArea, CircularProgress, Grid, Typography } from '@mui/material';
import { useDistribucionStore } from '../../../../hooks';
import { separadorMillares } from '../../../../hooks/hooks/useMillares';





export const DistribucionLote = () => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { distribuciones, startDistriPorLote } = useDistribucionStore();


    useEffect(() => {
        if( id ){
            startDistriPorLote(id);
        }
    }, [id]);
    


    const handleTotalDetalles = (dis) => {
        navigate(`/admin/det-total/${ dis.id }`)
    }


    return (


        <>

        {
            distribuciones.map( dis => (
            <Grid item md={3} sm={4} xs={12} key={ dis.id } textAlign='center'  >
                <CardActionArea onClick={ () => handleTotalDetalles(dis) } >
                <Box width='100%' sx={{ border:'2px solid orange', minHeight:'200px', padding:'10px' }}>
                    <Box width='100%' display='flex' justifyContent='center' alignItems='start' >
                        <Typography variant='h5' sx={{ fontWeight:'bold'  }}>
                            Galpon { dis.galpon.nombre }
                        </Typography>
                    </Box>
    
                    <Box width='100%' my={3} >
                        <Typography variant="h5" component="div" color='error.main'>
                            Cantidad Real 
                        </Typography>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress size={100} variant="determinate" value={ 100 - (dis.descuentos * 100 / dis.cantidad) } />
                            <Box
                                sx={{
                                top: 0, left: 0, bottom: 0, right: 0,
                                position: 'absolute', display: 'flex',
                                alignItems: 'center', justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" component="div" sx={{ fontSize: 21, fontWeight:'bold' }}>
                                    { separadorMillares(dis.cantidad - dis.descuentos) }
                                </Typography>
                            </Box>
                        </Box>
    
                    </Box>
    
                    <Box width='100%'>
                        <Typography component="div" color='error.main' sx={{ fontSize: 16, fontWeight:'bold' }}>
                            Cantidad Inicial { dis.genero }
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight:'bold' }}>
                            { separadorMillares(dis.cantidad) }
                        </Typography>
                    </Box>
    
                </Box>
            </CardActionArea>
            </Grid>
            ))
        }

        </>
    

    )


}
