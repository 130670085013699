

import { createSlice } from '@reduxjs/toolkit';



export const granjaSlice = createSlice({
    name: 'granja',
    initialState: {
        isLoadingGranja: true,
        granjas: [],
        activeGranja: null
    },
    reducers:{
        onSetActiveGranja: ( state, { payload } ) => {
            state.activeGranja = payload;
        },
        
        onAddNewGranja: ( state, { payload } ) => {
            state.granjas.push( payload );
            state.activeGranja = null;
        },

        onUpdateGranja: ( state, { payload } ) => {
            state.granjas = state.granjas.map( granja => {
                if( granja.id === payload.id ){
                    return payload
                }
                return granja
            });
        },

        onLoandingGranja: ( state, { payload = [] } ) => {
            state.isLoadingGranja = false;
            state.granjas = payload;
        },

        onDeleteGranja: ( state, { payload } ) => {
            // if( state.activeGranja ){
                state.granjas = state.granjas.filter( granja => granja.id !== payload.id );
            //     state.activeGranja = null;
            // }
        }
    }
});





export const { onSetActiveGranja, onAddNewGranja, onUpdateGranja, onDeleteGranja, onLoandingGranja } = granjaSlice.actions;