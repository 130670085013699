import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import { LayoutAddArchivar } from "../../../layout";
import { useLoteStore } from "../../../../hooks";
import { BuscarLotes } from "../components/BuscarLotes";
import { AlertScreen } from "../../../components/AlertScreen";
import Swal from "sweetalert2";




export const ListadoLotes = () => {


    const navigate = useNavigate();
    const { lotes, startLoadingLote, deleteLote, cambioEstadoLote } = useLoteStore();


    useEffect(() => {
        startLoadingLote();
    }, [])

    
    

    const eliminarLote = ( lote ) => {
      Swal.fire({
          title: 'Eliminar Lote',
          text: `¿Esta Seguro De Eliminar El Lote: ${ lote.lote }?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Eliminar' 
      }).then( ( result ) => {
          if(result.value){
            deleteLote( lote );
          }
      });
    }


    const handleActualizarLote = ( uid ) => {
      navigate(`/admin/lote-update/${ uid }`);
    }
    


    const handleInfoLote = ( uid ) => {
      navigate(`/admin/info/${uid}`);
    }


    const handleTrueFalse = (lote) => {
      Swal.fire({
        title: 'Archivar Lote',
        text: `¿Esta Seguro De Archivar Lote?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si' 
      }).then( ( result ) => {
          if(result.value){
            Swal.fire({
              title: 'Esta a Punto De Archivar El Lote',
              text: `Si Archiva El Lote No Puede Volver A Activarlo Y La Fecha Fin Sera Modificada`,
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Si, Archivar' 
          }).then( ( result ) => {
              if(result.value){
                cambioEstadoLote( lote.id );
              }
          });
          }
      });
    }



  return (

    <LayoutAddArchivar title='Listado de Lotes' url='/admin/lotes' url2='/admin/list-archiva'>
      

      <BuscarLotes activo={true} />


      { ( lotes.length > 0 )
      ?<TableContainer>
        <Table sx={{ minWidth: 700, }}>

          <TableHead>
            <TableRow>
              <TableCell>A.</TableCell>
              <TableCell>LOTE</TableCell>
              <TableCell>ENCUBADORA</TableCell>
              <TableCell>CANTIDAD TOTAL</TableCell>
              <TableCell>CANTIDAD MACHOS</TableCell>
              <TableCell>CANTIDAD HEMBRAS</TableCell>
              <TableCell>FECHA INICIO</TableCell>
              <TableCell>OPCIONES</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            { lotes.map( ( lote ) => (
              <TableRow key={ lote.id } >

                <TableCell>
                  { ( lote.activo ) 
                  ? <button className='boton-estado trru'
                      onClick={ () => handleTrueFalse(lote) }
                    >
                    </button>
                  : <button className='boton-estado fall'
                        onClick={ () => handleTrueFalse(lote) }
                    >
                    </button>
                  } 
                </TableCell>
                <TableCell> { lote.lote } </TableCell>
                <TableCell> { lote.encubadora?.nombre } </TableCell>
                <TableCell> { lote.cantidadTotal } </TableCell>
                <TableCell> { lote.machos } </TableCell>
                <TableCell> { lote.hembras } </TableCell>
                <TableCell> { lote.createdAt } </TableCell>

                <TableCell>
                    <IconButton onClick={ () => handleInfoLote( lote.id ) }>
                      <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                    </IconButton>

                    <IconButton color="success" onClick={ () => handleActualizarLote( lote.id ) } >
                      <UpdateIcon fontSize="small"/>
                    </IconButton>

                    <IconButton color="error" onClick={ () => eliminarLote( lote ) } >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                </TableCell>

              </TableRow>

            ))}
          </TableBody>

        </Table>
      </TableContainer>

      :<AlertScreen title="No Existen Datos De Lotes" />

      }

    </LayoutAddArchivar>

  )
}
