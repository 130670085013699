
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Grid, TextField, Backdrop, Box, Modal, Typography, MenuItem } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { adicionHoraMinuto } from '../../../../helpers';
import { useBodegaStore, useModalImgStore, useConsumo, useDistribucionStore, useInventarioStore } from '../../../../hooks';
import Swal from 'sweetalert2';





export const ModalConsumo = () => {


    const { id } = useParams();
    const { modalConsumo, closeModalConsumo } = useModalImgStore();         
    const { startSavingConsumo } = useConsumo();
    const { bodegas, startLoadingBodegasXGranja } = useBodegaStore();
    const { activeDistribucion } = useDistribucionStore();
    const { inventarios, startLoadingInventario } = useInventarioStore();
    const adicionarFecha = adicionHoraMinuto;



    
    const [formNombre, setFormNombre] = useState({
        cantidad: '',
        bodega: '',
        producto: '',
        lotegalpon: '',
        start: '',
    });
    const { cantidad, bodega, producto, start } = formNombre;
    const handleInputChange = ({ target }) => {
        setFormNombre({
            ...formNombre,
            [target.name]: target.value
        })
    }


    useEffect(() => {
        if( activeDistribucion ) {
            startLoadingBodegasXGranja(activeDistribucion.granja);
        }
    }, [ activeDistribucion ]);

    

    useEffect(() => {
        if(bodega) {
            startLoadingInventario(bodega);
        }
    }, [bodega]);


    const handleChangeStart = (e) => {
        const resultado = adicionarFecha(e.$d);
        setFormNombre({
            ...formNombre,
            start: resultado
        });
    }



    useEffect(() => {
        if( id ) {
            setFormNombre({
                ...formNombre,
                lotegalpon: id
            })
        }
    }, [ id ]);


    

    const handleCrearMedida = async(e) => {
        e.preventDefault();
    
        // if( bultos.trim().length < 1 ){ return }
        if( cantidad.trim().length < 1 || bodega.trim().length < 1 || producto.trim().length < 1 ){ 
            closeModalConsumo();
            Swal.fire('Error', 'Los Campos Son Obligatorios', 'error');
            return; 
        }

        closeModalConsumo();

        await Swal.fire({
            title: '¿Seguro de Hacer Descuento De Bodega?',
            text: `Esta Seguro De Hacer Este Consumo De Producto`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si ' 
        }).then( ( result ) => {
            if(result.value){
                startSavingConsumo( formNombre );
                closeModalConsumo();
                setFormNombre({
                    ...formNombre,
                    cantidad: '',
                    bodega: '',
                    producto: ''
                });
            }
        });
    }



    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ modalConsumo }
            onClose={ closeModalConsumo }
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Box className='modal-style2' >

                <Typography align='center' variant='h4' sx={{ mb:2, fontWeight:'bold'  }} >Crear Consumo</Typography>

                <form  onSubmit={ handleCrearMedida } >
                    <Grid container spacing={ 2 }>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                            <TextField required
                            autoComplete='off'
                                label='Cantidad'
                                type='number'
                                placeholder='Cantidad'
                                fullWidth
                                name='cantidad'
                                value={ cantidad }
                                onChange={ handleInputChange }
                            />
                        </Grid>

                        <Grid item xs={ 12 } sm={12} mt={ 2 } >
                            <TextField required
                                select
                                label="Selecione Bodega"
                                fullWidth
                                name='bodega'
                                value={ bodega }
                                onChange={ handleInputChange }
                            >
                                <MenuItem value=''>
                                    Selecione Bodega
                                </MenuItem>
                                {
                                bodegas
                                .filter( bo => bo.id !== '62eab14960e9636986e01a0a' )
                                .map( e => (
                                <MenuItem key={ e.id } value={ e.id } >
                                    { e.nombre }
                                </MenuItem>
                                ))
                                }
                            </TextField>
                        </Grid>

                        <Grid item xs={ 12 } sm={12} mt={ 2 } >
                            <TextField 
                                select
                                label="Selecione Producto"
                                fullWidth
                                name='producto'
                                value={ producto }
                                onChange={ handleInputChange }
                            >
                                <MenuItem value=''>
                                    Selecione Producto
                                </MenuItem>
                                {
                                inventarios.map( e => (
                                <MenuItem key={ e.id } value={ e.producto?._id } >
                                    { e.producto?.nombre }
                                </MenuItem>
                                ))
                                }
                            </TextField>
                        </Grid>

                        <Grid item xs={ 12 } sm={ 12 } mt={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Fecha de Mortalidad"
                                    value={ start }
                                    onChange={ handleChangeStart }
                                    renderInput={(params) => <TextField {...params} autoComplete='off' fullWidth /> }
                                    // disableFuture={true}
                                />
                            </LocalizationProvider>
                        </Grid>


                        <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                            <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                <Button variant='contained' fullWidth type='submit'>
                                    Crear Consumo
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                </form>
            </Box>
        </Modal>

    );



}