

import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useNotificacionStore, useProductoStore } from "../../../../hooks";
import { LayoutReturn } from "../../../layout";
import { TablaSalidas, TablaEntradas, ModalInfoRecibeEnvia } from "../components";




export const SalidasEntradasPorProducto = () => {

      
      const { id, pro }  = useParams();
      const  { loadingNotificacionQuienEnvia, loadingNotificacionQuienRecibe } = useNotificacionStore();
      const { activeProducto, setActiveProducto } = useProductoStore();


      
      useEffect(() => {
        if( id ){
            loadingNotificacionQuienEnvia({ id, pro });
        }
      }, [id]);
    
    
      useEffect(() => {
        if( id ){
          loadingNotificacionQuienRecibe({ id, pro });
        }
      }, [id] );


      useEffect(() => {
        if( pro ){
            setActiveProducto(pro);
        }
      }, [pro] );
    
    
    
    
      return (
    
          <>
          <LayoutReturn title={`Producto ${(activeProducto) ? activeProducto.nombre : 'Sin Nombre'}`} url={-1} >


                {/* Entradas de Productos  */}
                <TablaEntradas />
    
                
                {/* Salidas de Productos */}
                <TablaSalidas />


    
            </LayoutReturn>



            <ModalInfoRecibeEnvia />
            

            </>
    
    
      )
    
    
    
    }
    