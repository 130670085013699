
import { useDispatch, useSelector } from 'react-redux';
import { abrirModalAbono, abrirModalAbonoPlailla, abrirModalFactura, abrirModalMedida, abrirModalNota, abrirModalOp, abrirModalPedido, activePedidoDelete, activeUnidadMedidaClear, cerrarModaAbono, cerrarModaAbonoPlanilla, cerrarModaFactura, cerrarModalMedida, cerrarModalNota, cerrarModalOp, cerrarModalPedido, onActiveProductoClear, onCloseModal, onOpenModal, onSetEliminarInventario } from '../store';



export const useModalStore = () => {

    const dispatch = useDispatch();
    const { isModalOpen, modalMedida, modalOp, modalPedido, modalNota, modalFactura, modalAbono, modalPlanillaAbono } = useSelector( state => state.modal );


    const openModal = () => {
        dispatch( onOpenModal() );
    }

    const closeModal = () => {
        dispatch( onCloseModal() );
        dispatch( onSetEliminarInventario() );
    }


    const abrirMedida = () => {
        dispatch( abrirModalMedida() );
    }

    const cerrarMedida = () => {
        dispatch( cerrarModalMedida() );
        dispatch( activeUnidadMedidaClear() );
    }


    const openModalOP = () => {
        dispatch( abrirModalOp() );
    }
    

    const closeModalOP = () => {
        dispatch( cerrarModalOp() );
    }


    const openModalPedido = () => {
        dispatch( abrirModalPedido() );
    }

    
    const closeModalNota = () => {
        dispatch( cerrarModalNota() );
    }


    const openModalNota = () => {
        dispatch( abrirModalNota() );
    }

    const closeModalPedido = () => {
        dispatch( onActiveProductoClear() );
        dispatch( cerrarModalPedido() );
        dispatch( activePedidoDelete() );
    }


    const openModalFactura = () => {
        dispatch( abrirModalFactura() );
    }

    
    const closeModalFactura = () => {
        dispatch( cerrarModaFactura() );
    }



    const openModalAbono = () => {
        dispatch( abrirModalAbono() );
    }


    const closeModalAbono = () => {
        dispatch( cerrarModaAbono() );
    }

    
    
    const openModalAbonoPlanilla = () => {
        dispatch( abrirModalAbonoPlailla() );
    }


    const closeModalAbonoPlanilla = () => {
        dispatch( cerrarModaAbonoPlanilla() );
    }

    



    return {
        //Propiedades
        isModalOpen,
        modalMedida,
        modalOp,
        modalPedido,
        modalNota,
        modalFactura,
        modalAbono,
        modalPlanillaAbono,

        // Metodos
        openModal,
        closeModal,
        abrirMedida,
        cerrarMedida,
        openModalOP,
        closeModalOP,
        openModalPedido,
        closeModalPedido,
        closeModalNota,
        openModalNota,
        openModalFactura,
        closeModalFactura,
        openModalAbono,
        closeModalAbono,
        openModalAbonoPlanilla,
        closeModalAbonoPlanilla,
    }

}