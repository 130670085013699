
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { LayoutReturn } from '../../layout';

import { useProductoStore, useProveedorStore, useUnidadMedidaStore } from '../../../hooks';



  const initialState = {
    nombre: '',
    precioSin: '',
    unidadMedida: '',
    iva: '',
    proveedor: '',
    detalles: '',
  };



  export const CrearProducto = () => {


    const navigate = useNavigate();
    const { startSavingProducto } = useProductoStore();
    const { proveedores, startLoadingProveedores } = useProveedorStore();
    const { unidadMedidas, startLoadingUnidadMedida } = useUnidadMedidaStore();


    const [formProducto, setFormProducto] = useState( initialState );
    const { nombre, precioSin, proveedor, detalles, unidadMedida, iva } = formProducto;


    const  handleInputChange = ({ target }) => {
    setFormProducto({
            ...formProducto,
            [target.name]: target.value
        });
    }



    useEffect(() => {
        startLoadingProveedores();
    }, []);


    useEffect(() => {
        startLoadingUnidadMedida();
    }, []);


    const crearProducto = async(e) => {
        e.preventDefault();

        await startSavingProducto( formProducto );
        setFormProducto( initialState );
        console.log(formProducto);
    }



    return (
    
        <LayoutReturn title='Crear Producto' url='/admin/list-producto' >

            <form onSubmit={ crearProducto } >

                <Grid container spacing={4} >

                    <Grid item xs={ 12 } sm={ 6 } >
                        <TextField required
                            label='Nombre'
                            type='text'
                            placeholder='Nombre'
                            fullWidth
                            name='nombre'
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 }>
                        <TextField required
                        select
                        label="Proveedor"
                        fullWidth
                        name='proveedor'
                        value={ proveedor }
                        onChange={ handleInputChange }
                    >
                        <MenuItem value=''>Seleccione Proveedor</MenuItem>
                        {
                        proveedores.map( p => (
                            <MenuItem key={ p.id } value={ p.id }>{ p.nombre }</MenuItem>
                        ))
                        }
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 }>
                        <TextField required
                        select
                        label="Unidad Medida"
                        fullWidth
                        name='unidadMedida'
                        value={ unidadMedida }
                        onChange={ handleInputChange }
                    >
                        <MenuItem value=''>Seleccione Unidad Medida</MenuItem>
                        {
                        unidadMedidas.map( m => (
                            <MenuItem key={ m.id } value={ m.id }>{ m.nombre }</MenuItem>
                        ))
                        }
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 }>
                        <TextField required
                            label='Iva'
                            type='number'
                            placeholder='Iva'
                            fullWidth
                            name='iva'
                            value={ iva }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 }>
                        <TextField required
                            label='Precio Sin Iva'
                            type='number'
                            placeholder='Precio Sin Iva'
                            fullWidth
                            name='precioSin'
                            value={ precioSin }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } >
                        <TextField required
                            label="Detalles"
                            placeholder='Detalles del Producto'
                            multiline
                            fullWidth
                            name="detalles"
                            value={ detalles }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                </Grid>

                <Grid container 
                    sx={{ my:4 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit' >
                            Crear Producto
                        </Button>
                    </Grid>
                </Grid>

            </form>
            
        </LayoutReturn>

    )


    
  }
