
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Grid, MenuItem, TextField, Typography, Box, Backdrop, Modal } from '@mui/material';
import { useDistribucionStore, useGalponStore, useLoteStore, useModalImgStore } from '../../../../hooks';
import { NumberFormatCustomSoloNumber } from '../../../../hooks/hooks';





export const ModalDistriPollos = () => {



    const { id } = useParams();
    const { startSavingDistribucion } = useDistribucionStore();
    const { modalDistriPollos, closeModalDistribucion } = useModalImgStore();
    const { galpones, loadingGalponesPorGranja } = useGalponStore();
    const { activeLote } = useLoteStore();
    const soloNumber = NumberFormatCustomSoloNumber;



    useEffect(() => {
        if(activeLote){
            loadingGalponesPorGranja(activeLote.granja);
        }
    }, [activeLote])


    useEffect(() => {
        if(id){
            setFormPedido({
                ...formPedido,
                lote: id
            });
        }
    }, [id])



    const [formPedido, setFormPedido] = useState({
        cantidad: '',
        genero: '',
        lote: '',
        galpon: ''
    });
    const { genero, cantidad, galpon, lote } = formPedido;


    const handleInputChange = ({ target }) => {
        setFormPedido({
            ...formPedido,
            [target.name]: target.value
        })
    }


    const distribuirPollos = async(e) => {
        e.preventDefault();
        closeModalDistribucion();
        const resul = await startSavingDistribucion(formPedido);
        if( resul ){
            setFormPedido({
                ...formPedido,
                cantidad: '',
                galpon: '',
            });
        }
    }

    return (

        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={ modalDistriPollos }
                onClose={ closeModalDistribucion }
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Box className='modal-style2'>

                    <Typography align='center' variant='h4' sx={{ mb:2, fontWeight:'bold'  }} >Distribución</Typography>

                    <form  onSubmit={ distribuirPollos } >
                        <Grid container spacing={ 2 }>

                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    label='Genero'
                                    select
                                    fullWidth
                                    name='genero'
                                    value={ genero }
                                    id="formatted-numberformat-input"
                                    onChange={ handleInputChange }
                                >
                                    <MenuItem value=''>
                                        Selecione Genero
                                    </MenuItem>
                                    <MenuItem value='HEMBRA' >
                                        HEMBRA
                                    </MenuItem>
                                    <MenuItem value='MACHO' >
                                        MACHO
                                    </MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    label='Cantidad de Pollos'
                                    placeholder='Cantidad'
                                    fullWidth
                                    name='cantidad'
                                    value={ cantidad }
                                    onChange={ handleInputChange }
                                    InputProps={{
                                        inputComponent: soloNumber,
                                    }}
                                />
                            </Grid>
                            
                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    select
                                    label="Selecione Galpon"
                                    fullWidth
                                    value={ galpon }
                                    name='galpon'
                                    onChange={ handleInputChange }
                                >
                                    <MenuItem value=''>
                                        Selecione Galpon
                                    </MenuItem>
                                    {
                                    galpones
                                    // .filter( gal => gal.id !== activeInventario?.bodega._id )
                                    .map( e => (
                                    <MenuItem key={ e.id } value={ e.id } >
                                        { e.nombre }
                                    </MenuItem>
                                    ))
                                    }
                                </TextField>
                            </Grid>

                            <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                                <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                    <Button variant='contained' fullWidth type='submit'>
                                        Distribuir Ahora
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    );

}