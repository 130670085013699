


import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { AdminLayout } from "../../../layout/AdminLayout";
import {  useFacturaStore } from "../../../../hooks";
import { TablaProductosFactura } from "../components/TablaProductosFactura";




export const VerFactura = () => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { activeFactura, activeFacturaStart} = useFacturaStore();


    useEffect(() => {
        if(id){
            activeFacturaStart(id);
        }
    }, [id] );



    const regresar = () => {
        navigate('/admin/facturas');
    }


    return (

        <AdminLayout >

            <Grid 
            container
            direccion='row'
            alignItems='center'
            >
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ regresar }
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h4' sx={{ mb:2, fontWeight:'bold'  }} >
                    Facturas
                </Typography>

                <Box flex={ 1 } />
            </Grid>

            <TableContainer>
            <Table sx={{ minWidth: 700, }} aria-label="customized table">

                <TableHead >
                    <TableRow>
                        {/* <TableCell>A.</TableCell> */}
                        <TableCell>#FACTURA</TableCell>
                        <TableCell>FORMA DE PAGO</TableCell>
                        <TableCell>DIAS</TableCell>
                        <TableCell>PAGO</TableCell>
                        <TableCell>CREACION DE FACTURA</TableCell>
                        <TableCell>VENCIMIENTO</TableCell>
                        <TableCell>FECHA DE PAGO</TableCell>
                    </TableRow>
                </TableHead>

                { activeFactura &&(
                <TableBody>
                    <TableRow key={ activeFactura.id } >
                        <TableCell>{ activeFactura.numero }</TableCell>   
                        <TableCell>{ activeFactura.formaPago }</TableCell>
                        <TableCell>{ activeFactura.cuantosDias }</TableCell>
                        <TableCell>{ activeFactura.pago }</TableCell>
                        <TableCell>{ activeFactura.createdAt }</TableCell>
                        <TableCell>
                            { 
                            (activeFactura.vencimiento)
                            ? activeFactura.vencimiento
                            : <span>----/----/----</span> 
                            }
                        </TableCell>
                        <TableCell>
                            { 
                            (activeFactura.fechaPago)
                            ? activeFactura.fechaPago
                            : <span>----/----/----</span> 
                            }
                        </TableCell>
                    </TableRow>
                </TableBody>
                )}

            </Table>
            </TableContainer>

            {
            activeFactura &&
            <TablaProductosFactura productos={ activeFactura.productos } />
            }
                
        </AdminLayout>

    )

}
