
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Grid, IconButton, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';

import { useConsumo, useModalImgStore } from '../../../../hooks';
import { AlertScreen } from '../../../components';
import { ModalConsumo } from '../components/ModalConsumo';
import moment from 'moment';
import Swal from 'sweetalert2';



export const ConsumoScreen = () => {


    const { id } = useParams();
    const { openModalConsumo } = useModalImgStore(); 
    const { consumos, startLoadingConsumoLoteGalpon, deleteConsumoLote } = useConsumo(); 



    useEffect(() => {
        if( id ){
            startLoadingConsumoLoteGalpon(id);
        }
    }, [id])
    

    const crearConsumo = () => {
        openModalConsumo();
    }


    const deleteConsumo = (c) => {
        Swal.fire({
            title: `Eliminar Consumo`,
            text: `¿Esta Seguro De Eliminar este Consumo?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Eliminar' 
        }).then( ( result ) => {
            if(result.value){
                deleteConsumoLote( c.id );
            }
        });
    }



    return (
    
        <>
            <Grid container direccion='row' alignItems='center' mb={3}>
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ crearConsumo }
                >
                    <AddOutlined />
                </IconButton>

                {/* <OpcionesConsumo /> */}

                <Box flex={ 1 } />

                <Typography variant='h1'>Informacion de Consumo</Typography>

                <Box flex={ 1 } />
            </Grid>

            { ( consumos.length > 0 )
            ?<TableContainer>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">

                    <TableHead >
                        <TableRow>
                            <TableCell>Semana</TableCell>
                            <TableCell>Producto</TableCell>
                            <TableCell>Sugerido</TableCell>
                            <TableCell>Cantidad</TableCell>
                            <TableCell>Bodega</TableCell>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Opciones</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    { consumos.map( ( c ) => (
                        <TableRow key={ c.id } >
                            <TableCell>{ c.semana }</TableCell>   
                            <TableCell>{ c.producto?.nombre }</TableCell>
                            <TableCell>{ c.sugerido }</TableCell>
                            <TableCell>{ c.cantidad }</TableCell>
                            <TableCell>{ c.bodega.nombre }</TableCell>
                            <TableCell>{ moment(c.createdAt).format('lll')  }</TableCell>

                            <TableCell sx={{ width:'200px' }}>
                                {/* <IconButton >
                                    <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                                </IconButton> */}
                                <IconButton color="error" onClick={ () => deleteConsumo(c) }>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>

                </Table>
            </TableContainer>

            : <AlertScreen title="No Hay Registros En El Momento." />

            }

            <ModalConsumo />


        </>
    
    
    
        )
    

}
