import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import { useAbonoStore, useFacturaStore } from "../../../../hooks";
import { separadorMillares } from "../../../../hooks/hooks/useMillares";
import { useState } from "react";





export const TablaInformacionFactura = () => {

    const { id } = useParams();
    const { activeFactura, activeFacturaStart } = useFacturaStore();
    const { abonos, startLoadingAbonos } = useAbonoStore();


    const [abon, setAbon] = useState(0)


    useEffect(() => {
        if(id){
            activeFacturaStart(id);
        }
    }, [id]);


    useEffect(() => {
        if( id ){
            startLoadingAbonos(id);
        }
    }, [id]);

    useEffect(() => {
        if( abonos ){
            const abonosTotal = abonos.reduce( (total, ini) => total + ini.valor, 0 );
            setAbon(abonosTotal);
        }
    }, [abonos]);




    
    return (

        <>
            {
            activeFactura &&  
            <TableContainer>
            <Table sx={{ minWidth: 700, }} aria-label="customized table">

                <TableHead >
                <TableRow>
                    <TableCell>#FACTURA</TableCell>
                    <TableCell>PAGO TOTAL</TableCell>
                    <TableCell>ABONOS</TableCell>
                    <TableCell>SALDO</TableCell>
                </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>

                        <TableCell>{ activeFactura.numero }</TableCell>   
                        <TableCell>{ `$${ separadorMillares(activeFactura.totalPago) }` }</TableCell>
                        <TableCell>{ `$${ separadorMillares(abon) }` }</TableCell>
                        <TableCell>{ `$${ separadorMillares((activeFactura.totalPago - abon)) }` }</TableCell>

                    </TableRow>
                </TableBody>
            </Table>
            </TableContainer>
            }
        </>



    )




}
