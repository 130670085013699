
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { LayoutReturn } from '../../layout';
import { useUsuarioStore } from '../../../hooks';
import Swal from 'sweetalert2';
import { roles_select } from '../../../data';





export const ActualizarUsuario = () => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { activeUsuario, startSavingUsuario, setActiveUsuario } = useUsuarioStore();


    useEffect(() => {
        if( id ){
            setActiveUsuario( id )
        }
    }, [ id ] );


    useEffect(() => {
        if( activeUsuario ){
            setFormUser( activeUsuario );
        }
    }, [activeUsuario] );
    

    const [formUser, setFormUser] = useState({
        nombre: "",
        cedula: '',
        email: "",
        role: "",
        direccion: "",
        ciudad: "",
        movil: "",
    });
    const  { nombre, email, cedula, role, direccion, ciudad, movil } = formUser;

    const handleInputChange = ({ target }) => {
        setFormUser({
            ...formUser,
            [target.name]: target.value
        });
    }


    const handleUpdateUser = async(e) => {
        e.preventDefault();
        await Swal.fire({
            title: 'Acualizar Usuario?',
            text: `Esta Seguro De Actualizar a: ${ nombre }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                startSavingUsuario( formUser );
                navigate(-1);
            }
        });
    }

    return (

        <LayoutReturn title='Actualizar Usuario' url='/admin/list-user' >

            <form onSubmit={ handleUpdateUser } >
                <Grid container spacing={ 2 }>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Nombre Completo'
                            type='text'
                            placeholder='Nombre Completo'
                            fullWidth
                            name='nombre'
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Cedula'
                            type='text'
                            placeholder='Cedula'
                            fullWidth
                            name='cedula'
                            value={ cedula }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Email'
                            type='email'
                            placeholder='Email'
                            fullWidth
                            name='email'
                            value={ email }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            id="outlined-select-currency"
                            select
                            label="Seleccione Role"
                            fullWidth
                            name='role'
                            value={ role }
                            onChange={ handleInputChange }
                        >
                            <MenuItem value=''>
                                Seleccione Role
                            </MenuItem>
                            {roles_select.map( rol => (
                                 <MenuItem key={rol} value={rol}>
                                    {rol}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Direccion'
                            type='text'
                            placeholder='Direccion'
                            fullWidth
                            name='direccion'
                            value={ direccion }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Ciudad'
                            type='text'
                            placeholder='Ciudad'
                            fullWidth
                            name='ciudad'
                            value={ ciudad }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Telefono'
                            type='number'
                            placeholder='Telefono'
                            fullWidth
                            name='movil'
                            value={ movil }
                            onChange={ handleInputChange }
                        />
                    </Grid>
                    
                </Grid>

                <Grid container 
                    sx={{ mb:2, mt:2 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit'>
                            Actualizar Usuario
                        </Button>
                    </Grid>
                </Grid>

            </form>
            
        </LayoutReturn>
    )



}
