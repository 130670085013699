

import { createSlice } from '@reduxjs/toolkit';



export const descarteSlice = createSlice({
    name: 'descarte',
    initialState: {
        isLoadingDescarte: true,
        descartes: [],
        activeDescarte: null
    },
    reducers:{
        onSetActiveDescarte: ( state, { payload } ) => {
            state.activeDescarte = payload;
        },
        
        onAddNewDescarte: ( state, { payload } ) => {
            state.descartes.push( payload );
            state.activeDescarte = null;
        },

        // onUpdateDescarte: ( state, { payload } ) => {
        //     state.descartes = state.descartes.map( descarte => {
        //         if( descarte.id === payload.id ){
        //             return payload
        //         }
        //         return descarte
        //     });
        // },

        onLoandingDescarte: ( state, { payload = [] } ) => {
            state.isLoadingDescarte = false;
            state.descartes = payload;
        },

    }
});





export const { onSetActiveDescarte, onAddNewDescarte, onUpdateDescarte, onLoandingDescarte } = descarteSlice.actions;