
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Typography } from "@mui/material";

import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { AdminLayout } from "../../../layout/AdminLayout";
import {  useFacturaStore } from "../../../../hooks";
import { separadorMillares } from "../../../../hooks/hooks/useMillares";
import { FacturasTotalesPrecioFiltros } from "../components/FacturasTotalesPrecioFiltros";
import { AlertScreen } from "../../../components/AlertScreen";
import { OpcionesDeFiltro } from "../components";

import { generarPDF } from "../../../../hooks/hooks";
import Swal from 'sweetalert2';



export const FiltrosFacturas = () => {


    const divRef = useRef();
    const navigate = useNavigate();
    const { facturas, updateEstadoFactura, startLoadingFacturas } = useFacturaStore();
    

    
    useEffect(() => {
        startLoadingFacturas();
    }, [] );

    
    const verInfoFactura = (id) => {
        navigate(`/admin/factura/${ id }`);
    }


    const verAbonosFactura = (uid) => {
        navigate(`/admin/abonos/${ uid }`);
    }


    const updateFactura = ( uid ) => {
        Swal.fire({
        title: 'Archivar Factura',
        text: `¿Esta Seguro De Archivar Esta Factura?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si' 
        }).then( ( result ) => {
            if(result.value){
            Swal.fire({
                title: 'Esta a Punto De Archivar La Factura',
                text: `Si Archiva La Factura No Puede Volver A Activarla.`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Si, Archivar' 
            }).then( ( result ) => {
                if(result.value){
                updateEstadoFactura(uid);
                }
            });
            }
        });
    }


    const generarArchivo = () => {
        generarPDF(divRef.current, 'Reporte de Facturas');
    }


    const handleReturn = () => {
        navigate('/admin/facturas');
    }


    return (

        <AdminLayout >
            <Grid 
                container
                direccion='row'
                alignItems='center'
            >
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ handleReturn }
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h1' sx={{ mb:2, fontWeight:'bold' }} >
                    Filtro de Facturas
                </Typography>

                <Box flex={ 1 } />
                
            </Grid>

            <OpcionesDeFiltro />

            <div ref={ divRef } >

                { facturas && (
                <FacturasTotalesPrecioFiltros />
                )}

                { ( facturas?.length > 0 )
                ?<TableContainer>
                    <Table sx={{ minWidth: 700, }} aria-label="customized table">
                        <TableHead >
                            <TableRow>
                                <TableCell>A.</TableCell>
                                <TableCell>#FACTURA</TableCell>
                                <TableCell>PROVEEDOR</TableCell>
                                <TableCell>PAGO TOTAL</TableCell>
                                <TableCell>ABONO TOTAL</TableCell>
                                <TableCell>SALDO</TableCell>
                                <TableCell>CREACION DE FACTURA</TableCell>
                                <TableCell>VENCIMIENTO</TableCell>
                                <TableCell>OPCIONES</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                        { facturas.map( ( f ) => (
                        <TableRow key={ f.id } >
                            <TableCell>
                            { ( f.estado ) 
                            ? <button className='boton-estado trru'
                                onClick={ () => updateFactura(f.id) }
                                >
                                </button>
                            : <button className='boton-estado fall'
                                >
                                </button>
                            } 
                            </TableCell>
                            <TableCell>{ f.numero }</TableCell>   
                            <TableCell>{ f.proveedor.nombre }</TableCell>
                            <TableCell>{ `$${ separadorMillares(f.totalPago) }` }</TableCell>
                            <TableCell>{ `$${ separadorMillares(f.totalAbonos) }` }</TableCell>
                            <TableCell>{ `$${ separadorMillares(f.totalPago - f.totalAbonos) }` }</TableCell>
                            <TableCell>{ f.createdAt }</TableCell>
                            <TableCell>
                                { 
                                (f.vencimiento)
                                ? f.vencimiento
                                : <span>----/----/----</span> 
                                }
                            </TableCell>
                            <TableCell sx={{ width:'200px' }}>
                                <IconButton onClick={ () => verInfoFactura(f.id) } >    
                                    <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                                </IconButton>

                                <IconButton onClick={ () => verAbonosFactura(f.id) } >    
                                    <AddCircleIcon fontSize="small" sx={{ color: '#4caf50' }}/>
                                </IconButton>
                            </TableCell>

                        </TableRow>
                        ))
                        }
                        </TableBody>
                    </Table>
                </TableContainer>

                :<AlertScreen title={`No hay Facturas Existentes`} />

                }
            </div>


            <Grid container 
                sx={{my:5 }} 
                direccion='column'
                justifyContent='center' 
            >
                <Grid item xs={ 12 } sm={ 7 } >
                    <Button variant='contained' fullWidth
                        onClick={ generarArchivo }
                    >
                        Descargar
                    </Button>
                </Grid>
            </Grid>

        </AdminLayout>

    )


}
