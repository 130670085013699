
import { useEffect, useState } from "react";

import { Button, Grid, MenuItem, TextField } from "@mui/material";


import { useEncubadoraStore, useGranjaStore, useLoteStore } from "../../../../hooks";
import { LayoutReturn } from "../../../layout";

import Swal from "sweetalert2";



const initialState = {
    granja: '',
    encubadora: '',
    cantidadTotal: '',
    factura: '',
    machos: '',
    hembras: '',
}


export const LotesScreen = () => {


    const { startSavingLote } = useLoteStore();
    const { granjas, startLoadingGranjas } = useGranjaStore();
    const { encubadoras, startLoadingEncubadora } = useEncubadoraStore();



    const [formLote, setFormLote] = useState(initialState)
    const  { granja, encubadora, cantidadTotal, factura, machos, hembras } = formLote;

    const handleChangeInput = ({ target }) => {
        setFormLote({
            ...formLote,
            [target.name]: target.value
        });
    }


    useEffect(() => {
        startLoadingGranjas();
    }, [] );

    useEffect(() => {
        startLoadingEncubadora();
    }, []);



    const handleCrearLote = async(e) => {
        e.preventDefault();

        if( Number(cantidadTotal) !== (Number(machos) + Number(hembras)) ){
            return Swal.fire('Error', 'Las Cantidades No Coinciden, Verifique Por Favor', 'error');
        }
        await startSavingLote( formLote );
        setFormLote( initialState );
    }


    return (


        <LayoutReturn title='Crear Lote' url='/admin/list-lotes' >

            <form className="form-100" onSubmit={ handleCrearLote } >
                <Grid container spacing={ 2 }>
                
                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Factura'
                            type='text'
                            placeholder='Factura'
                            fullWidth
                            name='factura'
                            value={ factura }
                            onChange={ handleChangeInput }
                        />
                    </Grid>


                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            id="outlined-select-currency"
                            select
                            label="Selecione Granja"
                            fullWidth
                            value={ granja }
                            name='granja'
                            onChange={ handleChangeInput }
                        >
                            <MenuItem value=''>
                                Selecione Granja
                            </MenuItem>
                            {
                            granjas.map( e => (
                            <MenuItem key={ e.id } value={ e.id } >
                                { e.nombre }
                            </MenuItem>
                            ))
                            }
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Cantidad Total'
                            type='number'
                            placeholder='Cantidad Total'
                            fullWidth
                            name='cantidadTotal'
                            value={ cantidadTotal }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Cantidad Machos'
                            type='number'
                            placeholder='Cantidad Machos'
                            fullWidth
                            name='machos'
                            value={ machos }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Cantidad Hembras'
                            type='number'
                            placeholder='Cantidad Hembras'
                            fullWidth
                            name='hembras'
                            value={ hembras }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            select
                            label="Selecione Incubadora"
                            fullWidth
                            value={ encubadora }
                            name='encubadora'
                            onChange={ handleChangeInput }
                        >
                            <MenuItem value=''>
                                Selecione Incubadora
                            </MenuItem>
                            {
                            encubadoras.map( e => (
                            <MenuItem key={ e.id } value={ e.id } >
                                { e.nombre }
                            </MenuItem>
                            ))
                            }
                        </TextField>
                    </Grid>
                </Grid>

                <Grid container 
                    sx={{ my:3 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit' >
                            Crear Lote
                        </Button>
                    </Grid>
                </Grid>

            </form>

        </LayoutReturn>

    )

}
