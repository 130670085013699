
import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import Swal from "sweetalert2";
import { onAddNewNotificacion, onDeleteNotificacion, onLoandingNotificacion, onLoandingNotificacionEntradas, onLoandingNotificacionSalidas, onSetActiveNotificacion } from "../store";
import { fechaNotificacion, fechaNotificacionUno } from "../helpers/prepararFecha";
import { useSocket } from "./";

// import { useEffect } from "react";
// import io from 'socket.io-client';
// let socket;
const baseSocket = process.env.REACT_APP_SOCKET_IO_URL;



export const useNotificacionStore = () => {

    const { socket } = useSocket(baseSocket);
    const { notificaciones, activeNotificacion, salidas, entradas } = useSelector( state => state.notificacion );
    const dispatch = useDispatch();



    // useEffect(() => {
    //     // TODO: Cambiar la variabel por variable de Entorno
    //     socket = io(baseSocket);
    // }, []);


    const setActiveNotaEnvio = async( noti ) => {
        
        try {
            // const { data } = await granjaApi.get(`/usuario/porid/${ uid }`);
            // if( data.ok ){
            dispatch( onSetActiveNotificacion( noti ) );
            // }

        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }




    const startSavingNotificacion = async( not ) => {
        try {
            if( not.id ){
                // const { data } = await granjaApi.put(`/bodega/${ bodega.id }`, bodega );
                // if( data.ok ){
                //     dispatch( onUpdateBodega( data.bodega ) );
                //     Swal.fire('Exitoso', 'Bodega Actualizada', 'success');
                // }
            }else {
                const { data } = await granjaApi.post('/notificacion/new', { ...not } );
                if( data.ok ){
                    const noti = fechaNotificacionUno(data.notificacion);
                    // Socket io
                    socket.emit('nueva-notificacion', noti);
                    Swal.fire('Exitoso', 'Notificacion Creada', 'success');
                }
            }
        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    } 


    
    const startLoadingNotificacion = async() => {
        try {
            const { data } = await granjaApi.get(`/notificacion/todos`);
            const notificacines = fechaNotificacion(data.notificacion);
            dispatch( onLoandingNotificacion( notificacines ) );
            
        } catch (error) {
            console.log('Error al Cargar bodegas');
            console.log(error);
        }
    }



    
    const loadingNotificacionQuienEnvia = async( envia ) => {
        try {
            const { data } = await granjaApi.get(`/notificacion/envia/${ envia.id }/${ envia.pro }`);
            // const notificacines = fechaNotificacion(data.notificacion);
            dispatch( onLoandingNotificacionSalidas( data.notificacion ) );
            
        } catch (error) {
            console.log('Error al Cargar bodegas');
            console.log(error);
        }
    }


    const loadingNotificacionQuienRecibe = async( envia ) => {
        try {
            const { data } = await granjaApi.get(`/notificacion/recibe/${ envia.id }/${ envia.pro }`);
            // const notificacines = fechaNotificacion(data.notificacion)
            dispatch( onLoandingNotificacionEntradas( data.notificacion ) );
        } catch (error) {
            console.log('Error al Cargar bodegas');
            console.log(error);
        }
    }



    const deleteNotificacion = async( not ) => {
        try {
            const { data } = await granjaApi.delete(`/notificacion/${ not.id }`);
            if( data.ok ){
                // Socket io
                socket.emit('eliminar-notificacion', data.notificacion);
                Swal.fire('Exitoso', 'Notificacion Eliminada', 'success');
            }
        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    }




    //==============================   Eventos de Sockets   ================================================
    const agregarNotaDeSocket = ( noti ) => {
        dispatch( onAddNewNotificacion( noti ) );
    }

    const notaEliminadaDeSocket = ( noti ) => {
        dispatch( onDeleteNotificacion( noti ) );
    }



    return {
        //Propiedades
        notificaciones,
        activeNotificacion,
        salidas,
        entradas,

        //Metodos
        startLoadingNotificacion,
        deleteNotificacion,
        startSavingNotificacion,
        loadingNotificacionQuienEnvia,
        loadingNotificacionQuienRecibe,
        setActiveNotaEnvio,

        // Socket
        agregarNotaDeSocket,
        notaEliminadaDeSocket,
    }


}
