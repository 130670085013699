
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Grid, MenuItem, TextField, Typography, Box, Backdrop, Modal } from '@mui/material';
import { useModalStore, useProveedorStore } from '../../hooks';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};




export const ModalFactura = ({ pasarDatos }) => {


    const { id } =useParams();
    const { modalFactura, closeModalFactura } = useModalStore();
    const { proveedores, startLoadingProveedores } = useProveedorStore();

    



    const [formPedido, setFormPedido] = useState({
        numero: '',
        proveedor: '',
        formaPago: '',
        cuantosDias: '',
        op: '',
        // lote: '',
    });
    const { numero, proveedor, formaPago, cuantosDias } = formPedido;

    const handleInputChange = ({ target }) => {
        setFormPedido({
            ...formPedido,
            [target.name]: target.value
        })
    }



    useEffect(() => {
        startLoadingProveedores();
    }, []);


    useEffect(() => {
        if( id ){
            setFormPedido({
                ...formPedido,
                op: id
            });
        }
    }, [id]);


    const pasarDatosHijo = (e) => {
        e.preventDefault();
        pasarDatos(formPedido);
        closeModalFactura();
        setFormPedido({
            ...formPedido,
            numero: '',
            proveedor: '',
            formaPago: '',
            cuantosDias: '',
        });
    }


    return (

        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={ modalFactura }
                onClose={ closeModalFactura }
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Box sx={ style }>

                    <Typography align='center' variant='h4' sx={{ mb:2, fontWeight:'bold'  }} >Factura</Typography>
        
                    <form onSubmit={ pasarDatosHijo } >

                        <Grid container spacing={ 2 }>
                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                                <TextField required
                                    label="Numero de Factura"
                                    fullWidth
                                    name='numero'
                                    value={ numero }
                                    onChange={ handleInputChange }
                                >
                                </TextField>
                            </Grid>

                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    select
                                    label="Forma de Pago"
                                    fullWidth
                                    value={ formaPago }
                                    name='formaPago'
                                    onChange={ handleInputChange }
                                >
                                    <MenuItem value=''>
                                        Selecione Pago
                                    </MenuItem>
                                    <MenuItem value='CREDITO'>
                                        CREDITO
                                    </MenuItem>
                                    <MenuItem value='CONTADO'>
                                        CONTADO
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            
                            {
                            (formaPago === 'CREDITO') &&
                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField 
                                    label='Cuantos Dias'
                                    type='number'
                                    placeholder='Cuantos Dias'
                                    fullWidth
                                    name='cuantosDias'
                                    value={ cuantosDias }
                                    onChange={ handleInputChange }
                                />
                            </Grid>
                            }

                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    select
                                    label="Proveedor"
                                    fullWidth
                                    value={ proveedor }
                                    name='proveedor'
                                    onChange={ handleInputChange }
                                >
                                    <MenuItem value=''>
                                        Selecione Proveedor
                                    </MenuItem>
                                    {
                                    proveedores.map( e => (
                                    <MenuItem key={ e.id } value={ e.id } >
                                        { e.nombre }
                                    </MenuItem>
                                    ))
                                    }
                                </TextField>
                            </Grid>

                            {/* <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    select
                                    label="Lote"
                                    fullWidth
                                    value={ lote }
                                    name='lote'
                                    onChange={ handleInputChange }
                                >
                                    {
                                    lotes.map( e => (
                                    <MenuItem key={ e.id } value={ e.id } >
                                        { e.lote }
                                    </MenuItem>
                                    ))
                                    }
                                </TextField>
                            </Grid> */}

                            <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                                <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                    <Button variant='contained' fullWidth type='submit'>
                                        Crear Factura
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    );

}