
import { useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';

import { Grid, TextField, Button, InputAdornment } from '@mui/material';
import Swal from 'sweetalert2';



export const FormPlanillaDestaresCantidad = ({ datosEnviadosDos }) => {


    const planillas = JSON.parse(localStorage.getItem('planilla')) || [];


    
    const [informacion, setInformacion] = useState({
        huacales: '',
        avesXhuacal: '',
        pesoAvesHuacal: '',
        destare: '',
    });
    const { huacales, avesXhuacal, pesoAvesHuacal, destare } = informacion;


    const handleChangesInformacion = ({ target }) => {
        setInformacion({
            ...informacion,
            [target.name]: target.value
        });
    }



    const handleAgregarInfo = () => {

        const cant = JSON.parse(localStorage.getItem('cantidad')) || '';
        const info = Number(informacion.huacales) * Number(informacion.avesXhuacal);
        const resultado = planillas.reduce( ( total, item ) => Number(total) + (Number(item.avesXhuacal) * Number(item.huacales)), 0 );

        if( [huacales.trim(), avesXhuacal.trim(), pesoAvesHuacal.trim(), destare.trim()].includes('') ) {
            return Swal.fire('Error', 'Los Campos de Planilla Son Obligatorios', 'error');
        }

        if( cant < (resultado + info) ){
            return Swal.fire('Error', 'Los Items Superan La Cantidad En Pollos', 'error');
        }
        
        datosEnviadosDos(informacion);
        setInformacion({
            huacales: '',
            avesXhuacal: '',
            pesoAvesHuacal: '',
            destare: '',
        });
    }


    return (

        <form onSubmit={ handleAgregarInfo } >

            <Grid container spacing={ 2 }>

                <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                    <TextField 
                        label="Numero Huacales"
                        fullWidth
                        type="number"
                        placeholder='Numero Huacales'
                        name='huacales'
                        value={ huacales }
                        onChange={ handleChangesInformacion }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">Uni.</InputAdornment>,
                        }}
                    >
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={6} mt={ 2 }>
                    <TextField
                        label="Aves X Huacal"
                        fullWidth
                        type="number"
                        placeholder='Aves X Huacal'
                        name='avesXhuacal'
                        value={ avesXhuacal }
                        onChange={ handleChangesInformacion }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">Uni.</InputAdornment>,
                        }}
                    >
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                    <TextField
                        label="Peso Aves X Huacal"
                        fullWidth
                        placeholder='Peso Aves X Huacal'
                        name='pesoAvesHuacal'
                        value={ pesoAvesHuacal }
                        onChange={ handleChangesInformacion }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                        }}
                    >
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={6} mt={ 2 }>
                    <TextField
                        label="Destare"
                        fullWidth
                        type="number"
                        placeholder='Destare'
                        name='destare'
                        value={ destare }
                        onChange={ handleChangesInformacion }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                        }}
                    >
                    </TextField>
                </Grid>


                <Grid container direction='row' justifyContent='end' >
                    <Grid item xs={ 12 } sm={4} mt={ 2 } >
                        <Button variant='contained' fullWidth 
                            onClick={ handleAgregarInfo }
                        >
                            Agregar
                        </Button>
                    </Grid>
                </Grid>
            
            </Grid>
        </form>


    )





}
