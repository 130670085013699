
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AdminLayout } from "../../../layout/AdminLayout";
import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GppGoodIcon from '@mui/icons-material/GppGood';


import { useOpStore } from "../../../../hooks";
import { BusquedaOp } from "../components";
import { AlertScreen } from "../../../components";

import io from 'socket.io-client';
import moment from 'moment';
import Swal from 'sweetalert2';



const baseSocket = process.env.REACT_APP_SOCKET_IO_URL;


let socket;


export const ListadoOps = () => {

    const navigate = useNavigate();
    // const { openModalOP } = useModalStore();
    const { ops, startSavingOp, startLoadingOp, deleteOp, agregarOPDeSocket, opEliminadaDeSocket, opUpdateDeSocket, startUpdategOpSecretaria } = useOpStore();



    
    useEffect(() => {
        socket = io(baseSocket);
    }, []);


    useEffect(() => {
        socket.on('op-agregada', (op) => {
            agregarOPDeSocket(op);
        });

        socket.on('op-eliminada', (op) => {
            opEliminadaDeSocket(op);
        });

        socket.on('op-update', ( op ) => {
            opUpdateDeSocket(op);
        });
    }, []);


    useEffect(() => {
        startLoadingOp();
    }, []);



    const crearOp = async(e) => {
        e.preventDefault();
        await startSavingOp({
            bodega: '63bb78cb46f9e6f8d5fd55a8',
            fechaPedido: moment().toDate()
        });
    }


    const verOp = (op) => {
        navigate(`/admin/ver-op/${ op.id }`);
    }


    const eliminarOp = ( op ) => {
        Swal.fire({
            title: 'Eliminar OP',
            text: `¿Esta Seguro De Eliminar La Op: ${ op.op }?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Eliminar' 
        }).then( ( result ) => {
            if(result.value){
                deleteOp( op );
            }
        });
    }


    const aprobarParaInventario = (op) => {
        navigate(`/admin/aprobar-op/${ op.id }`);
    }



    const aprobarOP = (op) => {
        Swal.fire({
            title: 'Pedido Confirmado',
            text: `¿Aprobar OP Para Pedido?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Aprobar' 
        }).then( ( result ) => {
            if(result.value){
                startUpdategOpSecretaria( op );
            }
        });
    }



  return (

    
        <AdminLayout >

            <Grid 
                container
                direccion='row'
                alignItems='center'
            >
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ crearOp }
                >
                    <AddOutlined />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h1' sx={{ mb:2, fontWeight:'bold'  }} >
                    OPs Recientes 
                </Typography>

                <Box flex={ 1 } />
            </Grid>

            <BusquedaOp />

            { ( ops.length > 0 )
            ?<TableContainer>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <TableCell>OP</TableCell>
                            <TableCell>BODEGA</TableCell>
                            <TableCell>FECHA DE PEDIDO</TableCell>
                            <TableCell>APROBADO GERENCIA</TableCell>
                            <TableCell>FECHA APROBADO</TableCell>
                            <TableCell>APROBADO SECRETARIA</TableCell>
                            <TableCell>OPCIONES</TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody>
                    { ops.map( ( p ) => (
                        <TableRow key={ p.id } >
                            <TableCell>{ p.op }</TableCell>   
                            <TableCell>{ p.bodega?.nombre }</TableCell>   
                            <TableCell>{ p.fechaPedido }</TableCell>
                            <TableCell>
                                {
                                ( p.estado === 'APROBADA' ) && (
                                    <Typography variant="body2" fontWeight='bold' color="success.main">{ p.estado }</Typography>
                                )}
                                {
                                ( p.estado === 'RECHAZADA' ) && (
                                    <Typography variant="body2" fontWeight='bold' color="error.main">{ p.estado }</Typography>
                                )}
                                {
                                ( p.estado === 'EN PROCESO' ) && (
                                    <Typography variant="body2" fontWeight='bold'>{ p.estado }</Typography>
                                )}
                            </TableCell>   
                            <TableCell>
                                { 
                                ( p.fechaAprobado )
                                ? p.fechaAprobado
                                : <span>---/----/----</span> 
                                }
                            </TableCell>

                            <TableCell>
                                {
                                ( p.aprobacionSecretaria )
                                ? <Typography variant="body2" fontWeight='bold' color="success.main">APROBADA</Typography>
                                : <Typography variant="body2" fontWeight='bold'>EN PROCESO</Typography>
                                }
                            </TableCell> 

                            <TableCell>
                                <IconButton onClick={ () => verOp( p ) } >
                                    <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                                </IconButton>
                                
                                <IconButton color="error" onClick={ () => eliminarOp( p ) }>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>

                                <IconButton color="success" onClick={ () => aprobarOP( p ) } >
                                    <GppGoodIcon fontSize="small" />
                                </IconButton>
                                {
                                ( p.aprobacionSecretaria && p.aprobacionJefe ) &&
                                <IconButton color="error" onClick={ () => aprobarParaInventario( p ) } >
                                    <AddCircleIcon fontSize="small" sx={{ color: '#6fbf73' }}/>
                                </IconButton>
                                }
                            </TableCell>

                        </TableRow>
                    ))
                    }
                    </TableBody>
                </Table>
            </TableContainer>

            :<AlertScreen title="No Hay Registros" />

            }

        </AdminLayout>
  )




}
