import { Grid, Typography } from "@mui/material"




export const AuthLayout = ({ children, tittle='' }) => {


  return (

    <Grid
        container
        spacing={ 0 }
        direccion='column'
        alignItems='center'
        justifyContent='center'
        sx={{ minHeight:'100vh', padding:4}}  //backgroundColor:'primary.main'
    >

        <Grid item
            sx={{ 
                backgroundColor:'#fff', padding:3, borderRadius:2, border:'3px solid #ff9800', width:'550px'
            }}
        >

            <Typography variant='h4' sx={{ mb:2, textAlign:'center' }} >{ tittle }</Typography>


            { children }


        </Grid>
    </Grid>




  )


}
