
import { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { separadorMillares } from "../../../../hooks/hooks/useMillares";




export const TablaProductosFactura = ({ productos }) => {

    const [totales, setTotales] = useState({
        total: 0,
        subTotal: 0
    });


    useEffect(() => {
        const total = productos.reduce( (total, prod) => total + (prod.precioCon * prod.cantidad), 0 );
        const subTotal = productos.reduce( (total, prod) => total + (prod.precioSin * prod.cantidad), 0 );
        setTotales({
            total,
            subTotal
        });
    }, [productos]);
    




    return (

        <>
            <Typography variant='h5' sx={{ my:4, fontWeight:'bold', textAlign:'center' }} >
                Productos
            </Typography>

            <TableContainer>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <TableCell>NOMBRE</TableCell>
                            <TableCell>CANTIDAD</TableCell>
                            <TableCell>PRECIO SIN IVA</TableCell>
                            <TableCell>PRECIO CON IVA</TableCell>
                            <TableCell>TOTAL</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { productos.map( p => (
                        <TableRow key={ p._id } >
                            <TableCell>{ p.nombre }</TableCell>   
                            <TableCell>{ p.cantidad }</TableCell>
                            <TableCell>{ `$${ separadorMillares(p.precioSin) }` }</TableCell>
                            <TableCell>{ `$${ separadorMillares(p.precioCon) }` }</TableCell>
                            <TableCell>{ `$${ separadorMillares((p.precioCon * p.cantidad)) }` }</TableCell>
                        </TableRow>
                        ))}
                        <TableRow>
                            <TableCell rowSpan={3} />
                            <TableCell colSpan={3}>Subtotal:</TableCell>
                            <TableCell align="right">{ `$${ separadorMillares(totales.subTotal) }` }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3}>Iva</TableCell>
                            {/* <TableCell align="right">0</TableCell> */}
                            <TableCell align="right">{ `$${ separadorMillares(totales.total - totales.subTotal ) }` }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3}>Total:</TableCell>
                            <TableCell align="right">{ `$${ separadorMillares(totales.total) }` }</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </>



    )



}
