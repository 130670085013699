
import { useNavigate, useParams } from "react-router-dom";

import { Box, Button, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { AdminLayout } from "../../layout/AdminLayout";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from "react";
import { useGalponStore, useGranjaStore } from "../../../hooks";
import Swal from "sweetalert2";


const initialState = {
    nombre: '',
    cantidad: '',
    genero: '',
    granja: '',
    alto: '',
    largo: '',
    ancho: '',
}


export const ActualizarGalpon = () => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { activeGalpon, startSavingGalpon, setActiveGalpon } = useGalponStore();
    const { granjas, startLoadingGranjas } = useGranjaStore();


    useEffect(() => {
        startLoadingGranjas();
    }, []);


    useEffect(() => {
        if( id ){
            setActiveGalpon(id);
        }
    }, [id]);


    useEffect(() => {
        if( activeGalpon ){
            setFormGalpon({ 
                ...activeGalpon,
                granja: activeGalpon.granja || ''
            });
        }
    }, [activeGalpon]);


    const [formGalpon, setFormGalpon] = useState(initialState);
    const { nombre, cantidad, genero, granja, alto, largo, ancho } = formGalpon;
    const handleChangeInput = ({ target }) => {
        setFormGalpon({
            ...formGalpon,
            [target.name]: target.value
        });
    }


    const regresar = () => {
        navigate('/admin/list-galpones');
    }


    const actualizarGalpon = async(e) => {
        e.preventDefault();
        await Swal.fire({
            title: '¿Actualizar Galpon?',
            text: `Esta Seguro De Actualizar a: ${ nombre }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                startSavingGalpon( formGalpon );
                navigate('/admin/list-galpones');
            }
        });
    }


    return (

        <AdminLayout titulo='Galpones'>
            <Grid container direccion='row' alignItems='center'>
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ regresar }
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h4' sx={{ mb:2, fontWeight:'bold' }} >Actualizar Galpon</Typography>

                <Box flex={ 1 } />
            </Grid>

            <form onSubmit={ actualizarGalpon } >
                <Grid container spacing={ 2 }>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Nombre Galpon'
                            type='text'
                            placeholder='Nombre Galpon'
                            fullWidth
                            name='nombre'
                            value={ nombre }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Cantidad'
                            type='number'
                            placeholder='Cantidad'
                            fullWidth
                            name='cantidad'
                            value={ cantidad }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            id="outlined-select-currency"
                            select
                            label="Seleccione Granja"
                            fullWidth
                            name='granja'
                            value={ granja }
                            onChange={ handleChangeInput }
                        >
                            <MenuItem value=''>
                                Seleccione Granja
                            </MenuItem>
                            {
                                granjas.map( g => (
                                <MenuItem key={ g.id } value={ g.id } >
                                    { g.nombre }
                                </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                        id="outlined-select-currency"
                        select
                        label="Seleccione Genero"
                        fullWidth
                        name='genero'
                        value={ genero }
                        onChange={ handleChangeInput }
                        >
                            <MenuItem value=''>Seleccione Genero</MenuItem>
                            <MenuItem value='Hembra'>Hembra</MenuItem>
                            <MenuItem value='Macho'>Macho</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Alto'
                            type='number'
                            placeholder='Alto'
                            fullWidth
                            name='alto'
                            value={ alto }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Largo'
                            type='number'
                            placeholder='Largo'
                            fullWidth
                            name='largo'
                            value={ largo }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Ancho'
                            type='number'
                            placeholder='Ancho'
                            fullWidth
                            name='ancho'
                            value={ ancho }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                </Grid>

                <Grid container 
                    sx={{ mb:2, mt:2 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type="submit" >
                            Actualziar Galpon
                        </Button>
                    </Grid>
                </Grid>

            </form>
        </AdminLayout>


    )



}
