
import { useEffect } from "react";

import { LayoutReturn } from "../../../layout";
import {  useFacturaStore } from "../../../../hooks";
import { TablaDeFacturas } from "../components";



export const FacturasArchivadas = () => {


    const { startLoadingFacturasArchivadas } = useFacturaStore();


    useEffect(() => {
        startLoadingFacturasArchivadas();
    }, [] );

    


    return (


        <LayoutReturn title='Facturas Archivadas' url='/admin/facturas' >


            <TablaDeFacturas />


        </LayoutReturn>

    )


}
