
import { useDispatch, useSelector } from 'react-redux';
import { onCloseSidebar, onOpenSidebar } from '../store';
import { abrirModalGlobal, abrirModalNotificacion, cerrarModalGlobal, cerrarModalNotificacion } from '../store/ui/modalGlobalSlice';



export const useUIStore = () => {

    const dispatch = useDispatch();
    const { isSidebarOpen } = useSelector( state => state.ui );
    const { modalGlobal, modalNotificacion } = useSelector( state => state.modalGlobal );



    const openSidebar = () => {
        dispatch( onOpenSidebar() );
    }


    const closeSidebar = () => {
        dispatch( onCloseSidebar() );
    }



    const openGlobal = () => {
        dispatch( abrirModalGlobal() );
    }


    const closeGlobal = () => {
        dispatch( cerrarModalGlobal() );
    }


    const openModalNoti = () => {
        dispatch( abrirModalNotificacion() );
    }


    const closeModalNoti = () => {
        dispatch( cerrarModalNotificacion() );
    }



    return {
        //Propiedades
        isSidebarOpen,
        modalGlobal,
        modalNotificacion,

        // Metodos
        openSidebar,
        closeSidebar,
        openGlobal,
        closeGlobal,
        openModalNoti,
        closeModalNoti,
    }

}