import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import { onAddNewDistribucion, onLoandingDistribucion, onSetActiveDistribucion } from "../store";
import Swal from 'sweetalert2';



export const useDistribucionStore = () => {


    const dispatch = useDispatch();
    const { distribuciones, activeDistribucion } = useSelector( state => state.distribucion );


    const setActiveDistribucion = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/distribucion/xid/${ uid }`);
            if( data.ok ){
                dispatch( onSetActiveDistribucion( data.distribuido ) );
            }else {
                Swal.fire('Error', 'Algo Salio Mal', 'error');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    const startSavingDistribucion = async( distribucion ) => {
        try {

            if( distribucion.id ){
                // const { data } = await granjaApi.put(`/encubadora/${ encubadora.id }`, { ...encubadora } );
                // if( data.ok ){
                //     dispatch( onUpdateEncubadora( data.loteEncubadora ) );
                //     Swal.fire('Exitoso', 'Lote Encubadora Actualizado', 'success');
                console.log(distribucion);
            }else {
                const { data } = await granjaApi.post('/distribucion/new', { ...distribucion } );
                if( data.ok ){
                    Swal.fire('Ingreso Correcto', 'Ingreso a Galpon', 'success');
                    dispatch( onAddNewDistribucion( data.distribuido ) );
                    return true;
                } else{
                    Swal.fire('Error', 'Algo Salio Mal', 'error');
                    return false;
                }
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
            return false;
        }
    } 


    const startDistriPorLote = async(id) => {
        try {
            const { data } = await granjaApi.get(`/distribucion/xlote/${ id }`);
            dispatch( onLoandingDistribucion( data.resultado ) );
        } catch (error) {
            console.log('Error al Cargar Lotes');
            console.log(error);
        }
    }



    // const deleteEncubadora = async( encubadora ) => {
    //     try {
    //         const { data } = await granjaApi.delete(`/encubadora/${ encubadora.id }`);
    //         if( data.ok ){
    //             dispatch( onDeleteEncubadora( data.loteEncubadora ) );
    //             Swal.fire('Exitoso', 'Lote Encubadora Eliminado', 'success');
    //         }
    //     } catch (error) {
    //         Swal.fire('Error', error.response.data.msg, 'error');
    //         console.log(error);
    //     }
    // }


    

    return {
        //Propiedades
        distribuciones,
        activeDistribucion,

        //Metodos
        startSavingDistribucion,
        startDistriPorLote,
        setActiveDistribucion,
    }


}
