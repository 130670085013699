import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useProveedorStore } from "../../../hooks";

import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import { AdminLayout } from "../../layout/AdminLayout"
import Swal from "sweetalert2";




export const ListadoProveedor = () => {


    const navigate = useNavigate();
    const { proveedores, startLoadingProveedores, deleteProveedor } = useProveedorStore();

    
    useEffect(() => {
        startLoadingProveedores();
    }, []);
    

    const eliminarProveedor = ( pro ) => {
      Swal.fire({
          title: '¿Eliminar Proveedor?',
          text: `Esta Seguro De Eliminar a: ${ pro.nombre }`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Eliminar' 
      }).then( ( result ) => {
          if(result.value){
            deleteProveedor( pro );
          }
      });
    }


    const handleActualizar = (uid) => {
        navigate(`/admin/provee-update/${ uid }`);
    }


    
    const crearProveedor = () => {
        navigate('/admin/proveedor');
    }




    return (

        <AdminLayout>

            <Grid 
            container
            direccion='row'
            alignItems='center'
            >
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ crearProveedor }
                >
                    <AddOutlined />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h4' sx={{ mb:2, fontWeight:'bold' }} >Listado de Proveedores</Typography>

                <Box flex={ 1 } />

            </Grid>
            


            <TableContainer>
            <Table sx={{ minWidth: 700, }} aria-label="customized table">

                <TableHead sx={{ backgroundColor:'red'}}>
                <TableRow>
                    <TableCell>NOMBRE</TableCell>
                    <TableCell>NIT</TableCell>
                    <TableCell>FORMA PAGO</TableCell>
                    <TableCell>CIUDAD</TableCell>
                    <TableCell>TELEFONO</TableCell>
                    <TableCell>OPCIONES</TableCell>

                </TableRow>
                </TableHead>

                { proveedores && (
                <TableBody>
                { proveedores.map( (pro) => (
                    <TableRow key={ pro.id } >
                        <TableCell >{ pro.nombre }</TableCell>
                        <TableCell>{ pro.nit }</TableCell>
                        <TableCell>{ pro.formaPago }</TableCell>
                        <TableCell>{ pro.ciudad }</TableCell>
                        <TableCell>{ pro.movil }</TableCell>

                        <TableCell sx={{ width:'200px' }}>
                            <IconButton >
                                <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                            </IconButton>

                            <IconButton color="success" onClick={ () => handleActualizar(pro.id) }>
                                <UpdateIcon fontSize="small" />
                            </IconButton>

                            <IconButton color="error" onClick={ () => eliminarProveedor( pro ) } >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
                )}

            </Table>
            </TableContainer>

        </AdminLayout>


    )


  
}
