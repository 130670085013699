
import Swal from "sweetalert2";
import { fileUpload } from "../helpers/fetchImagen";




export const useUpload = () => {




    const subirImagenTodoCategoria = async( id, tipo, archivo ) => {
        try {
            Swal.fire({
                title: 'Cargando....',
                text: 'Espere Por Favor',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            const imagenResp = await fileUpload( id, tipo, archivo );
            if( typeof imagenResp !== 'string' ){ return }
            Swal.close();
            Swal.fire('Exitoso', 'Imagen Subida', 'success');
            return imagenResp;
            
        } catch (error) {
            console.log(error);
        }
    }





    return {
        //Metodos
        subirImagenTodoCategoria,
    }


}
