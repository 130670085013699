
import { useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';

import { VentaReporte, MortalidadReporte, PlanillaReporte, TrasladosReporte, DescuentosReporte } from '../../reportes-lote';
import { ConsumoScreen, SugeridosConsumos } from '../../consumo-lote-galpon/pages';
import { CuatroTiposDescuento } from '../views';



export const ContenedorDeTabs = () => {


    const [value, setValue] = useState('1'); 

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (

        <Box sx={{ width: '100%', typography: 'body1', mt:5 }}>
            <TabContext value={ value } >
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb:3 }}>
                    <Tabs onChange={ handleChange } 
                        value={value}
                        variant="scrollable"
                        scrollButtons
                    >
                        <Tab label="Promedios" value="1" />
                        <Tab label="Ventas" value="2" />
                        <Tab label="Planillas" value="3" />
                        <Tab label="Mortalidad" value="4" />
                        <Tab label="Traslados" value="5" />
                        <Tab label="Descuentos" value="6" />
                        <Tab label="Consumo" value="7" />
                        <Tab label="Sugeridos" value="8" />
                    </Tabs>
                </Box>

                <TabPanel value="1">
                    <CuatroTiposDescuento />
                </TabPanel>

                <TabPanel value="2">
                    <VentaReporte />
                </TabPanel>

                <TabPanel value="3">
                    <PlanillaReporte />
                </TabPanel>

                <TabPanel value="4">
                    <MortalidadReporte />
                </TabPanel>

                <TabPanel value="5">
                    <TrasladosReporte />
                </TabPanel>

                <TabPanel value="6">
                    <DescuentosReporte />
                </TabPanel>

                <TabPanel value="7">
                    <ConsumoScreen />
                </TabPanel>

                <TabPanel value="8">
                    <SugeridosConsumos />
                </TabPanel>

            </TabContext>
        </Box>


    )



}
