

import { createSlice } from '@reduxjs/toolkit';



export const bodegaSlice = createSlice({
    name: 'bodega',
    initialState: {
        isLoadingBodega: true,
        bodegas: [],
        activeBodega: null
    },
    reducers:{
        onSetActiveBodega: ( state, { payload } ) => {
            state.activeBodega = payload;
        },
        
        onAddNewBodega: ( state, { payload } ) => {
            state.bodegas.push( payload );
            state.activeBodega = null;
        },

        onUpdateBodega: ( state, { payload } ) => {
            state.bodegas = state.bodegas.map( bodega => {
                if( bodega.id === payload.id ){
                    return payload
                }
                return bodega
            });
        },

        onLoandingBodega: ( state, { payload = [] } ) => {
            state.isLoadingBodega = false;
            state.bodegas = payload;
            // payload.forEach( bodega => {
            //     const exists = state.bodegas.some( dbBodega => dbBodega.id === bodega.id );
            //     if( !exists ){
            //         state.bodegas.push( bodega );
            //     }
            // })
        },

        onDeleteBodega: ( state, { payload } ) => {
            // if( state.activebodega ){
                state.bodegas = state.bodegas.filter( bodega => bodega.id !== payload.id );
            //     state.activebodega = null;
            // }
        }
    }
});





export const { onSetActiveBodega, onAddNewBodega, onUpdateBodega, onLoandingBodega, onDeleteBodega } = bodegaSlice.actions;