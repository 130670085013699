import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";
import { prepararFechaOp } from "../helpers/prepararFecha";

import { onLoandingCliente, onLoandingEncubadora, onLoandingGalpon, onLoandingGranja, onLoandingInventario, onLoandingLote, onLoandingOp, onLoandingPlanilla, onLoandingProducto, onLoandingUsuario, onLoandingVehiculo } from "../store";


export const useBusquedas = () => {


    const dispatch = useDispatch();
    const { inventarios } = useSelector( state => state.inventario );


    // Busquedas por Vehiculos
    const busquedaPorVehiculos = async( text ) => {
        try {
            const { data } = await granjaApi.get(`/busqueda/xvehiculo/${ text }`);
            dispatch( onLoandingVehiculo(data.resultado) );
        } catch (error) {
            console.log('Error al Cargar Datos');
            console.log(error);
        }
    }


    // Busquedas por Producto
    const busquedaPorProducto = async( text ) => {
        try {
            const { data } = await granjaApi.get(`/busqueda/xproducto/${ text }`);
            dispatch( onLoandingProducto(data.resultado) );
        } catch (error) {
            console.log(error);
        }
    }



    const inventarioTotalDeBodegas = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/busqueda/todo-inventario/${ uid }`);
            dispatch( onLoandingInventario( data.inventario ) );
            
        } catch (error) {
            console.log('Error al Cargar bodegas');
            console.log(error);
        }
    }


    // Busquedas por Op
    const busquedaPorOrdenesPedido = async( text ) => {
        try {
            const { data } = await granjaApi.get(`/busqueda/xop/${ text }`);
            const todasOp = prepararFechaOp(data.resultado);
            dispatch( onLoandingOp(todasOp) );
        } catch (error) {
            console.log('Error al Cargar Datos');
            console.log(error);
        }
    }


     // Busquedas por Granjas
    const busquedaPorGranjas = async( text ) => {
        try {
            const { data } = await granjaApi.get(`/busqueda/xgranja/${ text }`);
            dispatch( onLoandingGranja(data.resultado) );
        } catch (error) {
            console.log('Error al Cargar Datos');
            console.log(error);
        }
    }



     // Busquedas por Galpones
    const busquedaPorGalpones = async( text ) => {
        try {
            const { data } = await granjaApi.get(`/busqueda/xgalpon/${ text }`);
            dispatch( onLoandingGalpon(data.resultado) );
        } catch (error) {
            console.log('Error al Cargar Datos');
            console.log(error);
        }
    }



    // Busquedas por Lote Incubadora
    const busquedaPorEncubadora = async( text ) => {
        try {
            const { data } = await granjaApi.get(`/busqueda/xencubadora/${ text }`);
            dispatch( onLoandingEncubadora(data.resultado) );
        } catch (error) {
            console.log('Error al Cargar Datos');
            console.log(error);
        }
    }



    
    // Busquedas por Usuarios
    const busquedaPorUsuario = async( text ) => {
        try {
            const { data } = await granjaApi.get(`/busqueda/xusuarios/${ text }`);
            dispatch( onLoandingUsuario(data.resultado) );
        } catch (error) {
            console.log('Error al Cargar Datos');
            console.log(error);
        }
    }


     // Busquedas por Clientes
     const busquedaPorClientes = async( text ) => {
        try {
            const { data } = await granjaApi.get(`/busqueda/xclientes/${ text }`);
            dispatch( onLoandingCliente(data.resultado) );
        } catch (error) {
            console.log('Error al Cargar Datos');
            console.log(error);
        }
    }




    // Busquedas por Lotes
    const busquedaPorLotes = async( text ) => {
        try {
            const { data } = await granjaApi.get(`/busqueda/xlotes/${ text.texto }/${ text.activo}`);
            dispatch( onLoandingLote(data.resultado) );
        } catch (error) {
            console.log('Error al Cargar Datos');
            console.log(error);
        }
    }



    const startSearchPlanillas = async( planilla, id ) => {
        try {
            const { data } = await granjaApi.get(`/busqueda/planilla/${ id }?start=${planilla.start}&end=${planilla.end}`);
            dispatch( onLoandingPlanilla( data.filtros ) );
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }


    const startLoadingNamePlanilla = async( nombre, id ) => {
        try {
            const { data } = await granjaApi.get(`/busqueda/planillaxname/${ id }/${nombre}`);
            dispatch( onLoandingPlanilla( data.resultado ) );
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }



    return {
        //Propiedades
        inventarios,

        //Metodos
        inventarioTotalDeBodegas,
        busquedaPorOrdenesPedido,
        busquedaPorGranjas,
        busquedaPorGalpones,
        busquedaPorEncubadora,
        busquedaPorUsuario,
        busquedaPorClientes,
        busquedaPorVehiculos,
        busquedaPorLotes,
        startSearchPlanillas,
        startLoadingNamePlanilla,
        busquedaPorProducto,
    }


}
