
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import { useGranjaStore } from "../../../hooks";

import { LayoutAdd } from "../../layout";
import { BusquedaGranjas } from "./BusquedaGranjas";
import { AlertScreen } from "../../components/AlertScreen";
import Swal from 'sweetalert2';




export const ListadoGranjas = () => {


    const navigate = useNavigate();
    const { granjas, startLoadingGranjas, deleteGranja } = useGranjaStore();


    useEffect(() => {
      startLoadingGranjas();
    }, [] );
    

    const eliminarGranja = ( granja ) => {
      Swal.fire({
          title: '¿Eliminar Granja?',
          text: `Esta Seguro De Eliminar a: ${ granja.nombre }`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Eliminar' 
      }).then( ( result ) => {
          if(result.value){
            deleteGranja( granja );
          }
      });
    }

    

    const handleActualizarGranja = ( uid ) => {
      navigate(`/admin/granja-update/${ uid }`);
    }


  return (


    <LayoutAdd title='Listado de Granjas' url='/admin/granjas' >


        <BusquedaGranjas />

        { ( granjas.length > 0 )
        ?<TableContainer>
          <Table sx={{ minWidth: 700, }} aria-label="customized table">

            <TableHead >
              <TableRow>
                <TableCell>NOMBRE</TableCell>
                <TableCell>MTS2</TableCell>
                <TableCell>CIUDAD</TableCell>
                <TableCell>#GALPONES</TableCell>
                <TableCell>#BODEGAS</TableCell>
                <TableCell>OPCIONES</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              { granjas.map( ( granja ) => (

                <TableRow key={ granja.id } >

                  <TableCell>{ granja.nombre }</TableCell>   
                  <TableCell>{ granja.metros }</TableCell>
                  <TableCell>{ granja.ciudad }</TableCell>
                  <TableCell>{ granja.canGalpones }</TableCell>
                  <TableCell>{ granja.canBodegas }</TableCell>

                  <TableCell sx={{ width:'200px' }}>

                    <IconButton >
                      <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                    </IconButton>

                    <IconButton color="success" onClick={ () => handleActualizarGranja( granja.id ) } >
                      <UpdateIcon fontSize="small" />
                    </IconButton>

                    <IconButton color="error" onClick={ () => eliminarGranja( granja ) } >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>

                </TableRow>
              ))
              }
            </TableBody>

          </Table>
        </TableContainer>

        :<AlertScreen title="No Existen Datos" />


        }

    </LayoutAdd>


  )



}
