import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import Swal from "sweetalert2";
import { limpiarLoandingFacturas, onAddNewFactura, onDeletefactura, onDeletePedido, onLoandingFactura, onSetActiveFactura, onUpdateFactura } from "../store";
import { prepararFechaCorta, prepararFechaFactura, prepararFechaUnoFactura } from "../helpers/prepararFecha";




export const useFacturaStore = () => {


    const dispatch = useDispatch();
    const { facturas, activeFactura } = useSelector( state => state.factura );



    const activeFacturaStart = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/factura/${ uid }`);
            const factura = prepararFechaUnoFactura(data.factura);
            dispatch( onSetActiveFactura( factura ) );
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error)
        }
    }



    const limpiarTodasLasFacturas = async() => {
        dispatch( limpiarLoandingFacturas() );
    }




    // const startSavingFactura = async( factura ) => {
    //     try {
    //         const { data1 } = await granjaApi.post('/factura/new', factura );
    //         if( data.ok ){
    //             dispatch( onAddNewFactura( data.factura ) );
    //             Swal.fire('Exitoso', 'Factura Creada', 'success');
    //         }

    //     } catch (error) {
    //         console.log(error);
    //         Swal.fire('Error', error.response.data.msg, 'error');
    //     }
    // } 





    const startSavingFactura = async( factura ) => {
        try {
            if( factura.id ){
                const { data } = await granjaApi.put(`/factura/${ factura.id }`, factura );
                if( data.ok ){
                    dispatch( onUpdateFactura( data.Factura ) );
                    Swal.fire('Exitoso', 'Producto de OP Actualizado', 'success');
                }
            }else {
                const { data } = await granjaApi.post('/factura/new', factura );
                if( data.ok ){
                    dispatch( onAddNewFactura( data.factura ) );
                    console.log('funcion 1')
                }
                return true;
            }

        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
            return false;
        }
    } 


    const startLoadingFacturas = async() => {
        try {
            const { data } = await granjaApi.get('/factura');
            const facturas = prepararFechaCorta(data.facturas);
            dispatch( onLoandingFactura(facturas) );
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }


    const startLoadingFacturasPorFecha = async( factura ) => {

        try {
            const { data } = await granjaApi.get(`/factura/porfecha?start=${factura.start}&end=${factura.end}`);
            const facturas = prepararFechaCorta(data.filtros);
            dispatch( onLoandingFactura(facturas) );
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }


    const startLoadingFacturasPorReciente = async( factura ) => {
        try {
            const { data } = await granjaApi.get(`/factura/pagoreciente`);
            const facturas = prepararFechaCorta(data.facturas);
            dispatch( onLoandingFactura(facturas) );
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }   



    const startLoadingFacturasPorNumero = async( nombre ) => {
        try {
            const { data } = await granjaApi.get(`/factura/pornumero/${nombre}`);
            const facturas = prepararFechaCorta(data.filtros);
            dispatch( onLoandingFactura(facturas) );
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }
    


    const startLoadingFacturasArchivadas = async() => {
        try {
            const { data } = await granjaApi.get('/factura/archivados');
            const facturas = prepararFechaCorta(data.facturas);
            dispatch( onLoandingFactura(facturas) );
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }       



    const updatePagoFactura = async( factura ) => {
        try {
            const { data } = await granjaApi.put(`/factura/pago/${ factura.uid }`, factura);
            dispatch( onUpdateFactura(data.factura) );
        } catch (error) {
            console.log(error);
        }
    }



    const updateEstadoFactura = async( uid ) => {
        try {
            const { data } = await granjaApi.put(`/factura/cambioestado/${ uid }`);
            dispatch( onUpdateFactura(data.factura) );
            dispatch( onDeletefactura(data.factura) );
        } catch (error) {
            console.log(error);
        }
    }



    return {
        //Propiedades
        facturas, 
        activeFactura,

        //Metodos
        startSavingFactura,
        startLoadingFacturas,
        activeFacturaStart,
        updateEstadoFactura,
        updatePagoFactura,
        startLoadingFacturasArchivadas,
        startLoadingFacturasPorFecha,
        startLoadingFacturasPorReciente,
        startLoadingFacturasPorNumero,
        limpiarTodasLasFacturas,
    }


}
