

import { createSlice } from '@reduxjs/toolkit';



export const planillaSlice = createSlice({
    name: 'planilla',
    initialState: {
        isLoadingPlanilla: true,
        planillas: [],
        activePlanilla: null
    },
    reducers:{
        onSetActivePlanilla: ( state, { payload } ) => {
            state.activePlanilla = payload;
        },

        activePlanillaDelete: ( state ) => {
            state.activePlanilla = null;
        },
        
        onAddNewPlanilla: ( state, { payload } ) => {
            state.planillas.push( payload );
            state.activePlanilla = null;
        },

        onUpdatePlanilla: ( state, { payload } ) => {
            state.planillas = state.planillas.map( pro => {
                if( pro.id === payload.id ){
                    return payload
                }
                return pro
            });
        },

        onLoandingPlanilla: ( state, { payload = [] } ) => {
            state.isLoadingPlanilla = false;
            state.planillas = payload;
        },


        onDeletePlanilla: ( state, { payload } ) => {
            state.planillas = state.planillas.filter( pro => pro.id !== payload.id );
        }
    }
});





export const {  onSetActivePlanilla,
                activePlanillaDelete,
                onAddNewPlanilla,
                onUpdatePlanilla,
                onLoandingPlanilla,
                onDeletePlanilla 
            } = planillaSlice.actions;