import { Box, Toolbar } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";


// Rutas de Modales
import { NavBarScreen, SideBarScreen, ModalEnviarProducto, ModalPedido, ModalNota, ModalUnidadMedida } from "../components";

// Rutas de Perfil
import { CambioDePassword, DashboardScreen, MiPerfilScreen } from "../pages/dashboard/pages";

// Rutas de Vehiculos
import { ActualizarVehiculo, CrearVehiculo, ImagesSeguros, ListadoVehiculos } from "../pages/vehiculos";

// Rutas de OP
import { ListadoOps, VerOp, AprobarPedidosScreen } from "../pages/op/pages";

// Rutas de Inventario
import { InventarioScreen, InventarioTodasBodegas, SalidasEntradasPorBodega, SalidasEntradasPorProducto, ListadoInventarioRaiz, TodoEntradasInventarioRaiz } from "../pages/inventario/pages";

// Rutas de Facturas y Abonos a Ellas
import { FacturasArchivadas, FacturasScreen, FiltrosFacturas, VerFactura, AbonosFacturaScreen } from "../pages/facturas/pages";

// Consumo de lote-galpon
import { InformeConsumoPorBodega } from "../pages/consumo-lote-galpon/pages";

// Rutas de Todo Lo que se vea de Lotes
import { ActualizarLote, DetallesTotalesLote, InfomacionLote, ListadoLotes, LotesArchivados, LotesScreen } from "../pages/lotes/pages";



import { PrepararVentaPlanilla } from "../pages/lotes";







import { GalponesScreen, ActualizarGalpon, ListadoGalpones } from "../pages/galpones";
import { ActualizarGranja, GranjasScreen, ListadoGranjas } from "../pages/granjas";
import { ActualizarProducto, CrearProducto, ListadoProductos } from "../pages/productos";
import { ActualizarProveedor, CrearProveedor, ListadoProveedor } from "../pages/proveedores";
import { ActualizarUsuario, CrearUsuarios, ListaUsuarios } from "../pages/usuarios";
import { ActualizarBodega, CrearBodegas, ListadoBodegas } from "../pages/bodegas";
import { ListaNotificaciones } from "../pages/notificacion/ListaNotificaciones";
import { ListadoUnidadMedidas } from "../pages/unidadMedida";
import { ActualizarEncubadora, EncubadoraScreen, ListadoEncubadora } from "../pages/encubadora";
import { VerPlanillaID } from "../pages/planillas";
import { ListadoAbonosXPlanilla } from "../pages/AbonosPlanilla";
import { ListadoClientes, VerPlanillasXCliente } from "../pages/Clientes";







export const AdminRoutes = () => {


    return (

        <>
        <NavBarScreen />


        <SideBarScreen />


        <Box
            component='main'
            sx={{ flexGrow: 1, p:3 }}
        >

        <Toolbar />
            <Routes>

                <Route path="dashboard" element={ <DashboardScreen /> } />
                <Route path="cambioclave" element={ <CambioDePassword /> } />
                <Route path="miperfil" element={ <MiPerfilScreen /> } />


                <Route path="list-user" element={ <ListaUsuarios /> } />
                <Route path="usuario" element={ <CrearUsuarios /> } />
                <Route path="user-update/:id" element={ <ActualizarUsuario /> } />
                <Route path="list-cliente" element={ <ListadoClientes /> } /> 
                <Route path="planillaxcliente/:id" element={ <VerPlanillasXCliente /> } /> 


                <Route path="list-vehiculo" element={ <ListadoVehiculos /> } />
                <Route path="vehiculo" element={ <CrearVehiculo /> } />
                <Route path="vehi-update/:id" element={ <ActualizarVehiculo /> } />
                <Route path="img-seguros/:id" element={ <ImagesSeguros /> } />


                <Route path="granjas" element={ <GranjasScreen /> } />
                <Route path="list-granjas" element={ <ListadoGranjas /> } />
                <Route path="granja-update/:id" element={ <ActualizarGranja /> } />

                <Route path="galpones" element={ <GalponesScreen /> } />
                <Route path="galpon-update/:id" element={ <ActualizarGalpon /> } />
                <Route path="list-galpones" element={ <ListadoGalpones /> } />

                <Route path="encuba" element={ <EncubadoraScreen /> } />
                <Route path="list-encuba" element={ <ListadoEncubadora /> } />
                <Route path="encuba-update/:id" element={ <ActualizarEncubadora /> } />


                <Route path="lotes" element={ <LotesScreen /> } />
                <Route path="list-lotes" element={ <ListadoLotes /> } />
                <Route path="lote-update/:id" element={ <ActualizarLote /> } />
                <Route path="list-archiva" element={ <LotesArchivados /> } />
                <Route path="info/:id" element={ <InfomacionLote /> } />    
                <Route path="det-total/:id" element={ <DetallesTotalesLote /> } />

                
                    
                <Route path="consumo-galponlote/:id" element={ <InformeConsumoPorBodega /> } />








                <Route path="abonosxplanilla/:id" element={ <ListadoAbonosXPlanilla /> } />
                <Route path="planilla/:id" element={ <PrepararVentaPlanilla /> } />
                <Route path="ver-planilla/:id" element={ <VerPlanillaID /> } />
                

                <Route path="proveedor" element={ <CrearProveedor /> } />
                <Route path="provee-update/:id" element={ <ActualizarProveedor /> } />
                <Route path="list-proveedor" element={ <ListadoProveedor /> } />

                <Route path="list-unimedida" element={ <ListadoUnidadMedidas /> } />

                <Route path="producto" element={ <CrearProducto /> } />
                <Route path="producto-update/:id" element={ <ActualizarProducto /> } />
                <Route path="list-producto" element={ <ListadoProductos /> } />

                <Route path="bodega" element={ <CrearBodegas /> } />
                <Route path="bodega-update/:id" element={ <ActualizarBodega /> } />
                <Route path="list-bodegas" element={ <ListadoBodegas /> } />

                <Route path="registros/:id" element={ <SalidasEntradasPorBodega /> } />
                <Route path="todo-inventario" element={ <InventarioTodasBodegas /> } />

                <Route path="inventario-raiz/:id" element={ <ListadoInventarioRaiz />} />
                <Route path="todoinventario" element={ <TodoEntradasInventarioRaiz />} />
                <Route path="inventario/:id" element={ <InventarioScreen /> } />
                <Route path="invxpr/:id/:pro" element={ <SalidasEntradasPorProducto /> } />
                <Route path="notificaciones" element={ <ListaNotificaciones /> } />

                <Route path="ops" element={ <ListadoOps /> } />
                <Route path="ver-op/:id" element={ <VerOp /> } />
                <Route path="aprobar-op/:id" element={ <AprobarPedidosScreen /> } />

                <Route path="facturas" element={ <FacturasScreen /> } />
                <Route path="factura/:id" element={ <VerFactura /> } />
                <Route path="fact-archi" element={ <FacturasArchivadas /> } />   
                <Route path="fact-filtro" element={ <FiltrosFacturas /> } />   
                <Route path="abonos/:id" element={ <AbonosFacturaScreen /> } />



                <Route path="/*" element={ <Navigate to="/admin/dashboard" />} />

            </Routes>

            <ModalEnviarProducto />
            
            <ModalUnidadMedida />

            <ModalPedido />

            <ModalNota />


        </Box>

        </>
    
    )



}
