
import { useEffect, useState } from 'react';

import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { LayoutReturn } from '../../layout';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useUsuarioStore, useVehiculoStore } from '../../../hooks';
import moment from 'moment';



const now = moment().hours(0).minutes(0).seconds(0);
const initialState = {
    placa: "",
    modelo: "",
    conductor: "",
    soatInicio: now,
    tecnoInicio: now,
    todoRiesgoInicio: now,
};



export const CrearVehiculo = () => {

    const  { startSavingVehiculo } = useVehiculoStore();    
    const  { conductores, startLoadingUsuariosConductor } = useUsuarioStore();


    useEffect(() => {
        startLoadingUsuariosConductor();
    }, [])
    


    const [formUser, setFormUser] = useState( initialState );
    const  { placa, modelo, conductor, soatInicio, tecnoInicio, todoRiesgoInicio } = formUser;
    const handleInputChange = ({ target }) => {
        setFormUser({
            ...formUser,
            [target.name]: target.value
        });
    }


    const handleChangeStart = (e) => {
        setFormUser({
            ...formUser,
            soatInicio: e.$d
        });
    }


    const handleChangeStartRiesgo = (e) => {
        setFormUser({
            ...formUser,
            todoRiesgoInicio: e.$d
        });
    }


    const handleChangeTecStart = (e) => {
        setFormUser({
            ...formUser,
            tecnoInicio: e.$d
        });
    }



    const handleCrearVehiculo = async(e) => {
        e.preventDefault();
        
        if( [placa, modelo, conductor].includes('') ){
            return console.log('Formulario Incorrecto');
        }
        await startSavingVehiculo( formUser );
        setFormUser( initialState );
    }


    // const regresar = () => {
    //     navigate('/admin/list-vehiculo');
    // }


    return (

        <LayoutReturn title='Crear Vehiculo' url='/admin/list-vehiculo' >

            <form onSubmit={ handleCrearVehiculo } >
                <Grid container spacing={ 2 }>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Placas'
                            type='text'
                            placeholder='Placas'
                            fullWidth
                            name='placa'
                            value={ placa }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Modelo'
                            type='text'
                            placeholder='Modelo'
                            fullWidth
                            name='modelo'
                            value={ modelo }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            id="outlined-select-currency"
                            select
                            label="Seleccione Conductor"
                            fullWidth
                            name='conductor'
                            value={ conductor }
                            onChange={ handleInputChange }
                        >
                            <MenuItem value=''>
                                Seleccione Conductor
                            </MenuItem>
                            {
                            conductores.map( e => (
                            <MenuItem key={ e.id } value={ e.id } >
                                { e.nombre }
                            </MenuItem>
                            ))
                            }
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Soat Inicio"
                                value={ soatInicio }
                                onChange={ handleChangeStart }
                                renderInput={ (params) => <TextField {...params} fullWidth /> }
                            />
                        </LocalizationProvider>
                    </Grid>

                    {/* <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Soat Vencimiento"
                                value={ soatFin }
                                onChange={ handleChangeEnd }
                                // minDate={ start }
                                renderInput={(params) => <TextField {...params} fullWidth /> }
                            />
                        </LocalizationProvider>
                    </Grid> */}
                    

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Tecnomecanica Inicio"
                                value={ tecnoInicio }
                                onChange={ handleChangeTecStart }
                                renderInput={ (params) => <TextField {...params} fullWidth /> }
                            />
                        </LocalizationProvider>
                    </Grid>


                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Todo Riesgo Inicio"
                                value={ todoRiesgoInicio }
                                onChange={ handleChangeStartRiesgo }
                                renderInput={ (params) => <TextField {...params} fullWidth /> }
                            />
                        </LocalizationProvider>
                    </Grid>


                </Grid>

                <Grid container 
                    sx={{ mb:2, mt:2 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit'>
                            Crear Vehiculo
                        </Button>
                    </Grid>
                </Grid>

            </form>
        </LayoutReturn>

    )




}
