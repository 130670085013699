
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Box, Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import DangerousIcon from '@mui/icons-material/Dangerous';
import GppGoodIcon from '@mui/icons-material/GppGood';

import { AlertScreen  } from "../../../components";
import { CardNotaPorOp, CardProductosPorOp } from "../components";
import { useModalStore, useNotaStore, useOpStore, usePedidoStore } from "../../../../hooks";
import { LayoutReturnSearch } from "../../../layout";

import moment from 'moment';
import Swal from "sweetalert2";



export const VerOp = () => {


    const { id } = useParams();
    const { activeOp, setActiveOp, startUpdategOp } = useOpStore();
    const { pedidos, startLoadingPedidosOP, activePedidoEliminar } = usePedidoStore();
    const { notas, startLoadingNotasOP } = useNotaStore();
    const { openModalPedido, openModalNota } = useModalStore();



    useEffect(() => {
      if( id ){
        setActiveOp(id);
      }
    }, [id]);


    useEffect(() => {
      if( id ){
        startLoadingPedidosOP(id);
      }
    }, [id]);


    useEffect(() => {
      if( id ){
        startLoadingNotasOP(id);
      }
    }, [id]);




    const handleCrear = () => {
      openModalPedido();
      activePedidoEliminar();
    }


    const handleNotaOP = () => {
      openModalNota();
      // activePedidoEliminar();
    }

    

    const aprobarOP = async(op) => {
        await Swal.fire({
            title: 'Aprobar OP',
            text: `¿Esta Seguro De Aprobar La OP: ${ op.op }?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
        }).then( ( result ) => {
            if(result.value){
              startUpdategOp({
                id: op.id,
                estado: "APROBADA",
                aprobacionJefe: true,
                fechaAprobado: moment().toDate()
              });
            }
        });
    }


    const denegarOP = async(op) => {
      await Swal.fire({
        title: 'Rechazar OP',
        text: `¿Esta Seguro De Rechazar La OP: ${ op.op }?`,
        icon: 'question',
        showCancelButton: true,
          confirmButtonText: 'Si, Rechazar' 
      }).then( ( result ) => {
          if(result.value){
            startUpdategOp({
              id: op.id,
              estado: "RECHAZADA",
              aprobacionJefe: false,
              fechaAprobado: ''
            });
          }
      });
    }




  return (

    <LayoutReturnSearch title='Detalles de OP ' url='/admin/ops' url2='/admin/todo-inventario' >


      <TableContainer>
            <Table sx={{ minWidth: 700, }} aria-label="customized table">

                <TableHead >
                    <TableRow>
                        <TableCell>OP</TableCell>
                        <TableCell>BODEGA</TableCell>
                        <TableCell>FECHA DE PEDIDO</TableCell>
                        <TableCell>ESTADO</TableCell>
                        <TableCell>FECHA APROBADO</TableCell>
                        <TableCell>OPCIONES</TableCell>
                    </TableRow>
                </TableHead>

                { activeOp &&(
                <TableBody>
                    <TableRow key={ activeOp.id } >
                        <TableCell>{ activeOp.op }</TableCell>   
                        <TableCell>{ activeOp.bodega?.nombre }</TableCell>   
                        <TableCell>{ activeOp.fechaPedido }</TableCell>
                        <TableCell>{ activeOp.estado }</TableCell>   
                        <TableCell>
                            { 
                            (activeOp.fechaAprobado)
                            ? activeOp.fechaAprobado
                            : <span>----/----/----</span> 
                            }
                        </TableCell>

                        <TableCell sx={{ width:'200px' }}>
                          <IconButton color="success" onClick={ () => aprobarOP( activeOp ) } >
                              <GppGoodIcon fontSize="small" />
                          </IconButton>

                          <IconButton color="error" onClick={ () => denegarOP( activeOp ) } >
                              <DangerousIcon fontSize="small" />
                          </IconButton>
                        </TableCell>

                    </TableRow>
                </TableBody>
                )}
            </Table>
      </TableContainer>



      <Grid 
        container
        direccion='row'
        alignItems='center'
        sx={{ my:5 }}
      >
          <Button variant='contained' onClick={ handleCrear }>Agregar</Button>

          <Box flex={ 1 } />

          <Typography variant='h4' sx={{ fontWeight:'bold'  }} >
              Productos 
          </Typography>

          <Box flex={ 1 } />

          <Button variant='contained' onClick={ handleNotaOP }>Nota</Button>
      </Grid>


      {
        ( pedidos.length > 0 || notas.length > 0 )
        ? <Grid
            container
            direccion='row'
            alignItems='center'
            justifyContent='space-between'
          >


            <Grid container spacing={2} >
              {
              pedidos.map( p => (
                <CardProductosPorOp pedidos={ p } key={ p.id } />
              ))
              }

              {
              notas.map( n => (
                <CardNotaPorOp nota={ n } key={ n.id } />
              ))
              }

            </Grid>


          </Grid>

        : <AlertScreen title="No Hay Productos"/>
      }
      
    </LayoutReturnSearch>

  )

}
