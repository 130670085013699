
import { useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, Typography } from "@mui/material"
import { AddOutlined } from "@mui/icons-material";




export const LayoutAdd = ({ children, title, url }) => {


    const navigate = useNavigate();


    const handleCrear = () => {
        navigate(url);
    }





    return (

        <Grid container sx={{ minHeight:'50vh', padding:4 }}>

            <Grid container direccion='row' alignItems='center'>
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ handleCrear }
                >
                    <AddOutlined />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h1' sx={{ fontWeight:'bold' }} >{ title }</Typography>

                <Box flex={ 1 } />
            </Grid>




            { children }





        </Grid>

    )


    
}
