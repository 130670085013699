import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2";
import { granjaApi } from "../api";
import { onAddNewDescarte, onLoandingDescarte, onSetActiveDescarte } from "../store/descartes/descartesSlice";



export const useDescarteStore = () => {


    const dispatch = useDispatch();
    const { descartes, activeDescarte } = useSelector( state => state.descarte );


    const setActiveDescarte = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/descarte/${ uid }`);
            dispatch( onSetActiveDescarte( data.descarte ) );
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error)
        }
    }


    const startSavingDescarte = async( descarte ) => {
        try {
            const { data } = await granjaApi.post('/descarte/new', { ...descarte } );
            if( data.ok ){
                dispatch( onAddNewDescarte( data.descarte ) );
                Swal.fire('Exitoso', 'Descarte Creado', 'success');
            }

        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    } 



    const startLoadingDescartes = async(uid) => {
        try {
            const { data } = await granjaApi.get(`/descarte/planilla/${ uid }`);
            dispatch( onLoandingDescarte(data.descartes) );
            
        } catch (error) {
            console.log(error);
        }
    }   


    // const deleteDescarte = async( Descarte ) => {
    //     try {

    //         const { data } = await granjaApi.delete(`/Descarte/${ Descarte.id }`);
    //         if( data.ok ){
    //             dispatch( onDeleteDescarte( Descarte ) );
    //             Swal.fire('Exitoso', 'Descarte Eliminado', 'success');
    //         }
            
    //     } catch (error) {
    //         Swal.fire('Error', error.response.data.msg, 'error');
    //         console.log(error);
    //     }
    // }


    return {
        //Propiedades
        descartes,
        activeDescarte,

        //Metodos
        setActiveDescarte,
        startSavingDescarte,
        startLoadingDescartes,
    }


}
