import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import Swal from "sweetalert2";
import { onAddNewDescuento, onDeleteDescuento, onLoandingDesss, onLoandingMortalidad, onLoandingTraslado, onLoandingVenta } from "../store";
// import { prepararFechaPedido } from "../helpers/prepararFecha";



export const useDescuentoStore = () => {


    const dispatch = useDispatch();
    const { descuentos, mortalidad, venta, traslado, descuento, activeDescuento } = useSelector( state => state.descuento );





    const startSavingDescuentos = async( descuento ) => {
        try {
            if( descuento.id ){
                // const { data } = await granjaApi.put(`/bodega/${ bodega.id }`, bodega );
                // if( data.ok ){
                //     dispatch( onUpdateBodega( data.bodega ) );
                //     Swal.fire('Exitoso', 'Bodega Actualizada', 'success');
                // }
                console.log(descuento)
            }else {
                const { data } = await granjaApi.post('/descuento/new', { ...descuento } );
                if( data.ok ){
                    dispatch( onAddNewDescuento( data.descuento ) );
                    Swal.fire('Exitoso', 'Descuento Creada', 'success');
                }
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    } 


    const startSavePlanilla = async( descuento ) => {
        try {

            if( descuento.id ){
                // const { data } = await granjaApi.put(`/bodega/${ bodega.id }`, bodega );
                // if( data.ok ){
                //     dispatch( onUpdateBodega( data.bodega ) );
                //     Swal.fire('Exitoso', 'Bodega Actualizada', 'success');
                // }
            }else {
                const { data } = await granjaApi.post('/descuento/venta', { ...descuento } );
                if( data.ok ){
                    dispatch( onAddNewDescuento( data.descuento ) );
                    Swal.fire('Exitoso', 'Descuento y Plinilla Creadas', 'success');
                }
            }

        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    } 



    const startLoadingVenta = async(uid) => {
        try {
            const { data } = await granjaApi.get(`/descuento/venta/${ uid }`);
            dispatch( onLoandingVenta( data.descuento ) );
        } catch (error) {
            console.log(error);
        }
    }



    const startLoadingMortalidad = async(uid) => {
        try {
            const { data } = await granjaApi.get(`/descuento/mortalidad/${ uid }`);
            // const mor = prepararFechaPedido( data.descuento );
            dispatch( onLoandingMortalidad(data.descuento) );
        } catch (error) {
            console.log(error);
        }
    }


    const startLoadingTraslado = async(uid) => {
        try {
            const { data } = await granjaApi.get(`/descuento/traslado/${ uid }`);
            dispatch( onLoandingTraslado( data.descuento ) );
        } catch (error) {
            console.log(error);
        }
    }



    const startLoadingDesss = async(uid) => {
        try {
            const { data } = await granjaApi.get(`/descuento/descuento/${ uid }`);
            dispatch( onLoandingDesss( data.descuento ) );
        } catch (error) {
            console.log(error);
        }
    }


    const busquedaPorFiltroCalendario = async(descuento) => {

        const { nombre } = descuento;
        try {
            const { data } = await granjaApi.get(`/descuento/calendario/${ descuento.uid }?start=${descuento.start}&end=${descuento.end}&descuento=${descuento.nombre}`);

            switch (nombre) {
                case 'MORTALIDAD':
                    dispatch( onLoandingMortalidad( data.filtros ) );
                break;

                case 'VENTA':
                    dispatch( onLoandingVenta( data.filtros ) );
                break;

                case 'TRASLADO':
                    dispatch( onLoandingTraslado( data.filtros ) );
                break;

                case 'DESCUENTO':
                    dispatch( onLoandingDesss( data.filtros ) );
                break;
            
                default:
                break;
            }
        } catch (error) {
            console.log(error);
        }

    }



    const deleteDesuento = async( uid ) => {
        try {
            const { data } = await granjaApi.delete(`/descuento/${ uid }`);
            if( data.ok ){
                console.log(data);
                dispatch( onDeleteDescuento( data.descuento ) );
                Swal.fire('Exitoso', 'Descuento Eliminado', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    return {
        //Propiedades
        descuentos,
        activeDescuento,
        mortalidad, 
        venta, 
        traslado, 
        descuento,

        //Metodos
        startSavingDescuentos,
        deleteDesuento,
        startLoadingVenta,
        startLoadingMortalidad,
        startLoadingTraslado,
        startLoadingDesss,
        startSavePlanilla,
        busquedaPorFiltroCalendario,
    }


}
