import Swal from "sweetalert2";


const baseUrl = process.env.REACT_APP_API_URL;




const fileUpload = async( id, tipo, archivo ) => {

    const base =`${ baseUrl }/uploads/subir/${ tipo }/${ id }`;
    const formData = new FormData();
    formData.append('imagen', archivo);

    try {
        const resp = await fetch( base, {
            method: 'PUT',
            body: formData
        });

        const body = await resp.json();

        if( body.ok ) {
            return body.nombreArchivo;
        } else {
            return Swal.fire('Error', body.msg, 'error');
        }
        
    } catch (error) {
        console.log(error);
    }
}




export {
    fileUpload,
}