



export const resultadoMermas = ( mermas ) => {
    const resultado = mermas.reduce( (total, init) => total + init.cantidad, 0 );
    const finall = Number(resultado.toFixed(3));

    return Number(finall.toFixed(3));
    
}