
import { useState, useEffect } from 'react';

import { Button, Grid, TextField } from '@mui/material';

import { useAuthStore, useUsuarioStore } from '../../../../hooks';
import { LayoutReturn } from "../../../layout"
import { DescargaArchivosConductor } from '../components';

import Swal from 'sweetalert2';



const initialState = {
    nombre: "",
    cedula: "",
    email: "",
    direccion: "",
    ciudad: "",
    movil: "",
}



export const MiPerfilScreen = () => {


    const { user } = useAuthStore();
    const { startSavingUsuario } = useUsuarioStore();


    useEffect(() => {
        if(Object.entries(user).length > 0){
            setFormUser({ ...user });
        }
    }, [user])



    const [formUser, setFormUser] = useState({
        nombre: "",
        cedula: "",
        email: "",
        direccion: "",
        ciudad: "",
        movil: "",
    });
    const  { nombre, email, cedula, direccion, ciudad, movil } = formUser;



    const handleInputChange = ({ target }) => {
        setFormUser({
            ...formUser,
            [target.name]: target.value
        });
    }
    



    const handleUpdateUser = async(e) => {
        e.preventDefault();
        await Swal.fire({
            title: 'Acualizar Mi Perfil',
            text: `¿Esta Seguro De Actualizar Ahora?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                startSavingUsuario( formUser );
            }
        });
    }



    return (


        <LayoutReturn title="Mi Perfil" url="/admin/dashboard" >

            <form onSubmit={ handleUpdateUser } >

                <Grid container 
                    sx={{ my:2 }} 
                    direccion='column'
                    justifyContent='end' 
                >
                    <Grid item xs={12} sm={6} md={4} >
                        <Button variant='contained' fullWidth type='submit'>
                            Actualizar Perfil
                        </Button>
                    </Grid>
                </Grid>


                <Grid container spacing={2} my={3}>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField required
                            variant="filled"
                            label='Nombre Completo'
                            type='text'
                            placeholder='Nombre Completo'
                            fullWidth
                            name='nombre'
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField required
                            variant="filled"
                            label='Cedula'
                            type='text'
                            placeholder='Cedula'
                            fullWidth
                            name='cedula'
                            value={ cedula }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField required
                            variant="filled"
                            label='Email'
                            type='email'
                            placeholder='Email'
                            fullWidth
                            name='email'
                            value={ email }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} >
                        <TextField required
                            variant="filled"
                            label='Direccion'
                            type='text'
                            placeholder='Direccion'
                            fullWidth
                            name='direccion'
                            value={ direccion }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} >
                        <TextField required
                            variant="filled"
                            label='Ciudad'
                            type='text'
                            placeholder='Ciudad'
                            fullWidth
                            name='ciudad'
                            value={ ciudad }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField required
                            variant="filled"
                            label='Telefono'
                            placeholder='Telefono'
                            fullWidth
                            name='movil'
                            value={ movil }
                            onChange={ handleInputChange }
                        />
                    </Grid>
                    
                </Grid>

            </form>


            {/* {
            ( user.role === 'CONDUCTOR' ) && */}
            <DescargaArchivosConductor />
            {/* } */}


        </LayoutReturn>


    )


}
