import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import { onAddNewIngresoRaiz, onDeletePedido, onLoandingIngresoRaiz } from "../store";
import Swal from 'sweetalert2';
import { prepararFechaPedido } from "../helpers/prepararFecha";
// import { prepararFecha, prepararFechaUno } from "../helpers/prepararFecha";



export const useIngresoRaizStore = () => {


    const dispatch = useDispatch();
    const { ingresos, activeIngresoRaiz } = useSelector( state => state.ingresoRaiz );


    // const setActiveLote = async( uid ) => {
    //     try {
    //         const { data } = await granjaApi.get(`/lote/lotexid/${ uid }`);
    //         if( data.ok ){
    //             dispatch( onSetActiveLote( data.lote ) );
    //         }else {
    //             Swal.fire('Error', 'Algo Salio Mal', 'error');
    //         }
    //     } catch (error) {
    //         Swal.fire('Error', error.response.data.msg, 'error');
    //         console.log(error);
    //     }
    // }


    // const startSavingLote = async( lote ) => {
    //     try {

    //         const { data } = await granjaApi.post('/lote/new', { ...lote } );
    //         if( data.ok ){
    //             Swal.fire('Proceso Exitoso', 'Lote Creado', 'success');
    //             dispatch( onAddNewLote( data.lote ) );
    //         } else{
    //             Swal.fire('Error', 'Algo Salio Mal', 'error');
    //         }

    //     } catch (error) {
    //         Swal.fire('Error', error.response.data.msg, 'error');
    //         console.log(error);
    //     }
    // } 


     //Ingresar a Bodega Raiz y crear Registro
    const aprobarProductosBodegaRaiz = async( producto ) => {
        try {
            const { data } = await granjaApi.put('/inraiz/ingreso-multiple', producto);
            if( data.ok ){
                console.log('funcion 2');
                return true;
                // await new Promise(resolve => {
                // setTimeout(() => {
                //     // console.log('segunda funcion');
                //     resolve();
                // }, 4000);
                // });
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
            return false;
        }
    }





    const startLoadingIngresoRaizProducto = async(uid) => {
        try {
            const { data } = await granjaApi.get(`/inraiz/porproducto/${ uid }`);
            const ingresos = prepararFechaPedido( data.ingreso );
            dispatch( onLoandingIngresoRaiz( ingresos ) );
            
        } catch (error) {
            console.log('Error al Cargar Lotes');
            console.log(error);
        }
    }



    const startLoadingIngresoRaiz = async() => {
        try {
            const { data } = await granjaApi.get('/inraiz/todos');
            const ingresos = prepararFechaPedido( data.ingresos );
            dispatch( onLoandingIngresoRaiz( ingresos ) );
            
        } catch (error) {
            console.log('Error al Cargar Lotes');
            console.log(error);
        }
    }





    const deletePedidoMultiple = async( pedido ) => {
        try {
            const { data } = await granjaApi.put(`/inraiz/pedido-muliple`, pedido );
            if( data.ok ){
                for( let p of pedido ){
                    dispatch( onDeletePedido({ id: p }) );
                }
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    

    return {
        //Propiedades
        ingresos,
        activeIngresoRaiz,

        //Metodos
        startLoadingIngresoRaiz,
        aprobarProductosBodegaRaiz,
        deletePedidoMultiple,
        startLoadingIngresoRaizProducto,
    }


}
