
import { Box, Grid, Typography } from "@mui/material"
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Navigation, Pagination, History, Autoplay } from "swiper";



const empresa = require.context('../../assets/empresa', true);



export const SlideShowScreen = () => {



    return (

        // <Grid container className="contenedor-principal">
        <div className="contenedor-principal">

            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                clickable: true,
                }}
                navigation={true}
                autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                }}
                modules={[ Navigation, Pagination, History, Autoplay ]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src={ empresa(`./pollo1.jpg`) } alt="" />
                </SwiperSlide>

                <SwiperSlide>
                    <img src={ empresa(`./pollo2.jpg`) } alt="" /> 
                </SwiperSlide>

                <SwiperSlide>
                    <img src={ empresa(`./pollocanal.webp`) } alt="" />   
                </SwiperSlide>

                <SwiperSlide>
                    <img src={ empresa(`./finca.jpg`) } alt="" />
                </SwiperSlide>
            
            </Swiper>

        </div>

        // {/* </Grid> */}

        
    )



}
