
// import { useState, useEffect } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';




export const TablaDestares = ({ destares, setItems }) => {


    const eliminarItem = (i) => {
        const resultadoItems = destares.filter( ( item, index ) => index !== i );
        setItems(resultadoItems);
    }



    return (

    
        <TableContainer sx={{ my:3 }}>
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>NUMERO HUACALES</TableCell>
                    <TableCell>AVES X HUACAL</TableCell>
                    <TableCell>PESO AVES X HUACAL</TableCell>
                    <TableCell>DESTARE</TableCell>
                    <TableCell>OPCION</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
            {
            destares.map( (des, i) => (
                <TableRow key={ i }>
                    <TableCell>{ i + 1 }</TableCell>
                    <TableCell>{ des.huacales }</TableCell>
                    <TableCell>{ des.avesXhuacal }</TableCell>
                    <TableCell>{ des.pesoAvesHuacal } Kg</TableCell>
                    <TableCell>{ des.destare } Kg</TableCell>
                    <TableCell>
                        <IconButton color="error" onClick={ () => eliminarItem( i ) } >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))
            }
            </TableBody> 
            </Table>
        </TableContainer>

    )


}
