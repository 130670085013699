
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';


import { useBodegaStore, useInventarioStore, useModalStore } from "../../../../hooks";
import { separadorMillares } from "../../../../hooks/hooks/useMillares";
import { LayoutReturnInfo } from "../../../layout";

import Swal from 'sweetalert2';



export const InventarioScreen = () => {



    const navigate = useNavigate();
    const { id }  = useParams();
    const { inventarios, startLoadingInventario, setActivInventario, deleteInventario } = useInventarioStore();
    const { activeBodega, setActiveBodega } = useBodegaStore();
    const { openModal } = useModalStore();



    useEffect(() => {
        if( id ){
            startLoadingInventario(id);
        }
    }, [id] );


    useEffect(() => {
        if( id ){
            setActiveBodega(id);
        }
    }, [id] );
    


    const eliminarInventario = ( inv ) => {
      Swal.fire({
          title: '¿Eliminar Producto de Bodega?',
          text: `Esta Seguro De Eliminar a: ${ inv.producto?.nombre }`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Eliminar' 
      }).then( ( result ) => {
          if(result.value){
            deleteInventario( inv );
          }
      });
    }

    


    const handleEnviarProducto = ( uid ) => {
        openModal();
        setActivInventario( uid );
    }



    const handleActivarProducto = (pro) => {
        navigate(`/admin/invxpr/${ id }/${ pro.producto._id }`);
    }


    const handleInventarioRaiz = (b) => {
        navigate(`/admin/inventario-raiz/${ b.producto._id }`);
    }


    // const inventarioConsumoPorGalpon = () => {
    //     navigate(`/admin/consumo-galponlote/${ id }`);
    // }



    return (


        <LayoutReturnInfo title={`Inventario de ${ (activeBodega) ? activeBodega.nombre : 'Sin Nombre' }`} 
            url='/admin/list-bodegas'  url2='/admin/todoinventario' 
        >


            {/* <Typography variant='h2' sx={{ my:3, textAlign:'center' }} >Inventario de Bodega</Typography> */}

            <TableContainer>
            <Table sx={{ minWidth: 700, }} aria-label="customized table">

                <TableHead >
                    <TableRow>
                        <TableCell>PRODUCTO</TableCell>
                        <TableCell>MEDIDA</TableCell>
                        <TableCell>STOCK</TableCell>
                        <TableCell>PRECIO SIN IVA</TableCell>
                        <TableCell>PRECIO CON IVA</TableCell>
                        <TableCell>OPCIONES</TableCell>
                    </TableRow>
                </TableHead>

                { inventarios &&(
                <TableBody>
                { inventarios.map( ( inv ) => (

                    <TableRow key={ inv.id } >

                    <TableCell>{ inv.producto?.nombre }</TableCell>  
                    <TableCell>{ inv.producto?.unidadMedida.nombre }</TableCell>   
                    <TableCell>{ inv.stock }</TableCell>
                    <TableCell>{ `$${ separadorMillares(inv.producto.precioSin) }` }</TableCell>
                    <TableCell>{ `$${ separadorMillares(inv.producto.precioCon) }` }</TableCell>

                    <TableCell sx={{ width:'200px' }}>
                        <Tooltip title="Notificacion Para Envio de Producto" placement="top">
                            <IconButton  onClick={ () => handleEnviarProducto(inv.id) }>    
                                <ExitToAppIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                            </IconButton>
                        </Tooltip>

                        {
                        ( activeBodega?.nombre === 'BODEGA-PRINCIPAL' ) &&
                        <Tooltip title="Registros De Ingresos A Bodega Principal" placement="top">
                            <IconButton onClick={ () => handleInventarioRaiz( inv ) } >   
                                <InfoIcon fontSize="small" color="success" />
                            </IconButton>
                        </Tooltip>
                        }

                        {/* {
                        ( activeBodega?.nombre !== 'BODEGA-PRINCIPAL' ) &&
                        <Tooltip title="Registro De Consumo Por Lote" placement="top">
                            <IconButton onClick={ () => inventarioConsumoPorGalpon() } >   
                                <InfoIcon fontSize="small" color="success" />
                            </IconButton>
                        </Tooltip>
                        } */}

                        <Tooltip title="Registro De Envio A Otras Bodegas" placement="top">
                            <IconButton onClick={ () => handleActivarProducto(inv) } >   
                                <InfoIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar Producto de Bodega" placement="top">
                            <IconButton color="error" onClick={ () => eliminarInventario( inv ) } >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </TableCell>

                    </TableRow>
                ))
                }
                </TableBody>
                )}

            </Table>
            </TableContainer>

        </LayoutReturnInfo>

    )


}
