
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, MenuItem, TextField, Typography, Box, Backdrop, Modal, InputAdornment } from '@mui/material';

import Swal from 'sweetalert2';
import { useModalImgStore, usePlanillaStore, useDescarteStore } from '../../hooks';
import { NumberFormatCustom } from '../../hooks/hooks';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



export const ModalMermas = () => {

    const millares = NumberFormatCustom;
    
    const { id } = useParams();
    const { closeModalDescuentos } = useModalImgStore();
    const { modalMermas } = useModalImgStore();
    const { updatePlanillaMermas } = usePlanillaStore();
    const { startSavingDescarte } =useDescarteStore();




    const [formDescuento, setFormDescuento] = useState({
        cantidad: '',
        nombre: '',
        pesoNeto: '',
        valorKilo: '',
        uid: '',
    });
    const { cantidad, nombre, pesoNeto, valorKilo } = formDescuento;

    const handleChanges = ({ target }) => {
        setFormDescuento({
            ...formDescuento,
            [target.name]: target.value
        });
    }


    useEffect(() => {
        if( id ){
            setFormDescuento({
                ...formDescuento,
                uid: id,
            })
        }
    }, [id]);



    const handleCrearDescuento = async(e) => {
        e.preventDefault();

        closeModalDescuentos();
        if( nombre === 'DESCARTES' ){
            await Swal.fire({
                title: `Ingresar Descarte A Planilla`,
                text: `¿Deseas Hacer Este Descuento?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Si' 
            }).then( ( result ) => {
                if(result.value){
                    startSavingDescarte( formDescuento );
                }
            });
            
        } else {
            await Swal.fire({
                title: `Ingresar Descuento A Planilla`,
                text: `¿Deseas Hacer Este Descuento?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Si' 
            }).then( ( result ) => {
                if(result.value){
                    updatePlanillaMermas( formDescuento );
                }
            });
        }
        setFormDescuento({
            ...formDescuento,
            cantidad: '',
            nombre: '',
            pesoNeto: '',
            valorKilo: '',
        });
        
    }


    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ modalMermas }
            onClose={ closeModalDescuentos }
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Box sx={ style } >

                <Typography align='center' variant='h4' sx={{ mb:2, fontWeight:'bold'  }} >Crear Merma Para Planilla</Typography>

                <form  onSubmit={ handleCrearDescuento } >
                    <Grid container spacing={ 2 }>

                        <Grid item xs={ 12 } sm={12} mt={ 2 }>
                            <TextField required
                                select
                                label="Tipo de Descuento"
                                fullWidth
                                name='nombre'
                                value={ nombre }
                                onChange={ handleChanges }
                            >    
                                <MenuItem value=''>
                                    Seleccione Descuento
                                </MenuItem>
                                <MenuItem value='DESCARTES'>
                                    DESCARTES
                                </MenuItem>
                                <MenuItem value='AHOGADOS'>
                                    AHOGADOS
                                </MenuItem>
                                <MenuItem value='BUCHES'>
                                    BUCHES
                                </MenuItem>
                                <MenuItem value='MERMAS'>
                                    MERMAS
                                </MenuItem>
                            </TextField>
                        </Grid>

                        { (nombre !== 'DESCARTES') &&
                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField 
                                required={nombre !== 'DESCARTES'}
                                label="Kilogramo"
                                fullWidth
                                type="number"
                                placeholder='Kilogramo'
                                name='cantidad'
                                value={ cantidad }
                                onChange={ handleChanges }
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                }}
                            >
                            </TextField>
                        </Grid>
                        }

                        { (nombre === 'DESCARTES') &&
                        <>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField 
                                required={ nombre === 'DESCARTES' }
                                label="Cantidad"
                                fullWidth
                                type="number"
                                placeholder='Cantidad'
                                name='cantidad'
                                value={ cantidad }
                                onChange={ handleChanges }
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Uni.</InputAdornment>,
                                }}
                            >
                            </TextField>
                        </Grid>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField 
                                required={ nombre === 'DESCARTES' }
                                label="Valor Kilo"
                                fullWidth
                                placeholder='Valor Kilo'
                                name='valorKilo'
                                value={ valorKilo }
                                onChange={ handleChanges }
                                InputProps={{
                                    inputComponent: millares,
                                }}
                            >
                            </TextField>
                        </Grid>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField 
                                required={ nombre === 'DESCARTES' }
                                label="Peso Neto"
                                fullWidth
                                type="number"
                                placeholder='Peso Neto'
                                name='pesoNeto'
                                value={ pesoNeto }
                                onChange={ handleChanges }
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                }}
                            >
                            </TextField>
                        </Grid>
                        </>
                        }

                        <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                            <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                <Button variant='contained' fullWidth type='submit'>
                                    Crear Descuento
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                </form>
            </Box>
        </Modal>

    );



}