
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useDescarteStore } from '../../../hooks';
import { dejarTresDecimales } from '../../../helpers/dejarDecimales';
import { separadorMillares } from '../../../hooks/hooks';
import moment from 'moment';




export const TablaDescartes = () => {


    const { id } = useParams();
    const { descartes, startLoadingDescartes } = useDescarteStore();


    useEffect(() => {
        if( id ){
            startLoadingDescartes(id);
        }
    }, [id]);
    


    return (

        <>
            <Typography variant='h5' sx={{ mb:1, mt:6, fontWeight:'bold', textAlign:'center' }} >DESCARTES</Typography>

            <TableContainer sx={{mb:3}} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="body1">CANTIDAD</Typography></TableCell>
                            <TableCell><Typography variant="body1">PESO NETO</Typography></TableCell>
                            <TableCell><Typography variant="body1">VALOR X KILO</Typography></TableCell>
                            <TableCell><Typography variant="body1">VALOR FACTURA</Typography></TableCell>
                            <TableCell><Typography variant="body1">PROMEDIO</Typography></TableCell>
                            <TableCell><Typography variant="body1">FECHA</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    {
                    ( descartes.length > 0 )
                    ?<TableBody>
                    { descartes.map( (descarte) => (
                    <TableRow key={ descarte.id } >
                        <TableCell><Typography variant="body2">{ descarte.cantidad }</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ descarte.pesoNeto }Kg</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ `$${separadorMillares(descarte.valorKilo)}` }</Typography></TableCell>  
                        <TableCell><Typography variant="body2">{ `$${separadorMillares(descarte.valorFactura)}` }</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ dejarTresDecimales(descarte.promedio) }Kg</Typography></TableCell> 
                        <TableCell><Typography variant="body2">{ moment(descarte.createAt).format('l') }</Typography></TableCell>  
                    </TableRow>
                    ))
                    }
                    </TableBody>

                    :<TableBody>
                        <TableRow>
                            <TableCell colSpan={6}>No Hay Datos</TableCell>
                        </TableRow>
                    </TableBody>
                    }
                    
                    
                </Table>
            </TableContainer>

        </>

        
    )




}
