

import { Avatar, Box, Card, CardContent, Grid, Typography, CardActionArea } from '@mui/material';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { useNavigate } from 'react-router-dom';





export const TarjetasPersonales = () => {

    const navigate = useNavigate();


    const handleNavegacion = (url) => {
        navigate(`/admin/${ url }`);
    }


  return (

   
        <Grid container spacing={4} display='flex' justifyContent='center' mt={5}>

            <Grid item >
                <Card sx={{ width: 340, height: 140 }} onClick={() => handleNavegacion('miperfil') }>
                    <CardActionArea sx={{ width: '100%', height: '100%', display: 'flex', justifyContent:'space-evenly' }} >

                        <Box pl={2}>
                            <Avatar sx={{ width: 65, height: 65, backgroundColor:'primary.main' }}>
                                <PersonSearchIcon sx={{ fontSize:40, color:'white' }} />
                            </Avatar>
                        </Box>

                        <CardContent sx={{ textAlign:'center' }}>
                            <Typography variant='h5' component="div">
                                Perfil
                            </Typography>
                            <Typography variant="body3" color="text.secondary">
                                Administrar, agregar o eliminar datos para una mejor experiencia.
                            </Typography>
                        </CardContent>

                    </CardActionArea>
                </Card>
            </Grid>

            <Grid item >
                <Card sx={{ width: 340, height: 140 }}>
                    <CardActionArea sx={{ width: '100%', height: '100%', display: 'flex', justifyContent:'space-evenly' }} >

                        <Box pl={2}>
                            <Avatar sx={{ width: 65, height: 65, backgroundColor:'primary.main' }}>
                                <PictureAsPdfIcon sx={{ fontSize:40, color:'white' }} />
                            </Avatar>
                        </Box>

                        <CardContent sx={{ textAlign:'center' }}>
                            <Typography variant='h5' component="div">
                                Mis Documentos
                            </Typography>
                            <Typography variant="body3" color="text.secondary">
                                Mira y descargar documentos personales de la Empresa.
                            </Typography>
                        </CardContent>

                    </CardActionArea>
                </Card>
            </Grid>

            <Grid item >
                <Card sx={{ width: 340, height: 140 }} onClick={() => handleNavegacion('cambioclave') } >
                    <CardActionArea sx={{ width: '100%', height: '100%', display: 'flex', justifyContent:'space-evenly' }} >

                        <Box pl={2}>
                            <Avatar sx={{ width: 65, height: 65, backgroundColor:'primary.main' }}>
                                <HealthAndSafetyIcon sx={{ fontSize:40, color:'white' }} />
                            </Avatar>
                        </Box>

                        <CardContent sx={{ textAlign:'center' }}>
                            <Typography variant='h5' component="div">
                                Seguridad de Cuenta
                            </Typography>
                            <Typography variant="body3" color="text.secondary">
                                cambia tu contraseña periodicamente para mejor seguridad.
                            </Typography>
                        </CardContent>

                    </CardActionArea>
                </Card>
            </Grid>


            <Grid item >
                <Card sx={{ width: 340, height: 140 }}>
                    <CardActionArea sx={{ width: '100%', height: '100%', display: 'flex', justifyContent:'space-evenly' }} >

                        <Box pl={2}>
                            <Avatar sx={{ width: 65, height: 65, backgroundColor:'primary.main' }}>
                                <MarkAsUnreadIcon sx={{ fontSize:40, color:'white' }} />
                            </Avatar>
                        </Box>

                        <CardContent sx={{ textAlign:'center' }}>
                            <Typography variant='h5' component="div" >
                                Tus Mensajes
                            </Typography>
                            <Typography variant="body3" color="text.secondary">
                                Ver Mensajes de la empresa para un mejor servicio.
                            </Typography>
                        </CardContent>

                    </CardActionArea>
                </Card>
            </Grid>



        </Grid>



  )
}
