
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {  Button, Grid, TextField } from '@mui/material';
import { LayoutReturn } from '../../layout';
import { useGranjaStore } from '../../../hooks';
import Swal from 'sweetalert2';


const initialState = {
    nombre: '',
    metros: '',
    direccion: '',
    // canGalpones: 0,
    // canBodegas: 0,
    propietario: '',
    ciudad: '',
    departamento: '',
};

export const ActualizarGranja = () => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { activeGranja, setActiveGranja, startSavingGranja } = useGranjaStore();



    useEffect(() => {
        if( id ){
            setActiveGranja( id );
        }
    }, [id]);


    useEffect(() => {
        if( activeGranja ){
            setFormGranja( activeGranja );
        }
    }, [activeGranja]);
    

    const [formGranja, setFormGranja] = useState( initialState );
    const { nombre, metros, direccion, propietario, ciudad, departamento } = formGranja;

    const  handleInputChange = ({ target }) => {
        setFormGranja({
            ...formGranja,
            [target.name]: target.value
        });
    }




    const actualizarGranja = async(e) => {
        e.preventDefault();
        await Swal.fire({
            title: '¿Actualizar Granja?',
            text: `Esta Seguro De Actualizar a: ${ nombre }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                startSavingGranja( formGranja );
                navigate('/admin/list-granjas');
            }
        });

    }


    return (

        <LayoutReturn title='Actualizar Granja' url='/admin/list-granjas' >

            <form onSubmit={ actualizarGranja } >
                <Grid container spacing={ 2 }>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField
                            label='Nombre Granja'
                            type='text'
                            placeholder='Nombre Granja'
                            fullWidth
                            name='nombre'
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField
                            label='Metros Cuadrados'
                            type='number'
                            placeholder='Metros Cuadrados'
                            fullWidth
                            name='metros'
                            value={ metros }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField
                            label='Direccion'
                            type='text'
                            placeholder='Direccion'
                            fullWidth
                            name='direccion'
                            value={ direccion }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    {/* <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField
                            label='Cantida Galpones'
                            type='number'
                            placeholder='Cantida de Galpones'
                            fullWidth
                            name='canGalpones'
                            value={ canGalpones }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField
                            label='Cantida Bodegas'
                            type='number'
                            placeholder='Cantida de Bodegas'
                            fullWidth
                            name='canBodegas'
                            value={ canBodegas }
                            onChange={ handleInputChange }
                        />
                    </Grid> */}

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField
                            label='Propietario'
                            type='text'
                            placeholder='Propietario'
                            fullWidth
                            name='propietario'
                            value={ propietario }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField
                            label='Ciudad'
                            type='text'
                            placeholder='Ciudad'
                            fullWidth
                            name='ciudad'
                            value={ ciudad }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField
                            label='Departamento'
                            type='text'
                            placeholder='Departamento'
                            fullWidth
                            name='departamento'
                            value={ departamento }
                            onChange={ handleInputChange }
                        />
                    </Grid>
                    
                </Grid>

                <Grid container 
                    sx={{ mb:2, mt:2 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit'>
                            Actualizar Granja
                        </Button>
                    </Grid>
                </Grid>

            </form>
            
        </LayoutReturn>


    )



}
