

import { createSlice } from '@reduxjs/toolkit';



export const pedidoSlice = createSlice({
    name: 'pedido',
    initialState: {
        isLoadingPedido: true,
        pedidos: [],
        activePedido: null
    },
    reducers:{
        onSetActivePedido: ( state, { payload } ) => {
            state.activePedido = payload;
        },

        activePedidoDelete: ( state ) => {
            state.activePedido = null;
        },
        
        onAddNewPedido: ( state, { payload } ) => {
            state.pedidos.push( payload );
            state.activePedido = null;
        },

        onUpdatePedido: ( state, { payload } ) => {
            state.pedidos = state.pedidos.map( pro => {
                if( pro.id === payload.id ){
                    return payload
                }
                return pro
            });
        },

        onLoandingPedido: ( state, { payload = [] } ) => {
            state.isLoadingPedido = false;
            state.pedidos = payload;
        },


        onDeletePedido: ( state, { payload } ) => {
            state.pedidos = state.pedidos.filter( pro => pro.id !== payload.id );
        }
    }
});





export const { onSetActivePedido, onAddNewPedido, onUpdatePedido, onLoandingPedido, onDeletePedido, activePedidoDelete } = pedidoSlice.actions;