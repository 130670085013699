
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CardActionArea, CircularProgress, Grid, Typography } from '@mui/material';

import { useDescuentoStore, useDistribucionStore } from '../../../../hooks';



export const CuatroTiposDescuento = () => {

    const  { id } = useParams();
    const { venta, mortalidad, traslado, descuento,
            startLoadingVenta,  
            startLoadingMortalidad,
            startLoadingTraslado,
            startLoadingDesss, } = useDescuentoStore();
    const { activeDistribucion } = useDistribucionStore();

    const [ventaValor, setVentaValor] = useState(0);
    const [mortalidadValor, setMortalidadValor] = useState(0);
    const [trasladoValor, setTrasladoValor] = useState(0);
    const [descuentoValor, setDescuentoValor] = useState(0);


    useEffect(() => {
        if( id ){
            startLoadingVenta(id);
            startLoadingMortalidad(id);
            startLoadingTraslado(id);
            startLoadingDesss(id);
        }
    }, [id] );


    useEffect(() => {
        const total = venta.reduce( (total, can) => total + can.cantidad, 0 );
        setVentaValor(total);
    }, [venta] )


    useEffect(() => {
        const total = mortalidad.reduce( (total, can) => total + can.cantidad, 0 );
        setMortalidadValor(total);
    }, [mortalidad] )


    useEffect(() => {
        const total = traslado.reduce( (total, can) => total + can.cantidad, 0 );
        setTrasladoValor(total);
    }, [traslado] )


    useEffect(() => {
        const total = descuento.reduce( (total, can) => total + can.cantidad, 0 );
        setDescuentoValor(total);
    }, [descuento] )
    



    return (


        <Grid container direccion='row' mt={3} spacing={2}>
            {
            activeDistribucion &&
            <>

            <Grid item md={3} sm={6} xs={12} textAlign='center'>
                <CardActionArea >
                <Box width='100%' sx={{ border:'2px solid #4caf50', minHeight:'200px', padding:'10px' }}>
                    <Box width='100%' display='flex' justifyContent='center' alignItems='start' >
                        <Typography variant='h5' sx={{ fontWeight:'bold'  }}>
                            Venta
                        </Typography>
                    </Box>

                    <Box width='100%' my={3} >
                        <Typography variant="h5" component="div" sx={{ color:'#4caf50' }} >
                            Cantidad Pollos 
                        </Typography>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress size={110} variant="determinate" 
                                value={ ventaValor * 100 / activeDistribucion.cantidad } 
                                sx={{ color:'#4caf50' }} 
                            />
                            <Box
                                sx={{
                                top: 0, left: 0, bottom: 0, right: 0,
                                position: 'absolute', display: 'flex',
                                alignItems: 'center', justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" component="div" sx={{ fontSize: 21, fontWeight:'bold' }}>
                                    { ventaValor }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box width='100%'>
                        <Typography component="div" sx={{ fontSize: 16, fontWeight:'bold', color:'#4caf50' }}>
                            Venta de Pollos 
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight:'bold' }}>
                            { Math.ceil(ventaValor * 100 / activeDistribucion.cantidad) }%
                        </Typography>
                    </Box>

                </Box>
                </CardActionArea>
            </Grid>


            <Grid item md={3} sm={6} xs={12} textAlign='center'>
                <CardActionArea >
                <Box width='100%' sx={{ border:'2px solid #f50057', minHeight:'200px', padding:'10px' }}>
                    <Box width='100%' display='flex' justifyContent='center' alignItems='start' >
                        <Typography variant='h5' sx={{ fontWeight:'bold'  }}>
                            Mortalidad
                        </Typography>
                    </Box>

                    <Box width='100%' my={3} >
                        <Typography variant="h5" component="div" sx={{ color:'#f50057' }} >
                            Cantidad Pollos 
                        </Typography>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress size={110} variant="determinate" 
                                value={ mortalidadValor * 100 / activeDistribucion.cantidad }  
                                sx={{ color:'#f50057' }} 
                            />
                            <Box
                                sx={{
                                top: 0, left: 0, bottom: 0, right: 0,
                                position: 'absolute', display: 'flex',
                                alignItems: 'center', justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" component="div" sx={{ fontSize: 21, fontWeight:'bold' }}>
                                    { mortalidadValor }
                                </Typography>
                            </Box>
                        </Box>

                    </Box>

                    <Box width='100%'>
                        <Typography component="div" sx={{ fontSize: 16, fontWeight:'bold', color:'#f50057' }}>
                            Mortalidad de Pollos 
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight:'bold' }}>
                            { Math.ceil(mortalidadValor * 100 / activeDistribucion.cantidad) }%
                        </Typography>
                    </Box>

                </Box>
                </CardActionArea>
            </Grid>


            <Grid item md={3} sm={6} xs={12} textAlign='center'>
                <CardActionArea>
                <Box width='100%' sx={{ border:'2px solid #ff9800', minHeight:'200px', padding:'10px' }}>
                    <Box width='100%' display='flex' justifyContent='center' alignItems='start' >
                        <Typography variant='h5' sx={{ fontWeight:'bold'  }}>
                            Translados
                        </Typography>
                    </Box>

                    <Box width='100%' my={3} >
                        <Typography variant="h5" component="div" sx={{ color:'#ff9800' }} >
                            Cantidad Pollos 
                        </Typography>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress size={110} variant="determinate"
                                value={ trasladoValor * 100 / activeDistribucion.cantidad }  
                                sx={{ color:'#ff9800' }} 
                            />
                            <Box
                                sx={{
                                top: 0, left: 0, bottom: 0, right: 0,
                                position: 'absolute', display: 'flex',
                                alignItems: 'center', justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" component="div" sx={{ fontSize: 21, fontWeight:'bold' }}>
                                    { trasladoValor }
                                </Typography>
                            </Box>
                        </Box>

                    </Box>

                    <Box width='100%'>
                        <Typography component="div" sx={{ fontSize: 16, fontWeight:'bold', color:'#ff9800' }}>
                            Translados de Pollos 
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight:'bold' }}>
                            { Math.ceil(trasladoValor * 100 / activeDistribucion.cantidad) }%
                        </Typography>
                    </Box>

                </Box>
                </CardActionArea>
            </Grid>


            <Grid item md={3} sm={6} xs={12} textAlign='center'>
                <CardActionArea>
                <Box width='100%' sx={{ border:'2px solid #9e9e9e', minHeight:'200px', padding:'10px' }}>
                    <Box width='100%' display='flex' justifyContent='center' alignItems='start' >
                        <Typography variant='h5' sx={{ fontWeight:'bold'  }}>
                            Descuentos
                        </Typography>
                    </Box>

                    <Box width='100%' my={3} >
                        <Typography variant="h5" component="div" sx={{ color:'#9e9e9e' }} >
                            Cantidad Pollos 
                        </Typography>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress size={110} variant="determinate" 
                                value={ descuentoValor * 100 / activeDistribucion.cantidad }
                                sx={{ color:'#9e9e9e' }} 
                            />
                            <Box
                                sx={{
                                top: 0, left: 0, bottom: 0, right: 0,
                                position: 'absolute', display: 'flex',
                                alignItems: 'center', justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" component="div" sx={{ fontSize: 21, fontWeight:'bold' }}>
                                    { descuentoValor }
                                </Typography>
                            </Box>
                        </Box>

                    </Box>

                    <Box width='100%'>
                        <Typography component="div" sx={{ fontSize: 16, fontWeight:'bold', color:'#9e9e9e' }}>
                            Descuentos de Pollos 
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight:'bold' }}>
                            { Math.ceil(descuentoValor * 100 / activeDistribucion.cantidad) }%
                        </Typography>
                    </Box>

                </Box>
                </CardActionArea>
            </Grid>

            </>
            }
        </Grid>

    )



}
