
import { useNavigate } from 'react-router-dom';
import { Box,  Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useNotaStore } from '../../../../hooks';

import Swal from 'sweetalert2';





export const CardNotaPorOp = ({ nota }) => {



    const { deleteNota } = useNotaStore();
    const navigate = useNavigate();


    
    const eliminarNotaOp = ( nota ) => {
        Swal.fire({
            title: 'Eliminar Nota',
            text: `¿Esta Seguro De Eliminar Esta Nota?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Eliminar' 
        }).then( ( result ) => {
            if(result.value){
                deleteNota( nota.id );
            }
        });
    }



    const handleNewProducto = (nota) => {
        navigate('/admin/producto')
        // activePedidoStart(pedido.id);
        console.log(nota);
    }


    return (

                    
        <Grid item md={3} sm={4} xs={12} >

            <Box width='100%' sx={{ background:'#fce4ec', minHeight:'170px'}}>
                <Box width='100%' display='flex' justifyContent='space-between' alignItems='start' >
                    <IconButton aria-label="delete" size="small"
                        sx={{
                            color: '#4caf50',
                            ':hover': {  opacity: 0.7 },
                        }}
                        onClick={ () => handleNewProducto(nota) }
                    >
                        <AddCircleIcon />
                    </IconButton>

                    {/* <Box flex={ 1 } /> */}

                    <Typography variant='h6' sx={{ fontWeight:'bold'  }}>
                        Nota 
                    </Typography>

                    {/* <Box flex={ 1 } /> */}

                    <IconButton aria-label="delete" size="small"
                        sx={{
                            color: 'error.main',
                            ':hover': {  opacity: 0.7 },
                        }}
                        onClick={ () => eliminarNotaOp(nota) }
                    >
                        <DeleteIcon />
                    </IconButton>
                    
                </Box>

                <Box width='100%' textAlign='center'>
                    <Typography variant="h6" component="div">
                        Producto
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        { nota.nombre }
                    </Typography>
                </Box>

                <Box width='100%' textAlign='center'>
                    <Typography variant="h6" component="div">
                        Cantidad
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        { nota.cantidad }
                    </Typography>
                </Box>
                <Box width='100%' textAlign='center'>
                    <Typography sx={{ fontSize:14, fontWeight:'bold' }} color="red">
                       Por Favor Crear Producto.
                    </Typography>
                </Box>
            </Box>
        </Grid>



    )
}
