
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import { LayoutAdd } from "../../layout";
import { useVehiculoStore } from "../../../hooks";
import { AlertScreen } from "../../components/AlertScreen";
import { BuscarVehiculos } from "./BuscarVehiculos";

import Swal from "sweetalert2";
import moment from "moment";




export const ListadoVehiculos = () => {


  const navigate = useNavigate();
  const { vehiculos, startLoadingVehiculos, deleteVehiculo } = useVehiculoStore();


  useEffect(() => {
    startLoadingVehiculos();
  }, [])

  


  const eliminarLote = ( v ) => {
    Swal.fire({
        title: 'Eliminar Vehiculo',
        text: `¿Esta Seguro De Eliminar Este Vehiculo: ${ v.placa }?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si, Eliminar' 
    }).then( ( result ) => {
        if(result.value){
          deleteVehiculo( v );
        }
    });
  }



  const handleActualizarLote = ( uid ) => {
    navigate(`/admin/vehi-update/${ uid }`);
  }



  const handleImgSeguros = ( uid ) => {
    navigate(`/admin/img-seguros/${ uid }`);
  }




  return (


      <LayoutAdd title='Listado de Vehiculos' url='/admin/vehiculo'>

          <BuscarVehiculos />


          { ( vehiculos.length > 0 ) 
          ?<TableContainer>
            <Table sx={{ minWidth: 700, }}>

              <TableHead>
                <TableRow>
                  <TableCell>PLACA</TableCell>
                  <TableCell>MODELO</TableCell>
                  <TableCell>CONDUCTOR</TableCell>
                  <TableCell>VENCIMIENTO SOAT</TableCell>
                  <TableCell>VENCIMIENTO TECNO</TableCell>
                  <TableCell>VENCIMIENTO RIESGO</TableCell>
                  <TableCell>OPCIONES</TableCell>
                </TableRow>
              </TableHead>
              
              <TableBody>
                { vehiculos.map( ( v ) => (
                  <TableRow key={ v.id } >
                    <TableCell> { v.placa } </TableCell>
                    <TableCell> { v.modelo } </TableCell>
                    <TableCell>
                      { 
                      ( v.conductor )
                      ? v.conductor.nombre
                      : <Typography variant="subtitles" color="error" >
                        User Eliminado o Bloqueado
                      </Typography>
                      } 
                    </TableCell>
                    <TableCell> { moment(v.soatFin).format('l') } </TableCell>
                    <TableCell> { moment(v.tecnoFin).format('l') } </TableCell>
                    <TableCell> { moment(v.todoRiesgoFin).format('l') } </TableCell>

                    <TableCell>

                    {/* onClick={ () => handlePictureClick(v.id) } */}
                        <IconButton sx={{ color: '#4caf50' }} onClick={ () => handleImgSeguros(v.id) }>
                            <AddPhotoAlternateIcon fontSize="small" />
                        </IconButton>

                        <IconButton color="success" onClick={ () => handleActualizarLote( v.id ) } >
                          <UpdateIcon fontSize="small"/>
                        </IconButton>

                        <IconButton color="error" onClick={ () => eliminarLote( v ) } >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          :<AlertScreen title="No Existen Datos De Vehiculos" />

          }

      </LayoutAdd>


        
  )




}
