import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuthStore } from '../../hooks';

import { Button, Grid, TextField, Typography } from "@mui/material";
import { AuthLayout } from '../layout/AuthLayout';
import Swal from 'sweetalert2';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';





export const LoginScreen = () => {

    const { startLogin, errorMessage } = useAuthStore();
    const navigate = useNavigate();
    // const { errorMessage } = useSelector( state => state.auth );


    
    const [formLogin, setFormLogin] = useState({
        // email: 'david@gmail.com',
        // password: '1234567'
        email: '',
        password: ''
    });
    const { email, password } = formLogin;
    const handleInputChange = ({ target }) => {
        setFormLogin({
            ...formLogin,
            [target.name]: target.value
        });
    }


    const handleSubmit = async(e) => {
        e.preventDefault();
        // console.log(formLogin);
        await startLogin(formLogin);
    }


    const volverHome = () => {
        navigate('/home');
    }


    useEffect(() => {
        if( errorMessage !== undefined ){
            Swal.fire('Error en la Autenticacion', errorMessage, 'error');
        }
    }, [ errorMessage ]);
    


    return (

        <AuthLayout tittle='Login' >
            <form onSubmit={ handleSubmit } >
                <Grid container >

                    <Grid item xs={ 12 } mt={ 2 }>
                        <TextField
                            label='Correo'
                            type='email'
                            placeholder='Correo'
                            fullWidth
                            name='email'
                            value={ email }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } mt={ 2 }>
                        <TextField
                            label='Contraseña'
                            type='password'
                            placeholder='Contraseña'
                            fullWidth
                            name='password'
                            value={ password }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid container spacing={ 2 } sx={{ mb:2, mt:2 }} diplay="flex" justifyContent="center" >
                        <Grid item xs={ 12 } sm={ 8 } >
                            <Button variant='contained' fullWidth type="submit" >
                                Login
                            </Button>
                        </Grid>

                        <Grid item xs={ 12 } sm={ 8 } >
                            <Button variant='contained' fullWidth onClick={ volverHome } >
                                <ArrowCircleLeftIcon />
                                <Typography sx={{ ml:1 }}>Volver Al Home</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    
                    {/* <Grid container direction='row' justifyContent='end' >
                        <Link component={ RouterLink } to="/auth/register">
                            Crear una Cuenta
                        </Link>
                    </Grid> */}

                </Grid>
            </form>
        </AuthLayout>


    )



}
