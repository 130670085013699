
import { useNavigate } from "react-router-dom";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useModalStore } from "../../hooks";




export const LayoutReturnAdd = ({ children, title, url }) => {


    const navigate = useNavigate();
    const { openModalAbono } = useModalStore();




    const regresar = () => {
        navigate(url);
    }


    const abrirModalAbonos = () => {
        openModalAbono();
    }




    return (


        <Grid container sx={{ minHeight:'50vh', padding:4 }}>
        

            <Grid  container direccion='row' alignItems='center' my={3} >
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ regresar }
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h1'>{ title }</Typography>

                <Box flex={ 1 } />

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'primary.main',
                        ':hover': { backgroundColor: 'primary.main', opacity: 0.3 }
                    }}
                    onClick={ abrirModalAbonos }
                >
                    <AddOutlined />
                </IconButton>
            </Grid>



            { children }





        </Grid>


    )


    
}
