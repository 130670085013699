

import moment from "moment";



export const adicionHoraMinuto = ( number ) => { 

    const selectedDateString = moment(number).format('YYYY-MM-DD');
    
    // Obtener la hora y los minutos actuales
    const now = moment();
    const hour = now.format('HH');
    const minute = now.format('mm');
    
    // Concatenar la fecha seleccionada y la hora actual en una cadena
    const datetimeString = `${selectedDateString} ${hour}:${minute}`;
    
    // Analizar la cadena completa como una fecha
    const datetime = moment(datetimeString);

    return datetime;

}
