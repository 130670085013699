

import { useNavigate, useParams } from "react-router-dom";

import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { AdminLayout } from "../../layout/AdminLayout";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useEffect } from "react";
import { usePlanillaStore, useUsuarioStore } from "../../../hooks";
import { AlertScreen } from "../../components/AlertScreen";
import moment from "moment";
import { separadorMillares } from "../../../hooks/hooks";
import { resultadoMermas } from "../../../helpers/resultadoMermas";






export const VerPlanillasXCliente = () => {


    const { id } = useParams();
    const navigate = useNavigate();
    const { planillas, startLoadingPlanillaCliente } = usePlanillaStore();
    const { activeUsuario, setActiveUsuario } = useUsuarioStore();


    useEffect(() => {
      if( id ){
        startLoadingPlanillaCliente(id);
      }
    }, [id])



    useEffect(() => {
        if( id ){
            setActiveUsuario( id )
        }
    }, [ id ] );


    const regresar = () => {
        navigate('/admin/list-cliente');
    }


    const verPlanillaId = (uid) => {
        navigate(`/admin/ver-planilla/${ uid }`);
    }


    return (

        <AdminLayout >

        <Grid 
        container
        direccion='row'
        alignItems='center'
        >
            <IconButton
                sx={{
                    color: '#fff',
                    backgroundColor: 'error.main',
                    ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                }}
                onClick={ regresar }
            >
                <ArrowBackIcon />
            </IconButton>

            <Box flex={ 1 } />

            <Typography variant='h4' sx={{ mb:2, fontWeight:'bold' }} >
                Listado de Planillas { (activeUsuario) ? `de ${activeUsuario.nombre}` : '' }
            </Typography>

            <Box flex={ 1 } />
        </Grid>


        { ( planillas?.length > 0 )
          ?<>

              {/* <OpcionesDeBusqueda /> */}

              <TableContainer sx={{my:3}} >
                <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>#PLANILLA</TableCell>
                          <TableCell>FECHA</TableCell>   
                          <TableCell>CANTIDAD</TableCell>
                          <TableCell>VALOR KILO</TableCell>   
                          <TableCell>TOTAL MERMAS</TableCell>
                          <TableCell>PESO NETO</TableCell>
                          <TableCell>VALOR TOTAL</TableCell>
                      </TableRow>
                  </TableHead>

                  <TableBody>
                      {
                      planillas.map( p => (
                      <TableRow key={ p.id } onClick={ () => verPlanillaId(p.id) } >
                          <TableCell>{ p.numero }</TableCell>   
                          <TableCell>{ moment(p.createdAt).format('L') }</TableCell>
                          <TableCell>{ p.cantidad }</TableCell>   
                          <TableCell>{ separadorMillares(p.valorKilo) }</TableCell>   
                          <TableCell>{ (p.pesoNeto > 0 ) ? p.pesoNeto : 0 }Kg</TableCell> 
                          <TableCell>{ resultadoMermas(p.mermas) }Kg</TableCell>
                          <TableCell>{ (p.pesoNeto > 0 ) ? `$${separadorMillares(p.valorKilo * (p.pesoNeto - resultadoMermas(p.mermas)))}` : 0 }</TableCell>   
                      </TableRow>
                      ))
                      }
                  </TableBody>
                </Table>
            </TableContainer>
          </>

        :<AlertScreen title="No Existen Planillas Para Este Cliente" />

        } 

    </AdminLayout>

    )



}
