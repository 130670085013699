

import { Button, Grid } from "@mui/material";
import { useEffect } from "react";

import { useIngresoRaizStore } from "../../../../hooks";
import { TablaResgistroIngresoRaiz } from "../components";
import { AlertScreen } from "../../../components";
import { LayoutReturn } from "../../../layout"

import { convertirJsonAExcel } from "../../../../helpers";





export const TodoEntradasInventarioRaiz = () => {



    const { ingresos, startLoadingIngresoRaiz } = useIngresoRaizStore();



    useEffect(() => {
        startLoadingIngresoRaiz();
    }, [] );

    

    const convertirAExel = () => {
        convertirJsonAExcel(ingresos);
    }




    return (

        <LayoutReturn title={`Todas Las Entradas A La Bodega-Principal`} url={-1} >

            { ( ingresos.length > 0 ) 
            
            ? <TablaResgistroIngresoRaiz />


            : <AlertScreen title="No Hay Ingresos De Este Productos A Bodega Principal"/>

            }


            {
            (ingresos.length > 0 ) &&
            <Grid container 
                sx={{ mb:2, mt:2 }} 
                direccion='column'
                justifyContent='center' 
            >
                <Grid item xs={ 12 } sm={ 8 } >
                    <Button variant='contained' fullWidth onClick={ convertirAExel }>
                        Exportar a Exel
                    </Button>
                </Grid>
            </Grid>
            }


        </LayoutReturn>


    )


    
}
