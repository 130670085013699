
import { useState } from "react";

import { Button, Grid, TextField } from "@mui/material";
import { LayoutReturn } from "../../layout";

import { useEncubadoraStore } from "../../../hooks";


const initialState = {
    nombre: "",
    telefono: "",
    nit: "",
    direccion: "",
    email: "",
    ciudad: "",
}




export const EncubadoraScreen = () => {


    const { startSavingEncubadora } = useEncubadoraStore();


    const [formEncubadora, setFormEncubadora] = useState( initialState );
    const  { nombre, telefono, nit, direccion, email, ciudad } = formEncubadora;

    const handleChangeInput = ({ target }) => {
        setFormEncubadora({
            ...formEncubadora,
            [target.name]: target.value
        });
    }


    const handleCrearEncubadora = async(e) => {
        e.preventDefault();
        await startSavingEncubadora(formEncubadora);
        setFormEncubadora( initialState );
    }


    return (


        <LayoutReturn title='Crear Incubadora' url='/admin/list-encuba' >


            <form onSubmit={ handleCrearEncubadora } >
                <Grid container spacing={ 2 }>
                
                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Nombre'
                            type='text'                                                 
                            placeholder='Nombre'
                            fullWidth                                               
                            name='nombre'
                            value={ nombre }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Email'
                            type='email'
                            placeholder='Email'
                            fullWidth
                            name='email'
                            value={ email }
                            onChange={ handleChangeInput }
                        />
                    </Grid> 

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Nit'
                            type='text'
                            placeholder='Nit'
                            fullWidth
                            name='nit'
                            value={ nit }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Telefono'
                            type='text'
                            placeholder='Telefono'
                            fullWidth
                            name='telefono'
                            value={ telefono }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Direccion'
                            type='text' 
                            placeholder='Direccion'
                            fullWidth
                            name='direccion'
                            value={ direccion }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Ciudad'
                            type='text' 
                            placeholder='Ciudad'
                            fullWidth
                            name='ciudad'
                            value={ ciudad }
                            onChange={ handleChangeInput }
                        />
                    </Grid>
                </Grid>

                <Grid container 
                    sx={{ mb:2, mt:2 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit' >
                            Crear Incubadora
                        </Button>
                    </Grid>
                </Grid>
            </form>


        </LayoutReturn>


    )


}
