

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { AdminLayout } from "../../layout/AdminLayout";
import {  useAbonoPlanillaStore, usePlanillaStore } from "../../../hooks";
import { separadorMillares } from "../../../hooks/hooks/useMillares";
import { AlertScreen } from "../../components/AlertScreen";



export const ListadoAbonosXPlanilla = () => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { abonosPlanilla, listadoAbonosPlanilla } = useAbonoPlanillaStore();
    const { activePlanilla, setActivePlanilla } = usePlanillaStore();

   const [totalAbonos, setTotalAbonos] = useState(0);


    useEffect(() => {
        if( id ){
            setActivePlanilla(id);
        }
    }, [id]);


    useEffect(() => {
        if( id ){
            listadoAbonosPlanilla(id);
        }
    }, [id]);


    useEffect(() => {
        if( abonosPlanilla ){
            const totales = abonosPlanilla.reduce( (total, can) => Number(total) + Number(can.valor), 0);
            setTotalAbonos( totales );
        }
    }, [abonosPlanilla]);

    
    
    // const eliminarAbono = (abono) => {
    //     Swal.fire({
    //         title: 'Eliminar Abono',
    //         text: `¿Esta Seguro De Eliminar Este Abono?`,
    //         icon: 'question',
    //         showCancelButton: true,
    //         confirmButtonText: 'Si' 
    //         }).then( ( result ) => {
    //         if(result.value){
    //             deleteAbono(abono);
    //         }
    //     });
    // }

    const regresar = () => {
        navigate(-1);
    }


    return (

        <>
        
        <AdminLayout >
            <Grid 
            container
            direccion='row'
            alignItems='center'
            >
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ regresar }
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h4' sx={{ mb:2, fontWeight:'bold'  }} >
                    Abonos Planilla 
                </Typography>

                <Box flex={ 1 } />

            </Grid>

            { activePlanilla && 
            <Table sx={{ marginBottom:5 }} >
                <TableHead>
                    <TableRow>
                        <TableCell>#PLANILLA</TableCell>
                        <TableCell>CANTIDAD</TableCell>
                        <TableCell>CLIENTE</TableCell>
                        <TableCell>TOTAL FACTURA</TableCell>   
                        <TableCell>TOTAL ABONOS</TableCell>
                        <TableCell>SALDO TOTAL</TableCell>   
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{ activePlanilla.numero }</TableCell>   
                        <TableCell>{ activePlanilla.cantidad }</TableCell>   
                        <TableCell>{ activePlanilla.cliente?.nombre }</TableCell>   
                        <TableCell>{ `$${separadorMillares(activePlanilla.valorKilo * activePlanilla.pesoNeto)}` }</TableCell>   
                        <TableCell>{ `$${separadorMillares(totalAbonos)}` }</TableCell>
                        <TableCell>{ `$${separadorMillares((activePlanilla.valorKilo * activePlanilla.pesoNeto) - totalAbonos)}` }</TableCell>   
                    </TableRow>
                </TableBody>
            </Table>
            }


            { (abonosPlanilla.length > 0 )
            ?<TableContainer>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">

                    <TableHead >
                    <TableRow>
                        <TableCell>VALOR</TableCell>
                        <TableCell>FECHA</TableCell>
                        <TableCell>MEDIO DE PAGO</TableCell>
                        <TableCell sx={{ width:200 }}>OBSERVACION</TableCell>
                        {/* <TableCell>OPCIONES</TableCell> */}
                    </TableRow>
                    </TableHead>

                    <TableBody>
                    { abonosPlanilla.map( ( f ) => (
                    <TableRow key={ f.id } >
                        <TableCell>{ `$${ separadorMillares(f.valor) }` }</TableCell>
                        <TableCell>{ f.createdAt }</TableCell>   
                        <TableCell>{ f.medio }</TableCell>
                        <TableCell className="cortar-texto">{ f.observacion }</TableCell>
                        {/* <TableCell sx={{ width:'200px' }}> */}
                            {/* <IconButton color="error" onClick={ () => eliminarAbono( f ) } >
                                <DeleteIcon fontSize="small" />
                            </IconButton> */}
                        {/* </TableCell> */}

                    </TableRow>
                    ))
                    }
                    </TableBody>

                </Table>
            </TableContainer>

            :<AlertScreen title={'No hay registro de Abonos.'} />

            }
            
        </AdminLayout>

        </>
    )


}
