

import { createSlice } from '@reduxjs/toolkit';



export const descuentoSlice = createSlice({
    name: 'descuento',
    initialState: {
        isLoadingDescuento: true,
        descuentos: [],
        mortalidad: [],
        venta: [],
        traslado: [],
        descuento: [],
        activeDescuento: null
    },
    reducers:{
        onSetActiveDescuento: ( state, { payload } ) => {
            state.activeDescuento = payload;
        },
    
        
        onAddNewDescuento: ( state, { payload } ) => {
            state.activeDescuento = null;
            switch( payload.descuento ){
                case 'MORTALIDAD':
                    state.mortalidad.unshift( payload )
                    break;
                default:
                    state.descuentos.unshift( payload );
            }
        },


        
        onUpdateDescuento: ( state, { payload } ) => {
            state.descuentos = state.descuentos.map( des => {
                if( des.id === payload.id ){
                    return payload
                }
                return des
            });
        },

        onLoandingDescuento: ( state, { payload = [] } ) => {
            state.isLoadingDescuento = false;
            state.descuentos = payload;
        },

        onLoandingMortalidad: ( state, { payload = [] } ) => {
            state.isLoadingDescuento = false;
            state.mortalidad = payload;
        },

        onLoandingVenta: ( state, { payload = [] } ) => {
            state.isLoadingDescuento = false;
            state.venta = payload;
        },

        onLoandingTraslado: ( state, { payload = [] } ) => {
            state.isLoadingDescuento = false;
            state.traslado = payload;
        },

        onLoandingDesss: ( state, { payload = [] } ) => {
            state.isLoadingDescuento = false;
            state.descuento = payload;
        },

        onDeleteDescuento: ( state, { payload } ) => {
            state.descuentos = state.descuentos.filter( des => des.id !== payload.id );
            switch (payload.descuento) {
                case 'MORTALIDAD':
                    state.mortalidad = state.mortalidad.filter( des => des.id !== payload.id );
                break;

                case 'VENTA':
                    state.venta = state.venta.filter( des => des.id !== payload.id );
                break;

                case 'TRASLADO':
                    state.traslado = state.traslado.filter( des => des.id !== payload.id );
                break;

                case 'DESCUENTO':
                    state.descuento = state.descuento.filter( des => des.id !== payload.id );
                break;
                default:
                break;
            }
        }
    }
});





export const { onSetActiveDescuento, 
                onAddNewDescuento, 
                onUpdateDescuento, 
                onLoandingDescuento, 
                onDeleteDescuento, 
                onLoandingMortalidad,
                onLoandingVenta,
                onLoandingTraslado,
                onLoandingDesss } = descuentoSlice.actions;