
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { LayoutReturn } from '../../layout';
import { useProductoStore, useProveedorStore, useUnidadMedidaStore } from '../../../hooks';
import Swal from 'sweetalert2';



    const initialState = {
        nombre: '',
        precioSin: '',
        unidadMedida: '',
        iva: '',
        proveedor: '',
        detalles: '',
    };



  export const ActualizarProducto = () => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { activeProducto, startSavingProducto, setActiveProducto } = useProductoStore();
    const { proveedores, startLoadingProveedores } = useProveedorStore();
    const { unidadMedidas, startLoadingUnidadMedida } = useUnidadMedidaStore();

    const [formProducto, setFormProducto] = useState( initialState );
    const { nombre, precioSin, proveedor, detalles, unidadMedida, iva } = formProducto;


    const  handleInputChange = ({ target }) => {
    setFormProducto({
            ...formProducto,
            [target.name]: target.value
        });
    }



    useEffect(() => {
        startLoadingProveedores();
    }, []);


    useEffect(() => {
        if( id ){
            setActiveProducto( id );
        }
    }, [id]);


    useEffect(() => {
        startLoadingUnidadMedida();
    }, []);



    useEffect(() => {
      if( activeProducto ){
        setFormProducto({ 
          ...activeProducto,
          proveedor: activeProducto.proveedor || '',
          unidadMedida: activeProducto.unidadMedida || '',
        });
      }
    }, [activeProducto]);


    const actualizarProducto = async(e) => {
        e.preventDefault();
        await Swal.fire({
          title: 'Actualizar Producto?',
          text: `Esta Seguro De Actualizar: ${ nombre }`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Actualziar' 
      }).then( ( result ) => {
          if(result.value){
            startSavingProducto( formProducto );
            navigate('/admin/list-producto');
          }
      });
    }



    return (
    
        <LayoutReturn title='Actualizar Producto' url='/admin/list-producto' >

            <form onSubmit={ actualizarProducto } >
                <Grid container spacing={ 2 }>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Nombre'
                            type='text'
                            placeholder='Nombre'
                            fullWidth
                            name='nombre'
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                        select
                        label="Proveedor"
                        fullWidth
                        name='proveedor'
                        value={ proveedor }
                        onChange={ handleInputChange }
                    >
                        <MenuItem value=''>Seleccione Proveedor</MenuItem>
                        {
                        proveedores.map( p => (
                            <MenuItem key={ p.id } value={ p.id }>{ p.nombre }</MenuItem>
                        ))
                        }
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                        select
                        label="Unidad Medida"
                        fullWidth
                        name='unidadMedida'
                        value={ unidadMedida }
                        onChange={ handleInputChange }
                    >
                        <MenuItem value=''>Seleccione Unidad Medida</MenuItem>
                        {
                        unidadMedidas.map( m => (
                            <MenuItem key={ m.id } value={ m.id }>{ m.nombre }</MenuItem>
                        ))
                        }
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Iva'
                            type='number'
                            placeholder='Iva'
                            fullWidth
                            name='iva'
                            value={ iva }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Precio Sin Iva'
                            type='number'
                            placeholder='Precio Sin Iva'
                            fullWidth
                            name='precioSin'
                            value={ precioSin }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label="Detalles"
                            placeholder='Detalles del Producto'
                            multiline
                            fullWidth
                            name="detalles"
                            value={ detalles }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                </Grid>

                <Grid container 
                    sx={{ mb:2, mt:2 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit' >
                            Actualizar Producto
                        </Button>
                    </Grid>
                </Grid>

            </form>
            
        </LayoutReturn>

    )


    
    }
