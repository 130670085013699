import { createSlice } from "@reduxjs/toolkit";




export const modalSlice = createSlice({

    name: 'modal',
    initialState: {
        isModalOpen: false,
        modalMedida: false,
        modalOp: false,
        modalPedido: false,
        modalNota: false,
        modalFactura: false,
        modalAbono: false,
        modalPlanillaAbono: false,

    },
    reducers: {
        onOpenModal: ( state ) => {
            state.isModalOpen = true;
            state.modalMedida = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        onCloseModal: ( state ) => {
            state.isModalOpen = false;
            state.modalMedida = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        abrirModalMedida: ( state ) => {
            state.isModalOpen = false;
            state.modalMedida = true;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        cerrarModalMedida: ( state ) => {
            state.modalMedida = false;
            state.isModalOpen = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        abrirModalOp: ( state ) => {
            state.isModalOpen = false;
            state.modalMedida = false;
            state.modalOp = true;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        cerrarModalOp: ( state ) => {
            state.modalMedida = false;
            state.isModalOpen = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        abrirModalPedido: ( state ) => {
            state.isModalOpen = false;
            state.modalMedida = false;
            state.modalOp = false;
            state.modalPedido = true;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        cerrarModalPedido: ( state ) => {
            state.modalMedida = false;
            state.isModalOpen = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        abrirModalNota: ( state ) => {
            state.isModalOpen = false;
            state.modalMedida = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = true;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        cerrarModalNota: ( state ) => {
            state.modalMedida = false;
            state.isModalOpen = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        abrirModalFactura: ( state ) => {
            state.isModalOpen = false;
            state.modalMedida = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = true;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        cerrarModaFactura: ( state ) => {
            state.modalMedida = false;
            state.isModalOpen = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        abrirModalAbono: ( state ) => {
            state.isModalOpen = false;
            state.modalMedida = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = true;
            state.modalPlanillaAbono = false;
        },

        cerrarModaAbono: ( state ) => {
            state.modalMedida = false;
            state.isModalOpen = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        },

        abrirModalAbonoPlailla: ( state ) => {
            state.isModalOpen = false;
            state.modalMedida = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = true;
        },

        cerrarModaAbonoPlanilla: ( state ) => {
            state.modalMedida = false;
            state.isModalOpen = false;
            state.modalOp = false;
            state.modalPedido = false;
            state.modalNota = false;
            state.modalFactura = false;
            state.modalAbono = false;
            state.modalPlanillaAbono = false;
        }
    }

});





export const { onOpenModal, onCloseModal, abrirModalMedida, cerrarModalMedida, abrirModalOp, cerrarModalOp, abrirModalPedido, cerrarModalPedido, abrirModalNota, cerrarModalNota, abrirModalFactura, cerrarModaFactura, abrirModalAbono, cerrarModaAbono, abrirModalAbonoPlailla, cerrarModaAbonoPlanilla } = modalSlice.actions;
