
import moment from 'moment';
// moment.locale('es');


export const prepararFecha = ( lote = [] ) => {
    return lote.map(
        (e) => ({
            ...e,
            fechaInicio: moment(e.fechaInicio).format('LLL'),
        })
    )
}



export const prepararFechaUno = ( lote = {} ) => {
    return lote = {
        ...lote,
        fechaInicio: moment(lote.fechaInicio).format('LLL'),
    }
}




export const fechaNotificacion = ( noti = [] ) => {
    return noti.map(
        (e) => ({
            ...e,
            createdAt: moment(e.createdAt).format('l'),
            dateRecibe: moment(e.dateRecibe).format('l'),
        })
    )
}




export const fechaNotificacionUno = ( noti = {} ) => {
    return noti = {
        ...noti,
        createdAt: moment(noti.createdAt).format('LLL'),
        dateRecibe: moment(noti.dateRecibe).format('LLL'),
    }
}




export const prepararFechaPedido = ( pedido = [] ) => {
    return pedido.map(
        (e) => ({
            ...e,
            createdAt: moment(e.createdAt).format('LLL'),
        })
    )
}



export const prepararFechaPedidoUno = ( pedido = {} ) => {
    return pedido = {
        ...pedido,
        createdAt: moment(pedido.createdAt).format('LLL'),
    }
}



export const prepararFechaOp = ( op = [] ) => {
    return op.map(
        (e) => ({
            ...e,
            fechaPedido: moment(e.fechaPedido).format('LL'),
            fechaAprobado: ( e.fechaAprobado ) ? moment(e.fechaAprobado).format('LLL') : '',
        })
    )
}



export const prepararFechaUnoOp = ( op = {} ) => {
    return op = {
        ...op,
        fechaPedido: moment(op.fechaPedido).format('LLL'),
        fechaAprobado: ( op.fechaAprobado ) ? moment(op.fechaAprobado).format('LLL') : '',
    }
}



export const prepararFechaFactura = ( f = [] ) => {
    return f.map(
        (e) => ({
            ...e,
            createdAt: moment(e.createdAt).format('LL'),
            vencimiento: ( e.vencimiento ) ? moment(e.vencimiento).format('LL') : '',
        })
    )
}

export const prepararFechaUnoFactura = ( f = {} ) => {
    return f = {
        ...f,
        createdAt: moment(f.createdAt).format('LL'),
        vencimiento: ( f.vencimiento ) ? moment(f.vencimiento).format('LL') : '',
    }
}




export const prepararFechaCorta = ( f = [] ) => {
    return f.map(
        (e) => ({
            ...e,
            createdAt: moment(e.createdAt).format('L'),
            vencimiento: ( e.vencimiento ) ? moment(e.vencimiento).format('L') : '',
        })
    )
}