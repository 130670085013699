

import { createSlice } from '@reduxjs/toolkit';



export const abonoSlice = createSlice({
    name: 'factura',
    initialState: {
        isLoadingAbono: true,
        abonos: [],
        activeAbono: null
    },
    reducers:{

        onSetActiveAbono: ( state, { payload } ) => {
            state.activeAbono = payload;
        },

        activeAbonoDelete: ( state ) => {
            state.activeAbono = null;
        },
        
        onAddNewAbono: ( state, { payload } ) => {
            state.abonos.unshift( payload );
            state.activeAbono = null;
        },

        onUpdateAbono: ( state, { payload } ) => {
            state.abonos = state.abonos.map( abono => {
                if( abono.id === payload.id ){
                    return payload
                }
                return abono
            });
        },

        onLoandingAbonos: ( state, { payload = [] } ) => {
            state.isLoadingAbono = false;
            state.abonos = payload;
        },

        onDeleteAbono: ( state, { payload } ) => {
            state.abonos = state.abonos.filter( abono => abono.id !== payload.id );
        }
    }
});





export const { onSetActiveAbono, activeAbonoDelete, onAddNewAbono, onUpdateAbono, onLoandingAbonos, onDeleteAbono } = abonoSlice.actions;