import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useGalponStore } from "../../../hooks";

import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import { AdminLayout } from "../../layout/AdminLayout"
import Swal from "sweetalert2";
import { BuscarGalpones } from "./BuscarGalpones";



export const ListadoGalpones = () => {


    const navigate = useNavigate();
    const { galpones, startLoadingGalpones, deleteGalpon } = useGalponStore();

    
    useEffect(() => {
        startLoadingGalpones();
    }, [])
    

    const eliminarGalpon = (galpon) => {
      Swal.fire({
          title: '¿Eliminar Galpon?',
          text: `Esta Seguro De Eliminar a: ${ galpon.nombre }`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Eliminar' 
      }).then( ( result ) => {
          if(result.value){
            deleteGalpon( galpon );
          }
      });
    }


    const handleActualizar = (uid) => {
      navigate(`/admin/galpon-update/${ uid }`);
    }
    
    const crearGalpon = () => {
        navigate('/admin/galpones');
    }



  return (


    <AdminLayout>

        <Grid 
          container
          direccion='row'
          alignItems='center'
        >
            <IconButton
                sx={{
                    color: '#fff',
                    backgroundColor: 'error.main',
                    ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                }}
                onClick={ crearGalpon }
            >
                <AddOutlined />
            </IconButton>

            <Box flex={ 1 } />

            <Typography variant='h4' sx={{ mb:2, fontWeight:'bold' }} >Listado de Galpones</Typography>

            <Box flex={ 1 } />

        </Grid>
        

        <BuscarGalpones />


        <TableContainer>
          <Table sx={{ minWidth: 700, }} aria-label="customized table">

            <TableHead sx={{ backgroundColor:'red'}}>
              <TableRow>
                <TableCell>NOMBRE</TableCell>
                <TableCell>GRANJA</TableCell>
                <TableCell>CANTIDAD</TableCell>
                <TableCell>ALTO</TableCell>
                <TableCell>LARGO</TableCell>
                <TableCell>ANCHO</TableCell>
                <TableCell>AREA</TableCell>
                <TableCell>OPCIONES</TableCell>
              </TableRow>
            </TableHead>

            { galpones && (
            <TableBody>
              { galpones.map( (galpon) => (
                <TableRow key={ galpon.id } >
                    <TableCell >{ galpon.nombre }</TableCell>
                    <TableCell>{ galpon.granja?.nombre }</TableCell>
                    <TableCell>{ galpon.cantidad }</TableCell>
                    <TableCell>{ galpon.alto }</TableCell>
                    <TableCell>{ galpon.largo }</TableCell>
                    <TableCell>{ galpon.ancho }</TableCell>
                    <TableCell>{ galpon.area }</TableCell>


                    <TableCell sx={{ width:'200px' }}>
                          <IconButton >
                            <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                          </IconButton>

                          <IconButton color="success" onClick={ () => handleActualizar(galpon.id) }>
                            <UpdateIcon fontSize="small" />
                          </IconButton>

                          <IconButton color="error" onClick={ () => eliminarGalpon( galpon ) } >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
            )}

          </Table>
        </TableContainer>

    </AdminLayout>




  )

}
