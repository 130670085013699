
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { usePlanillaStore } from '../../../hooks';




export const TablaItemsPlanilla = () => {


    const { activePlanilla } = usePlanillaStore();




    return (

        <>

            <Typography variant='h5' sx={{ mb:0, mt:5, fontWeight:'bold', textAlign:'center' }} >PESADAS</Typography>

            <TableContainer sx={{mb:3}} >
                <Table>
                    <TableHead className='amarillo'>
                        <TableRow>
                            <TableCell><Typography variant="body1">HUACALES</Typography></TableCell>   
                            <TableCell><Typography variant="body1">AVES X HUACAL</Typography></TableCell>
                            <TableCell><Typography variant="body1">DESTARE</Typography></TableCell>
                            <TableCell><Typography variant="body1">PESO AVES X HUACAL</Typography></TableCell>   
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { activePlanilla?.items.map( i => (
                        <TableRow key={ i._id } >
                            <TableCell><Typography variant="body2">{ i.huacales }</Typography></TableCell>   
                            <TableCell><Typography variant="body2">{ i.avesXhuacal }</Typography></TableCell>   
                            <TableCell><Typography variant="body2">{ i.destare }kg</Typography></TableCell>   
                            <TableCell><Typography variant="body2">{ i.pesoAvesHuacal }Kg</Typography></TableCell>   
                        </TableRow>
                        ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>


    )


}
