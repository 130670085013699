import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2";
import { granjaApi } from "../api";
import { onAddNewGalpon, onDeleteGalpon, onLoandingGalpon, onSetActiveGalpon, onUpdateGalpon } from "../store";



export const useGalponStore = () => {


    const dispatch = useDispatch();
    const { galpones, activeGalpon } = useSelector( state => state.galpon );


    const setActiveGalpon = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/galpon/galponxid/${ uid }`);
            dispatch( onSetActiveGalpon( data.galpon ) );
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error)
        }
    }


    const startSavingGalpon = async( galpon ) => {
        try {

            if( galpon.id ){
                const { data } = await granjaApi.put(`/galpon/${ galpon.id }`, galpon );
                if( data.ok ){
                    dispatch( onUpdateGalpon( data.galpon ) );
                    Swal.fire('Exitoso', 'Galpon Actualizado', 'success');
                }

            }else {
                const { data } = await granjaApi.post('/galpon/new', { ...galpon } );
                if( data.ok ){
                    dispatch( onAddNewGalpon( data.galpon ) );
                    Swal.fire('Exitoso', 'Galpon Creado', 'success');
                }
            }

        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    } 


    const startLoadingGalpones = async() => {
        try {
            const { data } = await granjaApi.get('/galpon');
            dispatch( onLoandingGalpon(data.galpones) );
            
        } catch (error) {
            console.log('Error al Cargar Galpones');
            console.log(error);
        }
    }   


    const loadingGalponesPorGranja = async(id) => {
        try {
            const { data } = await granjaApi.get(`/galpon/xgranja/${ id }`);
            dispatch( onLoandingGalpon(data.galpon) );
            
        } catch (error) {
            console.log('Error al Cargar Galpones');
            console.log(error);
        }
    } 



    const deleteGalpon = async( galpon ) => {
        try {

            const { data } = await granjaApi.delete(`/galpon/${ galpon.id }`);
            if( data.ok ){
                dispatch( onDeleteGalpon( galpon ) );
                Swal.fire('Exitoso', 'Galpon Eliminado', 'success');
            }
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    return {
        //Propiedades
        galpones,
        activeGalpon,

        //Metodos
        startSavingGalpon,
        startLoadingGalpones,
        deleteGalpon,
        setActiveGalpon,
        loadingGalponesPorGranja,
    }


}
