
import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";



export const yellowTheme = createTheme({

    palette: {
        primary: {
            main: '#ff9800'
        },
        secondary: {
            main: '#ed3237'
        },
        error: {
            main: red.A400
        }
    },

    components: {


        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontSize: 32,
                    fontWeight: "bold",                  
                },
                h2: {
                    fontSize: 25,
                    fontWeight: 'bold',
                },
                h5: {
                    fontSize: 21,
                    fontWeight: 'bold',
                },
                // h3: {
                //     fontSize: 20,
                //     fontWeight: 'bold',
                // },
                body1: {
                    fontSize:17,
                },
                body2: {
                    fontSize:18,
                },
                body3: {
                    fontSize:15,
                },
                
                // h4: {
                //     fontSize: 15,
                //     fontWeight: 'bold',
                // },
                
                
            }
        },

        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-of-type(odd)":{
                        backgroundColor: '#f5f5f5'
                    },
                    '&:last-child th': {
                        backgroundColor: '#d50000',
                        fontSize: '15px',
                        fontWeight: 'bold',
                        color: '#fff',
                        padding: '15px',
                    }
                }
            }
        },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#0000000',
                    fontSize: '12px',
                    padding: '7px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    backgroundColor: "transparent",
                }
            }
        },

        // MuiTableHead: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: '#d50000',
        //             color: '#fff',
        //         }
        //     }
        // },

    }

})