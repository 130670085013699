
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Grid, MenuItem, TextField, Typography, Box, Backdrop, Modal } from '@mui/material';

import { useDescuentoStore, useModalImgStore } from '../../../../hooks';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { adicionHoraMinuto } from '../../../../helpers';
import Swal from 'sweetalert2';





export const ModalAddDescuentosLote = () => {

    
    const { id } = useParams();
    const navigate = useNavigate();
    const { modalDescuentos, closeModalDescuentos } = useModalImgStore();
    const { startSavingDescuentos } = useDescuentoStore();
    const adicionarFecha = adicionHoraMinuto;


    const [formDescuento, setFormDescuento] = useState({
        cantidad: '',
        descuento: '',
        observacion: '',
        lotegalpon: '',
        start: '',
    });
    const { cantidad, descuento, start, observacion } = formDescuento;

    const handleChanges = ({ target }) => {
        setFormDescuento({
            ...formDescuento,
            [target.name]: target.value
        });
    }


    const handleChangeStart = (e) => {
        // const selectedDateString = moment(e.$d).format('YYYY-MM-DD');
        // // Obtener la hora y los minutos actuales
        // const now = moment();
        // const hour = now.format('HH');
        // const minute = now.format('mm');
        // // Concatenar la fecha seleccionada y la hora actual en una cadena
        // const datetimeString = `${selectedDateString} ${hour}:${minute}`;
        // // Analizar la cadena completa como una fecha
        // const datetime = moment(datetimeString);

        const resultado = adicionarFecha(e.$d);

        setFormDescuento({
            ...formDescuento,
            // start: datetime
            start: resultado
        });
    }


    useEffect(() => {
        if( id ){
            setFormDescuento({
                ...formDescuento,
                lotegalpon: id,
            })
        }
    }, [id]);


    useEffect(() => {
        if(descuento === 'VENTA'){
            navigate(`/admin/planilla/${ id }`);
            closeModalDescuentos();
        }
    }, [descuento]);




    const handleCrearDescuento = async(e) => {
        e.preventDefault();
        closeModalDescuentos();
        await Swal.fire({
            title: `Ingresar ${ descuento }`,
            text: `¿Deseas Hacer Este Ingreso?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
        }).then( ( result ) => {
            if(result.value){
                startSavingDescuentos( formDescuento );
                setFormDescuento({
                    ...formDescuento,
                    cantidad: '',
                    descuento: '',
                    start: '',
                    observacion: '',
                })
            }
        });
    }


    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ modalDescuentos }
            onClose={ closeModalDescuentos }
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Box className='modal-style2'>

                <Typography align='center' variant='h1'>Crear Descuento</Typography>

                <form  onSubmit={ handleCrearDescuento } >
                    <Grid container spacing={ 2 }>

                        <Grid item xs={ 12 } sm={12} mt={ 2 }>
                            <TextField required
                                select
                                label="Tipo de Descuento"
                                fullWidth
                                name='descuento'
                                value={ descuento }
                                onChange={ handleChanges }
                            >    
                                <MenuItem value=''>
                                    Seleccione Descuento
                                </MenuItem>
                                <MenuItem value='VENTA'>
                                    VENTA
                                </MenuItem>
                                <MenuItem value='MORTALIDAD'>
                                    MORTALIDAD
                                </MenuItem>
                                <MenuItem value='TRASLADO'>
                                    TRASLADO
                                </MenuItem>
                                <MenuItem value='DESCUENTO'>
                                    DESCUENTO
                                </MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField required
                                autoComplete='off'
                                label="Cantidad"
                                fullWidth
                                type="number"
                                placeholder='Cantidad'
                                name='cantidad'
                                value={ cantidad }
                                onChange={ handleChanges }
                            >
                            </TextField>
                        </Grid>

                        <Grid item xs={ 12 } sm={ 12 } mt={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Fecha de Mortalidad"
                                    value={ start }
                                    onChange={ handleChangeStart }
                                    renderInput={(params) => <TextField {...params} autoComplete='off' fullWidth /> }
                                    // disableFuture={true}
                                />
                            </LocalizationProvider>
                        </Grid>


                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField
                                label="Observacion"
                                fullWidth
                                multiline
                                type="text"
                                placeholder='Observacion'
                                name='observacion'
                                value={ observacion }
                                onChange={ handleChanges }
                            >
                            </TextField>
                        </Grid>


                        <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                            <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                <Button variant='contained' fullWidth type='submit'>
                                    Crear Descuento
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                </form>
            </Box>
        </Modal>

    );



}