import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2";
import { granjaApi } from "../api";
import { onAddNewConsumo, onDeleteConsumo, onLoandingConsumo } from "../store";



export const useConsumo = () => {


    const dispatch = useDispatch();
    const { consumos, activeConsumo } = useSelector( state => state.consumo );

    
    // const setActiveGranja = async( uid ) => {
    //     try {
    //         const { data } = await granjaApi.get(`/granja/${ uid }`);
    //         dispatch( onSetActiveConsumo( data.granja ) );
            
    //     } catch (error) {
    //         Swal.fire('Error', error.response.data.msg, 'error');
    //         console.log(error)
    //     }
    // }


    const startSavingConsumo = async( consumo ) => {
        try {
            const { data } = await granjaApi.post('/consumo/new', { ...consumo } );
            if( data.ok ){
                dispatch( onAddNewConsumo( data.consumo ) );
                Swal.fire('Exitoso', 'Consumo Creado', 'success');
            }

        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    } 


    
    const startLoadingConsumoLoteGalpon = async(uid) => {
        try {
            const { data } = await granjaApi.get(`/consumo/xlg/${ uid }`);
            dispatch( onLoandingConsumo(data.consumo) );
        } catch (error) {
            console.log(error);
        }
    }



    const deleteConsumoLote = async( uid ) => {
        try {
            const { data } = await granjaApi.delete(`/consumo/${ uid }`);
            if( data.ok ){
                console.log(data.consumo);
                dispatch( onDeleteConsumo( data.consumo ) );
                Swal.fire('Exitoso', 'Consumo Eliminado', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    return {
        //Propiedades
        consumos,
        activeConsumo,

        //Metodos
        startSavingConsumo,
        startLoadingConsumoLoteGalpon,
        deleteConsumoLote,
    }


}
