import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Grid, IconButton, Typography } from '@mui/material';
// import { TabPanel, TabContext, TabList } from '@mui/lab';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useDescuentoStore, useDistribucionStore } from "../../../hooks";


import { TablaDeDatos } from "./TablaDeDatos";
import { FiltroPorFecha } from "./FiltroPorFecha";





export const VentaReporte = () => {


    const { id } = useParams();
    const { venta, startLoadingVenta } = useDescuentoStore(); 
    const { activeDistribucion, setActiveDistribucion } = useDistribucionStore();
    const [calendario, setCalendario] = useState( false );



    useEffect(() => {
        if( id ){
            startLoadingVenta(id);
            setActiveDistribucion(id);
        }
    }, [id])



    const filtroFecha = () => {
        setCalendario( (c) => !calendario );
    }


    return (

        <>

            <Grid container direccion='row' alignItems='center' mb={3} >
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'primary.main',
                        ':hover': { backgroundColor: 'primary.main', opacity: 0.3 },
                    }}
                    onClick={ filtroFecha }
                >
                    <CalendarMonthIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h1'>Informacion de Ventas</Typography>

                <Box flex={ 1 } />

            </Grid>


            {
            calendario &&
            <FiltroPorFecha nombre={'VENTA'} />
            }

            <TablaDeDatos 
                activeDistribucion={ activeDistribucion } 
                datos={ venta } 
                titulo={'Venta'}
            />

        </>



        
    )




}
