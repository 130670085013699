

import { createSlice } from '@reduxjs/toolkit';



export const notificacionSlice = createSlice({
    name: 'notificacion',
    initialState: {
        isLoadingNotificacion: true,
        notificaciones: [],
        entradas: [],
        salidas: [],
        activeNotificacion: null
    },
    reducers:{

        onSetActiveNotificacion: ( state, { payload } ) => {
            state.activeNotificacion = payload;
        },

        
        onAddNewNotificacion: ( state, { payload } ) => {
            state.notificaciones.push( payload );
            state.activeNotificacion = null;
        },


        onLoandingNotificacion: ( state, { payload = [] } ) => {
            state.isLoadingNotificacion = false;
            // state.NOtificacions = payload;
            payload.forEach( notificacion => {
                const exists = state.notificaciones.some( dbNotificacion => dbNotificacion.id === notificacion.id );
                if( !exists ){
                    state.notificaciones.push( notificacion );
                }
            })
        },


        onLoandingNotificacionEntradas: ( state, { payload = [] } ) => {
            state.isLoadingNotificacion = false;
            state.entradas = payload;
        },


        onLoandingNotificacionSalidas: ( state, { payload = [] } ) => {
            state.isLoadingNotificacion = false;
            state.salidas = payload
        },


        onDeleteNotificacion: ( state, { payload } ) => {
            // if( state.activeNOtificacion ){
                state.notificaciones = state.notificaciones.filter( notificacion => notificacion.id !== payload.id );
            //     state.activeNOtificacion = null;
            // }
        }
    }
});





export const { onSetActiveNotificacion, onAddNewNotificacion, onLoandingNotificacion, onDeleteNotificacion, onLoandingNotificacionEntradas, onLoandingNotificacionSalidas } = notificacionSlice.actions;