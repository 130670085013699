import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import Swal from "sweetalert2";
import { activeNotaDelete, onAddNewNota, onDeleteNota, onLoandingNota, onSetActiveNota } from "../store";




export const useNotaStore = () => {


    const dispatch = useDispatch();
    const { notas, activeNota, isLoadingNota } = useSelector( state => state.nota );



    const activeNotaStart = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/nota/${ uid }`);
            dispatch( onSetActiveNota( data.nota ) );
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error)
        }
    }


    const activeNotaEliminar = async() => {
        dispatch( activeNotaDelete() );
    }


    const startSavingNota = async( nota ) => {
        try {
            if( nota.id ){
                // const { data } = await granjaApi.put(`/Nota/${ Nota.id }`, Nota );
                // if( data.ok ){
                //     dispatch( onUpdateNota( data.Nota ) );
                //     Swal.fire('Exitoso', 'Producto de OP Actualizado', 'success');
                // }
                console.log(nota)
            }else {
                const { data } = await granjaApi.post('/nota/new', nota );
                if( data.ok ){
                    dispatch( onAddNewNota( data.nota ) );
                    Swal.fire('Exitoso', 'Nota Creada', 'success');
                }
            }

        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    } 



    const startLoadingNotasOP = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/nota/porop/${ uid }`);
            dispatch( onLoandingNota(data.notas) );
            
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }

    
    const startLoadingNotas = async() => {
        try {
            const { data } = await granjaApi.get('/nota');
            dispatch( onLoandingNota(data.nota) );
            
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }


    const deleteNota = async( uid ) => {
        try {
            const { data } = await granjaApi.delete(`/nota/${ uid }`);
            if( data.ok ){
                dispatch( onDeleteNota( data.nota ) );
                Swal.fire('Exitoso', 'Nota Eliminada', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }




    return {
        //Propiedades
        notas, 
        activeNota,

        //Metodos
        startSavingNota,
        deleteNota,
        activeNotaStart,
        startLoadingNotasOP,
        startLoadingNotas,
        activeNotaEliminar,
    }


}
