
import { useState, useEffect } from 'react';

import { Button, Grid, TextField, Typography, Box, Backdrop, Modal } from '@mui/material';
import { useModalStore, useOpStore, useNotaStore } from '../../hooks';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



export const ModalNota = () => {


    const { modalNota, closeModalNota } = useModalStore();
    const { activeOp } = useOpStore();
    const { startSavingNota } = useNotaStore();


    const [formPedido, setFormPedido] = useState({
        cantidad: '',
        op: '',
        nombre: ''
    });
    const { cantidad, nombre } = formPedido;

    const handleInputChange = ({ target }) => {
        setFormPedido({
            ...formPedido,
            [target.name]: target.value
        })
    }


    useEffect(() => {
        if( activeOp ){
            setFormPedido({
                ...formPedido,
                op: activeOp.id
            });
        }
    }, [activeOp]);


    const handleSumarPedido = async(e) => {
        e.preventDefault();
        const [ nota, cerrar ] = await Promise.all([
            startSavingNota( formPedido ),
            closeModalNota()
        ]);
        setFormPedido({
            ...formPedido,
            id: null,
            cantidad: '',
            nombre: '',
        });

    }

    return (

        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={ modalNota }
                onClose={ closeModalNota }
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Box sx={ style }>

                    <Typography align='center' variant='h4' sx={{ mb:2, fontWeight:'bold'  }} >Nota</Typography>

                    <form  onSubmit={ handleSumarPedido } >
                        <Grid container spacing={ 2 }>
                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                                <TextField required
                                    label="Nombre Producto"
                                    fullWidth
                                    name='nombre'
                                    value={ nombre }
                                    onChange={ handleInputChange }
                                >
                                </TextField>
                            </Grid>
                            
                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    label='Cantidad'
                                    type='number'
                                    placeholder='Cantidad'
                                    fullWidth
                                    name='cantidad'
                                    value={ cantidad }
                                    onChange={ handleInputChange }
                                />
                            </Grid>

                            <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                                <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                    <Button variant='contained' fullWidth type='submit'>
                                        Añadir Producto
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    );

}