
import { useState, useEffect } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useTotalDestares } from '../../../hooks/hooks/useTotalDestares';
import { NumberFormatCustom } from '../../../hooks/hooks/useInputMillares';
import { separadorMillares } from '../../../hooks/hooks/useMillares';
import { dejarTresDecimales } from '../../../helpers/dejarDecimales';




export const TablaDestaresTotales = ({ onChange, destares }) => {


    const millares = NumberFormatCustom;
    const  { unidades, pesoBruto, totalDestare, pesoNeto, promedioEnPie } = useTotalDestares(destares);


    const [totales, setTotales] = useState({
        valorKilo: '',
        saldoAnterior: '',
    });
    const { valorKilo, saldoAnterior } = totales;

    const [resultados, setResultados] = useState({
        unidades,
        pesoBruto,
        totalDestare,
        pesoNeto,
        promedioEnPie,
        valorFactura: 0,
        total: 0,
    }); 
    const { valorFactura, total } = resultados;



    const handleChanges = ({ target }) => {
        setTotales({
            ...totales,
            [target.name]: target.value
        })
    }


    useEffect(() => {
        const unidades = destares.reduce( (total, can) => Number(total) + (Number(can.huacales) * Number(can.avesXhuacal)), 0);
        const pesoBruto = destares.reduce( (total, can) => Number(total) + Number(can.pesoAvesHuacal), 0);
        const totalDestare = destares.reduce( (total, can) => Number(total) + Number(can.destare), 0);
        setResultados({
            ...resultados,
            unidades,
            pesoBruto,
            totalDestare,
            pesoNeto: pesoBruto - totalDestare,
            promedioEnPie: (pesoBruto - totalDestare) / unidades,
        })
    }, [destares])       

    

    useEffect(() => {
        setResultados({
            ...resultados,
            valorFactura: Number(valorKilo) * Number(resultados.pesoNeto),
        })
    }, [valorKilo]) 


    useEffect(() => {
        setResultados({
            ...resultados,
            total: Number(valorFactura) + Number(saldoAnterior),
        })
    }, [saldoAnterior, valorFactura])


    useEffect(() => {
        localStorage.setItem('totales', JSON.stringify(totales) );
    }, [totales]); 



    useEffect(() => { 
        onChange({ 
            ...totales
        }); 
    }, [totales, onChange]);



    return (

        <form >
            <TableContainer sx={{ my:3 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>UNIDADES</TableCell>
                            <TableCell>PESO BRUTO</TableCell>
                            <TableCell>TOTAL DESTARE</TableCell>
                            <TableCell>PESO NETO</TableCell>
                            <TableCell>PROMEDIO EN PIE</TableCell>
                            <TableCell>VALOR X KILO</TableCell>
                            <TableCell>VALOR FACTURA</TableCell>
                            <TableCell>SALDO ANTERIOR</TableCell>
                            <TableCell>TOTAL</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow >
                            <TableCell>{ resultados.unidades }</TableCell>
                            <TableCell>{ dejarTresDecimales(resultados.pesoBruto) } Kg</TableCell>
                            <TableCell>{ dejarTresDecimales(resultados.totalDestare) } Kg</TableCell>
                            <TableCell>{ dejarTresDecimales(resultados.pesoNeto) } Kg</TableCell>
                            <TableCell>{ dejarTresDecimales(resultados.promedioEnPie) }</TableCell>
                            <TableCell >
                                <TextField
                                    size="small"
                                    label="Valor Kilo"
                                    name='valorKilo'
                                    value={ valorKilo }
                                    onChange={ handleChanges }
                                    InputProps={{
                                        inputComponent: millares,
                                    }}
                                />
                            </TableCell>
                            <TableCell>{ (valorFactura !== undefined) ? `$${separadorMillares(valorFactura)}` : 0 }</TableCell>
                            <TableCell >
                                <TextField required
                                    size="small"
                                    label="Saldo Anterior"
                                    name='saldoAnterior'
                                    value={ saldoAnterior }
                                    onChange={ handleChanges }
                                    InputProps={{
                                        inputComponent: millares,
                                    }}
                                />
                            </TableCell>
                            <TableCell>{ `$${separadorMillares(total)}` }</TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>
        </form>

    )



}
