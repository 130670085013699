

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";

import io from 'socket.io-client';
import { useFacturaStore, useIngresoRaizStore, useModalStore, usePedidoStore } from "../../../../hooks";
import { separadorMillares } from "../../../../hooks/hooks";
import { LayoutReturn } from "../../../layout";
import { ModalFactura } from "../../../components";

import Swal from "sweetalert2";



const baseSocket = process.env.REACT_APP_SOCKET_IO_URL;
let socket;




export const AprobarPedidosScreen = () => {


    useEffect(() => {
        socket = io(baseSocket);
    }, []);


    const { id } = useParams();
    const { pedidos, startLoadingPedidosOP, cambioCantidad, pedidoUpdateDeSocket } = usePedidoStore();
    const { deletePedidoMultiple } = useIngresoRaizStore();
    const { startSavingFactura } = useFacturaStore();
    const { aprobarProductosBodegaRaiz } = useIngresoRaizStore();
    const { openModalFactura } = useModalStore();


    const [articulos, setArticulos] = useState([]);


    useEffect(() => {
        if( pedidos ){
            setArticulos( pedidos );
        }
    }, [pedidos]);



    const cambiarCantidad = (e, id) => {
        const newCantidad = e.target.value;
        const pedidoActualizado = articulos.map( ( p ) => {
            if( p.id === id ){
                p = {
                    ...p, cantidad: newCantidad
                }
            }
            return p;
        });
        setArticulos( pedidoActualizado );
    }


    useEffect(() => {
        socket.on('update-cantidad', ( pedido ) => {
            pedidoUpdateDeSocket(pedido);
        });
    }, []);



    const onPerdioFoco = async( id, cantidad) => {
        await cambioCantidad({ id, cantidad });
    }
    


    useEffect(() => {
    if( id ){
        startLoadingPedidosOP(id);
    }
    }, [id]);



    const [selected, setSelected] = useState([]);


    const handleSelectAllClick = ( event ) => {
      
      if (event.target.checked) {
        const newSelected = pedidos.map( (p) => p.id );
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };
    

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }
        setSelected(newSelected);
    };



    const  eliminarMultiple = () => {
        Swal.fire({
            title: ( selected.length > 1 ) ? 'Eliminar Productos' : 'Eliminar Producto',
            text: ( selected.length > 1 ) ? `¿Esta Seguro De Eliminar Estos Productos?` : `¿Esta Seguro De Eliminar Este Producto?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Eliminar' 
        }).then( ( result ) => {
            if(result.value){
                deletePedidoMultiple( selected );
            }
        });
    }




    const recibirDatos = async(formPedido) => {
        const resultado = await Swal.fire({
            title: ( selected.length > 1 ) ? 'Aprobar Productos' : 'Aprobar Producto',
            text: ( selected.length > 1 ) ? `¿Esta Seguro De Aprobar Estos Productos?` : `¿Esta Seguro De Aprobar Este Producto?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Aprobar' 
        });
        
        if (resultado.isConfirmed) {
            await startSavingFactura({
                productos: selected,
                factura: formPedido
            });


            await aprobarProductosBodegaRaiz({
                productos: selected,
                factura: formPedido
            });

            await deletePedidoMultiple(selected);

            Swal.fire('Exitoso', 'Factura Creada Y Productos Agregados A Bodega-Pricipal', 'success');
            setSelected([]);
        
        } else {
            return;
        }
    }


    const multipleAprobacion = () => {
        openModalFactura();
    }


    const isSelected = ( name ) => selected.indexOf(name) !== -1;



    return (

        <>
            <LayoutReturn title='Aprobar Para Ingreso A Bodega' url='/admin/ops' >


            <TableContainer>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
            >

                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={ selected.length > 0 && selected.length < pedidos.length }
                            checked={ pedidos.length > 0 &&  selected.length === pedidos.length }
                            onChange={ handleSelectAllClick }
                            inputProps={{
                            'aria-label': 'select all desserts',
                            }}
                        />
                        </TableCell>
                        <TableCell>PRODUCTO</TableCell>
                        <TableCell>CANTIDAD</TableCell>
                        <TableCell>PRECIO SIN IVA</TableCell>
                        <TableCell>PRECIO CON IVA</TableCell>
                        <TableCell>TOTAL</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                
                    { articulos.map( ( ped ) => {

                    const isItemSelected = isSelected( ped.id );
                    const labelId = `enhanced-table-checkbox-${ ped.id}`;

                    return (
                    <TableRow
                        hover
                        onClick={ (event) => handleClick(event, ped.id)}
                        role="checkbox"
                        aria-checked={ isItemSelected }
                        tabIndex={-1}
                        key={ped.id}
                        selected={ isItemSelected }
                    >
                        <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                            'aria-labelledby': labelId,
                            }}
                        />
                        </TableCell>
                        <TableCell>{ ped.producto?.nombre }</TableCell>
                        <TableCell className="w-100">
                            <TextField 
                                size="small"
                                fullWidth
                                type="number"
                                value={ ped.cantidad }
                                onChange={ (e) => cambiarCantidad( e, ped.id ) }
                                onBlur={ (e) => onPerdioFoco(ped.id, ped.cantidad) }
                            />
                        </TableCell>
                        <TableCell>{ `$${ separadorMillares(ped.producto?.precioSin) }` }</TableCell>
                        <TableCell>{ `$${ separadorMillares(ped.producto?.precioCon) }` }</TableCell>
                        <TableCell>{ `$${ separadorMillares((ped.producto?.precioCon * ped.cantidad)) }` }</TableCell>
                    </TableRow>
                    );
                    })}
            
                </TableBody>
            </Table>
            </TableContainer>
                
            <Stack spacing={2} my={ 5 } direction="row">
                <Button variant='contained' onClick={ multipleAprobacion } disabled ={ selected.length <= 0 } >
                    {/* {( selected.length < 2 ) ? 'Agregar Producto' : 'Agregar Productos' } */}
                    Añadir A Factura
                </Button>
                <Button variant='contained' disabled ={ selected.length <= 0 }
                    sx={{ backgroundColor:'error.main', ':hover': { backgroundColor: 'error.main', opacity: 0.7 } }}
                    onClick={ eliminarMultiple }
                >
                    {( selected.length < 2 ) ? 'Borrar Producto' : 'Borrar Productos' }
                </Button>
            </Stack>

        </LayoutReturn>

        <ModalFactura pasarDatos={recibirDatos} />

        </>

    );

}



