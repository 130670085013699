import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import { onAddNewLote, onDeleteLote, onLoandingLote, onSetActiveLote, onUpdateLote } from "../store";
import Swal from 'sweetalert2';
import { prepararFechaPedido, prepararFechaPedidoUno, prepararFechaUno } from "../helpers/prepararFecha";



export const useLoteStore = () => {


    const dispatch = useDispatch();
    const { lotes, activeLote } = useSelector( state => state.lote );


    const setActiveLote = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/lote/lotexid/${ uid }`);
            if( data.ok ){
                const lote = prepararFechaPedidoUno(data.lote)
                dispatch( onSetActiveLote( lote ) );
            }else {
                Swal.fire('Error', 'Algo Salio Mal', 'error');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    const startSavingLote = async( lote ) => {
        try {
            if( lote.id ){
                const { data } = await granjaApi.put(`/lote/${ lote.id }`, { ...lote } );
                if( data.ok ){
                    const loteId = prepararFechaUno( data.lote );
                    dispatch( onUpdateLote( loteId ) );
                    Swal.fire('Exitoso', 'Lote Actualizado', 'success');
                }
            }else {
                const { data } = await granjaApi.post('/lote/new', { ...lote } );
                if( data.ok ){
                    Swal.fire('Proceso Exitoso', 'Lote Creado', 'success');
                    dispatch( onAddNewLote( data.lote ) );
                } else{
                    Swal.fire('Error', 'Algo Salio Mal', 'error');
                }
            }

        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    } 


    const cambioEstadoLote = async(uid) => {
        try {
            const { data } = await granjaApi.put(`/lote/estado/${ uid }`);
            const loteId = prepararFechaUno( data.lote );
            dispatch( onUpdateLote( loteId ) );
            dispatch( onDeleteLote( loteId ) );
            
        } catch (error) {
            console.log(error);
        }
    }


    const startLoadingLote = async() => {
        try {
            const { data } = await granjaApi.get('/lote');
            const lotes = prepararFechaPedido( data.lotes );
            dispatch( onLoandingLote( lotes ) );
            
        } catch (error) {
            console.log('Error al Cargar Lotes');
            console.log(error);
        }
    }

    const startLoadingLoteArchivado = async() => {
        try {
            const { data } = await granjaApi.get('/lote/archivados');
            const lotes = prepararFechaPedido( data.lotes );
            dispatch( onLoandingLote( lotes ) );
            
        } catch (error) {
            console.log('Error al Cargar Lotes');
            console.log(error);
        }
    }


    const deleteLote = async( lote ) => {
        try {
            const { data } = await granjaApi.delete(`/lote/${ lote.id }`);
            if( data.ok ){
                dispatch( onDeleteLote( lote ) );
                Swal.fire('Exitoso', 'Lote Eliminado', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }



    return {
        //Propiedades
        lotes,
        activeLote,

        //Metodos
        startSavingLote,
        startLoadingLote,
        startLoadingLoteArchivado,
        deleteLote,
        setActiveLote,
        cambioEstadoLote,
    }


}
