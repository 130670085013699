
import { useEffect } from "react";

import { Button, Grid, Typography } from "@mui/material"

import { useAuthStore, useVehiculoStore } from "../../../../hooks";
import { descargaImagenes } from "../../../../helpers";




export const DescargaArchivosConductor = () => {


    const { user } = useAuthStore();
    const { vehiculos, setVehiculoPorConductor } = useVehiculoStore();
    const descargar = descargaImagenes;

console.log(vehiculos);

    useEffect(() => {
        if(user.id){
            setVehiculoPorConductor(user.id);
        }
    }, [user])
    




    return (


        <>
        {
        vehiculos &&
        <>
        {
        vehiculos.map( (v) => (
        <Grid container sx={{ mb:3, mt:2 }} justifyContent='center' key={v.id}>

            <Typography variant='h1' sx={{ mt:3 }}>Vehiculo { v?.placa }</Typography>

            <Grid container sx={{ mb:3, mt:2 }} spacing={2} >

                <Grid item xs={12} sm={6} md={4} >
                    <Button variant='contained' fullWidth onClick={ () => descargar(v.imgSoat, 'soat') } disabled={!v.imgSoat}>
                        Descargar Soat
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={4} >
                    <Button variant='contained' fullWidth onClick={ () => descargar(v.imgTecno, 'tecno') } disabled={!v.imgTecno}>
                        Descargar Tecno
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={4} >
                    <Button variant='contained' fullWidth onClick={ () => descargar(v.imgPropiedad, 'propiedad') } disabled={!v.imgPropiedad}>
                        Descargar Tj. Propiedad
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={4} >
                    <Button variant='contained' fullWidth onClick={ () => descargar(v.imgTodoRiesgo, 'riesgo') } disabled={!v.imgTodoRiesgo}>
                        Descargar Todo Riesgo
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={4} >
                    <Button variant='outlined' fullWidth >
                        Descargar Planilla
                    </Button>
                </Grid>


                <Grid item xs={12} sm={6} md={4} >
                    <Button variant='outlined' fullWidth>
                        Descargar Seg. Social
                    </Button>
                </Grid>

            </Grid>

        </Grid>
        ))
        }

        </>
        }
        </>


    )



    
}
