
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { useGranjaStore, useBodegaStore } from '../../../hooks';
import Swal from 'sweetalert2';
import { LayoutReturn } from '../../layout';


const initialState = {
    nombre: '',
    granja: '',
    alto: '',
    ancho: '',
    largo: '',
};



export const ActualizarBodega = () => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { activeBodega, startSavingBodega, setActiveBodega } = useBodegaStore();
    const { startLoadingGranjas, granjas } = useGranjaStore();



    const [formBodega, setFormBodega] = useState( initialState );
    const {nombre, granja, alto, ancho, largo } = formBodega;

    const  handleInputChange = ({ target }) => {
        setFormBodega({
            ...formBodega,
            [target.name]: target.value
        });
    }



    useEffect(() => {
        startLoadingGranjas();
    }, []);


    useEffect(() => {
      if( id ){
        setActiveBodega( id );
      }
    }, [id]);

    

    useEffect(() => {
      if( activeBodega ){
        setFormBodega( activeBodega );
      }
    }, [activeBodega]);


    const actualizarBodega = async( e ) => {
        e.preventDefault();
        await Swal.fire({
          title: '¿Actualizar Bodega?',
          text: `Esta Seguro De Actualizar: ${ nombre }`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Actualizar' 
      }).then( ( result ) => {
          if(result.value){
            startSavingBodega( formBodega );
            navigate('/admin/list-bodegas');
          }
      });
    }


    return (

        <LayoutReturn title='Actualizar Bodega' url='/admin/list-bodegas'>

            <form onSubmit={ actualizarBodega } className='form-100'>
                <Grid container spacing={ 2 }>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Nombre Bodega'
                            type='text'
                            placeholder='Nombre Bodega'
                            fullWidth
                            name='nombre'
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                        id="outlined-select-currency"
                        select
                        label="Granja"
                        fullWidth
                        name='granja'
                        value={ granja }
                        onChange={ handleInputChange }
                        >
                            <MenuItem value=''>Seleccione Granja</MenuItem>
                            {
                            granjas.map( g => (
                            <MenuItem key={ g.id } value={ g.id } >
                                { g.nombre }
                            </MenuItem>
                            ))
                            }
                        </TextField>
                  </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Alto'
                            type='number'
                            placeholder='Alto'
                            fullWidth
                            name='alto'
                            value={ alto }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Ancho'
                            type='number'
                            placeholder='Ancho'
                            fullWidth
                            name='ancho'
                            value={ ancho }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Largo'
                            type='number'
                            placeholder='Largo'
                            fullWidth
                            name='largo'
                            value={ largo }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                </Grid>

                <Grid container 
                    sx={{ mb:2, mt:2 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit'>
                            Actualizar Bodega
                        </Button>
                    </Grid>
                </Grid>

            </form>
            
        </LayoutReturn>


    )



}
