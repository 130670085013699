import { Box} from "@mui/material"

import { FooterScreen, NavBarScreen, SeccionScreen, SlideShowScreen } from "../components"




export const HomeScreen = () => {

  return (

    <Box >


      <NavBarScreen />
      {/* <Toolbar/> */}

      <SlideShowScreen />

      
      <SeccionScreen />

      <FooterScreen />

    </Box>

  )


  
}
