import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";
import { clearErrorMessage, onChecking, onLogin, onLogout, startMenu } from "../store";







export const useAuthStore = () => {

    const { status, user, errorMessage } = useSelector( state => state.auth );
    const dispatch = useDispatch();



    const startLogin = async( login ) => {
        dispatch( onChecking() );
        try {
            const { data } = await granjaApi.post('/usuario', login );
            localStorage.setItem('token', data.token);
            localStorage.setItem('token-init-date', new Date().getTime() );
            dispatch( onLogin( data.usuario ) );
            // dispatch( startMenu( data.menu ) );
        } catch (error) {
            dispatch( onLogout( error.response.data?.msg || '' ) );
            setTimeout(() => {
                dispatch( clearErrorMessage() );
            }, 200);
        }
    }




    const checkAuthToken = async( ) => {

        const token = localStorage.getItem('token');
        if( !token ){ return dispatch( onLogout() ) }

        try {
            const { data } = await granjaApi.get('/usuario/renew');
            localStorage.setItem('token', data.token);
            localStorage.setItem('token-init-date', new Date().getTime() );
            dispatch( onLogin( data.usuario ) );           
            // dispatch( startMenu( data.menu ) );
        } catch (error) {
            localStorage.clear();
            dispatch( onLogout( error.response.data?.msg || '' ) );
            setTimeout(() => {
                dispatch( clearErrorMessage() );
            }, 200);
        }
    }




    const startLogout = () => {
        localStorage.clear();
        dispatch( onLogout() );
    }






    return {
        // Propiedades
        status, 
        user,
        errorMessage,

        // Metodos
        startLogin,
        checkAuthToken,
        startLogout,
    }


}