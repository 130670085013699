
import { useNavigate } from "react-router-dom";

import { Box, Button, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { AdminLayout } from "../../layout/AdminLayout";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from "react";
import { useGalponStore, useGranjaStore } from "../../../hooks";


const initialState = {
    nombre: '',
    cantidad: '',
    genero: '',
    granja: '',
    alto: '',
    largo: '',
    ancho: '',
    // descuento: '',
}




export const GalponesScreen = () => {


    const navigate = useNavigate();
    const { startSavingGalpon } = useGalponStore();
    const { granjas, startLoadingGranjas } = useGranjaStore();


    useEffect(() => {
        startLoadingGranjas();
      }, [] );


    const [formGalpon, setFormGalpon] = useState(initialState);
    const { nombre, cantidad, genero, granja, alto, largo, ancho, } = formGalpon;
    const handleChangeInput = ({ target }) => {
        setFormGalpon({
            ...formGalpon,
            [target.name]: target.value
        });
    }


    const regresar = () => {
        navigate('/admin/list-galpones');
    }


    const crearGalpon = async(e) => {
        e.preventDefault();
        await startSavingGalpon( formGalpon );
        setFormGalpon( initialState );
    }


    return (


      <AdminLayout titulo='Galpones'>

          <Grid container direccion='row' alignItems='center'>
              <IconButton
                  sx={{
                      color: '#fff',
                      backgroundColor: 'error.main',
                      ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                  }}
                  onClick={ regresar }
              >
                  <ArrowBackIcon />
              </IconButton>

              <Box flex={ 1 } />

              <Typography variant='h4' sx={{ mb:2, fontWeight:'bold' }} >Crear Galpon</Typography>

              <Box flex={ 1 } />
          </Grid>

          <form onSubmit={ crearGalpon } >
              <Grid container spacing={ 2 }>

                  <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                      <TextField required
                          label='Nombre Galpon'
                          type='text'
                          placeholder='Nombre Galpon'
                          fullWidth
                          name='nombre'
                          value={ nombre }
                          onChange={ handleChangeInput }
                      />
                  </Grid>

                  <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                      <TextField required
                          label='Cantidad'
                          type='number'
                          placeholder='Cantidad'
                          fullWidth
                          name='cantidad'
                          value={ cantidad }
                          onChange={ handleChangeInput }
                      />
                  </Grid>

                  <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                      <TextField required
                          id="outlined-select-currency"
                          select
                          label="Seleccione Granja"
                          fullWidth
                          name='granja'
                          value={ granja }
                          onChange={ handleChangeInput }
                      >
                          <MenuItem value=''>
                            Seleccione Granja
                          </MenuItem>
                          {
                            granjas.map( g => (
                            <MenuItem key={ g.id } value={ g.id } >
                                { g.nombre }
                            </MenuItem>
                            ))
                          }
                      </TextField>
                  </Grid>

                  <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                    <TextField required
                      id="outlined-select-currency"
                      select
                      label="Seleccione Genero"
                      fullWidth
                      name='genero'
                      value={ genero }
                      onChange={ handleChangeInput }
                    >
                        <MenuItem value=''>Seleccione Genero</MenuItem>
                        <MenuItem value='Hembra'>Hembra</MenuItem>
                        <MenuItem value='Macho'>Macho</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                      <TextField required
                          label='Alto'
                          type='number'
                          placeholder='Alto'
                          fullWidth
                          name='alto'
                          value={ alto }
                          onChange={ handleChangeInput }
                      />
                  </Grid>

                  <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                      <TextField required
                          label='Largo'
                          type='number'
                          placeholder='Largo'
                          fullWidth
                          name='largo'
                          value={ largo }
                          onChange={ handleChangeInput }
                      />
                  </Grid>

                  <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                      <TextField required
                          label='Ancho'
                          type='number'
                          placeholder='Ancho'
                          fullWidth
                          name='ancho'
                          value={ ancho }
                          onChange={ handleChangeInput }
                      />
                  </Grid>

                  {/* <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                      <TextField required
                          label='Descuento'
                          type='number'
                          placeholder='Descuento'
                          fullWidth
                          name='descuento'
                          value={ descuento }
                          onChange={ handleChangeInput }
                      />
                  </Grid> */}

              </Grid>

              <Grid container 
                  sx={{ mb:2, mt:2 }} 
                  direccion='column'
                  justifyContent='center' 
              >
                  <Grid item xs={ 12 } sm={ 8 } >
                      <Button variant='contained' fullWidth type="submit" >
                          Crear Galpon
                      </Button>
                  </Grid>
              </Grid>

          </form>

      </AdminLayout>


    )



}
