
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { AdminLayout } from '../../layout/AdminLayout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useProveedorStore } from '../../../hooks';
import Swal from 'sweetalert2';



 const initialState = {
    nombre: '',
    email: '',
    nit: '',
    formaPago: '',
    ciudad: '',
    movil: '',
    direccion: '',
  };


export const ActualizarProveedor = () => {

    

    const navigate = useNavigate();
    const  { id } = useParams();
    const { activeProveedor, startSavingProveedor, setActiveProveedor } = useProveedorStore();


    const [formProveedor, setFormProveedor] = useState( initialState );
    const { nombre, email, nit, formaPago, ciudad, movil, direccion } = formProveedor;

    const  handleInputChange = ({ target }) => {
      setFormProveedor({
            ...formProveedor,
            [target.name]: target.value
        });
    }

    const regresar = () => {
        navigate('/admin/list-proveedor');
    }

    useEffect(() => {
        if( id ){
            setActiveProveedor(id);
        }
    }, [id]);



    useEffect(() => {
        if( activeProveedor ){
            setFormProveedor(activeProveedor);
        }
    }, [activeProveedor]);



    const actualizarProveedor = async(e) => {
        e.preventDefault();
        await Swal.fire({
            title: '¿Actualizar Proveedor?',
            text: `Esta Seguro De Actualizar a: ${ nombre }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                startSavingProveedor( formProveedor );
                navigate('/admin/list-proveedor');
            }
        });
    }




    return (

        <AdminLayout >

            <Grid container direccion='row' alignItems='center'>
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ regresar }
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h4' sx={{ mb:2, fontWeight:'bold' }} >Actualizar Proveedor</Typography>

                <Box flex={ 1 } />
            </Grid>
        
            <form onSubmit={ actualizarProveedor } >
                <Grid container spacing={ 2 }>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Nombre'
                            type='text'
                            placeholder='Nombre'
                            fullWidth
                            name='nombre'
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Correo'
                            type='text'
                            placeholder='Email'
                            fullWidth
                            name='email'
                            value={ email }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Nit'
                            type='text'
                            placeholder='Nit'
                            fullWidth
                            name='nit'
                            value={ nit }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                    <TextField required
                        select
                        label="Forma de Pago"
                        fullWidth
                        name='formaPago'
                        value={ formaPago }
                        onChange={ handleInputChange }
                    >
                        <MenuItem value=''>Seleccione Forma de Pago</MenuItem>
                        <MenuItem value='CREDITO'>CREDITO</MenuItem>
                        <MenuItem value='CONTADO'>CONTADO</MenuItem>
                    </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Ciudad'
                            type='text'
                            placeholder='Ciudad'
                            fullWidth
                            name='ciudad'
                            value={ ciudad }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Telefono'
                            type='text'
                            placeholder='Telefono'
                            fullWidth
                            name='movil'
                            value={ movil }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Direccion'
                            type='text'
                            placeholder='Direccion'
                            fullWidth
                            name='direccion'
                            value={ direccion }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                </Grid>

                <Grid container 
                    sx={{ mb:2, mt:2 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit' disabled={ false } >
                            Actualizar Proveedor
                        </Button>
                    </Grid>
                </Grid>

            </form>
            
        </AdminLayout>


    )



}
