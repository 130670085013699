import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";
import { onAddNewBodega, onDeleteBodega, onLoandingBodega, onSetActiveBodega, onUpdateBodega } from "../store";

import Swal from "sweetalert2";



export const useBodegaStore = () => {


    const dispatch = useDispatch();
    const { bodegas, activeBodega } = useSelector( state => state.bodega );



    const setActiveBodega = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/bodega/bodegaxid/${ uid }`);
            dispatch( onSetActiveBodega( data.bodega ) );
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error)
        }
    }


    const startSavingBodega = async( bodega ) => {
        try {

            if( bodega.id ){
                const { data } = await granjaApi.put(`/bodega/${ bodega.id }`, bodega );
                if( data.ok ){
                    dispatch( onUpdateBodega( data.bodega ) );
                    Swal.fire('Exitoso', 'Bodega Actualizada', 'success');
                }
            }else {
                const { data } = await granjaApi.post('/bodega/new', { ...bodega } );
                if( data.ok ){
                    dispatch( onAddNewBodega( data.bodega ) );
                    Swal.fire('Exitoso', 'Bodega Creada', 'success');
                }
            }

        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    } 


    
    const startLoadingBodegas = async() => {
        try {
            const { data } = await granjaApi.get('/bodega');
            dispatch( onLoandingBodega( data.bodegas ) );
            
        } catch (error) {
            console.log('Error al Cargar bodegas');
            console.log(error);
        }
    }


    const startLoadingBodegasXGranja = async(uid) => {
        try {
            const { data } = await granjaApi.get(`/bodega/xgranja/${ uid }`);
            dispatch( onLoandingBodega( data.bodegas ) );
            
        } catch (error) {
            console.log('Error al Cargar bodegas');
            console.log(error);
        }
    }



    const deleteBodega = async( bodega ) => {
        try {
            const { data } = await granjaApi.delete(`/bodega/${ bodega.id }`);
            if( data.ok ){
                dispatch( onDeleteBodega( data.bodega ) );
                Swal.fire('Exitoso', 'Bodega Eliminada', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    return {
        //Propiedades
        bodegas,
        activeBodega,

        //Metodos
        startSavingBodega,
        startLoadingBodegas,
        deleteBodega,
        setActiveBodega,
        startLoadingBodegasXGranja,
    }


}
