
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { AdminLayout } from '../../layout/AdminLayout';
import { useDescuentoStore, useDistribucionStore } from '../../../hooks';
import { TablaDeDatos } from './TablaDeDatos';
import { FiltroPorFecha } from './FiltroPorFecha';



export const DescuentosReporte = () => {


    const { id } = useParams();
    const { descuento, startLoadingDesss } = useDescuentoStore(); 
    const { activeDistribucion, setActiveDistribucion } = useDistribucionStore();

    const [calendario, setCalendario] = useState( false );


    useEffect(() => {
        if( id ){
            startLoadingDesss(id);
            setActiveDistribucion(id);
        }
    }, [id])
    

    const filtroFecha = () => {
        setCalendario( (c) => !calendario );
    }



    return (

        <AdminLayout>
            <Grid container direccion='row' alignItems='center' mb={3}>

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'primary.main',
                        ':hover': { backgroundColor: 'primary.main', opacity: 0.3 },
                    }}
                    onClick={ filtroFecha }
                >
                    <CalendarMonthIcon />
                </IconButton>


                <Box flex={ 1 } />

                <Typography variant='h1' >Informacion de Descuentos</Typography>

                <Box flex={ 1 } />

            </Grid>

            {
            calendario &&
            <FiltroPorFecha nombre={'DESCUENTO'} />
            }

            <TablaDeDatos 
                activeDistribucion={ activeDistribucion } 
                datos={ descuento } 
                titulo={'Descuento'}
            />


        </AdminLayout>


    )




}
