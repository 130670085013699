import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"

import io from 'socket.io-client';
import Swal from "sweetalert2";
import { granjaApi } from "../api";
import { prepararFechaOp, prepararFechaUnoOp } from "../helpers/prepararFecha";
import { onAddNewOp, onDeleteOp, onLoandingOp, onSetActiveOp, onUpdateOp } from "../store";

const baseSocket = process.env.REACT_APP_SOCKET_IO_URL;

let socket;


export const useOpStore = () => {


    const dispatch = useDispatch();
    const { ops, activeOp } = useSelector( state => state.op );



    useEffect(() => {
        socket = io(baseSocket);
    }, []);


    const setActiveOp = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/op/porid/${ uid }`);
            const conFecha = prepararFechaUnoOp(data.op);
            dispatch( onSetActiveOp( conFecha ) );
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error)
        }
    }


    const startSavingOp = async( op ) => {
        try {
            const { data } = await granjaApi.post('/op/new', op );
            if( data.ok ){
                const conFecha = prepararFechaUnoOp(data.op);
                // dispatch( onAddNewOp( conFecha ) );
                socket.emit('nueva-op', conFecha);
                Swal.fire('Exitoso', 'Op Creada', 'success');
            }

        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    } 



    const startUpdategOp = async( op ) => {
        try {
            const { data } = await granjaApi.put(`/op/estado/${ op.id }`, op );
            if( data.ok ){
                const conFecha = prepararFechaUnoOp(data.op);
                // Socket Update
                socket.emit('update-op', conFecha );
                Swal.fire('Exitoso', 'Op Actualizada', 'success');
            }
        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    } 



    const startUpdategOpSecretaria = async( op ) => {
        try {
            const { data } = await granjaApi.put(`/op/secretaria/${ op.id }`);
            if( data.ok ){
                const conFecha = prepararFechaUnoOp(data.op);
                // Socket Update
                socket.emit('update-op', conFecha );
                Swal.fire('Exitoso', 'Pedido Aprobado', 'success');
            }
        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    } 


    
    const startLoadingOp = async() => {
        try {
            const { data } = await granjaApi.get('/op/todos');
            const todasOp = prepararFechaOp(data.ops);
            dispatch( onLoandingOp(todasOp) );
            
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }



    const deleteOp = async( op ) => {
        try {
            const { data } = await granjaApi.delete(`/op/${ op.id }`);
            if( data.ok ){
                // dispatch( onDeleteOp( data.op ) );
                // Socket io
                socket.emit('eliminar-op', data.op);
                Swal.fire('Exitoso', 'Op Eliminada', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }



    const agregarOPDeSocket = ( op ) => {
        dispatch( onAddNewOp( op ) );
    }


    const opEliminadaDeSocket = ( op ) => {
        dispatch( onDeleteOp( op ) );
    }


    const opUpdateDeSocket = ( op ) => {
        dispatch( onUpdateOp( op ) );
        dispatch( onSetActiveOp( op ) );
    }



    return {
        //Propiedades
        ops,
        activeOp,

        //Metodos
        startSavingOp,
        startLoadingOp,
        deleteOp,
        setActiveOp,
        startUpdategOp,

        //Socket
        agregarOPDeSocket,
        opEliminadaDeSocket,
        opUpdateDeSocket,
        startUpdategOpSecretaria,
    }


}
