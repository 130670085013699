import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import { onAddNewEncubadora, onDeleteEncubadora, onLoandingEncubadora, onSetActiveEncubadora, onUpdateEncubadora } from "../store";
import Swal from 'sweetalert2';



export const useEncubadoraStore = () => {


    const dispatch = useDispatch();
    const { encubadoras, activeEncubadora } = useSelector( state => state.encubadora );


    const setActiveEncubadora = async( uid ) => {

        try {
            const { data } = await granjaApi.get(`/encubadora/porid/${ uid }`);
            if( data.ok ){
                dispatch( onSetActiveEncubadora( data.loteencubadora ) );
            }else {
                Swal.fire('Error', 'Algo Salio Mal', 'error');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    const startSavingEncubadora = async( encubadora ) => {
        try {

            if( encubadora.id ){
                const { data } = await granjaApi.put(`/encubadora/${ encubadora.id }`, { ...encubadora } );
                if( data.ok ){
                    dispatch( onUpdateEncubadora( data.loteEncubadora ) );
                    Swal.fire('Exitoso', 'Lote Incubadora Actualizado', 'success');
                }
            }else {
                const { data } = await granjaApi.post('/encubadora/new', { ...encubadora } );
                if( data.ok ){
                    Swal.fire('Proceso Exitoso', 'Lote Incubadora Creado', 'success');
                    dispatch( onAddNewEncubadora( data.loteencubadora ) );
                } else{
                    Swal.fire('Error', 'Algo Salio Mal', 'error');
                }
            }

        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    } 


    const startLoadingEncubadora = async() => {
        try {
            const { data } = await granjaApi.get('/encubadora');
            // const lotes = prepararFecha( data.lotes );
            dispatch( onLoandingEncubadora( data.loteencubadora ) );
        } catch (error) {
            console.log('Error al Cargar Lotes');
            console.log(error);
        }
    }



    const deleteEncubadora = async( encubadora ) => {
        try {
            const { data } = await granjaApi.delete(`/encubadora/${ encubadora.id }`);
            if( data.ok ){
                dispatch( onDeleteEncubadora( data.loteEncubadora ) );
                Swal.fire('Exitoso', 'Incubadora Eliminada', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            // console.log(error);
        }
    }


    

    return {
        //Propiedades
        encubadoras,
        activeEncubadora,

        //Metodos
        setActiveEncubadora,
        startSavingEncubadora,
        startLoadingEncubadora,
        deleteEncubadora,
    }


}
