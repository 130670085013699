

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNotificacionStore } from "../../../../hooks";

import { LayoutReturn } from "../../../layout";
import { TablaEntradas, TablaSalidas } from "../components";





export const SalidasEntradasPorBodega = () => {



  
  const { id }  = useParams();
  const  { loadingNotificacionQuienEnvia, loadingNotificacionQuienRecibe } = useNotificacionStore();



  useEffect(() => {
    if( id ){
      loadingNotificacionQuienEnvia(id);
    }
  }, [id] );


  useEffect(() => {
    if( id ){
      loadingNotificacionQuienRecibe(id);
    }
  }, [id] );





  return (

    
    <LayoutReturn title="Pendiente Por Modoficar" url="modificar" >

            {/* <Grid 
            container
            direccion='row'
            alignItems='center'
            >

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ regresar }
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h4' sx={{ mb:2, fontWeight:'bold' }} >Entradas</Typography>

                <Box flex={ 1 } />

            </Grid> */}


            <TablaEntradas />

            {/* <Grid 
            container
            direccion='row'
            alignItems='center'
            sx={{ mt:20 }}
            >

              <Box flex={ 1 } />

              <Typography variant='h4' sx={{ mb:2, fontWeight:'bold' }} >Salidas</Typography>

              <Box flex={ 1 } />

            </Grid> */}
            

            <TablaSalidas />


        </LayoutReturn>


  )



}
