
import { useDispatch, useSelector } from "react-redux"

import { granjaApi } from "../api";
import Swal from "sweetalert2";
import { onAddNewVehiculo, onDeleteVehiculo, onLoandingVehiculo, onSetActiveVehiculo, onUpdateVehiculo } from "../store";



export const useVehiculoStore = () => {


    const dispatch = useDispatch();
    const { vehiculos, activeVehiculo } = useSelector( state => state.vehiculo );



    const setActiveVehiculo = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/vehiculo/xid/${ uid }`);
            if( data.ok ){
                dispatch( onSetActiveVehiculo( data.vehiculo ) );
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }
    


    const setVehiculoPorConductor = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/vehiculo/xconductor/${ uid }`);
            if( data.ok ){
                dispatch( onLoandingVehiculo( data.vehiculo ) );
            }
        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    }




    const startSavingVehiculo = async( vehiculo ) => {
        try {
            if( vehiculo.id ){
                const { data } = await granjaApi.put(`/vehiculo/${ vehiculo.id }`, vehiculo );
                if( data.ok ){
                    dispatch( onUpdateVehiculo({ ...data.vehiculo }) );
                    Swal.fire('Exitoso', 'Vehiculo Actualizado', 'success');
                }
            }else {
                const { data } = await granjaApi.post('/vehiculo/new', vehiculo );
                if( data.ok ){
                    dispatch( onAddNewVehiculo( data.vehiculo ) );
                    Swal.fire('Exitoso', 'Vehiculo Creado', 'success');
                }
            }
        } catch (error) {
            Swal.fire('Error', error.response.data?.msg || '', 'error');
            console.log(error);
        }
    } 


    const startLoadingVehiculos = async() => {
        try {
            const { data } = await granjaApi.get('/vehiculo/todos');
            dispatch( onLoandingVehiculo(data.vehiculo) );
        } catch (error) {
            console.log('Error al Cargar Vehiculos');
            console.log(error);
        }
    }



    const deleteVehiculo = async( vehiculo ) => {
        try {
            const { data } = await granjaApi.delete(`/vehiculo/${ vehiculo.id }`);
            if( data.ok ){
                dispatch( onDeleteVehiculo( data.vehiculo ) );
                Swal.fire('Exitoso', 'Vehiculo Eliminado', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }



    return {
        //Propiedades
        vehiculos,
        activeVehiculo,

        //Metodos
        setActiveVehiculo,
        setVehiculoPorConductor,
        startSavingVehiculo,
        startLoadingVehiculos,
        deleteVehiculo,
    }


}
