
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Divider, Drawer, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { LoginOutlined } from "@mui/icons-material";

// import { AccountCircleOutlined,  ConfirmationNumberOutlined, VpnKeyOutlined } from "@mui/icons-material";
// import QrCode2Icon from '@mui/icons-material/QrCode2';
// import AppleIcon from '@mui/icons-material/Apple';
// import GiteIcon from '@mui/icons-material/Gite';
// import AssessmentIcon from '@mui/icons-material/Assessment';
// import WarehouseIcon from '@mui/icons-material/Warehouse';
// import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
// import DescriptionIcon from '@mui/icons-material/Description';
// import SquareFootIcon from '@mui/icons-material/SquareFoot';
// import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
// import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';

import { useAuthStore, useUIStore } from "../../hooks";
import { retornarMenu } from "../../helpers/retornarMenu";




const empresa = require.context('../../assets/empresa', true);



export const SideBarScreen = () => {


    const navigate = useNavigate();
    const { startLogout, user } = useAuthStore();

    const { isSidebarOpen, closeSidebar } = useUIStore();
    const [menu, setMenu] = useState([]);


    useEffect(() => {
        if( user ){
            const items = retornarMenu(user.role);
            setMenu( items );
            // console.log(items);
        }
    }, [user]);
    

    
    const navegacionRutas = ( url ) => {
        if( !url ){
            return;
        }
        closeSidebar();
        navigate(url);
    }

    const handleLogout = () => {
        startLogout();
        navigate('/auth/login');
        closeSidebar();
    }



  return (

    <Drawer
        open={ isSidebarOpen }
        onClose={ closeSidebar }
        anchor='left'
        sx={{ backdropFilter: 'blur(4px)', transition: 'all 0.3s ease-out' }}
    >

        <Box sx={{ width: 250, paddingTop: 2, }}>
            
            <List >

                { user &&
                <Box display='flex' justifyContent='center'
                    alignItems='center' flexDirection='column'
                    sx={{ minHeight:'200px' }} 
                >
                    {/* <Avatar
                        alt="Avatar Administrador"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 100, height: 100, my:1 }}
                    /> */}

                    <div className="img-logo">  
                        <img src={ empresa(`./pccpollo.png`) } alt='LogoEmpresa' />
                    </div>

                    <Typography variant='h6' align='center'  component='div' sx={{ fontWeight:'bold' }} >
                        { user.nombre }
                    </Typography>
                    <Typography variant='p' align='center'  component='div' sx={{ mb:3, fontWeight:'light' }} >
                        { user.role }
                    </Typography>
                </Box>
                }

                <Divider sx={{ color:'#fff' }} />

                { ( menu ) &&
                <Grid container >
                    {
                    menu.map( ( m ) => (
                        <ListItem button key={ m.titulo } 
                            onClick={ () => navegacionRutas( m.url ) }
                        >
                            <ListItemIcon>
                                { m.icono }
                            </ListItemIcon>
                            <ListItemText primary={ m.titulo } />
                        </ListItem>
                    )) 
                    }

                    <Divider />

                    <ListItem button onClick={ handleLogout } sx={{ mb:2 }}>
                        <ListItemIcon>
                            <LoginOutlined/>
                        </ListItemIcon>
                        <ListItemText primary={'Salir'} />
                    </ListItem>

                </Grid>
                }
            </List>
        </Box>
    </Drawer>

 )
}

