
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useNotificacionStore, useUIStore } from "../../../../hooks";
import moment from "moment";



export const TablaEntradas = () => {

    const  { entradas, setActiveNotaEnvio } = useNotificacionStore();
    const { openModalNoti } = useUIStore();


    
    const handleVerInfo = (entrada) => {
        setActiveNotaEnvio(entrada);
        openModalNoti();
    }




    return (

        <Grid  container sx={{ mt:5 }}>

            <Grid container direccion='row' alignItems='center'>

                <Box flex={ 1 } />

                <Typography variant='h1'>Entradas</Typography>

                <Box flex={ 1 } />

            </Grid>


            <TableContainer>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">

                    <TableHead > 

                        <TableRow>
                            <TableCell>PRODUCTO</TableCell>
                            <TableCell>CANTIDAD</TableCell>
                            <TableCell>CANTIDAD INICIAL</TableCell>
                            <TableCell>CANTIDAD FINAL</TableCell>
                            <TableCell>ENVIA</TableCell>
                            <TableCell>FECHA INICIO</TableCell>
                            <TableCell>FECHA FIN</TableCell>
                        </TableRow>

                    </TableHead>

                    { entradas &&(
                    <TableBody>
                    { entradas.map( ( entrada ) => (
                        <TableRow key={ entrada.id } onClick={ () => handleVerInfo(entrada) }
                            style={{ backgroundColor: (entrada.de) ? '#F6CECE' : '#F5DA81' }}
                        >
                            <TableCell>{ entrada.producto?.nombre }</TableCell>   
                            <TableCell>{ entrada.cantidad }</TableCell>
                            <TableCell>{ entrada.cantidadInicialRecibe }</TableCell>   
                            <TableCell>{ entrada.cantidadFinalRecibe }</TableCell>
                            <TableCell>{ (entrada.de) ? entrada.de?.nombre : entrada.deLote?.lote }</TableCell>   
                            <TableCell>{ moment(entrada.createdAt).format('l') }</TableCell>
                            <TableCell>{ moment(entrada.dateRecibe).format('l') }</TableCell>
                        </TableRow>
                    ))
                    }
                    </TableBody>
                    )}

                </Table>
            </TableContainer>


        </Grid>



    )



}
