
import { useState } from "react";
import { Grid, TextField } from "@mui/material"

import { useBusquedas, useOpStore } from "../../../../hooks";







export const BusquedaOp = () => {


    const { startLoadingOp } = useOpStore();
    const { busquedaPorOrdenesPedido } = useBusquedas();
    

    const [valor, setValor] = useState('');

    const hanldeInputChange = ({ target }) => {
        setValor( target.value );
        if( target.value.trim().length > 0 ){
            busquedaPorOrdenesPedido(target.value);
        }else {
            startLoadingOp();
        }
    }


    
    return (


        <Grid
            item
            xs={ 12 } sm={ 6 }
            sx={{ my: 4 }}
        >
            <TextField  
                label="Buscar Op..." 
                variant="filled" 
                fullWidth
                name="valor"
                value={ valor }
                onChange={ hanldeInputChange }
            />

        </Grid>

        
    )




}
