
import { Box, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useModalStore, usePedidoStore } from '../../../../hooks';

import Swal from 'sweetalert2';





export const CardProductosPorOp = ({ pedidos }) => {


    const { deletePedido, activePedidoStart } = usePedidoStore();
    const { openModalPedido } = useModalStore();


    
    const eliminarProductoOp = ( pedido ) => {
        Swal.fire({
            title: 'Eliminar Producto',
            text: `¿Esta Seguro De Eliminar Este Producto?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Eliminar' 
        }).then( ( result ) => {
            if(result.value){
                deletePedido( pedido.id );
            }
        });
    }



    const actualizarOp = (pedido) => {
        activePedidoStart(pedido.id);
        openModalPedido();
    }


  return (

    <Grid item md={3} sm={4} xs={12} >
        <Box width='100%' sx={{ background:'#e8f5e9', minHeight:'170px'}}>
            <Box width='100%' display='flex' justifyContent='space-between' alignItems='start' >
                <IconButton aria-label="delete" size="small"
                    sx={{
                        color: '#4caf50',
                        ':hover': {  opacity: 0.7 },
                    }}
                    onClick={ () => actualizarOp(pedidos) }
                >
                    <EditIcon />
                </IconButton>


                <Typography variant='h6' sx={{ fontWeight:'bold'  }}>
                    Items 
                </Typography>


                <IconButton aria-label="delete" size="small"
                    sx={{
                        color: 'error.main',
                        ':hover': {  opacity: 0.7 },
                    }}
                    onClick={ () => eliminarProductoOp(pedidos) }
                >
                    <DeleteIcon />
                </IconButton>
                
            </Box>

            <Box width='100%' textAlign='center'>
                <Typography variant="h6" component="div" color='error.main'>
                    Producto
                </Typography>
                <Typography sx={{ fontSize: 17, fontWeight:'bold' }} >
                    { pedidos.producto?.nombre }
                </Typography>
            </Box>

            <Box width='100%' textAlign='center'>
                <Typography variant="h6" component="div" color='error.main'>
                    Cantidad
                </Typography>
                <Typography sx={{ fontSize: 17, fontWeight:'bold' }}>
                    { pedidos.cantidad }
                </Typography>
            </Box>
        </Box>
    </Grid>

  )



  
}
