import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { useUsuarioStore } from '../../../hooks';
import { LayoutReturn } from '../../layout';
import { roles_select } from '../../../data';



const initialState = {
    nombre: "",
    cedula: '',
    email: "",
    role: "",
    direccion: "",
    ciudad: "",
    movil: "",
};


export const CrearUsuarios = () => {

    const navigate = useNavigate();
    const  { startSavingUsuario } = useUsuarioStore();

    const [formUser, setFormUser] = useState( initialState );
    const  { nombre, email, cedula, role, direccion, ciudad, movil } = formUser;

    const handleInputChange = ({ target }) => {
        setFormUser({
            ...formUser,
            [target.name]: target.value
        });
    }


    const handleCrearUsuario = async(e) => {
        e.preventDefault();
        if([nombre, email, role, direccion, ciudad, movil].includes('') ){
            return console.log('Formulario Incorrecto');
        }
        await startSavingUsuario( formUser );
        setFormUser( initialState );
    }

    return (

        <LayoutReturn title='Crear Usuario' url='/admin/list-user' >

            <form onSubmit={ handleCrearUsuario } >
                <Grid container spacing={ 2 }>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Nombre Completo'
                            type='text'
                            placeholder='Nombre Completo'
                            fullWidth
                            name='nombre'
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Cedula'
                            type='text'
                            placeholder='Cedula'
                            fullWidth
                            name='cedula'
                            value={ cedula }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Email'
                            type='email'
                            placeholder='Email'
                            fullWidth
                            name='email'
                            value={ email }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            id="outlined-select-currency"
                            select
                            label="Seleccione Role"
                            fullWidth
                            name='role'
                            value={ role }
                            onChange={ handleInputChange }
                        > 
                            <MenuItem value=''>
                                Seleccione Role
                            </MenuItem>
                            {roles_select.map( rol => (
                                <MenuItem key={rol} value={rol}>
                                    {rol}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Direccion'
                            type='text'
                            placeholder='Direccion'
                            fullWidth
                            name='direccion'
                            value={ direccion }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Ciudad'
                            type='text'
                            placeholder='Ciudad'
                            fullWidth
                            name='ciudad'
                            value={ ciudad }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField 
                            label='Telefono'
                            type='number'
                            placeholder='Telefono'
                            fullWidth
                            name='movil'
                            value={ movil }
                            onChange={ handleInputChange }
                        />
                    </Grid>
                    
                </Grid>

                <Grid container 
                    sx={{ mb:2, mt:2 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit'>
                            Crear Usuario
                        </Button>
                    </Grid>
                </Grid>

            </form>
        
        </LayoutReturn>



    )




}
