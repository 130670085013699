

import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;





const granjaApi = axios.create({
    baseURL: baseURL
});


// Configurar Interceptores
granjaApi.interceptors.request.use( config => {

    config.headers = {
        ...config.headers,
        'x-token': localStorage.getItem('token')
    }

    return config;
})


export default granjaApi;