
import { useState } from "react";

import { Box, Button, Grid, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import moment from "moment";
import { useFacturaStore } from "../../../../hooks";



const now = moment().hours(0).minutes(0).seconds(0);
const nowPlus1 = now.clone().add(1, 'days').subtract(1, 'seconds');


export const OpcionesDeFiltro = () => {


    const { startLoadingFacturasPorFecha, startLoadingFacturasPorReciente, startLoadingFacturasPorNumero } = useFacturaStore();

    const [calendario, setCalendario] = useState( false );
    const [buscar, setBuscar] = useState( false );
    const [termino, setTermino] = useState('');

    const [formValues, setFormValues] = useState({
        start: now.toDate(),
        end: nowPlus1.toDate(),
    });
    const { start, end } = formValues;


    
    const handleCalendario = () => {
        setCalendario( (c) => !calendario );
        setBuscar( false );
    }


    const handleBusquedad = () => {
        setBuscar( (c) => !buscar );
        setCalendario( false );
    }


    const handleChangeStart = (e) => {
        setFormValues({
            ...formValues,
            start: e.$d
        });
    }

    const handleChangeEnd = (e) => {
        const resultado = moment(e.$d).hours(0).minutes(0).seconds(0);
        setFormValues({
            ...formValues,
            end: resultado.clone().add(24, 'hours').subtract(1, 'seconds').toDate(),
        });
    } 


    const handleConsultaFacturas = (e) => {
        e.preventDefault();
        startLoadingFacturasPorFecha(formValues);
    }

    
    const handleRecientes =  () => {
        startLoadingFacturasPorReciente();
    }


    const handleMenorPrecio = () => {

    }


    const handleMayorPrecio = () => {

    }


    const handlePorNumeroFactura = async(e) => {
        setTermino(e.target.value);
        const nombre = e.target.value;
        if( nombre.trim().length < 1 ){
            return;
        }
        await startLoadingFacturasPorNumero(nombre);
    }



    return (

        <>
        <Stack direction="row" justifyContent='center' spacing={1} my={3}>
            
            <Tooltip title="Buscar Por Fecha De Vencimiento" placement="top">
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: '#ff9800',
                        ':hover': { backgroundColor: '#ff9800', opacity: 0.3 },
                    }}
                    onClick={ handleCalendario }
                >
                    <CalendarMonthIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Mas Recientes" placement="top">
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: '#2196f3',
                        ':hover': { backgroundColor: '#2196f3', opacity: 0.3 },
                    }}
                    onClick={ handleRecientes }
                >
                    <PlayCircleFilledIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Buscar Por Numero de Factura" placement="top">
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: '#ffc107',
                        ':hover': { backgroundColor: '#ffc107', opacity: 0.3 },
                    }}
                    onClick={ handleBusquedad }
                >
                    <SearchIcon />
                </IconButton>
            </Tooltip>


            <Tooltip title="Buscar Por Menor Precio" placement="top">
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ handleMenorPrecio }
                >
                    <RemoveCircleOutlineIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Buscar Por Mayor Precio" placement="top">
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: '#4caf50',
                        ':hover': { backgroundColor: '#4caf50', opacity: 0.3 },
                    }}
                    onClick={ handleMayorPrecio }
                >
                    <AddCircleOutlineIcon />
                </IconButton>
            </Tooltip>

        </Stack>

        {
        calendario &&
        <Grid 
        container direccion='row'
        alignItems='center' my={2}
        >
            <Grid item xs={ 12 } sm={ 4 } mt={ 2 } >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Fecha de Inicio"
                        value={ start }
                        onChange={ handleChangeStart }
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </LocalizationProvider>
            </Grid>

            <Box flex={ 1 } />

            <Grid item xs={ 12 } sm={ 4 } mt={ 2 } >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Fecha Final"
                        value={ end }
                        onChange={ handleChangeEnd }
                        minDate={ start }
                        
                        renderInput={(params) => <TextField {...params} fullWidth /> }
                    />
                </LocalizationProvider>
            </Grid>

            <Box flex={ 1 } />

            <Grid item xs={ 12 } sm={ 3 } mt={ 2 } >
                <Button variant="contained" fullWidth onClick={ handleConsultaFacturas } >
                    Consultar
                </Button>
            </Grid>

        </Grid>
        }

        {
        buscar &&
        <Grid 
        container direccion='row'
        alignItems='center' my={2}
        >
            <Grid item xs={ 12 } sm={ 8 } mt={ 2 } >
                <TextField
                    label="Buscar Factura"
                    type="text"
                    value={ termino }
                    onChange={ handlePorNumeroFactura }
                    fullWidth
                />
            </Grid>
        </Grid>
        }

        </>

    )




}
