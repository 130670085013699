
import { useNavigate } from "react-router-dom";

import { Box, Grid, IconButton, Typography } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { useInventarioStore } from "../../hooks";




export const LayoutReturnSearch = ({ children, title, url, url2 }) => {


    const navigate = useNavigate();
    const { LimpiarListaInventario } = useInventarioStore();



    const regresar = () => {
        navigate(url);
    }


    const handleSearch = () => {
        LimpiarListaInventario();
        navigate(url2);
    }


  return (


    <Grid container sx={{ minHeight:'50vh', padding:4 }}>

        <Grid container direccion='row' alignItems='center' mb={2}>
            <IconButton
                sx={{
                    color: '#fff',
                    backgroundColor: 'error.main',
                    ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                }}
                onClick={ regresar }
            >
                <ArrowBackIcon />
            </IconButton>

            <Box flex={ 1 } />

            <Typography variant='h1' >{ title }</Typography>

            <Box flex={ 1 } />


            <IconButton
                sx={{
                    color: '#fff',
                    backgroundColor: 'primary.main',
                    ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                }}
                onClick={ handleSearch }
            >
                <SearchIcon />
            </IconButton>

        </Grid>




        { children }





    </Grid>



  )



}
