
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import FindInPageIcon from '@mui/icons-material/FindInPage';

import { useLoteStore } from "../../../../hooks";
import { BuscarLotes } from "../components";
import { LayoutReturn } from "../../../layout";


import Swal from "sweetalert2";





export const LotesArchivados = () => {


    const { lotes, startLoadingLoteArchivado, cambioEstadoLote } = useLoteStore();
    const navigate = useNavigate();



    useEffect(() => {
      startLoadingLoteArchivado();
    }, [])



    const handleTrueFalse = (lote) => {
      Swal.fire({
        title: 'Activar Lote',
        text: `¿Esta Seguro De Activar Este Lote?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si' 
      }).then( ( result ) => {
          if(result.value){
            Swal.fire({
              title: 'Esta a Punto De Activar El Lote',
              text: `El Lote Sera Activado.`,
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Si, Activar' 
          }).then( ( result ) => {
              if(result.value){
                cambioEstadoLote( lote.id );
              }
          });
          }
      });
    }



    const handleInfoLote = ( uid ) => {
      navigate(`/admin/info/${uid}`);
    }

    
    


  return (


    <LayoutReturn title='Lotes Archivados' url='/admin/list-lotes' >


      <BuscarLotes activo={false} />

      <TableContainer>
        <Table sx={{ minWidth: 700, }}>

          <TableHead>
            <TableRow>
              <TableCell>A.</TableCell>
              <TableCell>LOTE</TableCell>
              <TableCell>ENCUBADORA</TableCell>
              <TableCell>CANTIDAD TOTAL</TableCell>
              <TableCell>CANTIDAD MACHOS</TableCell>
              <TableCell>CANTIDAD HEMBRAS</TableCell>
              <TableCell>FECHA INICIO</TableCell>
              <TableCell>FECHA CIERRE</TableCell>
              <TableCell>OPCIONES</TableCell>
            </TableRow>
          </TableHead>

          { lotes && (
          <TableBody>
            { lotes.map( ( lote ) => (
              <TableRow key={ lote.id } >

                <TableCell>
                  { ( lote.activo ) 
                  ? <button className='boton-estado trru'
                    >
                    </button>
                  : <button className='boton-estado fall'
                      onClick={ () => handleTrueFalse(lote) }
                    >
                    </button>
                  } 
                </TableCell>
                <TableCell>{ lote.lote }</TableCell>
                <TableCell>{ lote.encubadora?.nombre }</TableCell>
                <TableCell>{ lote.cantidadTotal }</TableCell>
                <TableCell>{ lote.machos }</TableCell>
                <TableCell>{ lote.hembras }</TableCell>
                <TableCell>{ lote.createdAt }</TableCell>
                <TableCell>{ lote.fechaFin }</TableCell>

                <TableCell>
                    <IconButton onClick={ () => handleInfoLote( lote.id ) }>
                      <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                    </IconButton>
                </TableCell>

              </TableRow>

            ))}
          </TableBody>
          )}

        </Table>
      </TableContainer>

    </LayoutReturn>


  )



}
