
import { configureStore } from '@reduxjs/toolkit';

import { abonoSlice, granjaSlice, galponSlice, planillaSlice, loteSlice, uiSlice, authSlice, usuarioSlice, proveedorSlice, productoSlice, bodegaSlice, inventarioSlice, modalSlice, notificacionSlice, pedidoSlice, unidadMedidaSlice, encubadoraSlice, opSlice, ingresoRaizSlice, notaSlice, facturaSlice, modalImgSlice, distribucionSlice, descuentoSlice, vehiculoSlice, consumoSlice, galponConsumoSlice, abonoPlanillaSlice, descarteSlice, modalGlobalSlice, sugeridoSlice } from './';




export const store = configureStore({
    reducer: {
        ui: uiSlice.reducer,
        modalGlobal: modalGlobalSlice.reducer,

        modal: modalSlice.reducer,
        modalImg: modalImgSlice.reducer,
        auth: authSlice.reducer,
        usuario: usuarioSlice.reducer,
        vehiculo: vehiculoSlice.reducer,

        granja: granjaSlice.reducer, 
        galpon: galponSlice.reducer,
        bodega: bodegaSlice.reducer,

        encubadora: encubadoraSlice.reducer,
        lote: loteSlice.reducer,
        distribucion: distribucionSlice.reducer,
        descuento: descuentoSlice.reducer,
        planilla: planillaSlice.reducer,
        descarte: descarteSlice.reducer,
        abonoPlanilla: abonoPlanillaSlice.reducer,
        consumo: consumoSlice.reducer,
        sugerido: sugeridoSlice.reducer,
        galponConsumo: galponConsumoSlice.reducer,

        proveedor: proveedorSlice.reducer,
        unidadMedida: unidadMedidaSlice.reducer,
        producto: productoSlice.reducer,
        op: opSlice.reducer,

        ingresoRaiz: ingresoRaizSlice.reducer,
        inventario: inventarioSlice.reducer,
        notificacion: notificacionSlice.reducer,
        pedido: pedidoSlice.reducer,
        nota: notaSlice.reducer,
        factura: facturaSlice.reducer,
        abono: abonoSlice.reducer

    }
})