

import { useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, Typography } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';







export const LayoutReturn = ({ children, title, url }) => {


    const navigate = useNavigate();


    const regresar = () => {
        navigate(url);
    }



    return (

        // justifyContent:'center'
        <Grid container sx={{ minHeight:'50vh', padding:4, display:'flex', }}>

            <Grid container direccion='row' alignItems='center' mb={2}>
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ regresar }
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h1'>{ title }</Typography>

                <Box flex={ 1 } />
            </Grid>



                    
            { children }





        </Grid>

        
    )



    
}
