

import { createSlice } from '@reduxjs/toolkit';



export const galponConsumoSlice = createSlice({

    name: 'galponConsumo',
    
    initialState: {
        isLoadingGalponConsumo: true,
        galponConsumos: [],
        activeGalponConsumo: null
    },
    reducers:{
        onSetActiveGalponConsumo: ( state, { payload } ) => {
            state.activeGalponConsumo = payload;
        },
        
        onAddNewGalponConsumo: ( state, { payload } ) => {
            state.galponConsumos.push( payload );
            state.activeGalponConsumo = null;
        },

        onUpdateGalponConsumo: ( state, { payload } ) => {
            state.galponConsumos = state.galponConsumos.map( galponConsumo => {
                if( galponConsumo.id === payload.id ){
                    return payload
                }
                return galponConsumo
            });
        },

        onLoandingGalponConsumo: ( state, { payload = [] } ) => {
            state.isLoadingGalponConsumo = false;
            state.galponConsumos = payload;
        },

        onDeleteGalponConsumo: ( state, { payload } ) => {
            state.galponConsumos = state.galponConsumos.filter( galponConsumo => galponConsumo.id !== payload.id );
        }
    }
});





export const { onSetActiveGalponConsumo, onAddNewGalponConsumo, onUpdateGalponConsumo, onLoandingGalponConsumo, onDeleteGalponConsumo } = galponConsumoSlice.actions;