import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import { prepararFechaPedido, prepararFechaPedidoUno } from "../helpers/prepararFecha";
import { activeAbonoDelete, onAddNewAbono, onDeleteAbono, onLoandingAbonos, onSetActiveAbono } from "../store";
import Swal from "sweetalert2";




export const useAbonoStore = () => {


    const dispatch = useDispatch();
    const { abonos, activeAbono } = useSelector( state => state.abono );



    const activeAbonoStart = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/abonof/uno/${ uid }`);
            // const factura = prepararFechaUnoFactura(data.abono);
            dispatch( onSetActiveAbono( data.abono ) );
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error)
        }
    }



    const activeAbonoEliminar = async() => {
        dispatch( activeAbonoDelete() );
    }



    const startSavingAbono = async( abono ) => {
        try {
            const { data } = await granjaApi.post('/abonof/new', abono );
            if( data.ok ){
                const ab = prepararFechaPedidoUno(data.abono);
                dispatch( onAddNewAbono( ab ) );
                Swal.fire('Exitoso', 'Abono Creada', 'success');
            }
        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    } 


    const startLoadingAbonos = async(uid) => {
        try {
            const { data } = await granjaApi.get(`/abonof/porfactura/${uid}`);
            const abonos = prepararFechaPedido(data.abonos);
            dispatch( onLoandingAbonos(abonos) );
        } catch (error) {
            console.log(error);
        }
    }



    const deleteAbono = async( abono ) => {
        try {
            const { data } = await granjaApi.put(`/abonof/${ abono.id }`, abono);
            if( data.ok ){
                dispatch( onDeleteAbono( data.abonoDelete ) );
                Swal.fire('Exitoso', 'Abono Eliminado', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }




    return {
        //Propiedades
        abonos,
        activeAbono,

        //Metodos
        startSavingAbono,
        startLoadingAbonos,
        deleteAbono,
        activeAbonoStart,
        activeAbonoEliminar,
    }


}
