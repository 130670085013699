

import { Grid, TextField } from "@mui/material"
import { useState } from "react";
import { useBusquedas, useGranjaStore } from "../../../hooks"



export const BusquedaGranjas = () => {


    const { startLoadingGranjas } = useGranjaStore();
    const { busquedaPorGranjas } = useBusquedas();
    

    const [valor, setValor] = useState('');

    const hanldeInputChange = ({ target }) => {
        setValor( target.value );
        if( target.value.trim().length > 0 ){
            busquedaPorGranjas(target.value);
        }else {
            startLoadingGranjas();
        }
    }


    return (

        <Grid
            item
            xs={ 12 } sm={ 6 }
            sx={{ my: 4 }}
        >
            <TextField  
                autoComplete="off"
                label="Buscar Granja..." 
                variant="filled" 
                fullWidth
                name="valor"
                value={ valor }
                onChange={ hanldeInputChange }
            />

        </Grid>


    )




}
