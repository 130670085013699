



export const quitarComa = ( num ) => {

    if( typeof num === 'number' ) {
        return;
    }
    // let quitar = num.replaceAll(',', '');
     const quitar = Number(num);
     return quitar;
}




// export const quitarComasProductos = (producto = {}) => {

//     return {
//         ...producto,
//         costoMateria: ( typeof producto.costoMateria === 'number' ) ? producto.costoMateria : Number(producto.costoMateria.replaceAll(',', '') ),
//         costoProduccion: ( typeof producto.costoProduccion === 'number' ) ? producto.costoProduccion : Number(producto.costoProduccion.replaceAll(',', '') ),
//         costoTotal: ( typeof producto.costoTotal === 'number' ) ? producto.costoTotal : Number(producto.costoTotal.replaceAll(',', '') ),
//         precioVenta: ( typeof producto.precioVenta === 'number' ) ? producto.precioVenta : Number(producto.precioVenta.replaceAll(',', '') ),
//         precioDistri: ( typeof producto.precioDistri === 'number' ) ? producto.precioDistri : Number(producto.precioDistri.replaceAll(',', '') ),
//         precioPublicista: ( typeof producto.precioPublicista === 'number' ) ? producto.precioPublicista : Number(producto.precioPublicista.replaceAll(',', '') ),
//         precioMinimoVenta: ( typeof producto.precioMinimoVenta === 'number' ) ? producto.precioMinimoVenta : Number(producto.precioMinimoVenta.replaceAll(',', '') ),
//     }
// }


// export const quitarComasProductosYCrear = (producto = {}) => {

//     return {
//         ...producto,
//         costoMateria: ( typeof producto.costoMateria === 'number' ) ? producto.costoMateria : Number(producto.costoMateria.replaceAll(',', '') ),
//         costoProduccion: ( typeof producto.costoProduccion === 'number' ) ? producto.costoProduccion : Number(producto.costoProduccion.replaceAll(',', '') ),
//         precioVenta: ( typeof producto.precioVenta === 'number' ) ? producto.precioVenta : Number(producto.precioVenta.replaceAll(',', '') ),
//         precioDistri: ( typeof producto.precioDistri === 'number' ) ? producto.precioDistri : Number(producto.precioDistri.replaceAll(',', '') ),
//         precioPublicista: ( typeof producto.precioPublicista === 'number' ) ? producto.precioPublicista : Number(producto.precioPublicista.replaceAll(',', '') ),
//     }
// }



// export const redondearNumero = ( num ) => {

//     const numero = Number(num);
//     const total = Math.ceil(numero / 100) * 100;

//     return total;
// }