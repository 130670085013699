
import { useEffect, useState } from "react";

import { Grid, TextField } from "@mui/material"
import { useBusquedas, useProductoStore } from "../../../../hooks"



export const BuscarProductos = () => {


    const { startLoadingProducto } = useProductoStore();
    const { busquedaPorProducto } = useBusquedas();
    

    const [valor, setValor] = useState('');


    useEffect(() => {
        if( valor.trim().length > 0 ){
            busquedaPorProducto(valor);
        }else {
            startLoadingProducto();
        }
    }, [valor])



    return (

        <Grid
            item
            xs={ 12 } sm={ 6 }
            sx={{ my: 4 }}
        >
            <TextField  
                autoComplete="off"
                label="Buscar Producto... Nom, Unid, Provee" 
                variant="filled" 
                fullWidth
                name="valor"
                value={ valor }
                onChange={ (e) => setValor(e.target.value) }
            />

        </Grid>

    )



    
}
