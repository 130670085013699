
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';



export const AlertScreen = ({ title }) => {


    return (

        <Stack sx={{ width: '100%' }} spacing={2} mt={4} >
            <Alert sx={{ display:'flex', justifyContent:'center' }} severity="warning">{ title }</Alert>
        </Stack>

    )



}
