
import { Provider } from 'react-redux';

import { AppRouter } from "./router/AppRouter"
import { store } from './store';
import { AppTheme } from "./theme";

import moment from 'moment';
import dayjs from 'dayjs';
import 'moment/locale/es';
import 'dayjs/locale/es';
moment.locale('es');
dayjs.locale('es');





export const App = () => {


    return (

      <Provider store={ store } >
          <AppTheme>
            <AppRouter />
          </AppTheme>
      </Provider>

    )




}
