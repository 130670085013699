

import { useEffect, useState } from 'react';

import { Box, Backdrop, Modal, Grid, TextField, Typography } from '@mui/material';
import { useNotificacionStore, useUIStore } from '../../../../hooks';
import moment from 'moment';




export const ModalInfoRecibeEnvia = () => {


    const { closeModalNoti, modalNotificacion } = useUIStore();
    const  { activeNotificacion } = useNotificacionStore();



    const handleCerrarModal = () => {
        closeModalNoti();
    }



    const [formUser, setFormUser] = useState({
        producto: '',
        createdAt: '',
        dateRecibe: '', 
        de: '', 
        para: '', 
        recibe: '', 
        solicitud: '',
        cantidad: '',
    });
    const  { producto, createdAt, dateRecibe, de, para, recibe, solicitud, cantidad } = formUser;



    useEffect(() => {
        if( activeNotificacion ){
            setFormUser({
                ...activeNotificacion,
                createdAt: moment(activeNotificacion.createdAt).format('LLL'),
                dateRecibe: moment(activeNotificacion.dateRecibe).format('LLL'),
                producto: activeNotificacion.producto.nombre,
                de: (activeNotificacion.de) ? activeNotificacion.de.nombre : `${activeNotificacion.deLote.lote} - LOTE`,
                para: (activeNotificacion.para) ? activeNotificacion.para.nombre : `${activeNotificacion.paraLote.lote} - LOTE`,
                recibe: activeNotificacion.recibe?.nombre,
                solicitud: activeNotificacion.solicitud?.nombre,
            })
        }
    }, [activeNotificacion])
    




    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ modalNotificacion }
            onClose={ handleCerrarModal }
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >

            <Box className='modal-style' >

                <Typography variant='h1' sx={{ color:'white', textAlign:'center' }} mb={4} >Datos de Envio y Recibido</Typography>


                <Grid container spacing={2} my={3} >

                    <Grid item xs={12} sm={6}>
                        <TextField 
                            variant="filled"
                            label='Nombre Producto'
                            fullWidth
                            focused 
                            inputProps={{ style: {color: 'white',  opacity: 1} }}
                            size="small"
                            name='producto'
                            value={ producto }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField 
                            variant="filled"
                            label='Fecha Solicitud'
                            fullWidth
                            focused 
                            inputProps={{ style: {color: 'white',  opacity: 1} }}
                            size="small"
                            name='createdAt'
                            value={ createdAt }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField 
                            variant="filled"
                            label='Fecha Recibe'
                            fullWidth
                            focused 
                            inputProps={{ style: {color: 'white',  opacity: 1} }}
                            size="small"
                            name='dateRecibe'
                            value={ dateRecibe }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField 
                            variant="filled"
                            label='De'
                            fullWidth
                            focused 
                            inputProps={{ style: {color: 'white',  opacity: 1} }}
                            size="small"
                            name='de'
                            value={ de }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField 
                            variant="filled"
                            label='Para'
                            fullWidth
                            focused 
                            inputProps={{ style: {color: 'white',  opacity: 1} }}
                            size="small"
                            name='para'
                            value={ para }
                        />
                    </Grid>


                    {
                    ( activeNotificacion?.para  &&
                    <>
                    {
                    ( activeNotificacion?.de  &&
                    <>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                variant="filled"
                                label='Quien Solicitad'
                                fullWidth
                                focused 
                                inputProps={{ style: {color: 'white',  opacity: 1} }}
                                size="small"
                                name='solicitud'
                                value={ solicitud }
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField 
                                variant="filled"
                                label='Quien recibe'
                                fullWidth
                                focused 
                                inputProps={{ style: {color: 'white',  opacity: 1} }}
                                size="small"
                                name='recibe'
                                value={ recibe }
                            />
                        </Grid>
                    </>
                    )}
                    </>
                    )}

                    <Grid item xs={12} sm={6}>
                        <TextField 
                            variant="filled"
                            label='Cantidad'
                            fullWidth
                            focused 
                            inputProps={{ style: {color: 'white',  opacity: 1} }}
                            size="small"
                            name='cantidad'
                            value={ cantidad }
                        />
                    </Grid>

                </Grid>
            </Box>

        </Modal>


    );

}