import { Box, Grid, Link, Typography } from "@mui/material"


const empresa = require.context('../../assets/empresa', true);





export const SeccionScreen = () => {


    return (

        <Box sx={{ my:5 }} 
            display='flex' flexDirection='column'  alignItems='center' textAlign='center'
        >

            <Link className="carta">
                <Typography component='h2'>Conoce Nuestros Productos</Typography>
                <Box className="item">
                    <img src={ empresa(`./popollo.jpg`) } alt="" />
                </Box>
            </Link>

            <Link className="carta">
                <Typography component='h2'>Promociones</Typography>
                <Box className="popollo item">
                    <img src={ empresa(`./popollo.jpg`) } alt="" />
                </Box>
            </Link>

            <Link className="carta">
                <Typography component='h2'>Equipo de Trabajo</Typography>
                <Box className="popollo item">
                    <img src={ empresa(`./popollo.jpg`) } alt="" />
                </Box>
            </Link>

        </Box>


        
    )





}
