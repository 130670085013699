

import { createSlice } from '@reduxjs/toolkit';



export const abonoPlanillaSlice = createSlice({
    name: 'abonoPlanilla',
    initialState: {
        isLoadingAbono: true,
        abonosPlanilla: [],
        activeAbonoPlanilla: null
    },
    reducers:{

        onSetActiveAbonoPlanilla: ( state, { payload } ) => {
            state.activeAbonoPlanilla = payload;
        },

        activeAbonoPlanillaDelete: ( state ) => {
            state.activeAbonoPlanilla = null;
        },
        
        onAddNewAbonoPlanilla: ( state, { payload } ) => {
            state.abonosPlanilla.unshift( payload );
            state.activeAbonoPlanilla = null;
        },

        // onUpdateAbono: ( state, { payload } ) => {
        //     state.abonosPlanilla = state.abonosPlanilla.map( abono => {
        //         if( abono.id === payload.id ){
        //             return payload
        //         }
        //         return abono
        //     });
        // },

        onLoandingAbonosPlanilla: ( state, { payload = [] } ) => {
            state.isLoadingAbono = false;
            state.abonosPlanilla = payload;
        },

        // onDeleteAbono: ( state, { payload } ) => {
        //     state.abonosPlanilla = state.abonosPlanilla.filter( abono => abono.id !== payload.id );
        // }
    }
});





export const { onSetActiveAbonoPlanilla,
                activeAbonoPlanillaDelete,
                onAddNewAbonoPlanilla,
                onLoandingAbonosPlanilla, } = abonoPlanillaSlice.actions;