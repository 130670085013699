import { Grid } from "@mui/material"





export const AdminLayout = ({ children }) => {



  return (

    <Grid
        container 
        spacing={ 0 }
        direccion='column'
        justifyContent='center'
        sx={{ minHeight:'100vh', padding:4 }}   //backgroundColor:'primary.main'  
        // sx={{ width: '100%' }}
    >

        <Grid item
            xs={ 12 }
            sm={ 12 }
            sx={{ 
                backgroundColor:'#fff', mt:2, borderRadius:2,  width:'550px',
                position:'relative'
            }}     // border:'3px solid #ff9800',
        >


            { children }


        </Grid>

    </Grid>



    )





}
