


import { createSlice } from "@reduxjs/toolkit";





export const modalGlobalSlice = createSlice({

    name: 'modalGlobal',
    initialState: {
        modalGlobal: false,
        modalNotificacion: false,
    },
    reducers: {

        abrirModalGlobal: ( state ) => {
            state.modalGlobal = true;
            state.modalNotificacion = false;
        },

        cerrarModalGlobal: ( state ) => {
            state.modalGlobal = false;
            state.modalNotificacion = false;
        },

        abrirModalNotificacion: ( state ) => {
            state.modalGlobal = false;
            state.modalNotificacion = true;
        },

        cerrarModalNotificacion: ( state ) => {
            state.modalGlobal = false;
            state.modalNotificacion = false;
        },

    }
});




export const { abrirModalGlobal, cerrarModalGlobal, abrirModalNotificacion, cerrarModalNotificacion } = modalGlobalSlice.actions;
