
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { useDescuentoStore, useDistribucionStore } from '../../../../hooks';
import { DescuentosPorGalponLote } from '../../../layout';
import { ContenedorDeTabs, ModalAddDescuentosLote } from '../components';

import { separadorMillares } from '../../../../hooks/hooks';





export const DetallesTotalesLote = () => {


    const { id } = useParams();
    const { venta, mortalidad, traslado, descuento } = useDescuentoStore();
    const { activeDistribucion, setActiveDistribucion } = useDistribucionStore();


    const [total, setTotal] = useState(0);



    useEffect(() => {
        if(id){
            setActiveDistribucion(id);
        }
    }, [id])



    useEffect(() => {
        const v = venta.reduce( (total, can) => total + can.cantidad, 0 );
        const m = mortalidad.reduce( (total, can) => total + can.cantidad, 0 );
        const t = traslado.reduce( (total, can) => total + can.cantidad, 0 );
        const d = descuento.reduce( (total, can) => total + can.cantidad, 0 );
        const todo = v + m + t + d;
        setTotal(todo);
    }, [venta, mortalidad, traslado, descuento])




    return (

        <DescuentosPorGalponLote title='Informacion de Descuentos Por Galpon' url={-1}>
            
            {
            activeDistribucion &&
            <TableContainer>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>LOTE</TableCell>
                    <TableCell>GALPON</TableCell>
                    <TableCell>GENERO POLLOS</TableCell>
                    <TableCell>CANTIDAD TOTAL</TableCell>
                    <TableCell>SALDO AVES</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        <TableCell>{ activeDistribucion.lote.lote }</TableCell>
                        <TableCell>{ activeDistribucion.galpon.nombre }</TableCell>
                        <TableCell>{ activeDistribucion.genero }</TableCell>
                        <TableCell>{ separadorMillares(activeDistribucion.cantidad) }</TableCell>
                        <TableCell>{ separadorMillares(activeDistribucion.cantidad - total) }</TableCell>

                    </TableRow>
                </TableBody>

                </Table>
            </TableContainer>
            }

            
            {/* Contenedor de Tabs */}
            <ContenedorDeTabs />



            {/* Mdal de Descuentos */}
            <ModalAddDescuentosLote />


        </DescuentosPorGalponLote>

        
    )




}
