
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Button, Grid, MenuItem, TextField, Typography, Box, Backdrop, Modal } from '@mui/material';
import { useModalStore, useBodegaStore, useNotificacionStore } from '../../hooks';

import moment from 'moment';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const initialState = {
    cantidad: '',
    fecha: '',
    de: '',
    para: '',
    producto: ''
};



export const ModalEnviarProducto = () => {


    const { isModalOpen, closeModal } = useModalStore();
    const { bodegas, startLoadingBodegas } = useBodegaStore();
    const { startSavingNotificacion } = useNotificacionStore();
    const { activeInventario } = useSelector(state => state.inventario);


    const [formEnviar, setFormEnviar] = useState( initialState );
    const { cantidad, para } = formEnviar;


    const handleInputChange = ({ target }) => {
        setFormEnviar({
            ...formEnviar,
            [target.name]: target.value
        })
    }


    useEffect(() => {
        if( activeInventario ){
            setFormEnviar({
                ...formEnviar,
                de: activeInventario.bodega._id,
                producto: activeInventario.producto._id,
            });
        }
    }, [activeInventario]);


    useEffect(() => {
        startLoadingBodegas();
    }, [] );



    const handleEnviarProducto = async(e) => {
        e.preventDefault();
    
        await startSavingNotificacion({
            ...formEnviar,
            fechaInicio: moment().toDate()
        });
        setFormEnviar( initialState );
        closeModal();
    }


    return (
        <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ isModalOpen }
            onClose={ closeModal }
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Box sx={ style }>
                
                <Typography align='center' variant='h1' sx={{ mb:2 }} >Envio de Producto</Typography>

                <form  onSubmit={ handleEnviarProducto } >
                    <Grid container spacing={ 2 }>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                            <TextField required
                                label='Cantidad'
                                type='number'
                                placeholder='Cantidad'
                                fullWidth
                                name='cantidad'
                                value={ cantidad }
                                onChange={ handleInputChange }
                            />
                        </Grid>

                        <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                            <TextField required
                                id="outlined-select-currency"
                                select
                                label="Bodega"
                                fullWidth
                                name='para'
                                value={ para }
                                onChange={ handleInputChange }
                            >
                                <MenuItem value=''>
                                    Seleccione Bodega
                                </MenuItem>
                                { bodegas
                                    .filter( bog => bog.id !== activeInventario?.bodega._id )
                                    .map( b => (
                                    <MenuItem key={ b.id } value={ b.id }>
                                        { b.nombre }
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                            <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                <Button variant='contained' fullWidth type='submit'>
                                Enviar Producto
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                </form>
            </Box>
        </Modal>
        </div>
    );







}