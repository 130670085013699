

import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import { onLoandingSugerido } from "../store/consumo/sugeridoSlice";
import Swal from "sweetalert2";



export const useSugeridoStore = () => {


    const dispatch = useDispatch();
    const { sugeridos } = useSelector( state => state.sugerido );

    
    // const setActiveGranja = async( uid ) => {
    //     try {
    //         const { data } = await granjaApi.get(`/granja/${ uid }`);
    //         dispatch( onSetActiveConsumo( data.granja ) );
            
    //     } catch (error) {
    //         Swal.fire('Error', error.response.data.msg, 'error');
    //         console.log(error)
    //     }
    // }


    // const startSavingConsumo = async( consumo ) => {
    //     try {
    //         const { data } = await granjaApi.post('/consumo/new', { ...consumo } );
    //         if( data.ok ){
    //             dispatch( onAddNewConsumo( data.consumo ) );
    //             Swal.fire('Exitoso', 'Consumo Creado', 'success');
    //         }

    //     } catch (error) {
    //         console.log(error);
    //         Swal.fire('Error', error.response.data.msg, 'error');
    //     }
    // } 


    
    const startLoadingSugerido = async(genero) => {
        try {
            const { data } = await granjaApi.get(`/sugerido/${ genero }`);
            dispatch( onLoandingSugerido(data.sugerido) );
        } catch (error) {
            console.log(error);
        }
    }




    return {
        //Propiedades
        sugeridos,

        //Metodos
        startLoadingSugerido,
    }


}
