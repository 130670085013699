import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
// import FindInPageIcon from '@mui/icons-material/FindInPage';

import { BuscarEncubadora } from "./components";
import { LayoutAdd } from "../../layout";
import { useEncubadoraStore } from "../../../hooks";
import { AlertScreen } from "../../components/AlertScreen";

import Swal from "sweetalert2";




export const ListadoEncubadora = () => {

    const navigate = useNavigate();
    const { encubadoras, startLoadingEncubadora, deleteEncubadora } = useEncubadoraStore();


    useEffect(() => {
        startLoadingEncubadora();
    }, []);

    
    
    const eliminarEncubadora = ( encu ) => {
      Swal.fire({
          title: 'Eliminar Incubadora',
          text: `¿Esta Seguro De Eliminar a: ${ encu.nombre }?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Eliminar' 
      }).then( ( result ) => {
          if(result.value){
            deleteEncubadora( encu );
          }
      });
    }


    const actualizarEncubadora = ( uid ) => {
      navigate(`/admin/encuba-update/${ uid }`);
    }


    return (

        
        <LayoutAdd title='Listado Incubadoras' url='/admin/encuba' >


            <BuscarEncubadora />

            { ( encubadoras.length > 0 ) 
            ?<TableContainer>
                <Table sx={{ minWidth: 700, }}>

                    <TableHead>
                        <TableRow>
                        <TableCell>NOMBRE</TableCell>
                        <TableCell>EMAIL</TableCell>
                        <TableCell>NIT</TableCell>
                        <TableCell>TELEFONO</TableCell>
                        <TableCell>DIRECCION</TableCell>
                        <TableCell>CIUDAD</TableCell>
                        <TableCell>OPCIONES</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { encubadoras.map( ( encuba ) => (
                        <TableRow key={ encuba.id } >
                            <TableCell>{ encuba.nombre }</TableCell>
                            <TableCell>{ encuba.email }</TableCell>
                            <TableCell>{ encuba.nit }</TableCell>
                            <TableCell>{ encuba.telefono }</TableCell>
                            <TableCell>{ encuba.direccion }</TableCell>
                            <TableCell>{ encuba.ciudad }</TableCell>
                            <TableCell>

                                {/* <IconButton >
                                    <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                                </IconButton> */}

                                <IconButton color="success" onClick={ () => actualizarEncubadora( encuba.id ) } >
                                    <UpdateIcon fontSize="small"/>
                                </IconButton>

                                <IconButton color="error" onClick={ () => eliminarEncubadora( encuba ) } >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </TableCell>

                        </TableRow>

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            :<AlertScreen title="No Existen Datos De Encubadoras" />

            }


        </LayoutAdd>

    )




}
