
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, InputAdornment, TextField } from '@mui/material';



export const FormPlanillaCantidadTipoVenta = ({ onChange }) => {



    const cant = JSON.parse(localStorage.getItem('cantidad')) || '';
    const { id } = useParams();


    const [formDescuento, setFormDescuento] = useState({
        cantidad: cant,
        descuento: 'VENTA',
        lotegalpon: '',
    });
    const { cantidad, descuento, lotegalpon } = formDescuento;


    const handleChanges = ({ target }) => {
        setFormDescuento({
            ...formDescuento,
            [target.name]: target.value
        });
    }


    useEffect(() => {
        if( id ){
            setFormDescuento({
                ...formDescuento,
                lotegalpon: id,
            })
        }
    }, [id]);


    useEffect(() => {
        localStorage.setItem('cantidad', JSON.stringify(cantidad) );
    }, [cantidad]);



    useEffect(() => { 
        onChange({ 
            ...formDescuento
        }); 
    }, [formDescuento, onChange]);


    return (

        <form >
            <Grid container spacing={ 2 }>

                <Grid item xs={ 12 } sm={6} mt={ 2 }>
                    <TextField
                        label="Tipo de Descuento"
                        fullWidth
                        disabled
                        name='descuento'
                        value={ descuento }
                    >    
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={6} mt={ 2 } sx={{ display:"none" }}>
                    <TextField
                        label="Lote Galpon"
                        fullWidth
                        name='lotegalpon'
                        value={ lotegalpon }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">Uni.</InputAdornment>,
                        }}
                    >    
                    </TextField>
                </Grid>

                <Grid item xs={ 12 } sm={6} mt={ 2 }>
                    <TextField 
                        label="Cantidad"
                        fullWidth
                        type="number"
                        placeholder='Cantidad'
                        name='cantidad'
                        value={ cantidad }
                        onChange={ handleChanges }
                    >
                    </TextField>
                </Grid>
                    
            </Grid>
        </form>




    )





}
