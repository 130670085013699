
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import OutboundIcon from '@mui/icons-material/Outbound';

import { useModalImgStore, useProductoStore } from "../../../hooks";
import { LayoutAdd } from "../../layout";
import { BuscarProductos, ModalIngresoProductosABodega } from "./components";

import { separadorMillares } from "../../../hooks/hooks/useMillares";
import Swal from "sweetalert2";
import { AlertScreen } from "../../components/AlertScreen";




export const ListadoProductos = () => {


    const navigate = useNavigate();
    const { productos, startLoadingProducto, deleteProducto } = useProductoStore();
    const { openModalIngresoProductoBodega } = useModalImgStore();

    const [idProducto, setIdProducto] = useState('');

    
    useEffect(() => {
      startLoadingProducto();
    }, []);
    


    const eliminarProducto = ( pro ) => {
      Swal.fire({
          title: 'Eliminar Producto',
          text: `¿Esta Seguro De Eliminar: ${ pro.nombre }?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Eliminar' 
      }).then( ( result ) => {
          if(result.value){
            deleteProducto(pro);
          }
      });
    }


    const handleActualizar = (uid) => {
        navigate(`/admin/producto-update/${ uid }`);
    }

    
    const modalIngresoProducto = (uid) => {
        setIdProducto( uid );
        openModalIngresoProductoBodega();
    }




    return (

        <LayoutAdd title='Listado de Productos' url='/admin/producto' >

            <BuscarProductos />

            { ( productos.length > 0 ) 
            ? <TableContainer >
                <Table sx={{ minWidth: 700 }}>

                    <TableHead sx={{ backgroundColor:'red'}}>
                    <TableRow>
                        <TableCell>NOMBRE</TableCell>
                        <TableCell>IVA</TableCell>
                        <TableCell>PRECIO SIN</TableCell>
                        <TableCell>PRECIO CON</TableCell>
                        <TableCell>UNIDAD MEDIDA</TableCell>
                        <TableCell>PROVEEDOR</TableCell>
                        <TableCell>OPCIONES</TableCell>

                    </TableRow>
                    </TableHead>

                    { productos && (
                    <TableBody>
                    { productos.map( (pro) => (
                        <TableRow key={ pro.id } >
                            <TableCell >{ pro.nombre }</TableCell>
                            <TableCell >{ `${ pro.iva }%` }</TableCell>
                            <TableCell>{ `$${separadorMillares(pro.precioSin)}` }</TableCell>
                            <TableCell>{ `$${separadorMillares(pro.precioCon)}` }</TableCell>
                            <TableCell>{ pro.unidadMedida?.nombre }</TableCell>
                            <TableCell>{ pro.proveedor?.nombre }</TableCell>

                            <TableCell sx={{ width:'200px' }}>
                                <Tooltip title="Enviar Producto a Bodega" placement="top">
                                    <IconButton onClick={ () => modalIngresoProducto(pro.id) } >
                                        <OutboundIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Actualizar Datos de Producto" placement="top">
                                    <IconButton color="success" onClick={ () => handleActualizar(pro.id) }>
                                        <UpdateIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>

                                {/* <Tooltip title="Eliminar Producto" placement="top">
                                    <IconButton color="error" onClick={ () => eliminarProducto( pro ) } >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip> */}

                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                    )}

                </Table>
            </TableContainer>

            :<AlertScreen title="No Existen Datos De Productos" />

            }

            <ModalIngresoProductosABodega uid={ idProducto } />

        </LayoutAdd>

    )

}
