


import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Grid } from "@mui/material"
import { InputDePassword } from '../components/InputDePassword';
import { LayoutReturn } from "../../../layout"
import { useAuthStore, useUsuarioStore } from "../../../../hooks";

import Swal from "sweetalert2";






export const CambioDePassword = () => {


    const { startUpdatePassword, } = useUsuarioStore();
    const { user } = useAuthStore();
    const navigate = useNavigate();


    const [passwordAntiguo, setPasswordAntiguo] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmar, setPasswordConfirmar] = useState('');

    // const [formUser, setFormUser] = useState( initialState );
    // const  { password, passwordConfirmar, passwordAntiguo,  } = formUser;
    // const handleInputChange = ({ target }) => {
    //     setFormUser( (f) => ({
    //         ...f,
    //         [target.name]: target.value
    //     }));
    // }

    
    const handleInputChange1 = (e) => {
        setPasswordAntiguo(e.target.value);
    }

    const handleInputChange2 = (e) => {
        setPassword(e.target.value);
    }

    const handleInputChange3 = (e) => {
        setPasswordConfirmar(e.target.value);
    }



    const handleCambioClave = async(e) => {
        e.preventDefault();

        const contemPassword = {
            passwordAntiguo,
            password,
            passwordConfirmar,
            id: user.id
        }

        if( password !== passwordConfirmar ){
            return Swal.fire('Error', 'El Nuevo Password Y El Confirmar Password Deben De Ser Iguales.', 'error')
        }

        if( [passwordAntiguo, password, passwordConfirmar].includes('') ){
            return Swal.fire('Error', 'Todos Los Campos Son Obligatorios.', 'error')
        }
        if( !contemPassword.id ){
            return Swal.fire('Error', 'Ups Algo Salio Mal.', 'error')
        }
            
        const resp = await startUpdatePassword(contemPassword);
        if( resp ){
            setPasswordAntiguo('');
            setPassword('');
            setPasswordConfirmar('');
            navigate('/admin/dashboard');
        }
    }



    return (

        <LayoutReturn title='Cambio de Clave' url='/admin/dashboard' >

            <Grid sx={{ width:'100%' }}   >

                <form onSubmit={ handleCambioClave }>

                <Grid container  sx={{ width:'100%', my:4 }} direccion='column'
                    alignItems='center'
                    justifyContent='center' 
                >


                    <InputDePassword value={passwordAntiguo}
                        placeholder={'Clave Anterior'} 
                        onChangeInput={ handleInputChange1 } 
                    /> 

                    <InputDePassword value={password}
                        placeholder={'Nuevo Password'} 
                        onChangeInput={ handleInputChange2 } 
                    /> 


                    <InputDePassword value={passwordConfirmar}
                        placeholder={'Confirmar Password'} 
                        onChangeInput={ handleInputChange3 } 
                    /> 

                </Grid>
                

                <Grid container sx={{ width:'100%', my:4 }} direccion='column' justifyContent='center' alignItems='center'>
                    <Grid item xs={ 12 } sm={ 6 } >
                        <Button variant='contained' fullWidth type='submit'>
                            Cambiar Password
                        </Button>
                    </Grid>
                </Grid>


                </form>

            </Grid>

        </LayoutReturn>


        
    )




}
