

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useFacturaStore } from "../../../../hooks";
import { separadorMillares } from "../../../../hooks/hooks/useMillares";



export const FacturasTotalesPrecioFiltros = () => {


    const { facturas } = useFacturaStore();


    const [datos, setDatos] = useState({
        total: 0,
        precio: 0,
        abonos: 0,
    });


    useEffect(() => {
        if( facturas ){
            const total = facturas?.length;
            const precio = facturas.reduce( (total, f) => total + f.totalPago, 0 );
            const abonos = facturas.reduce( (total, f) => total + f.totalAbonos, 0 );

            setDatos({
                total,
                precio,
                abonos
            });
        }
    }, [facturas])
    


    return (

        <div>
            <TableContainer>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <TableCell>CANTIDAD DE FACTURAS</TableCell>
                            <TableCell>TOTAL</TableCell>
                            <TableCell>TOTAL DE ABONOS</TableCell>
                            <TableCell>TOTAL SALDOS</TableCell>
                        </TableRow>
                    </TableHead>

                        <TableBody>
                        <TableRow>
                            <TableCell>{ datos.total }</TableCell>   
                            <TableCell>{ `$${ separadorMillares(datos.precio) }` }</TableCell>
                            <TableCell>{ `$${ separadorMillares(datos.abonos) }` }</TableCell>
                            <TableCell>{ `$${ separadorMillares(datos.precio - datos.abonos) }` }</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    )




}
