

import { createSlice } from '@reduxjs/toolkit';



export const loteSlice = createSlice({
    name: 'lote',
    initialState: {
        isLoadingLote: true,
        lotes: [],
        activeLote: null
    },
    reducers:{

        onSetActiveLote: ( state, { payload } ) => {
            state.activeLote = payload;
        },
        
        onAddNewLote: ( state, { payload } ) => {
            state.lotes.push( payload );
            state.activeLote = null;
        },

        onUpdateLote: ( state, { payload } ) => {
            state.lotes = state.lotes.map( lote => {
                if( lote.id === payload.id ){
                    return payload
                }
                return lote
            });
        },

        onLoandingLote: ( state, { payload = [] } ) => {
            state.isLoadingLote = false;
            state.lotes = payload;
        },

        onDeleteLote: ( state, { payload } ) => {
            state.lotes = state.lotes.filter( lote => lote.id !== payload.id );
        }
    }
});





export const { onSetActiveLote, onAddNewLote, onUpdateLote, onLoandingLote, onDeleteLote } = loteSlice.actions;