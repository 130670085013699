
import { useNavigate } from "react-router-dom";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';






export const LayoutAddArchivar = ({ children, title, url, url2 }) => {



    const navigate = useNavigate();


    const crearLote = () => {
        navigate(url);
    }


    const lotesArchivados = () => {
      navigate(url2);
    }





    return (


        <Grid container sx={{ minHeight:'50vh', padding:4 }}>


            <Grid container direccion='row' alignItems='center'>
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ crearLote }
                >
                    <AddOutlined />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h1'>{ title }</Typography>

                <Box flex={ 1 } />

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'primary.main',
                        ':hover': { backgroundColor: 'primary.main', opacity: 0.3 },
                    }}
                    onClick={ lotesArchivados }
                >
                    <SaveIcon />
                </IconButton>
            </Grid>  

            


            { children }






        </Grid>



    )



    
}
