
import { useEffect, useState } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { separadorMillares } from '../../../hooks/hooks/useMillares';
import { useDescarteStore, usePlanillaStore } from '../../../hooks';
import { dejarTresDecimales } from '../../../helpers/dejarDecimales';



export const TablaTotalesPlanilla = () => {

    const { activePlanilla } = usePlanillaStore();
    const { descartes } =useDescarteStore();


    const [totalDescarte, setTotalDescarte] = useState({
        neto: 0,
        precio: 0,
    });
    const { neto, precio } = totalDescarte;

    useEffect(() => {
        const neto = descartes.reduce( (total, can) => Number(total) + Number(can.pesoNeto), 0);
        const precio = descartes.reduce( (total, can) => Number(total) + Number(can.valorFactura), 0);
        setTotalDescarte({
            neto,
            precio
        });
    }, [descartes])
    

    


    const [totales, setTotales] = useState({
        pesoBruto: '',
        totalDestare: '',
        pesoNeto: '',
        promedioEnPie: '',
        valorFactura: '',
        subtotal: '',
        total: '',
    });
    
    const { pesoBruto, totalDestare, pesoNeto, promedioEnPie, valorFactura, subtotal, total } = totales;


    useEffect(() => {
        if( activePlanilla ){
            const subtotal = activePlanilla.mermas.reduce( (total, can) => Number(total) + Number(can.cantidad), 0);
            const pesoBruto = activePlanilla.items.reduce( (total, can) => Number(total) + Number(can.pesoAvesHuacal), 0);
            const totalDestare = activePlanilla.items.reduce( (total, can) => Number(total) + Number(can.destare), 0);
            const pesoNeto = pesoBruto - totalDestare - subtotal - neto;

            setTotales({
                pesoBruto,
                totalDestare,
                pesoNeto,
                subtotal,
                promedioEnPie: pesoNeto / activePlanilla.cantidad,
                valorFactura: activePlanilla.valorKilo * pesoNeto,
                total: activePlanilla.saldoAnterior + (activePlanilla.valorKilo * pesoNeto) + precio,
            })
        }
    }, [activePlanilla, subtotal, neto, precio]);




    return (
        
        <>
        <Typography variant='h5' sx={{ mb:1, mt:5, fontWeight:'bold', textAlign:'center' }} >TOTALES</Typography>

        <TableContainer sx={{mb:3}} >
            <Table>
                <TableHead className='verde'>
                    <TableRow>
                        <TableCell><Typography variant="body1">PESO BRUTO</Typography></TableCell>   
                        <TableCell><Typography variant="body1">TOTAL DESTARE</Typography></TableCell>
                        <TableCell><Typography variant="body1">PESO NETO</Typography></TableCell>
                        <TableCell><Typography variant="body1">PROMEDIO EN PIE</Typography></TableCell>
                        <TableCell><Typography variant="body1">VALOR FACTURA</Typography></TableCell> 
                        <TableCell><Typography variant="body1">TOTAL DESCARTES</Typography></TableCell> 
                        <TableCell><Typography variant="body1">TOTAL</Typography></TableCell> 
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell><Typography variant="body2">{ dejarTresDecimales(pesoBruto) }Kg</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ dejarTresDecimales(totalDestare) }Kg</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ dejarTresDecimales(pesoNeto) }Kg</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ dejarTresDecimales(promedioEnPie) }Kg</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ `$${separadorMillares(valorFactura)}` }</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{`$${separadorMillares(precio)}`}</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ `$${separadorMillares(total)}` }</Typography></TableCell> 
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

        </>

    )



    
}
