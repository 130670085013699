

import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';

import { IconButton } from '@mui/material';
import { blueGrey, pink } from '@mui/material/colors';



export const FooterScreen = () => {

    return (

        

        <footer className="footer">

            <div className="redsocial itemmm">
                <h2 className="titleFooter">
                    Siguenos
                </h2>
                <div className="redes"> 
                    <IconButton variant="plain">
                        <FacebookIcon sx={{ fontSize:45, color: blueGrey[50] }} />
                    </IconButton>  
                    <IconButton variant="plain">
                        <WhatsAppIcon sx={{ fontSize:45, color: blueGrey[50] }} />
                    </IconButton> 
                    <IconButton variant="plain">
                        <InstagramIcon sx={{ fontSize:45, color: blueGrey[50] }} />
                    </IconButton> 
                </div>
            </div>

            {/* <div className="legal itemmm">
                <h2 className="titleFooter">
                    Marco Legal
                </h2>
                <ul className="legal-list">
                    <li><a href="">Política de Datos</a></li>   
                    <li><a href="">Sistema SAGRILAFT</a></li>  
                    <li><a href="">Certificación HACCP</a></li>  
                    <li><a href="">Transparencia y ética</a></li>   
                    <li><a href="">Términos y condiciones</a></li>   
                </ul>
            </div>

            <div className="pakpollo itemmm">
                <h2 className="titleFooter">
                    Acerca de Nosotros
                </h2>
                <ul className="legal-list">
                    <li><a href="">Acerca de nosotros</a></li> 
                    <li><a href="">Trabaje con nosotros</a></li> 
                    <li><a href="">Contactanos</a></li> 
                    <li><a routerLink="/home/cliente">Hacer Pedido</a></li> 
                    <li><a href="">Descargar app Conductores</a></li> 
                </ul>
            </div> */}

        </footer>

    )



}
