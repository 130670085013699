

import { createSlice } from '@reduxjs/toolkit';



export const usuarioSlice = createSlice({

    name: 'usuario',
    initialState: {
        isLoadingUsuario: true,
        usuarios: [],
        clientes: [],
        conductores: [],
        activeUsuario: null
    },
    reducers:{
        onSetActiveUsuario: ( state, { payload } ) => {
            state.activeUsuario = payload;
        },
        
        onAddNewUsuario: ( state, { payload } ) => {
            state.usuarios.push( payload );
            state.activeUsuario = null;
        },

        onUpdateUsuario: ( state, { payload } ) => {
            state.usuarios = state.usuarios.map( user => {
                if( user.id === payload.id ){
                    return payload
                }else {
                    return user
                }
            });
        },

        onLoandingUsuario: ( state, { payload = [] } ) => {
            state.isLoadingUsuario = false;
            state.usuarios = payload;
        },

        onLoandingConductor: ( state, { payload = [] } ) => {
            state.isLoadingUsuario = false;
            state.conductores = payload;
        },


        onLoandingCliente: ( state, { payload = [] } ) => {
            state.isLoadingUsuario = false;
            state.clientes = payload;
        },

        onDeleteUsuario: ( state, { payload } ) => {
            state.usuarios = state.usuarios.filter( user => user.id !== payload.id );
        }
    }
});





export const { onSetActiveUsuario, onAddNewUsuario, onUpdateUsuario, onLoandingUsuario, onDeleteUsuario, onLoandingConductor, onLoandingCliente } = usuarioSlice.actions;