import { Link as RouterLink } from 'react-router-dom';

import { Button, Grid, Link, TextField, Typography } from "@mui/material";
import { AuthLayout } from "../layout/AuthLayout";




export const RegisterScreen = () => {


    return (

        <AuthLayout tittle='Registro'>
            <form>
                <Grid container >

                    <Grid item xs={ 12 } mt={ 2 }>
                        <TextField
                            label='Nombre Completo'
                            type='text'
                            placeholder='Nombre Completo'
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={ 12 } mt={ 2 }>
                        <TextField
                            label='Correo'
                            type='email'
                            placeholder='Correo'
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={ 12 }  mt={ 2 }>
                        <TextField
                            label='Contraseña'
                            type='password'
                            placeholder='Contraseña'
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={ 12 }  mt={ 2 }>
                        <TextField
                            label='Confirmar Contraseña'
                            type='password'
                            placeholder='Confirmar Contraseña'
                            fullWidth
                        />
                    </Grid>

                    <Grid container spacing={ 2 } sx={{ mb:2, mt:2 }} >
                        <Grid item xs={ 12 } >
                            <Button variant='contained' fullWidth >
                                Crear Cuenta
                            </Button>
                        </Grid>
                    </Grid>
                    
                    <Grid container direction='row' justifyContent='end' >
                        <Typography sx={{ mr:1 }}>¿Ya tienes Cuenta? </Typography>
                        <Link component={ RouterLink } to="/auth/login">
                            Ingresar
                        </Link>
                    </Grid>

                </Grid>
            </form>
        </AuthLayout>
    )



}
