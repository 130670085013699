

import { createSlice } from '@reduxjs/toolkit';



export const opSlice = createSlice({
    name: 'op',
    initialState: {
        isLoadingOp: true,
        ops: [],
        activeOp: null
    },
    reducers:{
        onSetActiveOp: ( state, { payload } ) => {
            state.activeOp = payload;
        },
        
        onAddNewOp: ( state, { payload } ) => {
            state.ops.unshift( payload );
            state.activeOp = null;
        },

        onUpdateOp: ( state, { payload } ) => {
            state.ops = state.ops.map( op => {
                if( op.id === payload.id ){
                    return payload
                }
                return op
            });
        },

        onLoandingOp: ( state, { payload = [] } ) => {
            state.isLoadingOp = false;
            state.ops = payload;
            // payload.forEach( op => {
            //     const exists = state.ops.some( dbOp => dbOp.id === op.id );
            //     if( !exists ){
            //         state.ops.push( op );
            //     }
            // })
        },

        onDeleteOp: ( state, { payload } ) => {
            state.ops = state.ops.filter( op => op.id !== payload.id );
        }
    }
});





export const { onSetActiveOp, onAddNewOp, onUpdateOp, onLoandingOp, onDeleteOp } = opSlice.actions;