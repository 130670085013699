

import { createSlice } from '@reduxjs/toolkit';



export const encubadoraSlice = createSlice({
    name: 'encubadora',
    initialState: {
        isLoadingEncubadora: true,
        encubadoras: [],
        activeEncubadora: null
    },
    reducers:{

        onSetActiveEncubadora: ( state, { payload } ) => {
            state.activeEncubadora = payload;
        },
        
        onAddNewEncubadora: ( state, { payload } ) => {
            state.encubadoras.push( payload );
            state.activeEncubadora = null;
        },

        onUpdateEncubadora: ( state, { payload } ) => {
            state.encubadoras = state.encubadoras.map( encubadora => {
                if( encubadora.id === payload.id ){
                    return payload
                }
                return encubadora
            });
        },

        onLoandingEncubadora: ( state, { payload = [] } ) => {
            state.isLoadingEncubadora = false;
            state.encubadoras = payload;
        },

        onDeleteEncubadora: ( state, { payload } ) => {
            state.encubadoras = state.encubadoras.filter( encubadora => encubadora.id !== payload.id );
        }
    }
});





export const { onSetActiveEncubadora, onAddNewEncubadora, onUpdateEncubadora, onLoandingEncubadora, onDeleteEncubadora } = encubadoraSlice.actions;