
import { useState, useEffect } from 'react';
import { Button, Grid, MenuItem, TextField, Typography, Box, Backdrop, Modal } from '@mui/material';
import { useModalStore, useOpStore, usePedidoStore, useProductoStore, useProveedorStore } from '../../hooks';
import { NumberFormatCustom } from '../../hooks/hooks/useInputMillares';






const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};




export const ModalPedido = () => {


    const { modalPedido, closeModalPedido } = useModalStore();
    const millares = NumberFormatCustom;
    const { activeOp } = useOpStore();
    const { activeProducto, actualizarDesdeModal, setActiveProducto, productos, startProductoPorProveedor } = useProductoStore();
    const { activePedido, startSavingPedido, activePedidoEliminar } = usePedidoStore();
    const { proveedores, startLoadingProveedores } = useProveedorStore();



    const [precio, setPrecio] = useState('')
    const [formPedido, setFormPedido] = useState({
        cantidad: '',
        op: '',
        producto: '',
        proveedor: '',
    });
    const { cantidad, producto, proveedor } = formPedido;



    useEffect(() => {
        startLoadingProveedores();
    }, []);
    


    useEffect(() => {
        if( proveedor ){
            startProductoPorProveedor(proveedor);
        }
    }, [proveedor]);





    const handleInputChange = ({ target }) => {
        setFormPedido({
            ...formPedido,
            [target.name]: target.value
        })
    }


    useEffect(() => {
        if( producto ){
            setActiveProducto( producto );
        }
    }, [producto]);


    useEffect(() => {
        if( activeProducto ){
            setPrecio( activeProducto.precioSin );
        }else {
            setPrecio('');
        }
    }, [activeProducto]);



    useEffect(() => {
        if( activeOp ){
            setFormPedido({
                ...formPedido,
                op: activeOp.id
            });
        }
    }, [activeOp]);


    useEffect(() => {
        if( activePedido ){
            setFormPedido({
                ...activePedido,
                op: activeOp.id
            });
        }else {
            setFormPedido({
                ...formPedido,
                id: null,
                cantidad: '',
                producto: ''
            });
        }
    }, [activePedido]);


    const handleSumarPedido = async(e) => {
        e.preventDefault();
        const [ pedido, updateProduc, cerrar ] = await Promise.all([
            startSavingPedido( formPedido ),
            actualizarDesdeModal({
                id: producto,
                precioSin: precio
            }),
            closeModalPedido()
        ]);
        setFormPedido({
            ...formPedido,
            proveedor: '',
            id: null,
            cantidad: '',
            producto: '',
        });
    }


    const cerrarElModal = () => {
        closeModalPedido();
        setFormPedido({
            ...formPedido,
            id: null,
            cantidad: '',
            producto: '',
        });
        activePedidoEliminar();
    }

    return (

        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={ modalPedido }
                onClose={ cerrarElModal }
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Box sx={ style }>

                    <Typography align='center' variant='h4' sx={{ mb:2, fontWeight:'bold'  }} >Añadir A OP</Typography>

                    <form  onSubmit={ handleSumarPedido } >
                        <Grid container spacing={ 2 }>

                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                                <TextField required
                                    select
                                    label="Proveedor"
                                    fullWidth
                                    name='proveedor'
                                    value={ proveedor }
                                    onChange={ handleInputChange }
                                >
                                    <MenuItem value=''>
                                        Seleccione un Proveedor
                                    </MenuItem>
                                    { proveedores
                                        .map( p => (
                                        <MenuItem key={ p.id } value={ p.id }>
                                            { p.nombre }
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            { proveedor &&
                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 }>
                                <TextField required
                                    select
                                    label="Producto"
                                    fullWidth
                                    name='producto'
                                    value={ producto }
                                    onChange={ handleInputChange }
                                >
                                    <MenuItem value=''>
                                        Seleccione Producto
                                    </MenuItem>
                                    { productos
                                        .map( p => (
                                        <MenuItem key={ p.id } value={ p.id }>
                                            { p.nombre }
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            }
                            
                            { activeProducto &&
                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    label='Precio Producto'
                                    placeholder='Precio'
                                    fullWidth
                                    name='precio'
                                    value={ precio }
                                    onChange={ (e) => setPrecio(e.target.value) }
                                    InputProps={{
                                        inputComponent: millares,
                                    }}
                                />
                            </Grid>
                            }

                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    label='Cantidad'
                                    type='number'
                                    placeholder='Cantidad'
                                    fullWidth
                                    name='cantidad'
                                    value={ cantidad }
                                    onChange={ handleInputChange }
                                />
                            </Grid>

                            <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                                <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                    <Button variant='contained' fullWidth type='submit'>
                                        Añadir Producto
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    );

}