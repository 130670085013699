import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2";
import { granjaApi } from "../api";
import { onLoandingGalponConsumo, onSetActiveGalponConsumo } from "../store/galpon-consumo/galponConsumoSlice";



export const useGalponConsumoStore = () => {


    const dispatch = useDispatch();
    const { galponConsumos, activeGalponConsumo } = useSelector( state => state.galponConsumo );



    const setActiveGranja = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/galconsumo/xid/${ uid }`);
            console.log(data);
            // dispatch( onSetActiveGalponConsumo( data.consumos ) );
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error)
        }
    }


    // const startSavingGranja = async( granja ) => {
    //     try {

    //         const { data } = await granjaApi.post('/galconsumo/new', { ...granja } );
    //         if( data.ok ){
    //             dispatch( onAddNewGranja( data.granja ) );
    //             Swal.fire('Exitoso', 'Granja Creada', 'success');
    //         }

    //     } catch (error) {
    //         console.log(error);
    //         Swal.fire('Error', error.response.data.msg, 'error');

    //     }
    // } 


    
    const startLoadingXBodega = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/galconsumo/bodega/${ uid }`);
            dispatch( onLoandingGalponConsumo(data.consumos) );
            
        } catch (error) {
            console.log(error);
        }
    }


    const startLoadingXLoteGalpon = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/galconsumo/lotegal/${ uid }`);
            console.log(data);
            // dispatch( onLoandingGalponConsumo(data.granjas) );
            
        } catch (error) {
            console.log(error);
        }
    }



    // const deleteGranja = async( granja ) => {
    //     try {
    //         const { data } = await granjaApi.delete(`/granja/${ granja.id }`);
    //         if( data.ok ){
    //             dispatch( onDeleteGranja( data.granja ) );
    //             Swal.fire('Exitoso', 'Granja Eliminada', 'success');
    //         }
    //     } catch (error) {
    //         Swal.fire('Error', error.response.data.msg, 'error');
    //         console.log(error);
    //     }
    // }


    return {
        //Propiedades
        galponConsumos,
        activeGalponConsumo,

        //Metodos
        setActiveGranja,
        startLoadingXBodega,
        startLoadingXLoteGalpon,
    }


}
