
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AdminLayout } from "../../../layout/AdminLayout"
import { useGalponConsumoStore } from "../../../../hooks";
import { AlertScreen } from "../../../components/AlertScreen";
import moment from "moment";





export const InformeConsumoPorBodega = () => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { galponConsumos, startLoadingXBodega } = useGalponConsumoStore();



    useEffect(() => {
        if( id ){
            startLoadingXBodega(id);
        }
    }, [id]);
    




    const regresar = () => {
        navigate(-1);
    }



    return (

        
        <AdminLayout>

            <Grid 
                container
                direccion='row'
                alignItems='center'
            >
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ regresar }
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h4' sx={{ mb:2, fontWeight:'bold'  }} >
                    Registros Consumo Por Lotes
                </Typography>


                <Box flex={ 1 } />

            </Grid>

            { ( galponConsumos.length > 0 )
            ?<TableContainer>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            <TableCell>PRODUCTO</TableCell>
                            <TableCell>CANTIDAD</TableCell>
                            <TableCell>CANTIDAD INICIAL</TableCell>
                            <TableCell>CANTIDAD FINAL</TableCell>
                            <TableCell>FECHA</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    { galponConsumos.map( ( gal ) => (
                        <TableRow key={ gal.id } >
                            <TableCell>{ gal.producto?.nombre }</TableCell>   
                            <TableCell>{ gal.cantidad }</TableCell>
                            <TableCell>{ gal.cantidadInicia }</TableCell>
                            <TableCell>{ gal.cantidadFinal }</TableCell>
                            <TableCell>{ moment(gal.createdAt).format('LL') }</TableCell>
                        </TableRow>
                    ))
                    }
                    </TableBody>

                </Table>
            </TableContainer>

            :<AlertScreen title="No Hay Registros" />

            }

        </AdminLayout>

    )

}
