
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LayoutReturn } from "../../layout";
import { Button, Grid, TextField } from "@mui/material";
import { useEncubadoraStore } from "../../../hooks";
import Swal from "sweetalert2";


const initialState = {
    nombre: "",
    telefono: "",
    nit: "",
    direccion: "",
    email: "",
    ciudad: "",
}


export const ActualizarEncubadora = () => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { activeEncubadora, startSavingEncubadora, setActiveEncubadora } = useEncubadoraStore();


    const [formEncubadora, setFormEncubadora] = useState( initialState );
    const  { nombre, telefono, nit, direccion, email, ciudad } = formEncubadora;

    const handleChangeInput = ({ target }) => {
        setFormEncubadora({
            ...formEncubadora,
            [target.name]: target.value
        });
    }


    useEffect(() => {
        if( id ){
            setActiveEncubadora(id);
        }
    }, [id]);

    useEffect(() => {
        if( activeEncubadora ){
            setFormEncubadora( activeEncubadora );
        }
    }, [activeEncubadora]);



    const handleUpdateEncubadora = async(e) => {
        e.preventDefault();
        await Swal.fire({
            title: 'Actualizar Incubadora',
            text: `Esta Seguro De Actualizar a: ${ nombre }`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Actualizar' 
        }).then( ( result ) => {
            if(result.value){
                startSavingEncubadora(formEncubadora);
                navigate('/admin/list-encuba');
            }
        });
    }

    

    return (


        <LayoutReturn title='Actualizar Incubadora' url='/admin/list-encuba' >


            <form onSubmit={ handleUpdateEncubadora } >
                <Grid container spacing={ 2 }>
                
                <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField required
                            label='Nombre'
                            type='text'                                                 
                            placeholder='Nombre'
                            fullWidth                                               
                            name='nombre'
                            value={ nombre }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Email'
                            type='email'
                            placeholder='Email'
                            fullWidth
                            name='email'
                            value={ email }
                            onChange={ handleChangeInput }
                        />
                    </Grid> 

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Nit'
                            type='text'
                            placeholder='Nit'
                            fullWidth
                            name='nit'
                            value={ nit }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Telefono'
                            type='text'
                            placeholder='Telefono'
                            fullWidth
                            name='telefono'
                            value={ telefono }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Direccion'
                            type='text' 
                            placeholder='Direccion'
                            fullWidth
                            name='direccion'
                            value={ direccion }
                            onChange={ handleChangeInput }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField required
                            label='Ciudad'
                            type='text' 
                            placeholder='Ciudad'
                            fullWidth
                            name='ciudad'
                            value={ ciudad }
                            onChange={ handleChangeInput }
                        />
                    </Grid>
                </Grid>

                <Grid container 
                    sx={{ mb:2, mt:2 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit' >
                            Actualizar Incubadora
                        </Button>
                    </Grid>
                </Grid>

            </form>


        </LayoutReturn>


    )


}
