
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Grid, IconButton, Typography,  Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { AdminLayout } from '../../layout/AdminLayout';
import { TablaDestares } from './TablaDestares';
import { useDescuentoStore } from '../../../hooks';
import { TablaDestaresTotales } from './TablaDestaresTotales';
import { FormPlanillaInfoConductor } from './FormPlanillaInfoConductor';
import { FormPlanillaDestaresCantidad } from './FormPlanillaDestaresCantidad';
import { FormPlanillaCantidadTipoVenta } from './FormPlanillaCantidadTipoVenta';
import Swal from 'sweetalert2';





export const PrepararVentaPlanilla = () => {

    const planillas = JSON.parse(localStorage.getItem('planilla')) || [];


    // const { id } = useParams();
    const navigate = useNavigate();
    const { startSavePlanilla } = useDescuentoStore();


    const [items, setItems] = useState(planillas);
    const [formValues, setFormValues] = useState({});
    const [cantidades, setCantidades] = useState({});
    const [valores, setValores] = useState({});


    useEffect(() => {
        localStorage.setItem('planilla', JSON.stringify(items) );
    }, [items]); 



    const onAddItems = (newDato) => {
        setItems([
            newDato,
            ...items,
        ]);
    }



    const handleCrearDescuento = async(e) => {
        e.preventDefault();

        const unidades = items.reduce( (total, can) => Number(total) + (Number(can.huacales) * Number(can.avesXhuacal)), 0);


        if( Number(cantidades.cantidad) > unidades ){
            return Swal.fire('Error', 'Los Items Tienen Que Ser Exactos A La Cantidad En Pollos', 'error');
        }

        if( [formValues.ciudad, formValues.conductor, formValues.proveedor, formValues.pesador, formValues.horaInicio, formValues.horaFinal].includes('') ){
            return Swal.fire('Error', 'Verificar El Formulario Hay Datos Pendientes', 'error');
        }

        if( [valores.saldoAnterior, valores.valorKilo].includes('') ){
            return Swal.fire('Error', 'El Saldo Anterior o Valor x Kilo Son Campos Obligatorios', 'error');
        }

        const obtenerDatos = {
            descuento: cantidades,
            planilla: items,
            totales: valores,
            datosPersonales: formValues
        }

        await Swal.fire({
            title: `Crear Venta Y Planilla`,
            text: `¿Deseas Hacer Este Ingreso?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si' 
        }).then( ( result ) => {
            if(result.value){
                startSavePlanilla( obtenerDatos );
                localStorage.removeItem('planilla');
                localStorage.removeItem('cantidad');
                localStorage.removeItem('totales');
            }
        });
        window.location.reload();
    }



    const regresar = () => {
        navigate(-1);
    }


    return (

        <AdminLayout>
            <Grid container direccion='row' alignItems='center'>

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ regresar }
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                    <Typography variant='h4' sx={{ mb:2, fontWeight:'bold' }} >Crear Planilla</Typography>

                <Box flex={ 1 } />

            </Grid>

            <Box sx={{ my:3 }}>


                <FormPlanillaInfoConductor onChange={setFormValues} />

                <FormPlanillaCantidadTipoVenta onChange={setCantidades} />

                <FormPlanillaDestaresCantidad datosEnviadosDos={onAddItems} />


                
                { ( items.length > 0 ) && 
                <>
                    <TablaDestaresTotales destares={items} onChange={setValores} />

                    <TablaDestares destares={ items } setItems={ setItems } />
                </>
                }



                <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                    <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                        <Button variant='contained' fullWidth type='submit' onClick={ handleCrearDescuento } >
                            Crear Planilla
                        </Button>
                    </Grid>
                </Grid>

            </Box>

        </AdminLayout>



    )



}
