import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import io from 'socket.io-client';
import Swal from "sweetalert2";
import { onAddNewInventario, onDeleteInventario, onDeleteNotificacion, onLoandingInventario, onSetActiveInventario, onSetClearListaInventario } from "../store";

const baseSocket = process.env.REACT_APP_SOCKET_IO_URL;


let socket;


export const useInventarioStore = () => {


    const dispatch = useDispatch();
    const { inventarios, activeInventario } = useSelector( state => state.inventario );


    useEffect(() => {
        socket = io(baseSocket);
    }, []);



    const setActivInventario = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/inventario/porid/${ uid }`);
            dispatch( onSetActiveInventario( data.inventario ) );
            
        } catch (error) {
            console.log(error)
        }
    }



    const startCrearInventario = async( inv ) => {
        try {
            const { data } = await granjaApi.post('/inventario/adicional', { ...inv } );
            if( data.ok ){
                // dispatch( onAddNewInventario( data.inventario ) );
                // dispatch( onDeleteNotificacion({ id: inv.notificacion }) );
                Swal.fire('Exitoso', 'Sumado Al Inventario', 'success');
                 // Socket io
                socket.emit('nuevo-inventario', data.inventario );
                socket.emit('eliminar-notificacion', { id: inv.notificacion });
            }

        } catch (error) {
            console.log(error);
        }
    } 



    const startCrearInventarioProducto = async( inv ) => {
        try {
            const { data } = await granjaApi.post('/inventario/adicional-produc', { ...inv } );
            if( data.ok ){
                dispatch( onAddNewInventario( data.inventario ) );
                Swal.fire('Exitoso', 'Sumado Al Inventario', 'success');
                //  // Socket io
                // socket.emit('nuevo-inventario', data.inventario );
                // socket.emit('eliminar-notificacion', { id: inv.notificacion });
            }

        } catch (error) {
            console.log(error);
        }
    } 


    
    const startLoadingInventario = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/inventario/porbodega/${ uid }`);
            dispatch( onLoandingInventario( data.inventario ) );
            
        } catch (error) {
            console.log('Error al Cargar bodegas');
            console.log(error);
        }
    }

    
    const LimpiarListaInventario = async( ) => {
        dispatch( onSetClearListaInventario() );
    }



    const deleteInventario = async( inv ) => {
        try {
            const { data } = await granjaApi.delete(`/inventario/${ inv.id }`);
            if( data.ok ){
                dispatch( onDeleteInventario( data.inventario ) );
                Swal.fire('Exitoso', 'Producto Eliminada de Bodega', 'success');
            }
        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    }



    const inventarioAgregadoDeSocket = ( inv ) => {
        dispatch( onAddNewInventario( inv ) );
    }


    return {
        //Propiedades
        inventarios,
        activeInventario,

        //Metodos
        startLoadingInventario,
        setActivInventario,
        deleteInventario,
        startCrearInventario,
        startCrearInventarioProducto,
        LimpiarListaInventario,
        inventarioAgregadoDeSocket,
    }


}
