
import QrCode2Icon from '@mui/icons-material/QrCode2';
import AppleIcon from '@mui/icons-material/Apple';
import { AccountCircleOutlined,  ConfirmationNumberOutlined,  LoginOutlined, VpnKeyOutlined } from "@mui/icons-material";

import GiteIcon from '@mui/icons-material/Gite';
import AssessmentIcon from '@mui/icons-material/Assessment';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import DescriptionIcon from '@mui/icons-material/Description';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import PersonPinIcon from '@mui/icons-material/PersonPin';


export const retornarMenu = ( role ) => {

    let menu = [];

    if( role === 'SUPERADMIN' || role === 'ADMIN' ) {
        return menu = [
            {
                icono:  <ConfirmationNumberOutlined />,
                titulo: 'Dashboard',
                url: '/admin/list-dashboard'
            },
            {
                icono:  <PeopleAltIcon />,
                titulo: 'Usuarios',
                url: '/admin/list-user'
            },
            {
                icono:  <PersonPinIcon />,
                titulo: 'Clientes',
                url: '/admin/list-cliente'
            },
            {
                icono:  <AirportShuttleIcon />,
                titulo: 'Vehiculos',
                url: '/admin/list-vehiculo',
            },
            {
                icono:  <DescriptionIcon />,
                titulo: 'Orden Pedido',
                url: '/admin/ops'
            },
            {
                icono:  <AssignmentTurnedInIcon />,
                titulo: 'Facturas',
                url: '/admin/facturas'
            },
            {
                icono:  <GiteIcon />,
                titulo: 'Granjas',
                url: '/admin/list-granjas'
            },
            {
                icono:  <WarehouseIcon />,
                titulo: 'Galpones',
                url: '/admin/list-galpones'
            },
            {
                icono:  <AssessmentIcon />,
                titulo: 'Incubadora',
                url: '/admin/list-encuba'
            },
            {
                icono:  <AssessmentIcon />,
                titulo: 'Lotes',
                url: '/admin/list-lotes'
            },
            {
                icono:  <HolidayVillageIcon />,
                titulo: 'Bodegas',
                url: '/admin/list-bodegas'
            },
            {
                icono:  <SquareFootIcon />,
                titulo: 'Unidad Medida',
                url: '/admin/list-unimedida'
            },
            {
                icono:  <QrCode2Icon />,
                titulo: 'Productos',
                url: '/admin/list-producto'
            },
            {
                icono:  <AppleIcon />,
                titulo: 'Proveedores',
                url: '/admin/list-proveedor'
            },
        ]
    } else if( role === 'GALPONERO' ){
        return menu = [
            {
                icono:  <ConfirmationNumberOutlined />,
                titulo: 'Dashboard',
            },
            {
                icono:  <DescriptionIcon />,
                titulo: 'Orden Pedido',
                url: '/admin/ops'
            },
            {
                icono:  <GiteIcon />,
                titulo: 'Granjas',
                url: '/admin/list-granjas'
            },
            {
                icono:  <WarehouseIcon />,
                titulo: 'Galpones',
                url: '/admin/list-galpones'
            },
            {
                icono:  <AssessmentIcon />,
                titulo: 'Incubadora',
                url: '/admin/list-encuba'
            },
            {
                icono:  <AssessmentIcon />,
                titulo: 'Lotes',
                url: '/admin/list-lotes'
            },
            {
                icono:  <HolidayVillageIcon />,
                titulo: 'Bodegas',
                url: '/admin/list-bodegas'
            },
        ]
    } else if( role === 'CONDUCTOR' ){
        return menu = [
            {
                icono:  <ConfirmationNumberOutlined />,
                titulo: 'Dashboard',
            },
            {
                icono:  <DescriptionIcon />,
                titulo: 'Orden Pedido',
                url: '/admin/ops'
            },
        ]
    } else if( role === 'SECRETARIA' ){
        return menu = [
            {
                icono:  <ConfirmationNumberOutlined />,
                titulo: 'Dashboard',
            },
            {
                icono:  <AirportShuttleIcon />,
                titulo: 'Vehiculos',
                url: '/admin/list-vehiculo',
            },
            {
                icono:  <DescriptionIcon />,
                titulo: 'Orden Pedido',
                url: '/admin/ops'
            },
            {
                icono:  <AssignmentTurnedInIcon />,
                titulo: 'Facturas',
                url: '/admin/facturas'
            },
            {
                icono:  <GiteIcon />,
                titulo: 'Granjas',
                url: '/admin/list-granjas'
            },
            {
                icono:  <WarehouseIcon />,
                titulo: 'Galpones',
                url: '/admin/list-galpones'
            },
            {
                icono:  <AssessmentIcon />,
                titulo: 'Incubadora',
                url: '/admin/list-encuba'
            },
            {
                icono:  <AssessmentIcon />,
                titulo: 'Lotes',
                url: '/admin/list-lotes'
            },
            {
                icono:  <HolidayVillageIcon />,
                titulo: 'Bodegas',
                url: '/admin/list-bodegas'
            },
            {
                icono:  <SquareFootIcon />,
                titulo: 'Unidad Medida',
                url: '/admin/list-unimedida'
            },
            {
                icono:  <QrCode2Icon />,
                titulo: 'Productos',
                url: '/admin/list-producto'
            },
            {
                icono:  <AppleIcon />,
                titulo: 'Proveedores',
                url: '/admin/list-proveedor'
            },
        ]
    } else if( role === 'ADMIN-GRANJA' ){
        return menu = [
            {
                icono:  <ConfirmationNumberOutlined />,
                titulo: 'Dashboard',
            },
            {
                icono:  <DescriptionIcon />,
                titulo: 'Orden Pedido',
                url: '/admin/ops'
            },
            {
                icono:  <AssessmentIcon />,
                titulo: 'Lotes',
                url: '/admin/list-lotes'
            },
            {
                icono:  <HolidayVillageIcon />,
                titulo: 'Bodegas',
                url: '/admin/list-bodegas'
            },
        ]
    }


}


