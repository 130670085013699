

import { AppBar, Box, Link, Toolbar, Typography, Button } from "@mui/material"
import { useNavigate } from "react-router-dom";



// const navItems = ['Home', 'About', 'Contact'];



export const NavBarScreen = () => {


    const navigate = useNavigate();


    const loginUser = () => {
        navigate('/auth/login')
    }



    const navItems = [
        { name: 'Inicio', accion: null },
        { name: 'About', accion: null },
        { name: 'Login', accion: loginUser }
    ];



    return (
        
        <AppBar sx={{ height: '80px', display:'flex', justifyContent:'center' }}>
            <Toolbar>

            <Link >
                <Typography variant="h5" sx={{ color: '#fff' }}>C.P.P.</Typography>
            </Link>

            <Box flex={ 1 } />

            {/* xs: 'none', */}
            <Box sx={{ display:{ xs: 'block', height:'100%' } }}>
                { navItems.map( (item) => (
                <Button key={ item.name } sx={{ color: '#fff', height:'100%', px:'20px' }} onClick={ item.accion } >
                    { item.name }
                </Button>
                ))}
            </Box>


            </Toolbar>
        </AppBar>
    );
}



// NavBarScreen.propTypes = {
//     /**
//      * Injected by the documentation to work in an iframe.
//      * You won't need it on your project.
//      */
//     window: PropTypes.func,
// };
