
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import { useBodegaStore } from "../../../hooks/useBodegaStore";

import { LayoutAddInfo } from "../../layout";
import Swal from 'sweetalert2';




export const ListadoBodegas = () => {


    const navigate = useNavigate();
    const { bodegas, startLoadingBodegas, deleteBodega } = useBodegaStore();


    useEffect(() => {
      startLoadingBodegas();
    }, [] );
    

    const eliminarBodega = ( bodega ) => {
      Swal.fire({
          title: '¿Eliminar Bodega?',
          text: `Esta Seguro De Eliminar a: ${ bodega.nombre }`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Eliminar' 
      }).then( ( result ) => {
          if(result.value){
            deleteBodega( bodega );
          }
      });
    }



    const handleActualizarBodega = ( uid ) => {
      navigate(`/admin/bodega-update/${ uid }`);
    }


    const handleVerInventario = ( uid ) => {
      navigate(`/admin/inventario/${ uid }`);
    }


    

  return (


    <LayoutAddInfo title='Listado de Bodegas' url='/admin/bodega' url2='/admin/todo-inventario' >

        <TableContainer>
          <Table sx={{ minWidth: 700, }} aria-label="customized table">

            <TableHead >
              <TableRow>
                <TableCell>NOMBRE</TableCell>
                <TableCell>GRANJA</TableCell>
                <TableCell>ALTO</TableCell>
                <TableCell>ANCHO</TableCell>
                <TableCell>LARGO</TableCell>
                <TableCell>AREA</TableCell>
                <TableCell>OPCIONES</TableCell>
              </TableRow>
            </TableHead>

            { bodegas &&(
            <TableBody>
              { bodegas.map( ( bodega ) => (

                <TableRow key={ bodega.id } >

                  <TableCell>{ bodega.nombre }</TableCell>   
                  <TableCell>{ bodega.granja?.nombre }</TableCell>
                  <TableCell>{ bodega.alto }</TableCell>
                  <TableCell>{ bodega.ancho }</TableCell>
                  <TableCell>{ bodega.largo }</TableCell>
                  <TableCell>{ bodega.area }</TableCell>

                  <TableCell sx={{ width:'200px' }}>
                    <Tooltip title="Ver Productos De Bodega" placement="top">
                      <IconButton onClick={ () => handleVerInventario(bodega.id) } >
                        <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Actualizar Información De Bodega" placement="top">
                      <IconButton color="success" onClick={ () => handleActualizarBodega( bodega.id ) } >
                        <UpdateIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Eliminar Bodega" placement="top">
                      <IconButton color="error" onClick={ () => eliminarBodega( bodega ) } >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>

                </TableRow>
              ))
              }
            </TableBody>
            )}

          </Table>
        </TableContainer>

    </LayoutAddInfo>


  )



}
