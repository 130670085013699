
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import CancelIcon from '@mui/icons-material/Cancel';

import { usePlanillaStore } from "../../../hooks";
import { separadorMillares } from "../../../hooks/hooks/useMillares";
import { AlertScreen } from "../../components";

import moment from "moment";
import { OpcionesDeBusqueda } from "../planillas/OpcionesDeBusqueda";
import { resultadoMermas } from "../../../helpers/resultadoMermas";
import Swal from "sweetalert2";
import { dejarTresDecimales } from "../../../helpers/dejarDecimales";



export const PlanillaReporte = () => {


    const { id } = useParams();
    const navigate = useNavigate();
    const { planillas, startLoadingPlanillaLoteGalpon, updatePlanillaBloquearMermas } = usePlanillaStore();


    useEffect(() => {
      if( id ){
        startLoadingPlanillaLoteGalpon(id);
      }
    }, [id])


    const verPlanilla = (uid) => {
      navigate(`/admin/ver-planilla/${ uid }`);
    }


    const verAbonosPlanilla = (uid) => {
      navigate(`/admin/abonosxplanilla/${ uid }`);
    }


    const bloquearMermas = (uid) => {
      Swal.fire({
        title: 'Bloquear Mermas',
        text: `¿Esta Seguro De Bloquear Las Mermas Para Esta Planilla?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si, Bloquear' 
      }).then( ( result ) => {
        if(result.value){
          updatePlanillaBloquearMermas( uid );
        }
      });

    }
  


    return (


      <>
        
        { ( planillas?.length > 0 )
          ?<>
              <OpcionesDeBusqueda />

              <TableContainer sx={{my:3}} >
                <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>#PLANILLA</TableCell>
                          <TableCell>FECHA</TableCell>   
                          <TableCell>CANTIDAD</TableCell>
                          <TableCell>CLIENTE</TableCell>
                          <TableCell>VALOR KILO</TableCell>   
                          <TableCell>TOTAL MERMAS</TableCell>
                          <TableCell>PESO NETO</TableCell>
                          <TableCell>VALOR TOTAL</TableCell>
                          <TableCell>OPCIONES</TableCell>   
                      </TableRow>
                  </TableHead>

                  <TableBody>
                      {
                      planillas.map( p => (
                      <TableRow key={ p.id } >
                          <TableCell>{ p.numero }</TableCell>   
                          <TableCell>{ moment(p.createdAt).format('L') }</TableCell>
                          <TableCell>{ p.cantidad }</TableCell>   
                          <TableCell>{ p.cliente.nombre }</TableCell> 
                          <TableCell>{ separadorMillares(p.valorKilo) }</TableCell>   
                          <TableCell>{ (p.pesoNeto > 0 ) ? dejarTresDecimales(p.pesoNeto) : 0 }Kg</TableCell> 
                          <TableCell>{ resultadoMermas(p.mermas) }Kg</TableCell>
                          <TableCell>{ (p.pesoNeto > 0 ) ? `$${separadorMillares(p.valorKilo * (p.pesoNeto - resultadoMermas(p.mermas)))}` : 0 }</TableCell>   
                          <TableCell>

                          <Tooltip title="Ver Planilla" placement="top">
                            <IconButton onClick={ () => verPlanilla( p.id ) } >
                              <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                            </IconButton>
                          </Tooltip>  

                          <Tooltip title="Ver Abonos A Planilla" placement="top">
                            <IconButton color="success" onClick={ () => verAbonosPlanilla(p.id) }>
                              <RequestPageIcon  fontSize="small"/>
                            </IconButton> 
                          </Tooltip>
                          
                          { (p.cerrarMermas)
                          ?<Tooltip title="Bloquear Mermas" placement="top">
                            <IconButton color="error" onClick={ () => bloquearMermas( p.id ) } >
                              <StopCircleIcon  fontSize="small"/>
                            </IconButton>  
                          </Tooltip>

                          :<Tooltip title="Mermas Bloqueadas" placement="top">
                            <IconButton color="error" >
                              <CancelIcon  fontSize="small"/>
                            </IconButton>  
                          </Tooltip>
                          }
                          </TableCell>

                      </TableRow>
                      ))
                      }
                  </TableBody>
                </Table>
            </TableContainer>
          </>

          :<AlertScreen title={`No hay registro de Planillas.`} />

        }

      </>


    )





}
