
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2";
import { granjaApi } from "../api";
import { onAddNewProveedor, onDeleteProveedor, onLoandingProveedor, onSetActiveProveedor, onUpdateProveedor } from "../store";





export const useProveedorStore = () => {
  
    
    const dispatch = useDispatch();
    const { proveedores, activeProveedor } = useSelector( state => state.proveedor );


    const setActiveProveedor = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/proveedor/porid/${ uid }`);
            dispatch( onSetActiveProveedor( data.proveedor ) );
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error)
        }
    }


    const startSavingProveedor = async( proveedor ) => {
        try {

            if( proveedor.id ){
                const { data } = await granjaApi.put(`/proveedor/update/${ proveedor.id }`, proveedor );
                if( data.ok ){
                    dispatch( onUpdateProveedor( data.proveedor ) );
                    Swal.fire('Exitoso', 'Proveedor Actualizado', 'success');
                }

            }else {
                const { data } = await granjaApi.post('/proveedor/new', proveedor );
                if( data.ok ){
                    dispatch( onAddNewProveedor( data.proveedor ) );
                    Swal.fire('Exitoso', 'Proveedor Creado', 'success');
                }
            }

        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    } 


    const startLoadingProveedores = async() => {
        try {
            const { data } = await granjaApi.get('/proveedor/todos');
            dispatch( onLoandingProveedor(data.proveedores) );
            
        } catch (error) {
            console.log('Error al Cargar Proveedores');
            console.log(error);
        }
    }


    const deleteProveedor = async( proveedor ) => {
        try {
            const { data } = await granjaApi.delete(`/proveedor/${ proveedor.id }`);
            if( data.ok ){
                dispatch( onDeleteProveedor( proveedor ) );
                Swal.fire('Exitoso', 'Proveedor Eliminado', 'success');
            }
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    return {
        //Propiedades
        proveedores,
        activeProveedor,

        //Metodos
        startSavingProveedor,
        startLoadingProveedores,
        deleteProveedor,
        setActiveProveedor,
    }

}
