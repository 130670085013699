

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import UpdateIcon from '@mui/icons-material/Update';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import { useUsuarioStore } from "../../../hooks";
import { AlertScreen } from "../../components/AlertScreen";
import { BuscarClientes } from "./BuscarClientes";
import { LayoutAdd } from "../../layout";




export const ListadoClientes = () => {


    const navigate = useNavigate();
    const { clientes, startLoadingUsuariosCliente } = useUsuarioStore();


    useEffect(() => {
        startLoadingUsuariosCliente();
    }, []);
    



    const actualizarUsuario = ( user ) => {
        navigate(`/admin/user-update/${ user.id }`);
    }


    const listadoPlanillaClientes = (uid) => {
        navigate(`/admin/planillaxcliente/${ uid }`);
    }



    return (


        <LayoutAdd title='Listado de Clientes' url='/admin/usuario' >



            { ( clientes?.length > 0 )
            ?<>

                <BuscarClientes />

                <TableContainer>
                    <Table sx={{ minWidth: 700, }} aria-label="customized table">

                        <TableHead >
                            <TableRow>
                                <TableCell>NOMBRE</TableCell>
                                <TableCell>EMAIL</TableCell>
                                <TableCell>MOVIL</TableCell>
                                <TableCell>DIRECCION</TableCell>
                                <TableCell>OPCIONES</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                        { clientes.map( ( user ) => (
                            <TableRow  key={ user.id}>

                                <TableCell>{ user.nombre }</TableCell>   
                                <TableCell>{ user.email }</TableCell>
                                <TableCell>{ user.movil }</TableCell>
                                <TableCell>{ user.direccion }</TableCell>

                                <TableCell sx={{ width:'200px' }}>
                                    <Tooltip title="Ver Planillas" placement="top">
                                        <IconButton onClick={ () => listadoPlanillaClientes(user.id) }>
                                            <FindInPageIcon fontSize="small" sx={{ color: '#03a9f4' }}/>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Actualizar Cliente" placement="top">
                                        <IconButton color="success" onClick={ () => actualizarUsuario( user ) }>
                                            <UpdateIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                </TableCell>

                            </TableRow>
                        ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </>

            :<AlertScreen title="No Existen Clientes Creados" />

            }

        </LayoutAdd>

    )


}
