

import { createSlice } from '@reduxjs/toolkit';



export const galponSlice = createSlice({
    name: 'galpon',
    initialState: {
        isLoadingGalpon: true,
        galpones: [],
        activeGalpon: null
    },
    reducers:{
        onSetActiveGalpon: ( state, { payload } ) => {
            state.activeGalpon = payload;
        },
        
        onAddNewGalpon: ( state, { payload } ) => {
            state.galpones.push( payload );
            state.activeGalpon = null;
        },

        onUpdateGalpon: ( state, { payload } ) => {
            state.galpones = state.galpones.map( galpon => {
                if( galpon.id === payload.id ){
                    return payload
                }
                return galpon
            });
        },

        onLoandingGalpon: ( state, { payload = [] } ) => {
            state.isLoadingGalpon = false;
            state.galpones = payload;
        },


        // loandingGalponXGranja: ( state, { payload = [] } ) => {
        //     state.isLoadingGalpon = false;
        //     // state.galpons = payload;
        //     payload.forEach( galpon => {
        //         const exists = state.galpones.some( dbGalpon => dbGalpon.id === galpon.id );
        //         if( !exists ){
        //             state.galpones.push( galpon );
        //         }
        //     })
        // },

        onDeleteGalpon: ( state, { payload } ) => {
            state.galpones = state.galpones.filter( galpon => galpon.id !== payload.id );
        }
    }
});





export const { onSetActiveGalpon, onAddNewGalpon, onUpdateGalpon, onDeleteGalpon, onLoandingGalpon } = galponSlice.actions;