
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AdminRoutes } from '../admin/routes/AdminRoutes';
import { AuthRoutes } from '../auth/routes/AuthRoutes';
import { HomeRoutes } from '../home/routes/HomeRoutes';
import { useAuthStore } from '../hooks';







export const AppRouter = () => {


    const { status, checkAuthToken } = useAuthStore();


    useEffect(() => {
        checkAuthToken();
    }, [])
    


    if( status === 'checking' ){
        return(
            <h2>Cargando...</h2>
        )
    } 

    
    

    return (
        
        <Routes>


            {

            ( status === 'not-authenticated' )
            ?   (
                <>
                    <Route path="/auth/*" element={ <AuthRoutes /> } />
                    <Route path="/*" element={ <Navigate to="/home" /> } />
                </>
                )

            :   (
                <>
                    <Route path="/admin/*" element={ <AdminRoutes /> } />
                    <Route path="/*" element={ <Navigate to="/admin/dasboard" /> } />
                </>
                )
            }

            <Route path="/home/*" element={ <HomeRoutes /> } />
            <Route path="/*" element={ <Navigate to="/home" /> } />


            
        </Routes>

    )




}
