

import { useEffect } from "react";
import { useSelector } from "react-redux";

import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import DangerousIcon from '@mui/icons-material/Dangerous';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { AdminLayout } from "../../layout/AdminLayout";
import { useInventarioStore, useNotificacionStore } from "../../../hooks";

import io from 'socket.io-client';
import Swal from 'sweetalert2';
import moment from "moment";



const baseSocket = process.env.REACT_APP_SOCKET_IO_URL;
let socket;


export const ListaNotificaciones = () => {


    // const navigate = useNavigate();
    const { startCrearInventario, inventarioAgregadoDeSocket } = useInventarioStore();
    const { notificaciones, startLoadingNotificacion, deleteNotificacion, agregarNotaDeSocket, notaEliminadaDeSocket } = useNotificacionStore();


    useEffect(() => {
        startLoadingNotificacion();
    }, [] );
    

    useEffect(() => {
        socket = io(baseSocket);
    }, []);


    useEffect(() => {
        socket.on('notificacion-agregada', (noti) => {
            agregarNotaDeSocket(noti);
        });
    }, []);

    useEffect(() => {
        socket.on('notificacion-eliminada', (noti) => {
            notaEliminadaDeSocket(noti);
        });
    }, []);

    useEffect(() => {
        socket.on('inventario-agregado', ( inv ) => {
            inventarioAgregadoDeSocket(inv);
        });
    }, []);


    const eliminarNotificacion = async( noti ) => {
      await Swal.fire({
          title: 'Eliminar Envio',
          text: `¿Esta Seguro De Eliminar Esta Notificacion?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Eliminar' 
      }).then( ( result ) => {
          if(result.value){
            deleteNotificacion( noti );
          }
      });
    }

    
    const aprobarEnvio = async( noti ) => {
        const { producto, de, para, cantidad, id, dateSolicitud, solicitud } = noti;
        await Swal.fire({
            title: 'Aprobar',
            text: `¿Esta Seguro De Recibir Este Producto?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Aprobar' 
        }).then( ( result ) => {
            if(result.value){
                startCrearInventario({
                    producto: producto._id,
                    de: de._id,
                    para: para._id,
                    stock: cantidad,
                    aprobado: true,
                    notificacion: id,
                    fechaFin: moment().toDate(),
                    dateSolicitud,
                    solicitud
                });
            }
        });
    }


    return (


        <AdminLayout >

            <Grid 
            container
            direccion='row'
            alignItems='center'
            >
                <Box flex={ 1 } />

                <Typography variant='h4' sx={{ mb:2, fontWeight:'bold' }} >Notificaciones Pendientes</Typography>

                <Box flex={ 1 } />
            </Grid>

            <TableContainer>
            <Table sx={{ minWidth: 700, }} aria-label="customized table">

                <TableHead >
                <TableRow>
                    <TableCell>PRODUCTO</TableCell>
                    <TableCell>CANTIDAD</TableCell>
                    <TableCell>ENVIA</TableCell>
                    <TableCell>RECIBE</TableCell>
                    <TableCell>FECHA</TableCell>
                    <TableCell>OPCIONES</TableCell>
                </TableRow>
                </TableHead>

                { notificaciones &&(
                <TableBody>
                { notificaciones.map( ( noti ) => (

                    <TableRow key={ noti.id } >

                    <TableCell>{ noti.producto?.nombre }</TableCell>   
                    <TableCell>{ noti.cantidad }</TableCell>
                    <TableCell>{ noti.de?.nombre }</TableCell>
                    <TableCell>{ noti.para?.nombre }</TableCell>
                    <TableCell>{ noti.createdAt }</TableCell>

                    <TableCell sx={{ width:'200px' }}>

                        <IconButton color="success" onClick={ () => aprobarEnvio( noti ) } >
                            <GppGoodIcon fontSize="small" />
                        </IconButton>

                        <IconButton color="error" onClick={ () => eliminarNotificacion( noti ) } >
                            <DangerousIcon fontSize="small" />
                        </IconButton>
                    </TableCell>

                    </TableRow>
                ))
                }
                </TableBody>
                )}

            </Table>
            </TableContainer>

        </AdminLayout>

    )


    
}
