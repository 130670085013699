
import { useState } from 'react';

import {  Button, Grid, TextField } from '@mui/material';
import { useGranjaStore } from '../../../hooks';
import { LayoutReturn } from '../../layout';


const initialState = {
    nombre: '',
    metros: '',
    direccion: '',
    canGalpones: 0,
    canBodegas: 0,
    propietario: '',
    ciudad: '',
    departamento: '',
};

export const GranjasScreen = () => {


    const { startSavingGranja } = useGranjaStore();


    const [formGranja, setFormGranja] = useState( initialState );
    const { nombre, metros, direccion, propietario, ciudad, departamento } = formGranja;

    const  handleInputChange = ({ target }) => {
        setFormGranja({
            ...formGranja,
            [target.name]: target.value
        });
    }


    const crearGranja = async(e) => {
        e.preventDefault();

        await startSavingGranja( formGranja );
        setFormGranja( initialState );
    }


    return (

        <LayoutReturn title='Crear Granja' url='/admin/list-granjas' >

            <form onSubmit={ crearGranja } >
                <Grid container spacing={ 2 }>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField
                            label='Nombre Granja'
                            type='text'
                            placeholder='Nombre Granja'
                            fullWidth
                            name='nombre'
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField
                            label='Metros Cuadrados'
                            type='number'
                            placeholder='Metros Cuadrados'
                            fullWidth
                            name='metros'
                            value={ metros }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField
                            label='Direccion'
                            type='text'
                            placeholder='Direccion'
                            fullWidth
                            name='direccion'
                            value={ direccion }
                            onChange={ handleInputChange }
                        />
                    </Grid>


                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField
                            label='Propietario'
                            type='text'
                            placeholder='Propietario'
                            fullWidth
                            name='propietario'
                            value={ propietario }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 } >
                        <TextField
                            label='Ciudad'
                            type='text'
                            placeholder='Ciudad'
                            fullWidth
                            name='ciudad'
                            value={ ciudad }
                            onChange={ handleInputChange }
                        />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } mt={ 2 }>
                        <TextField
                            label='Departamento'
                            type='text'
                            placeholder='Departamento'
                            fullWidth
                            name='departamento'
                            value={ departamento }
                            onChange={ handleInputChange }
                        />
                    </Grid>
                    
                </Grid>

                <Grid container 
                    sx={{ mb:2, mt:2 }} 
                    direccion='column'
                    justifyContent='center' 
                >
                    <Grid item xs={ 12 } sm={ 8 } >
                        <Button variant='contained' fullWidth type='submit'>
                            Crear Granja
                        </Button>
                    </Grid>
                </Grid>

            </form>
            
        </LayoutReturn>


    )



}
