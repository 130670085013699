

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useDescuentoStore } from '../../../hooks';
import moment from "moment";




const now = moment().hours(0).minutes(0).seconds(0);
const nowPlus1 = now.clone().add(24, 'hours').subtract(1, 'seconds');



export const FiltroPorFecha = ({ nombre }) => {

    
    const { id } = useParams();
    const { busquedaPorFiltroCalendario } = useDescuentoStore(); 

    const [formValues, setFormValues] = useState({
        nombre: '',
        uid: id,
        start: now.toDate(),
        end: nowPlus1.toDate(),
    });
    const { start, end } = formValues;


    const handleChangeStart = (e) => {
        setFormValues({
            ...formValues,
            start: e.$d
        });
    }

    const handleChangeEnd = (e) => {
        // const resultado = moment(e.$d).add(1, 'days').subtract(1, 'seconds');
        const resultado = moment(e.$d).hours(0).minutes(0).seconds(0);
        setFormValues({
            ...formValues,
            end: resultado.clone().add(24, 'hours').subtract(1, 'seconds').toDate(),
        });
    } 


    useEffect(() => {
        if( id ){
            setFormValues({
                ...formValues,
                uid: id
            })
        }
    }, [id])


    useEffect(() => {
        if( nombre ){
            setFormValues({
                ...formValues,
                nombre
            })
        }
    }, [nombre])



    const handleConsultaFacturas = (e) => {
        e.preventDefault();
        busquedaPorFiltroCalendario(formValues);
        // console.log({ 'start': formValues.start, 'end': formValues.end});
    }



    return (

        <Grid 
            container direccion='row'
            alignItems='center' my={2}
        >
            <Grid item xs={ 12 } sm={ 4 } mt={ 2 } >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Fecha de Inicio"
                        value={ start }
                        onChange={ handleChangeStart }
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </LocalizationProvider>
            </Grid>

            <Box flex={ 1 } />

            <Grid item xs={ 12 } sm={ 4 } mt={ 2 } >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Fecha Final"
                        value={ end }
                        onChange={ handleChangeEnd }
                        minDate={ start }
                        renderInput={(params) => <TextField {...params} fullWidth /> }
                    />
                </LocalizationProvider>
            </Grid>

            <Box flex={ 1 } />

            <Grid item xs={ 12 } sm={ 3 } mt={ 2 } >
                <Button variant="contained" fullWidth onClick={ handleConsultaFacturas } >
                    Consultar
                </Button>
            </Grid>

        </Grid>


    )



}
