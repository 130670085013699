import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2";
import { granjaApi } from "../api";

import io from 'socket.io-client';
import { prepararFechaPedido } from "../helpers/prepararFecha";
import { activePedidoDelete, onAddNewPedido, onDeletePedido, onLoandingPedido, onSetActivePedido, onUpdatePedido } from "../store";

const baseSocket = process.env.REACT_APP_SOCKET_IO_URL;


let socket;


export const usePedidoStore = () => {


    const dispatch = useDispatch();
    const { pedidos, activePedido, isLoadingPedido } = useSelector( state => state.pedido );


    useEffect(() => {
        socket = io(baseSocket);
    }, []);


    const activePedidoStart = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/pedido/activar/${ uid }`);
            dispatch( onSetActivePedido( data.pedido ) );
            
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error)
        }
    }


    const activePedidoEliminar = async() => {
        dispatch( activePedidoDelete() );
    }


    const startSavingPedido = async( pedido ) => {
        try {
            if( pedido.id ){
                const { data } = await granjaApi.put(`/pedido/${ pedido.id }`, pedido );
                if( data.ok ){
                    dispatch( onUpdatePedido( data.pedido ) );
                    Swal.fire('Exitoso', 'Producto de OP Actualizado', 'success');
                }
            }else {
                const { data } = await granjaApi.post('/pedido/new', pedido );
                if( data.ok ){
                    dispatch( onAddNewPedido( data.ingreso ) );
                    Swal.fire('Exitoso', 'Ingreso de Pedido', 'success');
                }
            }

        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    } 



    const cambioCantidad = async( pedido ) => {
        try {
            const { data } = await granjaApi.post(`/pedido/cambio-cantidad/${ pedido.id }`, pedido );
            if( data.ok ){
                socket.emit('cambio-cantidad', data.pedido );
            }

        } catch (error) {
            console.log(error);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    } 




    const startLoadingPedidosOP = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/pedido/porop/${ uid }`);
            dispatch( onLoandingPedido(data.pedido) );
            
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }

    
    const startLoadingPedidos = async() => {
        try {
            const { data } = await granjaApi.get('/pedido');
            const ingresos = prepararFechaPedido(data.ingresos);
            dispatch( onLoandingPedido(ingresos) );
            
        } catch (error) {
            console.log('Error al Cargar Granjas');
            console.log(error);
        }
    }



    const deletePedido = async( uid ) => {
        try {
            const { data } = await granjaApi.delete(`/pedido/${ uid }`);
            if( data.ok ){
                dispatch( onDeletePedido( data.pedido ) );
                Swal.fire('Exitoso', 'Pedido Eliminada', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }



    const pedidoUpdateDeSocket = ( pedido ) => {
        dispatch( onUpdatePedido( pedido ) );
    }



    return {
        //Propiedades
        pedidos,
        activePedido,
        isLoadingPedido,
        //Metodos
        startSavingPedido, 
        cambioCantidad,
        activePedidoEliminar,
        startLoadingPedidos,
        startLoadingPedidosOP,
        deletePedido,
        activePedidoStart,

        // Socket
        pedidoUpdateDeSocket,
    }


}
