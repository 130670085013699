


import { Box, Toolbar } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom"
import { HomeScreen } from "../pages";




export const HomeRoutes = () => {


    return (

        <>

        {/* <SideBarScreen /> */}


        <Box
            component='main'
            sx={{ flexGrow: 1 }}
            className="todo-home"
        >

        <Toolbar />
            <Routes>

                <Route path="" element={ <HomeScreen /> } />
                {/* <Route path="galpones" element={ <GalponesScreen /> } /> */}
                


                <Route path="/*" element={ <Navigate to="/home" />} />

            </Routes>

        </Box>
        </>
    
    )



}
