
import { useEffect, useState } from 'react';

import { Autocomplete, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useBusquedas, useProductoStore } from '../../../../hooks';
import { AlertScreen } from '../../../components';
import { LayoutReturn } from '../../../layout';




export const InventarioTodasBodegas = () => {


    const { productos, startLoadingProducto } = useProductoStore();
    const { inventarios, inventarioTotalDeBodegas } = useBusquedas();
    const [value, setValue] = useState(null);



    useEffect(() => {
        if( value === null ) {
            return;
        }else {
            inventarioTotalDeBodegas(value.id);
        }
    }, [value]);


    useEffect(() => {
        startLoadingProducto();
    }, []);




    return (

        <LayoutReturn title='Ver Inventario De Productos En Bodegas' url={-1} >

            <Grid
                item
                sm={8}
                xs={12}
                direccion='row'
                alignItems='center'
                my={3}
            >
               
                <Autocomplete
                    options={ productos }
                    renderInput={ (params) => <TextField {...params} label="Buscar Producto" /> }
                    value={ value }
                    onChange={ (event, newValue) => setValue( newValue ) }
                    getOptionLabel={(option) => option.nombre}
                    fullWidth
                />

                <Box flex={ 1 } />
            </Grid>


            { ( inventarios.length > 0 ) 
            ? <TableContainer>
                <Table sx={{ minWidth: 700, }} aria-label="customized table">

                    <TableHead>
                        <TableRow>
                            <TableCell>PRODUCTO</TableCell>
                            <TableCell>STOCK</TableCell>
                            <TableCell>BODEGA</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>

                    { inventarios.map( ( inv ) => (
                        <TableRow key={ inv.id } >

                            <TableCell>{ inv.producto?.nombre }</TableCell>   
                            <TableCell>{ inv.stock }</TableCell>
                            <TableCell >{ inv.bodega?.nombre }</TableCell>

                        </TableRow>
                    ))
                    }
                    </TableBody>

                </Table>
            </TableContainer>

            : <AlertScreen title="No Hay Inventario En Bodegas"/>

            }


        </LayoutReturn>

    )

}
