

import { createSlice } from '@reduxjs/toolkit';



export const vehiculoSlice = createSlice({

    name: 'vehiculo',
    initialState: {
        isLoadingvehiculo: true,
        vehiculos: [],
        activeVehiculo: null
    },
    reducers:{
        onSetActiveVehiculo: ( state, { payload } ) => {
            state.activeVehiculo = payload;
        },
        
        onAddNewVehiculo: ( state, { payload } ) => {
            state.vehiculos.unshift( payload );
            state.activeVehiculo = null;
        },

        onUpdateVehiculo: ( state, { payload } ) => {
            state.vehiculos = state.vehiculos.map( v => {
                if( v.id === payload.id ){
                    return payload
                }else {
                    return v
                }
            });
        },

        onLoandingVehiculo: ( state, { payload = [] } ) => {
            state.isLoadingvehiculo = false;
            state.vehiculos = payload;
        },
        
        onDeleteVehiculo: ( state, { payload } ) => {
            state.vehiculos = state.vehiculos.filter( v => v.id !== payload.id );
        }
    }
});





export const {onSetActiveVehiculo, onAddNewVehiculo, onUpdateVehiculo, onLoandingVehiculo, onDeleteVehiculo } = vehiculoSlice.actions;