



export const useTotalDestares = ( destares ) => {

    // const unidadesDB = destares.reduce( (total, can) => Number(total) + (Number(can.huacales) * Number(can.avesXhuacal)), 0);
    // const pesoBrutoDB = destares.reduce( (total, can) => Number(total) + Number(can.pesoAvesHuacal), 0);
    // const totalDestareDB = destares.reduce( (total, can) => Number(total) + Number(can.destare), 0);
    // const pesoNetoDB = pesoBrutoDB - totalDestareDB;
    // const promedioEnPieDB = Math.ceil( pesoNetoDB / unidadesDB );

    // const [ totales, setTotales ] = useState({
    //     unidades: unidadesDB,
    //     pesoBruto: pesoBrutoDB,
    //     totalDestare: totalDestareDB,
    //     pesoNeto: pesoNetoDB,
    //     promedioEnPie: promedioEnPieDB
    // });
    // const { unidades, pesoBruto, totalDestare, pesoNeto, promedioEnPie } = totales;

    const unidades = destares.reduce( (total, can) => Number(total) + (Number(can.huacales) * Number(can.avesXhuacal)), 0);
    const pesoBruto = destares.reduce( (total, can) => Number(total) + Number(can.pesoAvesHuacal), 0);
    const totalDestare = destares.reduce( (total, can) => Number(total) + Number(can.destare), 0);
    const pesoNeto = pesoBruto - totalDestare;
    const promedioEnPie = Math.ceil( pesoNeto / unidades );



    // useEffect(() => {

    //     const unidades = destares.reduce( (total, can) => Number(total) + (Number(can.huacales) * Number(can.avesXhuacal)), 0);
    //     const pesoBruto = destares.reduce( (total, can) => Number(total) + Number(can.pesoAvesHuacal), 0);
    //     const totalDestare = destares.reduce( (total, can) => Number(total) + Number(can.destare), 0);
    //     const pesoNeto = pesoBruto - totalDestare;
    //     const promedioEnPie = Math.ceil( pesoNeto / unidades );

    //     setTotales({
    //         unidades,
    //         pesoBruto,
    //         totalDestare,
    //         pesoNeto,
    //         promedioEnPie
    //     });

    // }, [destares])
    
    

    return {
        unidades,
        pesoBruto,
        totalDestare,
        pesoNeto,
        promedioEnPie
    }

}
