
import { useState, useEffect } from 'react';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useAbonoStore, useModalStore } from '../../../../hooks';
import { Button, Grid, TextField, MenuItem } from '@mui/material';
import { NumberFormatCustom } from '../../../../hooks/hooks/useInputMillares';
import { useParams } from 'react-router-dom';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};




export const ModalAbonoFactura = () => {


    const { id } = useParams();
    const { modalAbono, closeModalAbono } = useModalStore();
    const { startSavingAbono } = useAbonoStore();
    const millares = NumberFormatCustom;


    const [formAbono, setFormAbono] = useState({
        valor: '',
        factura: '',
        medio: '',
        ref: '',
        observacion: '',
    });
    const { valor, medio, ref, observacion } = formAbono;

    const handleInputChange = ({ target }) => {
        setFormAbono({
            ...formAbono,
            [target.name]: target.value
        })
    }


    useEffect(() => {
        if( id ){
            setFormAbono({
                ...formAbono,
                factura: id
            });
        }
    }, [id]);


    const cerrarModalAbono = () => {
        setFormAbono({
            ...formAbono,
            valor: '',
            medio: '',
            ref: '',
            observacion: '',
        })
        closeModalAbono();
    }


    const crearAbono = async(e) => {
        e.preventDefault();
        await startSavingAbono(formAbono);
        cerrarModalAbono();
    }



    return (

        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={ modalAbono }
                onClose={ cerrarModalAbono }
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Box sx={ style }>

                    <Typography align='center' variant='h1' sx={{ mb:2, fontWeight:'bold'  }} >Crear Abono</Typography>
        
                    <form onSubmit={ crearAbono } >
                        <Grid container spacing={ 2 }>
                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    label="Valor Abonado"
                                    placeholder='Precio'
                                    fullWidth
                                    name='valor'
                                    value={ valor }
                                    onChange={ handleInputChange }
                                    InputProps={{
                                        inputComponent: millares,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField required
                                    select
                                    label="Medio de Pago"
                                    type='text'
                                    placeholder='Medio de Pago'
                                    fullWidth
                                    value={ medio }
                                    name='medio'
                                    onChange={ handleInputChange }
                                >
                                    <MenuItem value=''>
                                        Seleccione Medio de Pago
                                    </MenuItem>
                                    <MenuItem value="Nequi" >
                                        Nequi
                                    </MenuItem>
                                    <MenuItem value="Daviplata" >
                                        Daviplata
                                    </MenuItem>
                                    <MenuItem value="Bancolombia" >
                                        Bancolombia
                                    </MenuItem>
                                    <MenuItem value="Davivienda" >
                                        Davivienda
                                    </MenuItem>
                                    <MenuItem value="Banco Bogota" >
                                        Banco Bogota
                                    </MenuItem>
                                    <MenuItem value="Banco Agrario" >
                                        Banco Agrario
                                    </MenuItem>
                                    <MenuItem value="Efectivo" >
                                        Efectivo
                                    </MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField 
                                    label='Referencia'
                                    type='text'
                                    multiline
                                    placeholder='Referencia'
                                    fullWidth
                                    name='ref'
                                    value={ ref }
                                    onChange={ handleInputChange }
                                />
                            </Grid>
                            
                            <Grid item xs={ 12 } sm={ 12 } mt={ 2 } >
                                <TextField 
                                    label='Observacion'
                                    type='text'
                                    multiline
                                    placeholder='Observacion'
                                    fullWidth
                                    name='observacion'
                                    value={ observacion }
                                    onChange={ handleInputChange }
                                />
                            </Grid>

                            <Grid container  sx={{ mt:2 }}  direccion='column' justifyContent='center'  >
                                <Grid item xs={ 12 } sm={ 8 } display='flex' justifyContent='center'>
                                    <Button variant='contained' fullWidth type='submit'>
                                        Añadir Abono
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    );

}