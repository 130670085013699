

import { useIngresoRaizStore } from "../../../../hooks";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";







export const TablaResgistroIngresoRaiz = () => {

    const { ingresos } = useIngresoRaizStore();




    return (

        <TableContainer>
            <Table sx={{ minWidth: 700, }} aria-label="customized table">

                <TableHead >
                    <TableRow>
                        <TableCell>FACTURA</TableCell>
                        <TableCell>PRODUCTO</TableCell>
                        <TableCell>CANTIDAD</TableCell>
                        <TableCell>CANTIDAD INICIAL</TableCell>
                        <TableCell>CANTIDAD FINAL</TableCell>
                        <TableCell>FECHA DE INGRESO</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                { ingresos.map( ( inv ) => (
                    <TableRow key={ inv.id } >

                        <TableCell>{ inv.factura }</TableCell>
                        <TableCell>{ inv.producto?.nombre }</TableCell>   
                        <TableCell>{ inv.cantidad }</TableCell>
                        <TableCell>{ inv.ingresoInicial }</TableCell>
                        <TableCell>{ inv.ingresoFinal }</TableCell>
                        <TableCell>{ inv.createdAt }</TableCell>

                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer> 



    )




}
