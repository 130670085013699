

import { createSlice } from '@reduxjs/toolkit';



export const facturaSlice = createSlice({
    name: 'factura',
    initialState: {
        isLoadingFactura: true,
        facturas: [],
        activeFactura: null
    },
    reducers:{

        onSetActiveFactura: ( state, { payload } ) => {
            state.activeFactura = payload;
        },

        activeFacturaDelete: ( state ) => {
            state.activeFactura = null;
        },

        limpiarLoandingFacturas: ( state ) => {
            state.facturas = null;
        },
        
        onAddNewFactura: ( state, { payload } ) => {
            state.facturas.push( payload );
            state.activeFactura = null;
        },

        onUpdateFactura: ( state, { payload } ) => {
            state.facturas = state.facturas.map( factura => {
                if( factura.id === payload.id ){
                    return payload
                }
                return factura
            });
        },

        onLoandingFactura: ( state, { payload = [] } ) => {
            state.isLoadingFactura = false;
            state.facturas = payload;
        },

        onDeletefactura: ( state, { payload } ) => {
            state.facturas = state.facturas.filter( factura => factura.id !== payload.id );
        }
    }
});





export const { onSetActiveFactura, activeFacturaDelete, onAddNewFactura, onUpdateFactura, onLoandingFactura, onDeletefactura, limpiarLoandingFacturas } = facturaSlice.actions;