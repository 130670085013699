import { useDispatch, useSelector } from "react-redux"
import { granjaApi } from "../api";

import { onLoandingPlanilla, onSetActivePlanilla, onUpdatePlanilla } from "../store";
import Swal from 'sweetalert2';



export const usePlanillaStore = () => {


    const dispatch = useDispatch();
    const { planillas, activePlanilla } = useSelector( state => state.planilla );


    const setActivePlanilla = async( uid ) => {
        try {
            const { data } = await granjaApi.get(`/planilla/${ uid }`);
            if( data.ok ){
                dispatch( onSetActivePlanilla( data.planilla ) );
            }else {
                Swal.fire('Error', 'Algo Salio Mal', 'error');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    const startLoadingPlanillaLoteGalpon = async(uid) => {
        try {
            const { data } = await granjaApi.get(`/planilla/todos/${ uid }`);
            dispatch( onLoandingPlanilla( data.planilla ) );
        } catch (error) {
            console.log('Error al Cargar Planillas');
            console.log(error);
        }
    }


    const startLoadingPlanillaCliente = async(uid) => {
        try {
            const { data } = await granjaApi.get(`/planilla/xcliente/${ uid }`);
            dispatch( onLoandingPlanilla( data.planilla ) );
        } catch (error) {
            console.log('Error al Cargar Planillas');
            console.log(error);
        }
    }


    const updatePlanillaMermas = async(planilla) => {
        try {
            const { data } = await granjaApi.put(`/planilla/mermas`, planilla );
            if( data.ok ){
                Swal.fire('Exitoso', 'Merma Creada Con Exito', 'success');
                dispatch( onUpdatePlanilla( data.planillaDB ) );
                if( activePlanilla.id === data.planillaDB.id ){
                    dispatch( onSetActivePlanilla( data.planillaDB ) );
                }
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    const updatePlanillaBloquearMermas = async( uid ) => {
        try {
            const { data } = await granjaApi.put(`/planilla/blomermas/${ uid }`);
            if( data.ok ){
                Swal.fire('Exitoso', 'Mermas Bloqueadas Con Exito', 'success');
                dispatch( onUpdatePlanilla( data.planilla ) );
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }



    const deletePlanilla = async( planilla ) => {
        try {
            const { data } = await granjaApi.delete(`/planilla/${ planilla.id }`);
            if( data.ok ){
                console.log( data);
                // dispatch( onDeletePlanilla( data.planillaDB ) );
                Swal.fire('Exitoso', 'Planilla Eliminada', 'success');
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.msg, 'error');
            console.log(error);
        }
    }


    

    return {
        //Propiedades
        planillas,
        activePlanilla,

        //Metodos
        setActivePlanilla,
        startLoadingPlanillaLoteGalpon,
        updatePlanillaMermas,
        deletePlanilla,
        updatePlanillaBloquearMermas,
        startLoadingPlanillaCliente,
    }


}
