
import { useEffect, useState } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { separadorMillares } from '../../../hooks/hooks/useMillares';
import { usePlanillaStore } from '../../../hooks';
import { dejarTresDecimales } from '../../../helpers/dejarDecimales';



export const TablaSubTotalesDePlanillas = () => {


    const { activePlanilla } = usePlanillaStore();



    const [totales, setTotales] = useState({
        pesoBruto: '',
        totalDestare: '',
        pesoNeto: '',
        promedioEnPie: '',
        valorFactura: '',
        total: '',
    });
    const { pesoBruto, totalDestare, pesoNeto, promedioEnPie, valorFactura, total } = totales;


    useEffect(() => {
        if( activePlanilla ){
            const pesoBruto = activePlanilla.items.reduce( (total, can) => Number(total) + Number(can.pesoAvesHuacal), 0);
            const totalDestare = activePlanilla.items.reduce( (total, can) => Number(total) + Number(can.destare), 0);
            const pesoNeto = pesoBruto - totalDestare;

            setTotales({
                pesoBruto,
                totalDestare,
                pesoNeto,
                promedioEnPie: pesoNeto / activePlanilla.cantidad,
                valorFactura: activePlanilla.valorKilo * pesoNeto,
                total: activePlanilla.saldoAnterior + (activePlanilla.valorKilo * pesoNeto),
            })
        }
    }, [activePlanilla]);





    return (

        <>
        <Typography variant='h5' sx={{ mb:1, mt:5, fontWeight:'bold', textAlign:'center' }} >SUBTOTALES</Typography>

        <TableContainer sx={{mb:3}} >
            <Table>
                <TableHead className='verde'>
                    <TableRow>
                        <TableCell><Typography variant="body1">PESO BRUTO</Typography></TableCell>   
                        <TableCell><Typography variant="body1">TOTAL DESTARE</Typography></TableCell>
                        <TableCell><Typography variant="body1">PESO NETO</Typography></TableCell>
                        <TableCell><Typography variant="body1">PROMEDIO EN PIE</Typography></TableCell>
                        <TableCell><Typography variant="body1">VALOR FACTURA</Typography></TableCell> 
                        <TableCell><Typography variant="body1">SALDO ANTERIOR</Typography></TableCell> 
                        <TableCell><Typography variant="body1">TOTAL</Typography></TableCell> 
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell><Typography variant="body2">{ dejarTresDecimales(pesoBruto) }Kg</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ dejarTresDecimales(totalDestare) }Kg</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ dejarTresDecimales(pesoNeto) }Kg</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ dejarTresDecimales(promedioEnPie) }Kg</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ `$${separadorMillares(valorFactura)}` }</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ `$${separadorMillares(activePlanilla.saldoAnterior)}` }</Typography></TableCell>   
                        <TableCell><Typography variant="body2">{ `$${separadorMillares(total)}` }</Typography></TableCell> 
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>


        </>


    )


}
