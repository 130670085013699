

import { createSlice } from '@reduxjs/toolkit';



export const inventarioSlice = createSlice({
    name: 'inventario',
    initialState: {
        isLoadingInventario: true,
        inventarios: [],
        activeInventario: null
    },
    reducers:{
        onSetActiveInventario: ( state, { payload } ) => {
            state.activeInventario = payload;
        },

        onSetClearListaInventario: ( state ) => {
            state.inventarios = [];
        },

        onSetEliminarInventario: ( state ) => {
            state.activeInventario = null;
        },
        
        onAddNewInventario: ( state, { payload } ) => {
            state.inventarios = state.inventarios.map( inventario => {
                if( inventario.id === payload.id ){
                    return payload
                }
                return inventario
            });
            state.activeInventario = null;
        },


        onUpdateInventario: ( state, { payload } ) => {
            // state.inventarios = state.inventarios.map( inventario => {
            //     if( inventario.id === payload.id ){
            //         return payload
            //     }
            //     return inventario
            // });
            state.inventarios = payload;
        },


        // onLoandingInventario: ( state, { payload = [] } ) => {
        //     state.isLoadingInventario = false;
        //     // state.inventarios = payload;
        //     payload.forEach( inventario => {
        //         const exists = state.inventarios.some( dbInventario => dbInventario.id === inventario.id );
        //         if( !exists ){
        //             state.inventarios.push( inventario );
        //         }
        //     })
        // },


        onLoandingInventario: ( state, { payload = [] } ) => {
            state.isLoadingInventario = false;
            state.inventarios = payload;
        },

        onDeleteInventario: ( state, { payload } ) => {
            // if( state.activeInventario ){
                state.inventarios = state.inventarios.filter( inventario => inventario.id !== payload.id );
            //     state.activeInventario = null;
            // }
        }
    }
});





export const { onSetActiveInventario, onAddNewInventario, onUpdateInventario, onLoandingInventario, onDeleteInventario, onSetEliminarInventario, onSetClearListaInventario } = inventarioSlice.actions;