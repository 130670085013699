import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";


import { useLoteStore, useModalImgStore } from "../../../../hooks";
import { LayoutReturn } from "../../../layout";
import { ModalDistriPollos, DistribucionLote } from "../components";
import { separadorMillares } from "../../../../hooks/hooks";







export const InfomacionLote = () => {


    const { id } = useParams();
    const navigate = useNavigate();

    const { openModalDistribucion } = useModalImgStore();
    const { activeLote,  setActiveLote } = useLoteStore();
// console.log(activeLote);

    
    useEffect(() => {
        if( id ){
            setActiveLote( id );
        }
    }, [id]);



    const abrirModalDistri = () => {
        openModalDistribucion();
    }
    


    return (

        
        <LayoutReturn title='Información del Lote' url='/admin/list-lotes' >

            <TableContainer>
                <Table sx={{ minWidth: 700, }}>

                <TableHead>
                    <TableRow>
                    <TableCell>LOTE</TableCell>
                    <TableCell>INCUBADORA</TableCell>
                    <TableCell>CANTIDAD TOTAL</TableCell>
                    <TableCell>CANTIDAD MACHOS</TableCell>
                    <TableCell>CANTIDAD HEMBRAS</TableCell>
                    <TableCell>FECHA INICIO</TableCell>
                    </TableRow>
                </TableHead>

                { activeLote && (
                <TableBody>
                    <TableRow key={ activeLote.id } >
                        <TableCell>{ activeLote.lote }</TableCell>
                        <TableCell>{ activeLote.encubadora?.nombre }</TableCell>
                        <TableCell>{ separadorMillares(activeLote.cantidadTotal) }</TableCell>
                        <TableCell>{ separadorMillares(activeLote.machos) }</TableCell>
                        <TableCell>{ separadorMillares(activeLote.hembras) }</TableCell>
                        <TableCell>{ activeLote.createdAt }</TableCell>
                    </TableRow>
                </TableBody>
                )}

                </Table>
            </TableContainer>

            
            <Grid item sm={5} md={4} mt={5} >
                <Button variant='contained' fullWidth type='submit'
                        onClick={ abrirModalDistri }
                >
                    Distribuir Pollos
                </Button>
            </Grid>

            <Grid container direccion='row' mt={5} spacing={2}
            >
                <DistribucionLote />
            </Grid>
            

            <ModalDistriPollos />

            
        </LayoutReturn>


    )



}
