

import { Box, Grid, Typography } from "@mui/material"
import { useAuthStore } from "../../../../hooks";
import { TarjetasPersonales } from "../components/TarjetasPersonales";






export const DashboardScreen = () => {


  const { user } = useAuthStore();



  return (


    <Grid container >

        <Grid container direccion='row' alignItems='center'>

            <Box flex={ 1 } />

            <Typography variant='h1' sx={{ mt:3 }}>Tu Cuenta</Typography>

            <Box flex={ 1 } />

        </Grid>



        <TarjetasPersonales />


    </Grid>




  )



}
