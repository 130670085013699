import { useNavigate, useParams } from 'react-router-dom';

import { Box, SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import { useModalImgStore, useModalStore } from '../../../hooks';


export const BotonesOpcionales = ({ pasarDatos }) => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { openModalMermas } = useModalImgStore();
    const { openModalAbonoPlanilla } = useModalStore();



    const agregarMermas = () => {
        openModalMermas();
    }

    const abrirModalPlanilla = () => {
        openModalAbonoPlanilla();
    }


    const verAbonosPlanilla = () => {
        navigate(`/admin/abonosxplanilla/${ id }`);
    }

    const imprimir = () => {
        pasarDatos();
    }


    const actions = [
        { icon: <MonetizationOnIcon />, name: 'Crear Abono', accion: abrirModalPlanilla },  
        { icon: <FormatListNumberedIcon />, name: 'Ver Abonos', accion: verAbonosPlanilla },
        { icon: <RemoveCircleOutlineIcon />, name: 'Crear Mermas', accion: agregarMermas },
        { icon: <SimCardDownloadIcon />, name: 'Descargar', accion: imprimir },
    ];
    


    return (

        <Box sx={{ transform: 'translateZ(0px)' }}>
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', top:-40, right: 16, }}
                icon={<SpeedDialIcon />}
                direction='down'
            >
                {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={ action.accion }
                />
                ))}
            </SpeedDial>
        </Box>

    )




}
