
import { usePlanillaStore } from "../../../hooks";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { separadorMillares } from "../../../hooks/hooks/useMillares";
import moment from "moment";




export const TablaInfoPersonaPlanilla = () => {


    const { activePlanilla } = usePlanillaStore();



    return (


        <>

            <Typography variant='h5' sx={{ mb:1, mt:5, fontWeight:'bold', textAlign:'center' }} >INFORMACIÓN GENERAL</Typography>

            <TableContainer>
                <Table>
                    <TableHead className="amarillo">
                        <TableRow>
                            <TableCell><Typography variant="body1">#PLANILLA</Typography></TableCell>
                            <TableCell><Typography variant="body1">CIENTE</Typography></TableCell>
                            <TableCell><Typography variant="body1">CANTIDAD</Typography></TableCell>
                            <TableCell><Typography variant="body1">VALOR X KILO</Typography></TableCell>   
                            <TableCell><Typography variant="body1">FECHA</Typography></TableCell>   
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell><Typography variant="body2">{ activePlanilla.numero }</Typography></TableCell>   
                            <TableCell><Typography variant="body2"> { activePlanilla.cliente?.nombre }</Typography></TableCell>   
                            <TableCell><Typography variant="body2"> { activePlanilla.cantidad }</Typography></TableCell>   
                            <TableCell><Typography variant="body2"> { `$${separadorMillares(activePlanilla.valorKilo)}` }</Typography></TableCell>   
                            <TableCell><Typography variant="body2"> { moment(activePlanilla.createdAt).format('LL') }</Typography></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer>
                <Table>
                    <TableHead className="amarillo">
                        <TableRow>
                            <TableCell><Typography variant="body1">CONDUCTOR</Typography></TableCell>
                            <TableCell><Typography variant="body1">PLACA</Typography></TableCell>
                            <TableCell><Typography variant="body1">PESADOR</Typography></TableCell>
                            <TableCell><Typography variant="body1">HORA INICIO</Typography></TableCell>   
                            <TableCell><Typography variant="body1">HORA FINAL</Typography></TableCell>   
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell><Typography variant="body2">{ activePlanilla.conductor?.nombre }</Typography></TableCell>   
                            <TableCell><Typography variant="body2">{ activePlanilla.cantidad }</Typography></TableCell>  
                            <TableCell><Typography variant="body2">{ activePlanilla.pesador?.nombre }</Typography></TableCell>   
                            <TableCell><Typography variant="body2">{ activePlanilla.horaInicio }</Typography></TableCell>   
                            <TableCell><Typography variant="body2">{ activePlanilla.horaFinal }</Typography></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>



    )





}
