
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';

import { AdminLayout } from "../../../layout/AdminLayout";
import {  useFacturaStore } from "../../../../hooks";
import { TablaDeFacturas } from "../components";



export const FacturasScreen = () => {


    const navigate = useNavigate();
    const { startLoadingFacturas, limpiarTodasLasFacturas } = useFacturaStore();


    useEffect(() => {
        startLoadingFacturas();
    }, [] );



    const facturasArchivadas = () => {
        navigate(`/admin/fact-archi`);
    }



    const filtrosFactura = () => {
        limpiarTodasLasFacturas();
        navigate(`/admin/fact-filtro`);
    }


    return (

        <AdminLayout >

            <Grid 
                container
                direccion='row'
                alignItems='center'
                mb={4} 
            >

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ filtrosFactura }
                >
                    <SearchIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h1'>Facturas</Typography>


                <Box flex={ 1 } />

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'primary.main',
                        ':hover': { backgroundColor: 'primary.main', opacity: 0.3 },
                    }}
                    onClick={ facturasArchivadas }
                >
                    <SaveIcon />
                </IconButton>
            </Grid>


            <TablaDeFacturas />


        </AdminLayout>

    )


}
