
import { useNavigate } from 'react-router-dom';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AddOutlined } from "@mui/icons-material";

import { useModalImgStore } from '../../hooks';





export const DescuentosPorGalponLote = ({ children, title, url }) => {

    const navigate = useNavigate();
    const { openModalDescuentos } = useModalImgStore();



    const regresar = () => {
        navigate(url);
    }


    const agregarDescuento = () => {
        openModalDescuentos();
    }



    return (

        <Grid container sx={{ minHeight:'50vh', padding:4, display:'flex', justifyContent:'center' }} >

            <Grid container direccion='row' alignItems='center' mb={4} >
                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'error.main',
                        ':hover': { backgroundColor: 'error.main', opacity: 0.3 },
                    }}
                    onClick={ regresar }
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box flex={ 1 } />

                <Typography variant='h1' >{ title }</Typography>

                <Box flex={ 1 } />

                <IconButton
                    sx={{
                        color: '#fff',
                        backgroundColor: 'primary.main',
                        ':hover': { backgroundColor: 'primary.main', opacity: 0.3 },
                    }}
                    onClick={ agregarDescuento }
                >
                    <AddOutlined />
                </IconButton>
            </Grid>



            { children }



        </Grid>


    )


    
}
