

import { createSlice } from '@reduxjs/toolkit';



export const notaSlice = createSlice({
    name: 'nota',
    initialState: {
        isLoadingNota: true,
        notas: [],
        activeNota: null
    },
    reducers:{

        onSetActiveNota: ( state, { payload } ) => {
            state.activeNota = payload;
        },

        activeNotaDelete: ( state ) => {
            state.activeNota = null;
        },
        
        onAddNewNota: ( state, { payload } ) => {
            state.notas.push( payload );
            state.activeNota = null;
        },

        onUpdateNota: ( state, { payload } ) => {
            state.notas = state.notas.map( nota => {
                if( nota.id === payload.id ){
                    return payload
                }
                return nota
            });
        },

        onLoandingNota: ( state, { payload = [] } ) => {
            state.isLoadingNota = false;
            state.notas = payload;
        },

        onDeleteNota: ( state, { payload } ) => {
            state.notas = state.notas.filter( nota => nota.id !== payload.id );
        }
    }
});





export const { onSetActiveNota, onAddNewNota, onUpdateNota, onLoandingNota, onDeleteNota, activeNotaDelete } = notaSlice.actions;